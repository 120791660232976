import { UseQueryOptions } from '@tanstack/react-query';

import { GetAllCountriesQuery, useGetAllCountriesQuery } from '../__generated__/gateway-graphql';

/**
 * Ronnie asked for a workaround for non-nullable in FE,
 * it should not be possible to have null countries so this is just a hack
 * on the types :/
 */
export type Countries = NonNullable<GetAllCountriesQuery['countries']>;

export type CountryItem = NonNullable<Countries[0]>;

type CountriesData = {
    countries: Array<CountryItem>
};

export function useGetCountriesQuery (
    options?: UseQueryOptions<GetAllCountriesQuery, unknown, CountriesData>
) {
    return useGetAllCountriesQuery<CountriesData>(undefined, options);
}
