import { theme as baseMathleticsTheme } from '@3plearning/chakra-mathletics-theme';
import { extendTheme } from '@chakra-ui/react';

import { Link } from './components/link';
import { breakpoints } from './foundations/breakpoints';
import * as app from './foundations/colors';
import { layerStyles } from './foundations/layer-styles';
import { shadows } from './foundations/shadows';
import styles from './foundations/styles';
import { textStyles } from './foundations/text-styles';

/**
 * Extend the base shared theme with the custom colors and variants in portal.
 *
 * @see https://chakra-ui.com/docs/theming/customize-theme
 */

const theme = extendTheme(baseMathleticsTheme, {
    styles,
    colors: { app },
    components: { Link },
    layerStyles,
    textStyles,
    breakpoints,
    shadows
});

export default theme;
