import { useSize } from '@chakra-ui/react-use-size';
import React, { type PropsWithChildren, useRef } from 'react';

import { ContentfulUrlButton } from '../../components/contentful-url-button/contentful-url-button';
import { __PROD_ENV__ } from '../../env-config';
import { ModalMathleticsLogo } from '../../page/page-modal/modal-mathletics-logo';
import { PageModal } from '../../page/page-modal/page-modal';
import backgroundsSrc from './assets/success-backgrounds.svg';
import { ConfettiCanvas } from './confetti-canvas/confetti-canvas';
import { usePurchaseSuccess } from './use-purchase-success';

type Props = {
    headingText: string
    bodyText: string
    buttonText: string
};

/**
 * Story https://mathletics.atlassian.net/browse/FRAME-5595
 */
export function SuccessView ({
    bodyText, headingText, buttonText
}: Props) {
    usePurchaseSuccess();

    const elementRef = useRef<HTMLDivElement>(null);
    const dimensions = useSize(elementRef);

    return (
        <PageModal
            ref={elementRef}
            data-testid={'subscription-create-purchase-success-route'}
            headingText={headingText}
            bodyText={bodyText}
            Header={<ModalMathleticsLogo zIndex={2} />}
            Body={<GoToDashboardButton>
                {buttonText}
            </GoToDashboardButton>}
            backgroundProps={{
                src: backgroundsSrc,
                top: '-947px',
                left: '-991px',
                minWidth: '2330px',
                minHeight: '2330px'
            }}
        >

            {dimensions && <ConfettiCanvas
                width={dimensions.width}
                height={dimensions.height}
            />}
        </PageModal>
    );
}

function GoToDashboardButton ({ children }: PropsWithChildren) {
    const isProduction = __PROD_ENV__;

    return (
        <ContentfulUrlButton
            height={'54px'}
            size={'lg'}
            width={'100%'}
            contentfulUrlName={isProduction ? 'Sign In' : 'Sign In QA'}
            zIndex={2}
        >
            {children}
        </ContentfulUrlButton>
    );
}
