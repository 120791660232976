import React from 'react';

import {
    ExistingCustomerCheckoutPage
} from '../../../../checkout/existing-customer/existing-customer-checkout-page/existing-customer-checkout-page';
import { ErrorBoundary } from '../../../error/errors/error-boundary';

export function AddCheckoutRoute () {
    return (
        <ErrorBoundary context={'AddCheckoutRoute'}>
            <ExistingCustomerCheckoutPage
                successRedirectTo={'/subscription/add/success'}
            />
        </ErrorBoundary>
    );
}
