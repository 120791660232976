import { useMemo } from 'react';

import type { ProductItemWithText } from '../api/gateway/onboarding-data-contract';
import { useTPrice } from '../i18n/t-price/use-t-price';
import { useAppSelector } from '../store/store';
import { mapProductItemText } from './use-plans-data-with-text-query';

type Options = {
    currencyCode: string
    countryCode: string
    excludeTrials?: boolean
    enabled?: boolean
    childrenCount: number
};

/**
 * To retrieve the required plan data from redux store
 * and all aggregate the required marketing copy text from Contentful.
 */
export function useQuoteAndPricingDataWithText (options: Options) {
    const { currencyCode, childrenCount } = options;
    const tPrice = useTPrice();
    const quoteAndPricingData = useAppSelector(
        state => state.subscription.quoteAndPricingData
    );
    const location = useAppSelector(
        state => state.subscription.location
    );
    const productItems: ProductItemWithText = quoteAndPricingData?.productItem as any;
    const countryCode = location?.countryCode || 'AUS';
    const productItemsData = useMemo(() => {
        return mapProductItemText(
            countryCode,
            currencyCode,
            productItems,
            childrenCount,
            tPrice
        );
    }, [
        tPrice,
        productItems,
        countryCode,
        currencyCode,
        childrenCount
    ]);
    const data: null | any = {
        plan: {
            productItems: productItemsData
        }
    };

    return {
        data
    };
}

