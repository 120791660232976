import { SelectControl, TextInputControl } from '@3plearning/chakra-mathletics-theme';
import { chakra, Skeleton, Stack } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React, { type PropsWithChildren, useEffect } from 'react';

import { useEnvConfig } from '../../../../../../env-config';
import { type LocationData, useActiveLocation } from '../../../../../../location/use-active-location';
import { useLocationField } from './hooks/use-location-field';

export function LocationField ({ onLoadingChange }: { onLoadingChange: (isLoading: boolean) => any }) {
    const {
        data,
        isLoading
    } = useActiveLocation();

    if (isLoading || !data) {
        return (
            <LocationFieldSkeleton />
        );
    }

    return (
        <LocationFieldView
            data={data}
            onLoadingChange={onLoadingChange}
        />
    );
}

type Props = {
    data: LocationData
    onLoadingChange: (isLoading: boolean) => any
};

export function LocationFieldView ({ data: locationData, onLoadingChange }: Props) {
    const {
        shouldShowStates,
        allCountries,
        states,
        defaultCountry,
        defaultState,
        activeCountry,
        setCountry,
        setState,
        isLoading,
        handleOnBlur,
        inputRef,
        inputTextProps,
        inputProps
    } = useLocationField(locationData);
    const { PARENT_GATEWAY_API_URL } = useEnvConfig();

    useEffect(() => {
        onLoadingChange(isLoading);
    }, [isLoading, onLoadingChange]);

    return (
        <>
            <FieldRow>
                <SelectControl
                    id={'country'}
                    data-testid={'country'}
                    labelText={'Country'}
                    isRequired={true}
                    controlProps={{
                        onChange: setCountry,
                        defaultValue: defaultCountry,
                        isDisabled: isLoading
                    }}
                >
                    {allCountries.map(country => (
                        <option
                            key={country.countryCode}
                            value={country.countryCode}
                        >
                            {country.countryName}
                        </option>
                    ))}
                </SelectControl>

                {states && shouldShowStates ? <SelectControl
                    id={'state'}
                    data-testid={'state'}
                    controlProps={{
                        onChange: setState,
                        defaultValue: defaultState,
                        isDisabled: isLoading
                    }}
                    labelText={activeCountry.statesName || 'State'}
                    isRequired={true}
                    placeholder={`Choose ${activeCountry.statesName || 'State'}`}
                >
                    {states.map(state => (
                        <option
                            key={state}
                            value={state}
                        >
                            {state}
                        </option>
                    ))}
                </SelectControl> : activeCountry.countryCode === 'USA' ?
                    <TextInputControl
                        controlProps={{
                            type: 'number'
                        }}
                        mb={{
                            mobile: '24px',
                            desktop: 0
                        }}
                        mr={{
                            mobile: 0,
                            desktop: '20px'
                        }}
                        data-testid={'zipcode-input'}
                        id={'zipcode'}
                        ref={inputRef}
                        labelText={t`ZIP Code`}
                        isRequired={true}
                        maxWidth={{
                            mobile: '100%',
                            subscriptionInclusionsTablet: '48%'
                        }}
                        onBlur={() => handleOnBlur({ url: PARENT_GATEWAY_API_URL })}
                        {...inputTextProps}
                        {...inputProps}
                    /> :
                    <chakra.span w={'100%'} />
                }
            </FieldRow>
        </>
    );
}

function FieldRow ({ children }: PropsWithChildren<any>) {
    return (
        <Stack
            direction={{
                mobile: 'column',
                fieldRowTablet: 'row'
            }}
            spacing={{
                mobile: '24px',
                tablet: '32px'
            }}
            w={'100%'}
        >
            {children}
        </Stack>
    );
}

function LocationFieldSkeleton () {
    return <Skeleton h={'56px'} w={'100%'} />;
}
