/* eslint-disable max-len */
import {
    chakra, ChakraProps, OmitCommonProps
} from '@chakra-ui/react';
import React from 'react';

type Props =
    & JSX.IntrinsicAttributes
    & OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof ChakraProps>
    & ChakraProps & { as?: 'svg' | undefined };

export function QuoteIcon (props: Props) {
    return (
        <chakra.svg
            width={'64px'}
            height={'65px'}
            viewBox={'0 0 64 65'}
            fill={'none'}
            sx={{
                position: 'absolute',
                top: {
                    mobile: '11px',
                    testimonialsTablet: '14px',
                    desktop: '24px'
                },
                left: {
                    desktop: '40px',
                    testimonialsTablet: '16px',
                    mobile: '18px'
                }
            }}
            {...props}
        >
            <path
                fillRule={'evenodd'}
                clipRule={'evenodd'}
                d={'M28.5399 45.7743C28.5399 52.1903 23.0319 57.5 15.8476 57.5C7.94485 57.5 1 51.5266 1 40.6858C1 27.1903 9.38172 14.8009 20.3977 7.5L29.2584 14.1372C21.5951 18.7832 14.6502 27.6327 13.9318 34.7124C14.0058 34.7124 14.217 34.6913 14.5089 34.6621C15.1616 34.5968 16.2176 34.4911 17.045 34.4911C23.7504 34.4911 28.5399 39.1372 28.5399 45.7743ZM62.9239 45.7743C62.9239 52.1903 57.1765 57.5 49.9921 57.5C42.0894 57.5 35.1445 51.5266 35.1445 40.6858C35.1445 27.1903 43.5263 14.8009 54.5422 7.5L63.4029 14.1372C55.7396 18.7832 49.0342 27.6327 48.0763 34.7124C48.1503 34.7124 48.3615 34.6913 48.6534 34.6621C49.3061 34.5968 50.3622 34.4911 51.1895 34.4911C57.8949 34.4911 62.9239 39.1372 62.9239 45.7743Z'}
                fill={'#EDF1F4'}
            />
        </chakra.svg>
    );
}
