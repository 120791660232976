import { LoaderDots } from '@3plearning/chakra-mathletics-theme';
import { Center, type CenterProps } from '@chakra-ui/react';
import React from 'react';

export function LoadingView (props: CenterProps) {
    return (
        <Center minHeight={'300px'} w={'100%'} {...props}>
            <LoaderDots />
        </Center>
    );
}
