import { chakra, Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React from 'react';

import { ContentfulLink } from '../../../../../../../components/contentful-link/contentful-link';

type CheckBoxFieldProps = {
    setCheckboxValues: (value: any) => any
    checkboxValues: string[]
};

export function CheckBoxField ({ checkboxValues, setCheckboxValues }: CheckBoxFieldProps) {
    return (
        <CheckboxGroup
            defaultValue={checkboxValues}
            onChange={(value) => {
                setCheckboxValues(value);
            }}
        >
            <Stack align={'start'} direction={['column']} spacing={[2, 4]}>
                <Checkbox
                    value={'termsAndConditionsCookie'}
                    alignItems={'baseline'}
                    verticalAlign={'super'}
                >
                    <chakra.span
                        mb={{
                            mobile: '22px !important',
                            tablet: '30px !important'
                        }}
                        sx={{
                            color: 'neutral.700',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            lineHeight: '20px',
                            letterSpacing: '-0.15px',
                            verticalAlign: 'text-top'
                        }}
                    >
                        {t`I agree to 3P Learning’s`}
                    </chakra.span>

                    <ContentfulLink
                        contentName={'Terms and Conditions'}
                        isExternal={true}
                        sx={{
                            fontSize: '13px',
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            letterSpacing: '-0.15px',
                            verticalAlign: 'text-top'
                        }}
                    >
                        Terms and Conditions
                    </ContentfulLink>

                    <chakra.span
                        sx={{
                            color: 'neutral.700',
                            fontSize: '14px',
                            verticalAlign: 'text-top'
                        }}
                    >
                        {' & '}
                    </chakra.span>

                    <ContentfulLink
                        contentName={'Privacy Policy'}
                        isExternal={true}
                        sx={{
                            fontSize: '13px',
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            letterSpacing: '-0.15px',
                            verticalAlign: 'text-top'
                        }}
                    >
                        Privacy Policy
                    </ContentfulLink>
                </Checkbox>

                <Checkbox
                    value={'marketingOptInCookie'}
                    alignItems={'baseline'}
                >
                    <chakra.span
                        color={'neutral.700'}
                        mb={{
                            mobile: '22px !important',
                            tablet: '30px !important'
                        }}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'normal',
                            lineHeight: '20px',
                            letterSpacing: '-0.15px',
                            verticalAlign: 'super'

                        }}
                    >
                        {t`Marketing Opt In`}
                    </chakra.span>
                </Checkbox>
            </Stack>
        </CheckboxGroup>
    );
}
