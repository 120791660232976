import {
    TextInputControl, TextInputControlProps
} from '@3plearning/chakra-mathletics-theme';
import { useField } from 'formik';
import React from 'react';

export type TextInputControlError = {
    helperText: string
    props?: Omit<TextInputControlProps, 'id' | 'helperText'>
};

type Props =
    & TextInputControlProps
    & {
    /**
     * This object is a record of custom errors to handle scenarios
     * such as `isUserWarning` or `isWarning` which come from validation
     * "errors" but are treated as custom error types for UX.
     */
    customErrors?: Record<string, TextInputControlError>
};

export function TextInputControlField ({ customErrors, ...props }: Props) {
    const [field, meta] = useField({
        type: 'text',
        id: props.id,
        name: props.id
    });
    const { defaultValue: _, ...existingProps } = props.controlProps || {};
    const {
        onChange,
        ...controlFieldProps
    } = field;
    const controlProps = {
        ...existingProps,
        ...controlFieldProps,
        onChange
    };
    const isInvalid = !!meta.error;
    const helperText = meta.error;

    let propsOverride: Omit<TextInputControlProps, 'id'> | null = null;

    if (customErrors && meta?.error && !!customErrors[meta.error]) {
        const customError = customErrors[meta.error];

        propsOverride = {
            helperText: customError.helperText,
            ...customError.props
        };
    }

    return (
        <TextInputControl
            {...props}
            data-testid={props['data-testid'] || props.id}
            controlProps={controlProps}
            isInvalid={isInvalid}
            helperText={helperText}
            {...propsOverride}
        />
    );
}
