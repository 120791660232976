import { Box, chakra, useRadio } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import { StatusEnum } from '../../../__generated__/gateway-graphql';

type SavedCardsRadioButtonProps = {
    status?: string | null
    index?: number
} & PropsWithChildren;

export function SavedCardsRadioButton ({ status = null, index, ...props }: SavedCardsRadioButtonProps) {
    const { children, ...radioProps } = props;
    const {
        state, getInputProps, getCheckboxProps, htmlProps, getLabelProps
    } = useRadio(radioProps);

    return (
        <Box w={'100%'} display={'flex'} flexDirection={'row'} marginBottom={'16px'}>
            <chakra.label
                display={'flex'}
                alignItems={'center'}
                width={'100%'}
                {...htmlProps}
                cursor={(status === StatusEnum.Expired) ? '' : 'pointer'}
                {...getLabelProps}
            >
                <chakra.input
                    {...getInputProps({})}
                    hidden
                    disabled={status === StatusEnum.Expired}
                />

                <Box
                    tabIndex={index}
                    display={'inline-flex'}
                    visibility={status === StatusEnum.Expired ? 'hidden' : 'initial'}
                    marginRight={'14px'}
                    {...getCheckboxProps()}
                    bg={state.isChecked ? 'white' : 'transparent'}
                    borderColor={state.isChecked ? 'primary.600' : 'neutral.200'}
                    borderWidth={state.isChecked ? '6px' : '1px'}
                    w={'20px'}
                    h={'20px'}
                    rounded={'full'}
                    sx={{
                        _hover: {
                            outline: '1px solid #4dc4ef'
                        },
                        _focus: {
                            outline: '1px solid #4dc4ef'
                        }
                    }}
                />

                {children}
            </chakra.label>
        </Box>
    );
}
