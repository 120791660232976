import { Box, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import type { FallbackRender } from '@sentry/react/types/errorboundary';
import React, { useMemo } from 'react';

import { isString } from '../../../__utils__/utils';
import { useContentfulUrl } from '../../../components/contentful-url-button/use-contentful-url';
import { __LOCAL__ } from '../../../env-config';
import { ErrorPageModal } from '../../../page/page-modal/error-page/error-page-modal';

type Props =
    & Omit<Partial<Parameters<FallbackRender>[0]>, 'error'>
    & {
    context?: string
    error?: any | Error
};

export function CreateUserAccountError (props: Props) {
    const headingText = t`Uh oh...<br/>Something went wrong`;
    const contactUsUrl = useContentfulUrl('Contact Us');
    const bodyText1 = t`Error - Create - Account Body 1`;
    const bodyText2 = t({
        id: 'Error - Create - Account Body 2',
        values: { contactUsUrl }
    });

    return (
        <ErrorPageModal
            headingText={headingText}
            bodyText1={bodyText1}
            bodyText2={bodyText2}
        >
            {__LOCAL__ && <DevErrorView {...props} />}
        </ErrorPageModal>
    );
}

function DevErrorView (props: Props) {
    const {
        error, componentStack, context = 'Unknown context'
    } = props;

    console.error('UnexpectedError:', context, props);

    const message = useMemo(() => {
        if (isString(error)) {
            return error;
        }

        if (isString(error?.message)) {
            // maybe a json graphql error
            try {
                return JSON.stringify(error.message, null, 2);
            } catch {
                return error.message;
            }
        }

        if (isString(error)) {
            return error;
        }

        return 'Unknown error';
    }, [error]);

    return (
        <Box
            data-context={context}
            color={'red'}
            background={'black'}
            p={'20px'}
            w={'100%'}
        >
            <Text as={'pre'} w={'80vw'}>
                DEV Error:<br />
                {message}
            </Text>

            <br />

            <Text as={'pre'} w={'80vw'}>
                Error context:<br />
                {context}
            </Text>

            <br />

            {componentStack && <Text as={'pre'} w={'80vw'}>
                Component Stack:<br />
                {componentStack}
            </Text>}
        </Box>
    );
}
