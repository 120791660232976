import { useTPrice } from '../../i18n/t-price/use-t-price';
import { TPriceOptions } from '../../i18n/t-price/use-t-price-polyfiller';
import { useAppSelector } from '../../store/store';
import { useCurrentSubscriptionIpQuery } from './use-current-ip-subscription-query';
import { resolveSelectedProductData } from './use-selected-product';

export function useSelectedProductIpQuery () {
    const tPrice = useTPrice();
    const childrenCount = useAppSelector(
        rootState => rootState.subscription.childrenCount
    );
    const subscription = useAppSelector(rootState => rootState.subscription);
    const { quoteAndPricingData, location } = subscription;
    const discountAmount = subscription?.quoteAndPricingData?.coupon
        ? tPrice({
            amount: quoteAndPricingData?.coupon?.discountAmount,
            countryCode: location?.countryCode,
            currencyCode: location?.currencyCode
        } as TPriceOptions)
        : null;
    const query = useCurrentSubscriptionIpQuery({ childrenCount });
    const {
        data: originalQueryData
    } = query;

    if (!originalQueryData) {
        return {
            ...query,
            data: null,
            discountAmount: null
        };
    }

    const data = resolveSelectedProductData({
        childrenCount,
        tPrice,
        data: originalQueryData
    });

    return {
        ...query,
        data,
        discountAmount
    };
}
