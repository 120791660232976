import * as Yup from 'yup';

import { verifyEmailYupTest } from '../../email-validation/email-validation-errors';

const passwordValidationRules = {
    minChars: 'Minimum of 8 characters',
    containsCapital: 'Contains a capital letter',
    containsNumber: 'Contains a number'
};

export const passwordValidationNames = Object.values(passwordValidationRules);

export const INITIAL_PASSWORD_STATE = passwordValidationNames
    .map(labelText => ({
        labelText,
        isValid: false
    }));

export const VALID_PASSWORD_STATE = passwordValidationNames
    .map(labelText => ({
        labelText,
        isValid: true
    }));

export type SchemaOptions = {
    url: string
};

export function createProfileSchema ({ url }: SchemaOptions) {
    return Yup.object()
        .shape({
            firstName: Yup.string()
                .required('Your first name is required')
                .max(50, 'Maximum of 50 characters')
                // eslint-disable-next-line max-len
                .matches(/^[\sa-zA-Z0-9]([A-Za-z0-9\s*()_,.\-"']){0,50}$/, 'First name can contain letters, numbers, and these special characters " * ( ) - _ , . \' and should not start with a special character.'),
            lastName: Yup.string()
                .required('Your last name is required')
                .max(50, 'Maximum of 50 characters')
                // eslint-disable-next-line max-len
                .matches(/^[\sa-zA-Z0-9]([A-Za-z0-9\s*()_,.\-"']){0,50}$/, 'Last name can contain letters, numbers and these special characters " * ( ) - _ , . \' and should not start with a special character.'),
            email: Yup.string()
                .required('Your email is required')
                .email('Invalid email')
                .test(verifyEmailYupTest({ url })),
            password: Yup.string()
                .required('Your password is required')
                .min(8, passwordValidationRules.minChars)
                .matches(/[A-Z]/g, passwordValidationRules.containsCapital)
                .matches(/[0-9]/g, passwordValidationRules.containsNumber)
        }).required();
}

export const EMPTY_FORM = {
    password: '',
    email: '',
    lastName: '',
    firstName: ''
};
