import { useMathleticsToast } from '@3plearning/chakra-mathletics-theme';
import { t } from '@lingui/macro';
import { Severity } from '@sentry/react';
import * as Sentry from '@sentry/react';
import type { NavigateFunction } from 'react-router';
import type { To } from 'react-router-dom';

import { CreateSubscriptionPayload } from '../../__generated__/gateway-graphql';
import { isString } from '../../__utils__/utils';
import { mapToastErrors } from './map-toast-errors';

type ErrorHandleOptions = {
    info: string
    error: any
    contactUsUrl: string
    toast: ReturnType<typeof useMathleticsToast>
};

/**
 * Generic error handling for the multiple scenarios in creating a subscription
 */
export function handleSubscriptionError ({ error, contactUsUrl, toast, info }: ErrorHandleOptions) {
    const extra = {
        info,
        context: 'Checkout creating subscription failure',
        code: error?.code || 'unknown',
        message: error?.message || 'unknown',
        type: error?.type || 'unknown',
        name: error?.name || 'unknown'
    };

    console.error('handleSubscriptionError', error);

    const description = mapToastErrors({
        error: extra,
        contactUsUrl
    });

    toast({
        description,
        status: 'error',
        duration: 9000
    });

    Sentry.captureException(error, {
        level: Severity.Fatal,
        extra
    });
}

type PayloadHandleOptions = {
    payload: { result?: CreateSubscriptionPayload | null }
    redirectTo: To
    navigate: NavigateFunction
    toast?: ReturnType<typeof useMathleticsToast>
};

export function handleSubscriptionPayload ({ payload, redirectTo, navigate, toast }: PayloadHandleOptions) {
    console.info('handleSubscriptionPayload', payload);

    // Currently result can be true and errorMessage be present,
    // eg it had "Success" as the message, the BE devs were shown this.
    // For now this will log the message irrespective of success,
    // there have been too many BE errors happening with the Hybrid approach
    // to be confident this not being of value as no logs are reviewed anywhere else :/
    if (isString(payload?.result?.errorMessage)) {
        Sentry.captureMessage(
            payload?.result?.errorMessage || 'Unknown CreateSubscriptionPayload errorMessage',
            {
                level: payload?.result?.success === false ? Severity.Error : Severity.Log,
                extra: {
                    info: 'handleSubscriptionPayload',
                    errorMessage: payload?.result?.errorMessage,
                    success: payload?.result?.success
                }
            });
    }

    if (payload.result?.success !== true) {
        Sentry.captureMessage('Checkout success false', {
            level: Severity.Error,
            extra: {
                info: 'handleSubscriptionPayload',
                errorMessage: payload?.result?.errorMessage,
                success: payload?.result?.success
            }
        });

        if(payload.result?.errorCode === 'CHARGEBEE_ACCOUNT_ALREADY_EXISTS') {
            if (toast) {
                toast({
                    description: t`Error - Chargebee - Account - Exist`,
                    status: 'error',
                    duration: 9000
                });
            }
        } else {
            const message = 'create_user_failed';

            console.error(
                'createSubscription success not true',
                payload.result
            );
            console.error(message);

            navigate('/error/user-not-created', {
                state: message
            });

            throw new Error(message);
        }
    }

    navigate(redirectTo);
}
