import {
    Image, SkeletonCircle
} from '@chakra-ui/react';
import React from 'react';

import type { Testimonial } from '../../fetch-static-content/contentful/testimonials';
import { useThemeBreakpointMinWidth } from '../../hooks/use-breakpoints';

type Props = {
    authorImageDesktop: Testimonial['authorImageDesktop']
    authorImageMobile: Testimonial['authorImageDesktop']
};

export function SpeakerImage ({ authorImageDesktop, authorImageMobile }: Props) {
    const testimonialsTablet = useThemeBreakpointMinWidth('testimonialsTablet');
    const authorImageSrc = testimonialsTablet ?
        authorImageDesktop.url :
        authorImageMobile.url;

    return (
        <Image
            fallback={<FallbackSkeletonImage />}
            sx={{
                'label': 'speaker-image',
                'w': '100%',
                'h': '100%',
                '@media screen and (max-width: 637px)': {
                    minWidth: '64px',
                    minHeight: '64px',
                    width: '64px',
                    height: '64px',
                    borderRadius: '50%'
                }
            }}
            src={authorImageSrc}
            alt={'speaker-image'}
        />
    );
}

function FallbackSkeletonImage () {
    return (
        <SkeletonCircle
            startColor={'neutral.100'}
            endColor={'neutral.300'}
            fadeDuration={1.2}
            sx={{
                'w': '140px',
                'h': '140px',
                '@media screen and (max-width: 637px)': {
                    width: '64px',
                    height: '64px'
                }
            }}
        />
    );
}
