import React from 'react';

import { useSelectedProductIpQuery } from '../../hooks/selected-product/use-selected-product-ip-query';
import { CheckoutTermsPanelSkeleton } from '../checkout-terms-panel/components/checkout-terms-panel-skeleton';
import { CheckoutPricePanelView } from './checkout-price-panel-view';

export const CheckoutPriceIpPanel = () => {
    const {
        data, discountAmount
    } = useSelectedProductIpQuery();

    if (!data) {
        return <CheckoutTermsPanelSkeleton />;
    }

    return (
        <CheckoutPricePanelView
            data={data}
            discountAmount={discountAmount}
        />
    );
};
