import { t } from '@lingui/macro';
import React from 'react';

import { SuccessView } from '../../../../checkout/success/success-view';

/**
 * Story https://mathletics.atlassian.net/browse/FRAME-5595
 */
export function CreateSuccessRoute () {
    return (
        <SuccessView
            data-testid={'subscription-create-purchase-success-route'}
            headingText={t`Hooray, <br />You’re now subscribed!`}
            bodyText={t`You’re almost there. Login to the Parent Dashboard to add your children.`}
            buttonText={t`Go to Parent Dashboard Login`}
        />
    );
}
