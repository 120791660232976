import React from 'react';
import {
    Navigate, Route, Routes
} from 'react-router-dom';

import { NavigateToNotFoundRoute } from '../../error/not-found-error/components/navigate-to-not-found-route';
import { RedirectIfNoPlanOnceGate } from '../__utils__/redirect-if-no-plan-once.gate';
import { RedirectIfNoPlanGate } from '../__utils__/redirect-if-no-plan.gate';
import { AddCheckoutRoute } from './checkout/add-checkout';
import { AddSelectRoute } from './select/select';
import { AddSuccessRoute } from './success/add-success-route';

export function AddRoute () {
    return (
        <Routes>
            <Route
                path={'select'}
                element={<AddSelectRoute />}
            />

            <Route
                path={'checkout'}
                element={
                    <RedirectIfNoPlanGate to={'/subscription/add/select'}>
                        <AddCheckoutRoute />
                    </RedirectIfNoPlanGate>
                }
            />

            <Route
                path={'success'}
                element={
                    <RedirectIfNoPlanOnceGate to={'/subscription/add/select'}>
                        <AddSuccessRoute />
                    </RedirectIfNoPlanOnceGate>
                }
            />

            <Route
                index={true}
                element={<Navigate to={'select'} />}
            />

            <Route
                path={'*'}
                element={<NavigateToNotFoundRoute />}
            />
        </Routes>
    );
}
