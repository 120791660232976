import { Box } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React, { useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';

import {
    VerifyEmailDocument, VerifyEmailMutation, VerifyEmailMutationVariables
} from '../../../__generated__/gateway-graphql';
import { useEffectOnce } from '../../../__utils__/use-effect-once';
import { isStringDefined } from '../../../__utils__/utils';
import { useFetcher } from '../../../api/gateway/use-fetcher';
import { ContentfulUrlButton } from '../../../components/contentful-url-button/contentful-url-button';
import { LoadingView } from '../../../components/loading-view/loading-view';
import { useQueryParams } from '../../../hooks/use-query-params';
import { ModalMathleticsLogo } from '../../../page/page-modal/modal-mathletics-logo';
import { PageModal } from '../../../page/page-modal/page-modal';
import backgroundsSrc from './assets/success-backgrounds.svg';
import SuccessIcon from './components/success-icon';
import { verifyEmailQuery } from './components/verify-email-query';

/**
 * Story https://mathletics.atlassian.net/browse/FRAME-5642
 */
export function VerifyEmailRoute () {
    const params = useQueryParams();
    const identifier = resolveUrlIdentifier(params);

    if (!params || params.identifier === null) {
        return (
            <Navigate to={'/error'} />
        );
    }

    return (
        <EmailVerificationController emailIdentifier={identifier} />
    );
}

type EmailVerificationControllerProps = {
    emailIdentifier: string
};

function EmailVerificationController ({ emailIdentifier }: EmailVerificationControllerProps) {
    const [isLoading, setIsLoading] = useState(true);
    const verifyEmail = useFetcher<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument);

    useEffectOnce(() => {
        verifyEmailQuery({
            verifyEmail,
            emailIdentifier
        }).then((response) => {
            console.log('response', response);
            setIsLoading(false);
        });
    });

    if (isLoading) {
        return (
            <LoadingView />
        );
    }

    return (
        <EmailVerificationSuccess />
    );
}

function EmailVerificationSuccess () {
    const elementRef = useRef<HTMLDivElement>(null);

    return (
        <PageModal
            ref={elementRef}
            data-testid={'subscription-create-purchase-success-route'}
            Header={<ModalMathleticsLogo zIndex={2} />}
            Body={<BodyContent />}
            backgroundProps={{
                src: backgroundsSrc,
                top: '-947px',
                left: '-991px',
                minWidth: '2330px',
                minHeight: '2330px'
            }}
        >
        </PageModal>
    );
}

function BodyContent () {
    return (
        <>
            <SuccessIcon />
            <Box
                fontWeight={'medium'}
                fontSize={'30px'}
                lineHeight={'37px'}
                display={'flex'}
                alignItems={'center'}
                letterSpacing={'-0.6px'}
                color={'neutral.900'}
            >
                {t`Thanks for verifying your email`}
            </Box>
            <Box
                fontWeight={'normal'}
                fontSize={'14px'}
                lineHeight={'20px'}
                display={'flex'}
                alignItems={'center'}
                letterSpacing={'-0.15px'}
                color={'neutral.700'}
            >
                {t`Account - Setup - Complete - Message`}
            </Box>
            <ContentfulUrlButton
                height={'54px'}
                size={'lg'}
                width={'100%'}
                contentfulUrlName={'Sign In'}
                zIndex={2}
            >
                {t`Log in to the Parent Dashboard`}
            </ContentfulUrlButton>
        </>
    );
}

function resolveUrlIdentifier (
    params: Record<string, string | undefined>
): any | null {
    let urlIdentifier;

    try {
        if (!isStringDefined(params.identifier)) {
            throw new Error('identifier must be a string');
        }

        urlIdentifier = params.identifier;
    } catch {
        urlIdentifier = null;
    }

    return urlIdentifier;
}
