import { Box, Stack } from '@chakra-ui/react';
import {
    FormikProvider
} from 'formik';
import React from 'react';

import { isString } from '../../../../__utils__/utils';
import { useUrlQuery } from '../../../../api/contentful/urls/use-url-query';
import { CheckoutFooter } from '../../../../checkout/checkout-footer/checkout-footer';
import { NewCustomerDetails } from '../../../../checkout/header-details/new-customer-details';
import { LoadingView } from '../../../../components/loading-view/loading-view';
import { type LocationData, useActiveLocation } from '../../../../location/use-active-location';
import { PageCenterForm } from '../../../../page/page-content/page-content.styles';
import { PageHeading } from '../../../../page/page-heading/page-heading';
import { useChosenSubscriptionSelector } from '../../../../subscription-model/subscription.selectors';
import { ErrorBoundary } from '../../../error/errors/error-boundary';
import { UnexpectedError } from '../../../error/errors/unexpected-error';
import { CheckoutForm } from './components/checkout-form';
import { FormAccountView } from './components/form-account-view';
import { useCreateCheckout } from './hooks/use-create-checkout';

export function CreateCheckoutRoute () {
    return (
        <ErrorBoundary context={'CreateCheckoutRoute'}>
            <CreateCheckout />
        </ErrorBoundary>
    );
}

function CreateCheckout () {
    const {
        data: locationData,
        isLoading: locationIsLoading,
        isError: locationError,
        error
    } = useActiveLocation();
    const {
        data: urlQueryData,
        isError: urlQueryError,
        isLoading: urlQueryIsLoading
    } = useUrlQuery('Contact Us');
    const contactUsUrl = urlQueryData?.url || null;
    const isLoading = locationIsLoading || urlQueryIsLoading;
    const isError = urlQueryError || locationError;

    if (isError) {
        return (
            <UnexpectedError
                context={'CheckoutWithLocation useActiveLocation'}
                error={error}
            />
        );
    }

    if ((!isString(contactUsUrl) || !locationData) || isLoading) {
        return (
            <LoadingView />
        );
    }

    return (
        <CreateCheckoutForm
            contactUsUrl={contactUsUrl}
            location={locationData}
        />
    );
}

type Props = {
    location: LocationData
    contactUsUrl: string
};

function CreateCheckoutForm ({ location, contactUsUrl }: Props) {
    const {
        onBack,
        cardRef,
        formValue,
        submitButtonText
    } = useCreateCheckout({
        contactUsUrl,
        location
    });
    const subscription = useChosenSubscriptionSelector(false);
    const ribbonText = subscription?.ribbonText || null;

    return (
        <div data-testid={'subscription-create-checkout-route'}>
            <PageCenterForm>
                <PageHeading
                    headingText={'Finish setup'}
                    onBack={onBack}
                    ribbonText={ribbonText}
                >
                    <NewCustomerDetails />
                </PageHeading>
            </PageCenterForm>

            <PageCenterForm>
                <FormikProvider value={formValue}>
                    <Stack
                        data-testid={'checkout-form-container'}
                        layerStyle={'card'}
                        w={'100%'}
                        align={'center'}
                        mb={'32px'}
                        spacing={0}
                    >
                        <FormAccountView />

                        <Box
                            data-testid={'checkout-form'}
                            w={'100%'}
                            px={{
                                mobile: '24px',
                                fieldRowTablet: '64px',
                                desktop: '74px'
                            }}
                        >
                            <CheckoutForm
                                submitButtonText={submitButtonText}
                                cardRef={cardRef}
                            />
                        </Box>
                    </Stack>
                </FormikProvider>
            </PageCenterForm>

            <CheckoutFooter />
        </div>
    );
}
