import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../store/store';
import { subscriptionActions } from '../../subscription-model/subscription.slice';
import { useTrialDataQuery } from '../use-trial-data-query';

export function useFreeTrialInfo () {
    const query = useTrialDataQuery();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const buttonText = query.data?.trialButtonText || null;
    const productCode = query.data?.productCode || null;

    /**
     * This is a business rule for a UX to move to the next step with one button.
     *
     * @see https://mathletics.atlassian.net/browse/FRAME-5308
     */
    function handleStartFreeTrial () {
        dispatch(subscriptionActions.chooseSubscription({
            productCode,
            childrenCount: 1
        }));
        navigate('/subscription/create/create-account');
    }

    return {
        buttonText,
        isLoading: !query.data || query.isLoading,
        onStartFreeTrial: handleStartFreeTrial
    };
}
