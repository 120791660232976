import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import * as countryData from 'country-data';
import type { MutableRefObject } from 'react';

import {
    LogExceptionMutation,
    LogExceptionMutationVariables
} from '../../__generated__/gateway-graphql';
import type { AdditionalData, ChargebeeInstance, PaymentIntent } from '../../chargebee/chargebee.types';
import type { SubscriptionState } from '../../subscription-model/subscription.types';

type AuthorizeOptions = {
    cardRef: MutableRefObject<ChargebeeInstance>
    paymentIntent: PaymentIntent
    additionalData: AdditionalData
    logException: (
        variables: LogExceptionMutationVariables
    ) => Promise<LogExceptionMutation>
    quoteId: string
};

/**
 * Recursively replace alpha3 countryCode value with alpha2 countrycode as authorizeWith3ds
 * is failing with alpha3 in chargebee/sprite
 */
export function replaceCountryCodeWithAlfa2 (obj: any) {
    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            replaceCountryCodeWithAlfa2(obj[key]);
        } else if (key === 'countryCode') {
            const countries = countryData.countries.all;
            const newCountryCode = countries.find((c: { alpha3: any }) => c.alpha3 === obj[key]);

            obj[key] = newCountryCode?.alpha2;
        }
    }

    return obj;
}

/**
 * Authorize a new credit card with a payment intent from BE.
 */
export async function authorizeWith3dsNewCard ({
    cardRef,
    paymentIntent,
    additionalData,
    logException,
    quoteId
}: AuthorizeOptions) {
    Sentry.setExtra('authorizeWith3dsNewCard additionalData', additionalData);

    const creditCardReference = cardRef.current;

    replaceCountryCodeWithAlfa2(additionalData);
    const authorizedPaymentIntent = await creditCardReference.authorizeWith3ds(
        paymentIntent,
        additionalData,
        {
            error: async (error: any) => {
                console.error('authorizeWith3ds error', error);

                Sentry.captureException(error, {
                    level: Severity.Fatal,
                    extra: {
                        authorizeWith3dsNewCard: 'authorizeWith3ds.error'
                    }
                });

                await logException({
                    exceptionsDetail: {
                        cardBillingAddress: {
                            countryCode: additionalData.billingAddress?.countryCode,
                            firstName: additionalData.billingAddress?.firstName,
                            lastName: additionalData.billingAddress?.lastName,
                            state: additionalData.billingAddress?.state
                        },
                        email: additionalData.email,
                        paymentIntentId: paymentIntent.id,
                        plan: additionalData.plan,
                        exceptionsDetail: error,
                        quoteId
                    }
                });
            },
            success: (...args: any[]) => {
                console.log('authorizeWith3ds success', args);
            },
            change: (...args: any[]) => {
                console.log('authorizeWith3ds change', args);
            }
        }
    );

    Sentry.setExtra('authorizedPaymentIntent', authorizedPaymentIntent.id);

    return authorizedPaymentIntent;
}

type Options = {
    planId: string | null
    subscription: SubscriptionState
};

/**
 * This metadata is all optional on the api docs,
 * add validation if its required.
 */
export function resolveAdditionalData ({
    subscription: { profile, location }, planId
}: Options) {
    return {
        plan: planId,
        // This is optional in Chargebee api, it's here for completeness
        ...(profile && location) ? {
            email: profile?.email,
            billingAddress: {
                firstName: profile?.firstName,
                lastName: profile?.lastName,
                countryCode: location?.countryCode,
                state: location?.state
            }
        } : null
    } as AdditionalData;
}
