import { Severity } from '@sentry/types';
import { useDispatch } from 'react-redux';

import type { GetAllCountriesQuery } from '../__generated__/gateway-graphql';
import { useGetUserLocationQuery } from '../__generated__/gateway-graphql';
import { createQueryErrorHandler } from '../sentry/on-query-error';
import { useAppSelector } from '../store/store';
import { subscriptionActions } from '../subscription-model/subscription.slice';
import { pickCountryFromCountryCode } from './__utils__/pick-country-from-country-code';
import { CountryItem, useGetCountriesQuery } from './use-get-countries-query';

export type ActiveCountry = {
    countryName: string
    countryCode: string
    currencyCode: string
    statesName?: string | null
    state: string | null
    states: Array<string>
};

// TODO put this in backend schema, no reason to have a null country??
//      this is a workaround for the null type in the graphql schema
type NonNullableCountry = NonNullable<NonNullable<GetAllCountriesQuery['countries']>[0]>;

export type LocationData = {
    countries: Array<NonNullableCountry>
    country: ActiveCountry
};

type Hook = {
    data: LocationData | null
    isLoading: boolean
    isError: boolean
    error: unknown
};

/**
 * Load the user's location data with a business rule to persist an "active location" to "session storage".
 * First the location is determined in backend by the user's browser request ip or it is overriden by the
 * user in the session storage, which is currently persisted in the redux store.
 */
export function useActiveLocation (): Hook {
    const {
        data: allCountriesData,
        isLoading: allCountriesIsLoading,
        isError: allCountriesIsError,
        error: allCountriesError
    } = useGetCountriesQuery({
        onError: createQueryErrorHandler({
            severity: Severity.Fatal
        })
    });
    const countries: Array<NonNullableCountry> = allCountriesData?.countries || [] as any;
    const {
        data: userLocation,
        isLoading: userLocationLoading,
        isError: userLocationIsError,
        error: userLocationError
    } = useResolveUserLocation(countries);
    const isLoading = userLocationLoading || allCountriesIsLoading;
    const isError = allCountriesIsError || userLocationIsError;
    const countryCode = userLocation?.countryCode || 'AUS';
    // Cast needed for NonNullableCountry workaround

    if (isLoading) {
        return {
            error: null,
            isError,
            isLoading: true,
            data: null
        };
    }

    if (isError) {
        return {
            error: {
                allCountriesError,
                userLocationError
            },
            isError,
            isLoading: false,
            data: null
        };
    }

    // BE did not want to change type to non-nullable, was asked to
    // throw an error in this scenario, it should never happen.
    if (typeof countryCode !== 'string') {
        throw new Error('Unable to determine user countryCode');
    }

    const country = pickCountryFromCountryCode({
        countryCode,
        state: userLocation?.state
    }, countries);

    // It's not possible to use a country or state that is not in the
    // GetAllCountries query, this data needs to be available in both places.
    if (country === null) {
        throw new Error('Unable to determine user country from countryCode ' + countryCode);
    }

    return {
        isError: false,
        error: null,
        isLoading: false,
        data: {
            country,
            countries
        }
    };
}

type UserLocation = {
    error: unknown
    isError: boolean
    data: null | {
        state: null | string
        countryCode: string
        currencyCode: string
    }
    isLoading: boolean
};

function useResolveUserLocation (countries: Array<CountryItem>): UserLocation {
    // This is persisted in the Redux sessionStorage api,
    // so the user's progress in the form is saved for page reloads.
    // If they change their country in checkout page, that choice should remain
    // throughout the app.
    const persistedLocation = useAppSelector(
        state => {
            const location = state.subscription?.location || null;

            return (
                // very basic validation of persisted location
                typeof location?.countryCode === 'string' &&
                typeof location?.currencyCode === 'string'
            ) ? location : null;
        }
    );
    const shouldLoadBackendLocation = persistedLocation === null;
    const {
        data,
        isLoading,
        isError,
        error
    } = useGetUserLocationQuery(undefined, {
        enabled: shouldLoadBackendLocation,
        onError: createQueryErrorHandler({
            severity: Severity.Fatal
        })
    });
    const dispatch = useDispatch();

    if (shouldLoadBackendLocation) {
        if (!data || isLoading) {
            return {
                error,
                isError,
                isLoading: true,
                data: null
            };
        }

        // BE did not want to change type to non-nullable, was asked to
        // throw an error in this scenario, it should never happen.
        if (
            typeof data.userCountry?.countryCode !== 'string' ||
            typeof data.userCountry?.currencyCode !== 'string'
        ) {
            throw new Error('Unable to determine userCountry countryCode and currencyCode');
        }

        const { countryCode, currencyCode } = data.userCountry;
        const userCountry = pickCountryFromCountryCode({
            countryCode
        }, countries);

        // This is a side effect that needs to happen outside
        // of render loop, TODO open to alternative solutions? this is a temporary fix :(
        setTimeout(() => {
            // Update the store so it becomes persistent in sessionStorage
            dispatch(subscriptionActions.updateLocationCountryCode({
                currencyCode,
                countryCode: userCountry?.countryCode || 'AUS'
            }));
        }, 0);

        return {
            error,
            isError,
            isLoading,
            data: {
                currencyCode,
                countryCode,
                state: null
            }
        };
    }

    return {
        error,
        isError,
        isLoading: false,
        data: persistedLocation
    };
}
