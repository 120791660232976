import { t } from '@lingui/macro';

import { isObject, isString } from '../../__utils__/utils';

enum HandledErrorCodes {
    cardDeclined = 'card_declined',
    incorrectNumber = 'incorrect_number',
    processingError = 'processing_error',
    incorrectCvc = 'incorrect_cvc',
    expiredCard = 'expired_card',
    createUserFailed = 'create_user_failed'
}

type Options = {
    error: {
        info: string
        context: string
        code: string
        message:string
        type: string
        name: string
    }
    contactUsUrl: string
};

/**
 * Map custom user error messages for the toast display
 *
 * @see https://mathletics.atlassian.net/browse/FRAME-5622
 * @see https://mathletics.atlassian.net/wiki/spaces/PIV/pages/2613411894/Credit+Card+Error+Codes
 */
export function mapToastErrors ({ error, contactUsUrl }: Options): string {
    if ((error.message).includes('insufficient')) {
        return t`Error - Insufficient Funds`;
    }

    const errorCodesCopy = resolveErrorCodeCopy({
        contactUsUrl
    });

    if (!error || typeof error === 'undefined' || typeof error !== 'object') {
        return errorCodesCopy.processing_error;
    }

    if (isChargebeePaymentError(error)) {
        return errorCodesCopy[error.code] || errorCodesCopy.processing_error;
    }

    if (isObject(error)) {
        // @ts-ignore
        if (error.message === 'create_user_failed') {
            return errorCodesCopy.create_user_failed;
        }
    }

    return errorCodesCopy.processing_error;
}

function resolveErrorCodeCopy ({ contactUsUrl }: { contactUsUrl: string }): Record<HandledErrorCodes|string, string> {
    // noinspection HtmlUnknownTarget
    return {
        [HandledErrorCodes.cardDeclined]: t`Error - Card Declined`,
        [HandledErrorCodes.expiredCard]: t`Error - Expired Card`,
        [HandledErrorCodes.incorrectCvc]: t`Error - Incorrect Cvc`,
        [HandledErrorCodes.processingError]: t({
            id: 'Error - Processing Error',
            values: { contactUsUrl }
        }),
        [HandledErrorCodes.incorrectNumber]: t`Error - Incorrect Number`,
        [HandledErrorCodes.createUserFailed]: t`Error - Create - User - Failed`
    };
}

type ChargebeePaymentError = {
    code: string | HandledErrorCodes
    message: string
    type: string
    name: string
    context?: string
    info?: string
};

function isChargebeePaymentError (value: unknown): value is ChargebeePaymentError {
    if (!isObject(value)) return false;

    return (
        isString(value.code) &&
        isString(value.message) &&
        isString(value.type) &&
        isString(value.name)
    );
}
