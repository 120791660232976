import { Box, type BoxProps } from '@chakra-ui/react';
import type { LayoutProps } from '@chakra-ui/styled-system';
import React from 'react';

const headerHeight = {
    mobile: 68,
    tablet: 68,
    desktop: 72
};
const footerHeight = {
    // height and padding
    mobile: 142 + 40,
    tablet: 80 + 72,
    desktop: 90 + 72
};
const bodyHeight = {
    mobile: headerHeight.mobile + footerHeight.mobile,
    tablet: headerHeight.tablet + footerHeight.tablet,
    desktop: headerHeight.desktop + footerHeight.desktop
};

// Calculate the body height for a semi-fixed footer position

export const BodyHeight: LayoutProps['minH'] = {
    mobile: `calc(100vh - ${bodyHeight.mobile}px)`,
    footerTablet: `calc(100vh - ${bodyHeight.tablet}px)`,
    desktop: `calc(100vh - ${bodyHeight.desktop}px)`
};

type Props = BoxProps;

export function Body ({ children, ...restProps }: Props) {
    return (
        <Box
            data-testid={'body'}
            minH={BodyHeight}
            {...restProps}
        >
            {children}
        </Box>
    );
}

