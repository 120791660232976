import { useMathleticsToast } from '@3plearning/chakra-mathletics-theme';
import { t } from '@lingui/macro';
import type { MutableRefObject } from 'react';
import type { NavigateFunction } from 'react-router';
import type { To } from 'react-router-dom';

import type {
    CreateCustomerSubscriptionMutation,
    CreateCustomerSubscriptionMutationVariables,
    CreateCustomerSubscriptionInput,
    CreatePaymentIntentFromQuoteMutation,
    CreatePaymentIntentFromQuoteMutationVariables
} from '../../__generated__/gateway-graphql';
import { LogExceptionMutation, LogExceptionMutationVariables } from '../../__generated__/gateway-graphql';
import { USER_ATTEMPT_MAX_LIMIT_REACHED } from '../../__utils__/utils';
import type {
    ChargebeeInstance
} from '../../chargebee/chargebee.types';
import { SalesforceURLData } from '../../subscription-model/subscription.types';
import type { SubscriptionState } from '../../subscription-model/subscription.types';
import { authorizeWith3dsNewCard, resolveAdditionalData } from '../__utils__/authorize-with3ds-new-card';
import { createGatewayPaymentIntent } from '../__utils__/create-gateway-payment-intent';
import { assertNewCardCustomerPaymentIntent } from '../__utils__/validation';

type Options = {
    cardRef: MutableRefObject<ChargebeeInstance>
    createCustomerSubscription: (
        variables: CreateCustomerSubscriptionMutationVariables
    ) => Promise<CreateCustomerSubscriptionMutation>
    createPaymentIntentFromQuote: (
        variables: CreatePaymentIntentFromQuoteMutationVariables
    ) => Promise<CreatePaymentIntentFromQuoteMutation>
    logException: (
        variables: LogExceptionMutationVariables
    ) => Promise<LogExceptionMutation>
    navigate: NavigateFunction
    successRedirectTo: To
    subscription: SubscriptionState
    salesforce: SalesforceURLData
    quoteId: string
    toast: ReturnType<typeof useMathleticsToast>
    contactUsUrl: string
};

export async function createCustomerNewCardSubscriptionInput ({
    createPaymentIntentFromQuote, subscription, cardRef, salesforce, quoteId, logException, toast, contactUsUrl
}: Options): Promise<CreateCustomerSubscriptionInput | null> {
    const payment = {
        // Add the required data for a using a payment source,
        // note this is optional in graphql schema and things are named differently
        // and sessionId -> token.
        quoteId,
        sessionId: salesforce.token,
        userId: salesforce.userId
    };
    const userMaxAttemptError = t({
        id: 'Error - User - Max - Attempt',
        values: { contactUsUrl }
    });

    assertNewCardCustomerPaymentIntent(payment);

    if (!cardRef.current) {
        throw new Error('Unable to get Chargebee component reference cardRef');
    }

    if (!subscription.quoteAndPricingData) {
        throw new Error('Unable to get quote Id from store');
    }

    const { paymentIntent, errorMessage } = await createGatewayPaymentIntent({
        createPaymentIntentFromQuote,
        input: payment
    });
    const additionalData = resolveAdditionalData({
        planId: subscription.productCode,
        subscription
    });

    if(errorMessage !== USER_ATTEMPT_MAX_LIMIT_REACHED && paymentIntent !== null) {
        const authorizedPaymentIntent = await authorizeWith3dsNewCard({
            additionalData,
            cardRef,
            paymentIntent,
            logException,
            quoteId: subscription.quoteAndPricingData.quoteId
        });

        return {
            payment: {
                intentId: authorizedPaymentIntent.id,
                quoteId: subscription.quoteAndPricingData.quoteId,
                userId: salesforce.userId
            }
        };
    } else {
        toast({
            description: userMaxAttemptError,
            status: 'error',
            duration: 9000
        });

        return null;
    }
}
