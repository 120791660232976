import { useFormik, yupToFormErrors } from 'formik';
import type { FormikConfig, FormikValues } from 'formik';
import { useState } from 'react';

/**
 * This is a basic wrapper on formik useFormik to provide UX with validateAfterSubmit behaviour.
 * It is similar to an open pull request https://github.com/jaredpalmer/formik/pull/3500.
 * The code here interrupts the regular formik validation cycle to allow validateOnChange/Blur
 * only after the first form submission.
 */
export function useFormikValidateAfterSubmit <Values extends FormikValues = FormikValues> (
    options: FormikConfig<Values>
) {
    const [isValidationEnabled, setIsValidationEnabled] = useState(false);
    const formValue = useFormik({
        ...options,
        enableReinitialize: true,
        validateOnChange: isValidationEnabled,
        validateOnBlur: isValidationEnabled,
        validate: async (values) => {
            // Allow validation onChange/Blur after first submit of the form which calls this.
            if (!isValidationEnabled) {
                setIsValidationEnabled(true);
            }

            try {
                await options.validationSchema.validate(values, { abortEarly: false });
            } catch (error: any) {
                // Standard formik yup error handling.
                const errors = yupToFormErrors(error);

                return errors;
            }
        }
    });

    return formValue;
}
