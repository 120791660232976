import { Skeleton, Box, Stack } from '@chakra-ui/react';
import React from 'react';

import {
    ButtonWrapper, ContentWrapper, RootWrapper
} from '../free-trial-info.styled';

export function FreeTrialInfoSkeleton () {
    return (
        <RootWrapper data-testid={'free-trial-info-loading-fallback'}>
            <ContentWrapper>
                <Stack
                    mt={{
                        mobile: '350px',
                        freeTrialInfoColumn: 0
                    }}
                    spacing={5}
                    width={'480px'}
                    height={'calc(100% - 160px)'}
                    p={'75px'}
                >
                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        height={'50px'}
                        w={'100%'}
                    />

                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        height={'50px'}
                        w={'70%'}
                    />

                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        height={'50px'}
                        w={'40%'}
                    />

                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        height={'40px'}
                    >
                        <ButtonWrapper />
                    </Skeleton>
                </Stack>
                <Box
                    width={{
                        mobile: '100%',
                        freeTrialInfoColumn: '480px'
                    }}
                    height={'100%'}
                    pos={{
                        mobile: 'absolute',
                        freeTrialInfoColumn: 'relative'
                    }}
                >
                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        height={'400px'}
                        width={'100%'}
                    />
                </Box>
            </ContentWrapper>
        </RootWrapper>
    );
}
