import { useMathleticsToast } from '@3plearning/chakra-mathletics-theme';
import { t } from '@lingui/macro';
import { useFormik } from 'formik';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';

import {
    useCreatePaymentIntentFromQuoteMutation,
    useCreateSubscriptionMutation,
    useLogExceptionMutation
} from '../../../../../__generated__/gateway-graphql';
import type { ChargebeeInstance } from '../../../../../chargebee/chargebee.types';
import { useChargebeeInit } from '../../../../../chargebee/use-chargebee-init';
import { createNewCustomerSubscription } from '../../../../../checkout/new-customer/create-new-customer-subscription';
import { useCurrentSubscriptionIpQuery } from '../../../../../hooks/selected-product/use-current-ip-subscription-query';
import type { LocationData } from '../../../../../location/use-active-location';
import { useAppSelector } from '../../../../../store/store';
import { createCheckoutFormSchema } from './__utils__/form-schema';

type Hook = {
    location: LocationData
    contactUsUrl: string
};

export function useCreateCheckout ({ location, contactUsUrl }: Hook) {
    useChargebeeInit();

    const { mutateAsync: createPaymentIntentFromQuote } = useCreatePaymentIntentFromQuoteMutation();
    const { mutateAsync: createSubscription } = useCreateSubscriptionMutation();
    const { mutateAsync: logException } = useLogExceptionMutation();
    const subscription = useAppSelector(rootState => rootState.subscription);
    const cardRef = useRef<ChargebeeInstance>(null as any);
    const navigate = useNavigate();
    const submitButtonText = useSelectSubmitButtonText();
    const validationSchema = useMemo(() => createCheckoutFormSchema(location), [location]);
    const toast = useMathleticsToast();
    const formValue = useFormik({
        initialValues: {
            creditCardComplete: false,
            location: {
                state: location.country.state,
                countryCode: location.country.countryCode
            }
        },
        validationSchema,
        onSubmit: () => createNewCustomerSubscription({
            successRedirectTo: '/subscription/create/success',
            createPaymentIntentFromQuote,
            createSubscription,
            logException,
            cardRef,
            navigate,
            toast,
            contactUsUrl,
            subscription
        })
    });

    function handleBack () {
        navigate('/subscription/create/create-account');
    }

    return {
        onBack: handleBack,
        submitButtonText,
        cardRef,
        formValue
    };
}

function useSelectSubmitButtonText (): string | null {
    const subscription = useCurrentSubscriptionIpQuery();

    if (!subscription?.data) {
        return null;
    }

    return subscription.data?.productItem?.isTrial === true ?
        t`Start my free trial` :
        t`Purchase`;
}
