import { VStack, HStack, chakra, Stack, Box, BoxProps } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React from 'react';

import { useContentfulUrl } from '../../components/contentful-url-button/use-contentful-url';

export function CheckoutFooter () {
    const contactUsUrl = useContentfulUrl('Support - Contact Us');
    const havingTroubleText = t({
        id: 'Checkout - Footer - Having trouble',
        values: {
            contactUsUrl
        }
    });
    const guaranteedText = t`Checkout - Footer - Guaranteed safe and secure`;

    return (
        <VStack
            align={'center'}
            spacing={{
                mobile: '12px',
                checkoutFooterTablet: '13px'
            }}
        >
            <Stack
                justify={'start'}
                align={'center'}
                direction={{
                    mobile: 'column',
                    checkoutFooterTablet: 'row'
                }}
                spacing={{
                    mobile: '8px',
                    checkoutFooterTablet: '20px'
                }}
            >
                <PoweredByStripe />

                <VerticalSeparator
                    display={{
                        mobile: 'none',
                        checkoutFooterTablet: 'block'
                    }}
                />

                <HStack
                    justify={'start'}
                    align={'center'}
                    spacing={'10px'}
                >
                    <LockIcon />

                    <chakra.div
                        color={'neutral.700'}
                        fontSize={'15px'}
                        dangerouslySetInnerHTML={{ __html: guaranteedText }}
                    />
                </HStack>
            </Stack>

            <chakra.div
                color={'neutral.700'}
                fontSize={'14px'}
                sx={{ a: { fontSize: '14px' } }}
                textAlign={{
                    mobile: 'center',
                    checkoutFooterTablet: 'left'
                }}
                dangerouslySetInnerHTML={{ __html: havingTroubleText }}
            />
        </VStack>
    );
}

function VerticalSeparator
(props: BoxProps) {
    return (
        <Box
            as={'span'}
            w={'1px'}
            h={'44px'}
            backgroundColor={'neutral.400'}
            {...props}
        />
    );
}

function PoweredByStripe () {
    /* eslint-disable max-len */
    return (
        <chakra.svg
            width={{
                mobile: '106px',
                checkoutFooterTablet: '150px'
            }}
            minWidth={{
                mobile: '106px',
                checkoutFooterTablet: '150px'
            }}
            height={{
                mobile: '24px',
                checkoutFooterTablet: '34px'
            }}
            viewBox={'0 0 150 34'}
            fill={'none'}
        >
            <g clipPath={'url(#clip0_4_524)'}>
                <path
                    d={'M146 0H3.73C2.74074 0 1.792 0.392981 1.09249 1.09249C0.392981 1.792 0 2.74074 0 3.73L0 30.27C0 31.2593 0.392981 32.208 1.09249 32.9075C1.792 33.607 2.74074 34 3.73 34H146C147.061 34 148.078 33.5786 148.828 32.8284C149.579 32.0783 150 31.0609 150 30V4C150 2.93913 149.579 1.92172 148.828 1.17157C148.078 0.421427 147.061 0 146 0V0ZM149 30C149 30.7956 148.684 31.5587 148.121 32.1213C147.559 32.6839 146.796 33 146 33H3.73C3.00677 32.9974 2.31391 32.7089 1.80251 32.1975C1.2911 31.6861 1.00263 30.9932 1 30.27V3.73C1.00263 3.00677 1.2911 2.31391 1.80251 1.80251C2.31391 1.2911 3.00677 1.00263 3.73 1H146C146.796 1 147.559 1.31607 148.121 1.87868C148.684 2.44129 149 3.20435 149 4V30Z'}
                    fill={'#ADB9C7'}
                />
                <path
                    d={'M17.07 11.24H12.77V22H14.69V17.84H17.07C19.47 17.84 20.97 16.68 20.97 14.54C20.97 12.4 19.47 11.24 17.07 11.24ZM16.97 16.24H14.69V12.94H17C18.38 12.94 19.11 13.53 19.11 14.59C19.11 15.65 18.35 16.19 17 16.19L16.97 16.24Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M25.1 14C24.5743 13.9939 24.0531 14.0978 23.5699 14.3051C23.0868 14.5124 22.6523 14.8185 22.2945 15.2037C21.9366 15.5888 21.6633 16.0446 21.492 16.5417C21.3207 17.0387 21.2553 17.5661 21.3 18.09C21.2531 18.6167 21.3164 19.1474 21.4858 19.6484C21.6552 20.1493 21.9271 20.6095 22.2841 20.9996C22.641 21.3898 23.0753 21.7013 23.5592 21.9145C24.0432 22.1277 24.5662 22.2377 25.095 22.2377C25.6238 22.2377 26.1468 22.1277 26.6308 21.9145C27.1147 21.7013 27.549 21.3898 27.906 20.9996C28.2629 20.6095 28.5348 20.1493 28.7042 19.6484C28.8736 19.1474 28.9369 18.6167 28.89 18.09C28.9362 17.5667 28.8721 17.0395 28.702 16.5424C28.5319 16.0453 28.2596 15.5894 27.9025 15.2041C27.5454 14.8187 27.1115 14.5125 26.6288 14.3051C26.1461 14.0977 25.6253 13.9938 25.1 14ZM25.1 20.67C23.88 20.67 23.1 19.67 23.1 18.09C23.1 16.51 23.86 15.51 25.1 15.51C26.34 15.51 27.1 16.51 27.1 18.09C27.1 19.67 26.31 20.66 25.1 20.66V20.67Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M36.78 19.35L35.37 14.13H33.89L32.49 19.35L31.07 14.13H29.22L31.59 22.01H33.15L34.59 16.85L36.03 22.01H37.59L39.96 14.13H38.18L36.78 19.35Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M44 14C43.4812 14.0096 42.9697 14.1246 42.4966 14.3379C42.0236 14.5513 41.5988 14.8585 41.2481 15.241C40.8974 15.6235 40.6281 16.0733 40.4565 16.563C40.2849 17.0528 40.2147 17.5723 40.25 18.09C40.2082 18.6157 40.2766 19.1444 40.4508 19.6422C40.625 20.14 40.9012 20.596 41.2617 20.9809C41.6221 21.3659 42.059 21.6714 42.5443 21.8778C43.0296 22.0843 43.5527 22.1872 44.08 22.18C44.805 22.2191 45.524 22.0297 46.1357 21.6386C46.7475 21.2476 47.2211 20.6744 47.49 20L46 19.38C45.8831 19.7664 45.6383 20.1016 45.3057 20.3306C44.9732 20.5595 44.5727 20.6686 44.17 20.64C43.8832 20.6468 43.5979 20.5954 43.3315 20.4888C43.0652 20.3821 42.8232 20.2226 42.6203 20.0197C42.4174 19.8168 42.2579 19.5748 42.1512 19.3085C42.0446 19.0421 41.9932 18.7568 42 18.47H47.52V17.87C47.54 15.71 46.32 14 44 14ZM42.07 17.13C42.1401 16.6708 42.3743 16.2526 42.7292 15.9529C43.0841 15.6532 43.5356 15.4923 44 15.5C44.2207 15.4813 44.4428 15.5099 44.6516 15.5837C44.8604 15.6576 45.051 15.775 45.2109 15.9283C45.3708 16.0815 45.4962 16.2671 45.5788 16.4726C45.6614 16.678 45.6993 16.8987 45.69 17.12L42.07 17.13Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M50.69 15.3V14.13H48.89V22H50.69V17.87C50.674 17.6032 50.7147 17.336 50.8095 17.0861C50.9044 16.8361 51.0511 16.6092 51.2402 16.4202C51.4292 16.2311 51.6561 16.0844 51.9061 15.9895C52.156 15.8947 52.4232 15.854 52.69 15.87C52.9097 15.8545 53.1303 15.8545 53.35 15.87V14.07C53.21 14.07 53.05 14.07 52.84 14.07C52.4014 14.0473 51.9655 14.1511 51.5843 14.3692C51.2031 14.5873 50.8927 14.9104 50.69 15.3Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M57.48 14C56.9612 14.0096 56.4497 14.1246 55.9766 14.3379C55.5036 14.5513 55.0788 14.8585 54.7281 15.241C54.3774 15.6235 54.1081 16.0733 53.9365 16.563C53.7649 17.0528 53.6947 17.5723 53.73 18.09C53.6882 18.6157 53.7566 19.1444 53.9308 19.6422C54.105 20.14 54.3812 20.596 54.7417 20.9809C55.1021 21.3659 55.539 21.6714 56.0243 21.8778C56.5096 22.0843 57.0327 22.1872 57.56 22.18C58.2784 22.2106 58.9885 22.0172 59.5922 21.6267C60.1959 21.2361 60.6634 20.6677 60.93 20L59.39 19.41C59.2731 19.7964 59.0283 20.1316 58.6957 20.3606C58.3632 20.5895 57.9627 20.6986 57.56 20.67C57.279 20.6674 57.0014 20.609 56.7432 20.4982C56.485 20.3874 56.2514 20.2263 56.056 20.0244C55.8606 19.8225 55.7073 19.5837 55.6049 19.322C55.5026 19.0603 55.4534 18.7809 55.46 18.5H61V17.9C61 15.71 59.76 14 57.48 14ZM55.55 17.13C55.622 16.6744 55.8557 16.2599 56.2083 15.9624C56.5609 15.6649 57.0087 15.5043 57.47 15.51C57.6907 15.4913 57.9128 15.5199 58.1216 15.5937C58.3304 15.6676 58.521 15.785 58.6809 15.9383C58.8408 16.0915 58.9662 16.2771 59.0488 16.4826C59.1314 16.688 59.1693 16.9087 59.16 17.13H55.55Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M67.56 15C67.2825 14.6744 66.9353 14.4154 66.544 14.2423C66.1528 14.0692 65.7276 13.9864 65.3 14C63.09 14 61.83 15.85 61.83 18.09C61.83 20.33 63.09 22.18 65.3 22.18C65.7279 22.1958 66.1538 22.114 66.5454 21.9407C66.937 21.7675 67.2839 21.5073 67.56 21.18V22H69.36V11.24H67.56V15ZM67.56 18.35C67.6003 18.6351 67.5786 18.9256 67.4963 19.2016C67.414 19.4775 67.2731 19.7325 67.0832 19.949C66.8933 20.1654 66.6589 20.3384 66.396 20.4559C66.1331 20.5734 65.848 20.6328 65.56 20.63C64.25 20.63 63.56 19.63 63.56 18.11C63.56 16.59 64.26 15.59 65.56 15.59C66.67 15.59 67.56 16.4 67.56 17.88V18.35Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M79.31 14C78.8744 13.9809 78.4401 14.061 78.04 14.2343C77.6398 14.4075 77.2842 14.6693 77 15V11.24H75.2V22H77V21.17C77.279 21.4964 77.628 21.7558 78.0209 21.9289C78.4139 22.102 78.8408 22.1844 79.27 22.17C81.47 22.17 82.73 20.31 82.73 18.08C82.73 15.85 81.51 14 79.31 14ZM79 20.6C78.712 20.6028 78.4269 20.5434 78.164 20.4259C77.9011 20.3084 77.6667 20.1354 77.4768 19.919C77.2869 19.7025 77.146 19.4475 77.0637 19.1716C76.9814 18.8956 76.9597 18.6051 77 18.32V17.85C77 16.37 77.84 15.56 79 15.56C80.3 15.56 81 16.56 81 18.08C81 19.6 80.25 20.6 79 20.6Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M86.93 19.66L85 14.13H83.1L86 21.72L85.7 22.46C85.649 22.7135 85.5018 22.9375 85.2892 23.0848C85.0767 23.2321 84.8153 23.2913 84.56 23.25C84.3603 23.2646 84.1597 23.2646 83.96 23.25V24.76C84.2016 24.7959 84.4457 24.8127 84.69 24.81C85.3143 24.8554 85.9348 24.68 86.4429 24.3144C86.951 23.9489 87.3146 23.4164 87.47 22.81L90.71 14.19H88.82L86.93 19.66Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M125 12.43C124.605 12.4277 124.214 12.5034 123.848 12.6527C123.483 12.802 123.15 13.022 122.87 13.3L122.73 12.61H120.34V25.53L123.06 24.94V21.81C123.601 22.2631 124.284 22.5109 124.99 22.51C126.93 22.51 128.71 20.92 128.71 17.4C128.71 14.18 126.91 12.43 125 12.43ZM124.35 20.06C124.111 20.0738 123.873 20.0342 123.651 19.9443C123.43 19.8543 123.231 19.7162 123.07 19.54V15.43C123.232 15.2473 123.433 15.1031 123.658 15.008C123.883 14.9128 124.126 14.8691 124.37 14.88C125.37 14.88 126.05 16.01 126.05 17.46C126.05 18.91 125.36 20.06 124.35 20.06Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M133.73 12.43C131.11 12.43 129.52 14.69 129.52 17.54C129.52 20.91 131.4 22.62 134.08 22.62C135.126 22.6371 136.159 22.3857 137.08 21.89V19.64C136.243 20.0621 135.317 20.2748 134.38 20.26C133.3 20.26 132.38 19.87 132.24 18.56H137.62C137.62 18.41 137.62 17.82 137.62 17.56C137.71 14.69 136.35 12.43 133.73 12.43ZM132.26 16.5C132.26 15.24 133.03 14.71 133.71 14.71C134.39 14.71 135.11 15.24 135.11 16.5H132.26Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M113 13.36L112.83 12.54H110.51V22.25H113.19V15.67C113.432 15.3761 113.758 15.1632 114.124 15.0595C114.491 14.9559 114.88 14.9665 115.24 15.09V12.54C114.831 12.3797 114.377 12.3746 113.965 12.5257C113.552 12.6767 113.209 12.9735 113 13.36Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M99.46 15.46C99.46 15.02 99.82 14.85 100.39 14.85C101.335 14.8703 102.261 15.1173 103.09 15.57V12.94C102.233 12.5933 101.315 12.42 100.39 12.43C98.18 12.43 96.71 13.61 96.71 15.59C96.71 18.69 100.85 18.19 100.85 19.52C100.85 20.04 100.41 20.21 99.85 20.21C98.7935 20.1483 97.766 19.84 96.85 19.31V22C97.7941 22.4211 98.8162 22.6391 99.85 22.64C102.11 22.64 103.67 21.49 103.67 19.48C103.62 16.12 99.46 16.72 99.46 15.46Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M107.28 10.24L104.63 10.82V19.75C104.616 20.1263 104.68 20.5015 104.816 20.8526C104.952 21.2036 105.158 21.5233 105.422 21.7919C105.686 22.0605 106.002 22.2725 106.351 22.4148C106.699 22.5572 107.073 22.627 107.45 22.62C108.107 22.6494 108.762 22.5226 109.36 22.25V20C109.01 20.15 107.3 20.66 107.3 19V15H109.36V12.66H107.3L107.28 10.24Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M116.25 11.7L118.98 11.13V8.97L116.25 9.54V11.7Z'}
                    fill={'#5C6C7F'}
                />
                <path
                    d={'M118.98 12.61H116.25V22.25H118.98V12.61Z'}
                    fill={'#5C6C7F'}
                />
            </g>
            <defs>
                <clipPath id={'clip0_4_524'}>
                    <rect width={'150'} height={'34'} fill={'white'} />
                </clipPath>
            </defs>
        </chakra.svg>
    );
}

function LockIcon () {
    return (
        <chakra.svg
            data-id={'lock-icon'}
            viewBox={'0 0 16 21'}
            marginTop={'-7px'}
            minWidth={{
                mobile: '12px',
                checkoutFooterTablet: '16px'
            }}
            maxWidth={{
                mobile: '12px',
                checkoutFooterTablet: '16px'
            }}
            width={{
                mobile: '12px',
                checkoutFooterTablet: '16px'
            }}
            height={{
                mobile: '16px',
                checkoutFooterTablet: '21px'
            }}
            fill={'none'}
        >
            <g clipPath={'url(#clip0_4_544)'}>
                <path
                    d={'M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM5 7V5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5Z'}
                    fill={'#5C6C7F'}
                />
            </g>
        </chakra.svg>
    );
}
