import { useMathleticsToast } from '@3plearning/chakra-mathletics-theme';
import * as Sentry from '@sentry/react';
import type { MutableRefObject } from 'react';
import type { NavigateFunction } from 'react-router';
import type { To } from 'react-router-dom';

import type {
    CreateCustomerSubscriptionMutation,
    CreateCustomerSubscriptionMutationVariables,
    CreateCustomerSubscriptionInput,
    CreatePaymentIntentFromQuoteMutation,
    CreatePaymentIntentFromQuoteMutationVariables
} from '../../__generated__/gateway-graphql';
import { LogExceptionMutation, LogExceptionMutationVariables } from '../../__generated__/gateway-graphql';
import type { ChargebeeInstance } from '../../chargebee/chargebee.types';
import type {
    SalesforceURLData,
    SubscriptionState
} from '../../subscription-model/subscription.types';
import { handleSubscriptionError, handleSubscriptionPayload } from '../__utils__/subscription-handlers';
import { assertCreateCustomerSubscriptionInput } from '../__utils__/validation';
import { NEW_CARD_PAYMENT_SOURCE_ID } from '../payment-sources/components/new-card/new-card';
import { createCustomerNewCardSubscriptionInput } from './create-customer-new-card-subscription-input';
import { createCustomerPaymentSourceSubscriptionInput } from './create-customer-payment-source-subscription-input';

export type CreateExistingCustomerSubscriptionOptions = {
    formValues: {
        customerPaymentSource?: string
    }
    cardRef: MutableRefObject<ChargebeeInstance>
    createCustomerSubscription: (
        variables: CreateCustomerSubscriptionMutationVariables
    ) => Promise<CreateCustomerSubscriptionMutation>
    createPaymentIntentFromQuote: (
        variables: CreatePaymentIntentFromQuoteMutationVariables
    ) => Promise<CreatePaymentIntentFromQuoteMutation>
    logException: (
        variables: LogExceptionMutationVariables
    ) => Promise<LogExceptionMutation>
    navigate: NavigateFunction
    toast: ReturnType<typeof useMathleticsToast>
    contactUsUrl: string
    salesforce: SalesforceURLData
    chargebeeInstance: ChargebeeInstance
    successRedirectTo: To
    subscription: SubscriptionState
};

export async function createExistingCustomerSubscription (options: CreateExistingCustomerSubscriptionOptions) {
    try {
        const {
            formValues: { customerPaymentSource },
            createCustomerSubscription,
            successRedirectTo,
            navigate,
            subscription
        } = options;

        Sentry.setExtra('customerPaymentSource', customerPaymentSource);
        Sentry.setExtra('createExistingCustomerSubscription subscription', subscription);

        let subscriptionInputData: CreateCustomerSubscriptionInput | null;

        if (!subscription.quoteAndPricingData?.quoteId) {
            return null;
        }

        // Handle the different scenarios charging with different payment sources for existing customers
        if (customerPaymentSource === NEW_CARD_PAYMENT_SOURCE_ID) {
            console.info('using authorizeWith3ds for a new card');

            // This is for using a new card, but for an existing customer.
            // The Chargebee api is different to using a payment source,
            // eg the cardRef is required from the credit card number / expiry etc components
            subscriptionInputData = await createCustomerNewCardSubscriptionInput({
                ...options,
                quoteId: subscription.quoteAndPricingData.quoteId
            });
        } else {
            console.info('using load3DSHandler -> handleCardPayment for a payment source id');

            // This requires special handling for the use of authorising an intent with a payment source
            subscriptionInputData = await createCustomerPaymentSourceSubscriptionInput({
                ...options,
                quoteId: subscription.quoteAndPricingData.quoteId
            });
        }

        // This is for BE devs debugging their local code
        if (window.__OVERRIDE_INTENT__) {
            console.info('returning early for __OVERRIDE_INTENT__ no subscription created');

            return null;
        }

        if(subscriptionInputData !== null) {
            assertCreateCustomerSubscriptionInput(subscriptionInputData);
            const payload = await createCustomerSubscription({
                input: subscriptionInputData
            });

            handleSubscriptionPayload({
                redirectTo: successRedirectTo,
                payload,
                navigate
            });
        }
    } catch (error: any) {
        handleSubscriptionError({
            info: 'createExistingCustomerSubscription',
            error,
            contactUsUrl: options.contactUsUrl,
            toast: options.toast
        });
    }
}
