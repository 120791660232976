import { CouponDetails, ProductItem } from '../__generated__/gateway-graphql';

export type SubscriptionState = {
    productCode: string | null
    childrenCount: number
    childrenDetail: Array<ChildDetail>
    profile: UserProfile | null
    location: UserLocation | null
    salesforce: SalesforceURLData | null
    isTokenValid: boolean
    quoteAndPricingData: QuoteAndPricingData | null
    isCountryChanged: boolean
    isValidZipCode: boolean
};

export type UserLocation = {
    currencyCode: string
    countryCode: string
    state: string | null
    zipcode: string
};

export type SalesforceURLData = {
    token: string
    userId: number
    currencyCode?: string
};

export type ChildDetail = {
    firstName: string
    lastName: string
    year: string
};

export type UserProfile = {
    firstName: string
    lastName: string
    email: string
    // Storing this here in plain text was Chen's original SF design.
    // To avoid the security issue, it needs backend support.
    password: string
};

export const INITIAL_SUBSCRIPTION_STATE: SubscriptionState = {
    childrenCount: 1,
    childrenDetail: [],
    productCode: null,
    profile: null,
    location: null,
    // This value is derived from url search params
    salesforce: null,
    isTokenValid: false,
    quoteAndPricingData: null,
    isCountryChanged: false,
    isValidZipCode: false
};

export type CouponCode = {
    couponCode: string
};

export type QuoteAndPricingData = {
    quoteId: string
    productItem: ProductItem | null
    coupon: CouponDetails | null
};
