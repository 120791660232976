import { i18n } from '@lingui/core';
import { I18nProvider as LinguiI18nProvider } from '@lingui/react';
import React, { PropsWithChildren } from 'react';

import { useLinguiActivate } from './use-lingui-activate';

export function I18nProvider ({ children }: PropsWithChildren<unknown>) {
    const { isReady } = useLinguiActivate();

    if (!isReady) {
        return null;
    }

    return (
        <LinguiI18nProvider i18n={i18n}>
            {children}
        </LinguiI18nProvider>
    );
}
