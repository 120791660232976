import {
    SkeletonCircle, SkeletonText
} from '@chakra-ui/react';
import React from 'react';

import {
    RootWrapper,
    AuthorWrapper,
    ContentWrapper,
    TestimonialText
} from '../testimonial.styles';
import { BurstBackground } from './burst-background';

// eslint-disable-next-line max-len
const PLACEHOLDER_TEXT = 'Mathletics is brilliant and I have seen with my own eyes what a solid foundation in maths it has given both my children. They are confident and comfortable with the subject and I believe that Mathletics is the reason.';

export function TestimonialSkeleton () {
    return (
        <RootWrapper data-testid={'testimonial-loading-fallback'}>
            <BurstBackground />

            <ContentWrapper data-testid={'testimonial'}>
                <TestimonialText as={'div'} paddingLeft={'40px'}>
                    <SkeletonText
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        noOfLines={4}
                        skeletonHeight={'18px'}
                        spacing={'12px'}
                    >
                        {PLACEHOLDER_TEXT}
                    </SkeletonText>
                </TestimonialText>

                <AuthorWrapper>
                    <SkeletonCircle
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        size={{
                            mobile: '60px',
                            testimonialsTablet: '130px'
                        } as any}
                    />
                </AuthorWrapper>
            </ContentWrapper>
        </RootWrapper>
    );
}
