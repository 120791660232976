import React from 'react';

import { useSelectedProductSalesforceQuery } from '../../hooks/selected-product/use-selected-product-salesforce-query';
import { CheckoutTermsPanelSkeleton } from '../checkout-terms-panel/components/checkout-terms-panel-skeleton';
import { CheckoutPricePanelView } from './checkout-price-panel-view';

export const CheckoutPriceSalesforcePanel = () => {
    const {
        data, isLoading, discountAmount
    } = useSelectedProductSalesforceQuery();

    if (isLoading || !data) {
        return <CheckoutTermsPanelSkeleton />;
    }

    return (
        <CheckoutPricePanelView
            data={data}
            discountAmount={discountAmount}
        />
    );
};
