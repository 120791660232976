import {
    createSlice, PayloadAction
} from '@reduxjs/toolkit';

import { isString } from '../__utils__/utils';
import { INITIAL_ROOT_STATE } from '../store/state.types';
import { SubscriptionState } from './subscription.types';

type ChooseSubscriptionPayload = PayloadAction<Pick<SubscriptionState, 'productCode' | 'childrenCount'>>;

type UpdateProfilePayload = PayloadAction<SubscriptionState['profile']>;

type UpdateLocationPayload = PayloadAction<{
    currencyCode: string
    countryCode: string
    productCode?: string
} | null>;

type UpdateStateCodePayload = PayloadAction<string | null>;

type UpdateChildrenDetailsPayload = PayloadAction<SubscriptionState['childrenDetail']>;

type UpdateSalesforcePayload = PayloadAction<SubscriptionState['salesforce']>;

type UpdateTurnstileTokenPayload = PayloadAction<Pick<SubscriptionState, 'isTokenValid'>>;

type ValidZipCodePayload = PayloadAction<Pick<SubscriptionState, 'isValidZipCode'>>;

type UpdatePricingDataPayload = PayloadAction<SubscriptionState['quoteAndPricingData']>;

type CountryChangedPayload = PayloadAction<Pick<SubscriptionState, 'isCountryChanged'>>;

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: INITIAL_ROOT_STATE.subscription,
    reducers: {
        clearData: () => {
            return INITIAL_ROOT_STATE.subscription;
        },
        updateSalesforceContract: (state, { payload }: UpdateSalesforcePayload) => {
            return {
                ...state,
                salesforce: payload
            };
        },
        updateProfile: (state, { payload }: UpdateProfilePayload) => {
            return {
                ...state,
                profile: payload
            };
        },
        updateLocationCountryCode: (
            state,
            { payload }: UpdateLocationPayload
        ) => {
            if (payload === null) {
                return {
                    ...state,
                    state: null,
                    location: null
                };
            }

            return {
                ...state,
                ...isString(payload.productCode) && {
                    productCode: payload.productCode
                },
                location: {
                    state: null,
                    countryCode: payload.countryCode,
                    currencyCode: payload.currencyCode,
                    zipcode: ''
                }
            };
        },
        updateLocationState: (state, { payload }: UpdateStateCodePayload) => {
            const countryCode = state.location?.countryCode;
            const currencyCode = state.location?.currencyCode;

            if (
                typeof countryCode !== 'string' ||
                typeof currencyCode !== 'string'
            ) {
                throw new Error('Unable to set state with no countryCode and currencyCode setup');
            }

            return {
                ...state,
                location: {
                    currencyCode,
                    countryCode,
                    state: payload,
                    zipcode: ''
                }
            };
        },
        updateLocationZipCode: (state, { payload }: any) => {
            return {
                ...state,
                location: {
                    ...state.location,
                    ...payload
                }
            };
        },
        chooseSubscription: (state, { payload }: ChooseSubscriptionPayload) => {
            return {
                ...state,
                productCode: payload.productCode,
                childrenCount: payload.childrenCount
            };
        },
        updateChildrenDetails: (state,
            { payload }: UpdateChildrenDetailsPayload) => {
            return {
                ...state,
                childrenDetail: payload
            };
        },
        updateTurnstileToken: (state, { payload }: UpdateTurnstileTokenPayload) => {
            return {
                ...state,
                isTokenValid: payload.isTokenValid
            };
        },
        updatePricingData: (state, { payload }: UpdatePricingDataPayload) => {
            return {
                ...state,
                quoteAndPricingData: payload
            };
        },
        isCountryChanged: (state, { payload }: CountryChangedPayload) => {
            return {
                ...state,
                isCountryChanged: payload.isCountryChanged
            };
        },
        isValidZipcode: (state, { payload }: ValidZipCodePayload) => {
            return {
                ...state,
                isValidZipCode: payload.isValidZipCode
            };
        }
    }
});

const { actions: subscriptionActions } = subscriptionSlice;

// @ts-ignore for debugging
window.__subscriptionActions__ = subscriptionActions;

export { subscriptionActions };

