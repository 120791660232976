import { Box, HStack, Image, SkeletonCircle } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React from 'react';

import { type PaymentSource, StatusEnum } from '../../../../__generated__/gateway-graphql';
import AmexIcon from './assets/ico_amex.svg';
import BanContactIcon from './assets/ico_bancontact.svg';
import DinersClubIcon from './assets/ico_dinersClub.svg';
import DiscoverIcon from './assets/ico_discover.svg';
import JCBIcon from './assets/ico_jcb.svg';
import MasterCardIcon from './assets/ico_masterCard.svg';
import OtherIcon from './assets/ico_other.svg';
import VisaIcon from './assets/ico_visa.svg';

export function SavedCard ({ brand, expiryMonth, expiryYear, id, lastFour, status }: PaymentSource) {
    function renderSelectIcon (brand: string) {
        switch (brand) {
        case 'Visa':
            return VisaIcon;
        case 'Mastercard':
            return MasterCardIcon;
        case 'DinersClub':
            return DinersClubIcon;
        case 'Bancontact':
            return BanContactIcon;
        case 'AmericanExpress':
            return AmexIcon;
        case 'Discover':
            return DiscoverIcon;
        case 'JCB':
            return JCBIcon;
        case 'Other':
            return OtherIcon;
        case 'NotApplicable':
            return OtherIcon;
        default:
            return OtherIcon;
        }
    }
    const renderExpiryDetailsOnCard = (cardStatus: StatusEnum) => {
        if (cardStatus !== StatusEnum.Expired) {
            return (
                <Box display={'flex'}>
                    <span data-testid={'expiry-month'}>{expiryMonth}</span>
                    <span>/</span>
                    <span data-testid={'expiry-year'}>{expiryYear}</span>
                </Box>
            );
        }
    };
    const renderExpiredIcon = (cardStatus: StatusEnum) => {
        if (cardStatus === StatusEnum.Expired) {
            return (
                <Box
                    width={'max-content'}
                    fontWeight={'500'}
                    fontSize={'12px'}
                    lineHeight={'15px'}
                    letterSpacing={'0.01em'}
                    textTransform={'uppercase'}
                    color={'error.800'}
                    padding={'4px 6px'}
                    alignItems={'center'}
                    borderWidth={'1px'}
                    borderColor={'error.700'}
                    borderRadius={'4px'}
                    margin={'0 !important'}
                >
                    <span>
                        {t`Expired`}
                    </span>
                </Box>
            );
        }
    };

    return (
        <>
            <Box data-testid={'display-saved-card'} display={'flex'} flexDirection={'row'} width={'100%'}>
                <Box
                    w={'100%'}
                    height={'56px'}
                    boxShadow={'0 0 0 1px #cbd3dc'}
                    borderRadius={'12px'}
                    px={'12px'}
                    py={'16px'}
                    background={status === StatusEnum.Expired ? 'neutral.50' : ''}
                    _hover={{ ...(status === StatusEnum.Expired) ? {} : { boxShadow: '0 0 0 1px #4DC4EF' } }}
                >
                    <HStack justifyContent={'space-between'}>
                        <Box
                            id={id}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            color={'neutral.600'}
                        >
                            <Image
                                width={'36px'}
                                height={'25px'}
                                src={renderSelectIcon(brand)}
                                fallback={<SkeletonCircle width={'36px'} height={'25px'} />}
                            />

                            <Box
                                display={'flex'}
                                marginRight={'24px'}
                                marginLeft={'8px'}
                                width={'124px'}
                                fontSize={'16px'}
                                lineHeight={'24px'}
                                letterSpacing={'0.15px'}
                            >
                                {t`Ending in`} {lastFour}
                            </Box>

                            {renderExpiryDetailsOnCard(status)}
                        </Box>

                        {renderExpiredIcon(status)}
                    </HStack>
                </Box>
            </Box>
        </>
    );
}
