import {
    Accordion,
    AccordionItem,
    Box
} from '@chakra-ui/react';
import React from 'react';

import { useFaqsDataQuery } from '../api/contentful/faq/use-faqs-data-query';
import type { Faq } from '../fetch-static-content/contentful/faqs';
import { AccordionIcon } from './components/accordion-icon';
import { FAQSkeleton } from './components/faq-skeleton';
import {
    FAQContainer,
    FAQTitle,
    FAQAccordionButton,
    FAQAccordionPanel
} from './faq.styles';

export function FAQ () {
    const {
        data, isLoading
    } = useFaqsDataQuery();

    if (isLoading || !data) {
        return <FAQSkeleton />;
    }

    return (
        <FAQContainer>
            <FAQTitle data-testid={'faq-title'}>
                Pricing FAQs
            </FAQTitle>

            <Accordion allowToggle>
                <AccordionItems faqs={data.items} />
            </Accordion>
        </FAQContainer>
    );
}

function AccordionItems ({ faqs }: { faqs: Array<Faq> }) {
    return (
        <>
            {faqs.map((faq, index) => (
                <AccordionItem data-testid={`faq-item-${index}`} key={faq.sys.id}>
                    <h2>
                        <FAQAccordionButton>
                            <Box
                                flex={'1'}
                                textAlign={'left'}
                                color={'neutral.900'}
                            >
                                {faq.question}
                            </Box>
                            <AccordionIcon />
                        </FAQAccordionButton>
                    </h2>

                    <FAQAccordionPanel>
                        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                        <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
                    </FAQAccordionPanel>
                </AccordionItem>
            ))}
        </>
    );
}
