import { Image, type ImageProps, Skeleton } from '@chakra-ui/react';
import React from 'react';

import { ContentfulLink } from '../contentful-link/contentful-link';
import logoSrc from './logo_mathletics.svg';

const LogoSize = {
    width: {
        mobile: '96px',
        tablet: '114px',
        desktop: '120px'
    },
    height: {
        mobile: '36px',
        tablet: '40px',
        desktop: '43px'
    }
};

export function MathleticsLogo (props: ImageProps) {
    return (
        <Image
            fallback={<LogoSkeleton />}
            data-testid={'mathletics-logo'}
            alt={'Mathletics Logo'}
            sx={LogoSize}
            {...props}
            src={logoSrc}
        />
    );
}

export function MathleticsLogoLink (props: ImageProps) {
    return (
        <ContentfulLink
            contentName={'Mathletics Home'}
            data-testid={'mathletics-logo-link'}
            fallback={<LogoSkeleton />}
        >
            <MathleticsLogo {...props} />
        </ContentfulLink>
    );
}

function LogoSkeleton () {
    return (
        <Skeleton
            data-testid={'mathletics-logo'}
            sx={LogoSize}
        />
    );
}
