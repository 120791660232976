import React from 'react';

import { useSelectedProductSalesforceQuery } from '../../hooks/selected-product/use-selected-product-salesforce-query';
import { CheckoutTermsPanelView } from './checkout-terms-panel-view';
import { CheckoutTermsPanelSkeleton } from './components/checkout-terms-panel-skeleton';

export const CheckoutTermsSalesforcePanel = () => {
    const {
        isLoading, data
    } = useSelectedProductSalesforceQuery();

    if (isLoading || !data) {
        return <CheckoutTermsPanelSkeleton />;
    }

    return (
        <CheckoutTermsPanelView
            data={data}
        />
    );
};
