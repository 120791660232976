import {
    QueryClient, QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { useApiMocking } from './use-api-mocking';

// Prevent duplicate toast messages with basic debounce
const retryAttempts = 2;

// Main Query configuration these values are different to the defaults,
// see comments for reasoning.
// @see https://tanstack.com/query/v4/docs/guides/important-defaults
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // We don't expect data in this app to change frequently,
            // so we can trust the cache to have the "latest value".
            // Note Salesforce also has api usage limits.
            staleTime: 60 * 5000,
            // This refetchOnWindowFocus setting is not useful as the data does not update much,
            // also it was mentioned from BS there are api limits on requests.
            refetchOnWindowFocus: false,
            // Default retry behaviour only twice 3 is unlikely to solve the issue,
            // try twice, wait on the delayMs exponential increase
            // @see https://tanstack.com/query/v4/docs/guides/query-retries
            retry: retryAttempts,
            // eslint-disable-next-line no-inline-comments
            retryDelay: /* istanbul ignore next */ (attemptIndex, error) => {
                const delayMs = Math.min(2000 * 2 ** attemptIndex, 30000);

                if (attemptIndex === retryAttempts) {
                    console.error('Max retry attempts reached, there maybe an issue with the Network.');

                    return delayMs;
                }

                console.error('Query default retry attempt:' + (attemptIndex + 1), error);
                console.info(`Retrying in ${delayMs}`);

                return delayMs;
            }
        }
    }
});

export function ApiProvider ({
    children, client = queryClient
}: PropsWithChildren<{
    client?: QueryClient
}>) {
    const { isReady } = useApiMocking();

    return (
        <QueryClientProvider client={client}>
            <>
                {isReady ? children : null}
                {process.env.NODE_ENV === 'development' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
            </>
        </QueryClientProvider>
    );
}
