import { Severity } from '@sentry/types';

import { useGetCustomerCurrencyQuery } from '../__generated__/gateway-graphql';
import { createQueryErrorHandler } from '../sentry/on-query-error';

type Options = {
    enabled?: boolean
    excludeTrials?: boolean
    userId: number
};

export function useCustomerCurrencyQuery ({
    excludeTrials = true,
    userId,
    enabled = true
}: Options) {
    return useGetCustomerCurrencyQuery({
        options: {
            // Cast to string as `enabled` flag check below will comply with string type requirement
            userId,
            excludeTrials
        }
    }, {
        enabled,
        onError: createQueryErrorHandler({
            severity: Severity.Fatal
        })
    });
}
