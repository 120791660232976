import * as Yup from 'yup';

import type {
    CreateCustomerSubscriptionInput,
    CreatePaymentIntentFromQuoteInput,
    CreateNewSubscriptionInput
} from '../../__generated__/gateway-graphql';

const createNewSubscriptionInputType = Yup.object({
    customerDetails: Yup.object({
        countryCode: Yup.string().required(),
        email: Yup.string().required(),
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        password: Yup.string().required(),
        state: Yup.string().optional().nullable()
    }),
    payment: Yup.object({
        quoteId: Yup.string().required(),
        intentId: Yup.string().required()
    })
});

/**
 * This is all required from the form design to submit a subscription to the BE
 * it will throw if the requirements are not met.
 */
export function assertCreateNewSubscriptionInput (input: any): asserts input is CreateNewSubscriptionInput {
    createNewSubscriptionInputType.validateSync(input);
}

const createCreateCustomerSubscriptionInputType = Yup.object({
    payment: Yup.object({
        quoteId: Yup.string().required(),
        intentId: Yup.string().required(),
        paymentSourceId: Yup.string().optional()
    })
});

export function assertCreateCustomerSubscriptionInput (input: any): asserts input is CreateCustomerSubscriptionInput {
    createCreateCustomerSubscriptionInputType.validateSync(input);
}

const newCustomerPaymentIntentInput = Yup.object({
    quoteId: Yup.string().required(),
    email: Yup.string().required()
});

export function assertNewCustomerPaymentIntent (input: any): asserts input is CreatePaymentIntentFromQuoteInput {
    newCustomerPaymentIntentInput.validateSync(input);
}

const newCardCustomerPaymentIntent = Yup.object({
    quoteId: Yup.string().required(),
    sessionId: Yup.string().required(),
    userId: Yup.number().required()
});

export function assertNewCardCustomerPaymentIntent (input: any): asserts input is CreatePaymentIntentFromQuoteInput {
    newCardCustomerPaymentIntent.validateSync(input);
}

const existingCustomerPaymentIntentInput = Yup.object({
    paymentSourceId: Yup.string().required(),
    quoteId: Yup.string().required(),
    sessionId: Yup.string().required(),
    userId: Yup.number().required()
});

export function assertExistingCustomerPaymentIntent (input: any): asserts input is CreatePaymentIntentFromQuoteInput {
    existingCustomerPaymentIntentInput.validateSync(input);
}
