/* istanbul ignore file */
// Not worth testing scroll in jsdom
import { useLayoutEffect } from 'react';

/**
 * When this initially renders it will force the browser to scroll to the top.
 * This is useful in scenarios such as react-router url transitions where the
 * scroll position needs to be reset to the top when the new route is transitioned to.
 */
export function useScrollToTop () {
    useLayoutEffect(() => {
        window?.scrollTo(0, 0);
    }, []);
}
