// coverage for this is out of scope atm
/* istanbul ignore file */
import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill';
import { captureException } from '@sentry/browser';
import { useRef } from 'react';

import { useEffectOnce } from '../../__utils__/use-effect-once';
import { isString } from '../../__utils__/utils';

/**
 * Feature detection on Polyfill requirement,
 * using 'narrowSymbol' will throw errors on old versions of Safari.
 *
 * @see https://github.com/mdn/browser-compat-data/issues/9319
 *
 * formatjs does not cover this in their `shouldPolyfill` api.
 *
 * @see https://github.com/formatjs/formatjs/blob/main/packages/intl-numberformat/should-polyfill.ts
 */
export function needsNarrowSymbolPolyfill () {
    try {
        new Intl.NumberFormat('AUS', {
            currency: 'AUD',
            style: 'currency',
            // narrowSymbol removes prefix of eg, CA for CAD currency
            currencyDisplay: 'narrowSymbol',
            currencySign: 'standard',
            signDisplay: 'never',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(888.88);

        return false;
    } catch {
        // if we end up here assume it needs a polyfill
        return true;
    }
}

type Options = {
    isPolyfillRequired: boolean
    setIsReady: (isReady: boolean) => any
};

/**
 * On demand load the correct formatjs intl-numberformat polyfill
 */
export function useTPricePolyFiller ({ setIsReady, isPolyfillRequired }: Options) {
    const isLoading = useRef(false);
    const tPricePolyFilled = useRef<typeof tPriceFormat>(() => '');

    useEffectOnce(() => {
        if (!isPolyfillRequired) return;
        // noinspection JSIgnoredPromiseFromCall
        loadPolyFillForLocale('en');
    });

    // Use the method directly if no polyfill is required
    if (!isPolyfillRequired) return tPriceFormat;

    async function loadPolyFillForLocale (locale: string) {
        if (isLoading.current) return;

        if (!isString(shouldPolyfill(locale))) {
            return;
        }

        console.info('loading intl-numberformat polyfill locale', locale);

        isLoading.current = true;
        setIsReady(false);

        await import(
            /* webpackChunkName: "intl-numberformat-polyfill"*/
            '@formatjs/intl-numberformat/polyfill-force'
        );
        await import(
            `@formatjs/intl-numberformat/locale-data/${locale}`
        );

        isLoading.current = false;
        tPricePolyFilled.current = tPriceWrapped;
        setIsReady(true);
    }

    function tPriceWrapped (options: TPriceOptions) {
        const unsupportedLocale = shouldPolyfill(options.countryCode);

        if (isLoading.current) {
            return '';
        }

        if (isString(unsupportedLocale)) {
            // noinspection JSIgnoredPromiseFromCall
            loadPolyFillForLocale(unsupportedLocale);
        }

        // it should now be polyfill safe to use the method
        return tPriceFormat(options);
    }

    return tPricePolyFilled.current;
}

export type TPriceOptions = {
    countryCode: string
    currencyCode: string
    amount: number
};

/**
 * Warning, do not use this directly, older browsers need 'narrowSymbol'
 * support, instead the polyfill is provided when using useTPrice hook.
 */
function tPriceFormat ({
    countryCode,
    currencyCode,
    amount
}: TPriceOptions): string {
    let newCountryCode;

    /** Following country code gives incorrect format for currencies(EUR, USD and AUD) using Intl.NumberFormat library.
     * To avoid this we are using assigning respective country codes for these countries instead of using their default country codes
     */
    const europeanCountryList = ['AZE', 'HRV', 'CZE', 'FIN', 'FRA', 'DEU', 'HUN', 'ITA', 'NLD', 'NOR', 'POL', 'SWE', 'UKR'];
    const americanCodeCountryList = ['SLV', 'RUS', 'TUR'];

    if (europeanCountryList.includes(countryCode)) {
        newCountryCode = 'ARM';
    } else if (americanCodeCountryList.includes(countryCode)) {
        newCountryCode = 'USD';
    } else if (countryCode === 'IND') {
        newCountryCode = 'AUS';
    } else {
        newCountryCode = countryCode;
    }
    try {
        return new Intl.NumberFormat(newCountryCode, {
            currency: currencyCode,
            style: 'currency',
            // narrowSymbol removes prefix of eg, CA for CAD currency
            currencyDisplay: 'narrowSymbol',
            currencySign: 'standard',
            signDisplay: 'never',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    } catch (error) {
        captureException(error, {
            extra: {
                tPrice: 'Intl.NumberFormat',
                countryCode,
                currencyCode,
                amount
            }
        });
    }

    // Fallback with just the number, some old browser don't work
    return amount.toString();
}
