import { Center } from '@chakra-ui/react';
import React from 'react';

import { useTestimonialsDataQuery } from '../api/contentful/testimonials/use-testimonials-data-query';
import { TestimonialSkeleton } from './components/testimonial-skeleton';
import { TestimonialView } from './components/testimonial-view';

export function Testimonial () {
    return (
        <Center
            data-testid={'testimonial-comp'}
            mx={{
                mobile: 0,
                testimonialsTablet: '50px',
                desktop: 0
            }}
        >
            <TestimonialController />
        </Center>
    );
}

function TestimonialController () {
    const {
        data, isLoading
    } = useTestimonialsDataQuery();

    if (isLoading || !data) {
        return (
            <TestimonialSkeleton />
        );
    }

    return (
        <TestimonialView {...data} />
    );
}
