import { useMathleticsToast } from '@3plearning/chakra-mathletics-theme';
import { t } from '@lingui/macro';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    CreateContactDocument,
    type CreateContactMutation,
    type CreateContactMutationVariables,
    MarketingInfoInput
} from '../../../../../../../__generated__/gateway-graphql';
import { isString, USER_ATTEMPT_MAX_LIMIT_REACHED } from '../../../../../../../__utils__/utils';
import { useFetcher } from '../../../../../../../api/gateway/use-fetcher';
import { useContentfulUrl } from '../../../../../../../components/contentful-url-button/use-contentful-url';
import {
    useFormikValidateAfterSubmit
} from '../../../../../../../components/formik/hooks/use-formik-validate-after-submit';
import { useOnPasswordChange } from '../../../../../../../components/formik/hooks/use-on-password-change';
import { useEnvConfig } from '../../../../../../../env-config';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/store';
import { subscriptionActions } from '../../../../../../../subscription-model/subscription.slice';
import type { UserProfile } from '../../../../../../../subscription-model/subscription.types';
import { createProfileSchema, EMPTY_FORM } from '../__utils__/form-validation';

const INITIAL_PROFILE_FORM_STATE: UserProfile = {
    firstName: '',
    lastName: '',
    email: '',
    password: ''
};

/**
 * Implementation of the validation UX requested for the form:
 * - The validation should only occur after first submitting the form
 * - After validation is enabled, it can happen onChange / onBlur
 * - Password validation should occur in a "real-time" popup while typing
 * - The form state on successful submission, must persist to the redux store and sessionStorage
 */
export function useCreateAccountForm () {
    const [checkboxValues, setCheckboxValues] = useState<string[]>([]);
    const { PARENT_GATEWAY_API_URL, ENABLE_CREATE_CONTACT } = useEnvConfig();
    const profileSchema = useMemo(() => createProfileSchema({
        url: PARENT_GATEWAY_API_URL
    }), [PARENT_GATEWAY_API_URL]);
    const contactUsUrl = useContentfulUrl('Contact Us');
    const dispatch = useAppDispatch();
    const toast = useMathleticsToast();
    const navigate = useNavigate();
    const createContact = useFetcher<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument);
    const currencyCode = useAppSelector(
        rootState => rootState.subscription.location?.currencyCode || ''
    );
    const storedProfile = useAppSelector(
        rootState => rootState.subscription.profile || INITIAL_PROFILE_FORM_STATE
    );
    const isTokenValid = useAppSelector(
        rootState => rootState.subscription.isTokenValid
    );
    const quoteId = useAppSelector(
        rootState => rootState.subscription.quoteAndPricingData?.quoteId || ''
    );
    const turnstileTokenError = t({
        id: 'Error - Invalid - Turnstile',
        values: { contactUsUrl }
    });
    /** Get marketing cookie from setURLParamsCookie in browser*/
    const getMarketingCookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith('setURLParamsCookie='))
        ?.split('=')[1];
    const marketingCookieRetrieved = getMarketingCookie ? decodeURIComponent(getMarketingCookie) : null;
    const onSubmit = async (values: UserProfile) => {
        // Intentionally do not await, let it happen in bg.
        // noinspection ES6MissingAwait
        if (isTokenValid) {
            /** if marketing cookie is set then only pass the cookie object
             * else pass null value to marketing cookie object
             * */
            const marketingCookie = resolveMarketingCookieContract({
                marketingCookieRetrieved,
                checkboxValues,
                currencyCode
            });
            const result = await createContactHandler({
                quoteId,
                values,
                createContact,
                enableCreateContact: ENABLE_CREATE_CONTACT,
                marketingInfo: marketingCookie,
                contactUsUrl,
                toast
            });

            if(result) {
                dispatch(subscriptionActions.updateProfile(values));
                navigate('/subscription/create/checkout');
            }
        } else {
            /** By default, token is false.
             * only show error if token is not valid in all env.
             * continue to checkout page if turnstile flag is false */
            toast({
                description: turnstileTokenError,
                status: 'error',
                duration: 9000
            });
        }
    };
    const initialValues = {
        ...EMPTY_FORM,
        ...storedProfile
    };
    const formValue = useFormikValidateAfterSubmit({
        initialValues,
        onSubmit,
        validationSchema: profileSchema
    });
    const {
        passwordValidation,
        onPasswordChange
    } = useOnPasswordChange(profileSchema);

    let isCreateAccountButtonDisabled = currencyCode === 'EUR' || currencyCode === 'GBP';

    if(isCreateAccountButtonDisabled && checkboxValues.includes('termsAndConditionsCookie')) {
        isCreateAccountButtonDisabled = false;
    }

    return {
        formValue,
        passwordValidation,
        onPasswordChange,
        checkboxValues,
        setCheckboxValues,
        currencyCode,
        isCreateAccountButtonDisabled
    };
}

type Options = {
    values: UserProfile
    createContact: (input: CreateContactMutationVariables) => Promise<CreateContactMutation>
    enableCreateContact: boolean
    marketingInfo?: MarketingInfoInput | null
    quoteId: string
    contactUsUrl?: string | null
    toast: ReturnType<typeof useMathleticsToast>
};

async function createContactHandler ({
    values,
    createContact,
    enableCreateContact,
    marketingInfo,
    quoteId,
    contactUsUrl,
    toast
}: Options) {
    // TODO remove this when SF and Product backends are ready for release to prod
    if (!enableCreateContact) {
        console.info('Currently prod does not use createContact api here');

        return;
    }
    const createUserMaxAttemptError = t({
        id: 'Error - Create - User - Failed',
        values: { contactUsUrl }
    });

    // if there is any error, ux will then prevent the user to continue to the next page.
    // this is to restrict the bot attack for creating dummy accounts in db
    // The contact is actually created later in `createSubscription`,
    // even if none exists.
    return createContact({
        contact: {
            quoteId,
            email: values.email,
            firstname: values.firstName.trim(),
            lastname: values.lastName.trim(),
            marketingInfo
        }
    }).then(onResolve, onReject);

    function onResolve (data: CreateContactMutation) {
        const result = data?.result;

        // Payload has explicit error property with errorMessage,
        // the application cannot continue without first creating a
        // Salesforce contact user in the system.
        if (
            result?.isSuccessful !== true ||
            typeof result?.errorMessage === 'string'
        ) {
            const errorMessage = isString(result?.errorMessage) ?
                result?.errorMessage : 'Unknown createContact error';

            console.log('errorMessage', errorMessage);

            if (errorMessage === USER_ATTEMPT_MAX_LIMIT_REACHED) {
                toast({
                    description: createUserMaxAttemptError,
                    status: 'error',
                    duration: 9000
                });
            }

            return false;
        }

        return true;
    }

    function onReject (error: unknown) {
        // If this fails intentionally not handle it,
        // instead the createSubscription will handle creating the user.
        console.error('Create contact failed', error);

        Sentry.captureException(error, {
            level: Severity.Fatal,
            extra: {
                email: values.email
            }
        });

        return false;
    }
}

type ResolveMarketingCookieContractProps = {
    marketingCookieRetrieved: string | null
    checkboxValues?: string[]
    currencyCode?: string
};

export function resolveMarketingCookieContract ({
    marketingCookieRetrieved,
    checkboxValues,
    currencyCode
}: ResolveMarketingCookieContractProps) {
    let campaignDescription;
    let emailOptIn = false;
    let marketingOptIn = false;

    if (checkboxValues && checkboxValues.includes('termsAndConditionsCookie')) {
        emailOptIn = true;
    }
    if (checkboxValues && checkboxValues.includes('marketingOptInCookie')) {
        marketingOptIn = true;
    }

    if (marketingCookieRetrieved !== null) {
        const marketingCookieValue = new URLSearchParams(marketingCookieRetrieved.replace('?', '&'));
        const marketingCookieObject = Object.fromEntries(marketingCookieValue);
        /** Get utm source cookie from __gtm_referrer in browser.
         * if utm_source is absent in the above cookie set this value for source */
        const getUTMSourceCookie = document.cookie
            .split('; ')
            .find((row) => row.startsWith('__gtm_referrer='))
            ?.split('=')[1];
        const utmSourceCookieValue = getUTMSourceCookie ? decodeURIComponent(getUTMSourceCookie) : '';
        const isGDPRRegion = currencyCode === 'EUR' || currencyCode === 'GBP';

        if (marketingCookieObject.utm_campaign !== '') {
            campaignDescription = 'This customer has shown interest in the following campaign: ' + marketingCookieObject.utm_campaign;
        } else {
            campaignDescription = 'There is no campaign currently associated to this customer';
        }

        return {
            /** check source from utm_source in marketingCookieValue cookie.
             * if source is absent get source from __gtm_referrer cookie and send it to BE
             */
            utmSource: marketingCookieObject.utm_source !== '' ? marketingCookieObject.utm_source : utmSourceCookieValue,
            utmCampaign: marketingCookieObject.utm_campaign,
            utmMedium: marketingCookieObject.utm_medium,
            utmContent: marketingCookieObject.utm_content,
            utmTerm: marketingCookieObject.utm_term,
            gclid: marketingCookieObject.gclid,
            description: campaignDescription,
            emailOptIn: isGDPRRegion ? emailOptIn : true,
            marketingOptIn: isGDPRRegion ? marketingOptIn : true
        };
    } else {
        return null;
    }
}
