import type { SystemStyleObject } from '@chakra-ui/react';

/**
 * Docs from Chakra: Text and Layer Styles
 *
 * @see https://chakra-ui.com/docs/styled-system/features/text-and-layer-styles
 *
 * In most projects you might find yourself repeating specific text properties
 * (font size, font weight, line height) or layer properties (bg, color, shadow).
 * This can be painful as your project grows in size.
 *
 * Using textStyles and layerStyle props can help you keep things organized and consistent.
 */
export const layerStyles: SystemStyleObject = {
    card: {
        borderRadius: '20px',
        background: 'app.white',
        boxShadow: 'baseShadow'
    }
};

export const textStyles: SystemStyleObject = {
    pageTitleLarge: {
        color: 'neutral.900',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: {
            desktop: '40px',
            mobile: '28px'
        }
    },
    pageTitleMedium: {
        color: 'neutral.900',
        fontStyle: 'normal',
        fontWeight: {
            mobile: 'normal',
            tablet: 'medium'
        },
        fontSize: {
            desktop: '30px',
            mobile: '28px'
        }
    },
    pageSubtitle: {
        color: 'neutral.700',
        fontSize: {
            tablet: '14px',
            desktop: '16px'
        },
        lineHeight: {
            mobile: '143%',
            tablet: '150%'
        },
        fontWeight: 'normal',
        letterSpacing: {
            tablet: '-0.15px',
            desktop: '-0.1px'
        }
    },
    cardHeading: {
        fontWeight: 500,
        fontSize: {
            base: '26px',
            modalCardTablet: '30px'
        },
        lineHeight: '123.5%',
        letterSpacing: {
            base: '0.25px',
            modalCardTablet: '-0.6px'
        },
        color: 'neutral.900'
    },
    cardBody: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '-0.1px',
        color: 'neutral.700'
    },
    createAccountTerms: {
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '20px',
        letterSpacing: '-0.15px'
    },
    createAccountTermsLink: {
        fontSize: '13px',
        fontWeight: 'medium',
        lineHeight: '20px',
        letterSpacing: '-0.15px'
    },
    createAccountSignIn: {
        color: 'neutral.900',
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: {
            mobile: '14px',
            tablet: '16px'
        },
        lineHeight: {
            mobile: '143%',
            tablet: '150%'
        },
        letterSpacing: {
            mobile: '-0.15px',
            tablet: '-0.1px'
        }
    },
    createAccountSignInLink: {
        fontWeight: '500',
        fontSize: {
            mobile: '13px',
            tablet: '16px'
        },
        lineHeight: {
            mobile: '19px',
            tablet: '24px'
        },
        letterSpacing: '-0.15px'
    },
    formSectionHeading: {
        color: 'neutral.900',
        fontSize: '18px',
        fontWeight: 'normal',
        lineHeight: {
            mobile: '27px',
            tablet: '24px'
        },
        letterSpacing: '0.15px'
    }
};
