import React, { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ApiProvider } from '../../api/api-provider';
import { Compose } from '../../components/compose/compose';
import { EnvConfigProvider } from '../../env-config';
import { I18nProvider } from '../../i18n/lingui/i18n-provider';
import { IntlPolyfillProvider } from '../../i18n/t-price/intl-polyfill-provider';
import { ReduxStoreProvider } from '../../store/redux-store-provider';
import { AppStylesProvider } from '../../styles/app-styles-provider';

type Props = PropsWithChildren<unknown>;

const providers = [
    EnvConfigProvider,
    I18nProvider,
    AppStylesProvider,
    ReduxStoreProvider,
    ApiProvider,
    BrowserRouter,
    IntlPolyfillProvider
];

export const AppProviders = ({ children }: Props) => {
    return (
        <Compose wrappers={providers}>
            {children}
        </Compose>
    );
};
