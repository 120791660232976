import { configureStore } from '@reduxjs/toolkit';
import {
    TypedUseSelectorHook, useDispatch, useSelector
} from 'react-redux';
import {
    persistStore,
    persistCombineReducers,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';

import { persistConfig } from './persist/store-persistor';
import { rootReducers } from './root-reducer';
import type { RootState } from './state.types';

const persistedReducer = persistCombineReducers<RootState>(
    persistConfig,
    rootReducers
);

export const store = configureStore<RootState>({
    // @ts-expect-error persist types not compat with toolkit configureStore.
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV === 'development' ? {
        name: '3p-b2c-cra',
        trace: true
    } : false,
    // Middleware require for documented workaround for use of redux-toolkit and redux-persist libraries.
    // @see https://github.com/reduxjs/redux-toolkit/blob/e5ed5bcbdc3690a79eaae268a9f810d107222e35/docs/usage/usage-guide.md#use-with-redux-persist
    // @ts-expect-error persist types not compat with toolkit
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
