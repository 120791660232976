import {
    VStack,
    HStack,
    Text,
    PopoverArrow,
    PopoverContent
} from '@chakra-ui/react';
import React from 'react';

import { ValidationInvalidIcon } from '../icons/validation-invalid-icon';
import { ValidationIsValidIcon } from '../icons/validation-is-valid-icon';

export type ValidationItem = {
    labelText: string
    isValid: boolean
};

type Props = {
    state: Array<ValidationItem>
};

export function PopoverValidationHelper ({ state }: Props) {
    return (
        <PopoverContent
            filter={'drop-shadow(0px 2px 20px rgba(0, 25, 55, 0.08))'}
            p={'24px'}
            borderRadius={'12px'}
        >
            <PopoverArrow />

            <VStack spacing={'8px'} alignItems={'flex-start'}>
                {state.map(validation => (
                    <ValidationHelperItem
                        key={validation.labelText}
                        {...validation}
                    />
                ))}
            </VStack>
        </PopoverContent>
    );
}

function ValidationHelperItem (props: ValidationItem) {
    return (
        <HStack alignItems={'flex-start'}>
            {props.isValid ? <ValidationIsValidIcon /> : <ValidationInvalidIcon />}

            <Text color={'neutral.700'} fontSize={'14px'} fontWeight={'400'}>
                {props.labelText}
            </Text>
        </HStack>
    );
}
