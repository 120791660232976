// no value in covering this
/* istanbul ignore file */
import {
    GetAllCountriesDocument,
    GetUserLocationDocument
} from './__generated__/gateway-graphql';
import { queryClient } from './api/api-provider/api-provider';
import { createStandaloneFetcher } from './api/gateway/standalone-fetcher';

const fetcher = createStandaloneFetcher();

/**
 * This is a performance tweak to load data according to a url match
 * before React renders.
 *
 * WARNING If you modify the requirements on this data or the routes or the URLs,
 * you also need to update this code.
 *
 * @see https://react-query-v3.tanstack.com/guides/prefetching
 */
export function startDataPrefetcher () {
    if (/subscription\/create/.test(window.location.href)) {
        console.info('prefetching subscription create');
        // This match is for pages that require pricing data to be loaded,
        // to determine this, we need user location and countries for mapping.
        //
        // This match on subscription create and it's sub routes where pricing data
        // is always required

        queryClient.prefetchQuery(['GetAllCountries'], () => {
            return fetcher({
                query: GetAllCountriesDocument,
                url: window._env_.PARENT_GATEWAY_API_URL
            });
        });
        queryClient.prefetchQuery(['GetUserLocation'], () => {
            return fetcher({
                query: GetUserLocationDocument,
                url: window._env_.PARENT_GATEWAY_API_URL
            });
        });
    }
}
