import type { SystemStyleObject } from '@chakra-ui/react';

/**
 * Docs from Chakra: Text and Layer Styles
 *
 * @see https://chakra-ui.com/docs/styled-system/features/text-and-layer-styles
 *
 * In most projects you might find yourself repeating specific text properties
 * (font size, font weight, line height) or layer properties (bg, color, shadow).
 * This can be painful as your project grows in size.
 *
 * Using textStyles and layerStyle props can help you keep things organized and consistent.
 */
export const layerStyles: SystemStyleObject = {
    card: {
        borderRadius: '20px',
        background: 'app.white',
        boxShadow: 'baseShadow'
    }
};
