import { Center, type CenterProps } from '@chakra-ui/react';
import React, { forwardRef, type PropsWithChildren } from 'react';

import { ModalCard, type ModalCardProps } from '../../components/modal-card/modal-card';
import { BodyHeight } from '../page-body/page-body';

type Props = PropsWithChildren<& ModalCardProps
    & {
    containerProps?: CenterProps
    ['data-testid']?: string
}>;

export const PageModal = forwardRef<HTMLDivElement, Props>(({
    containerProps,
    'data-testid': dataTestId = 'page-modal',
    children,
    ...restProps
}, ref) => {
    return (
        <Center
            data-testid={dataTestId}
            position={'relative'}
            minH={BodyHeight}
            w={'100%'}
            paddingTop={{
                base: 0,
                modalCardTablet: '22px'
            }}
            alignItems={{
                base: 'start',
                modalCardTablet: 'center'
            }}
            {...containerProps}
        >
            <ModalCard
                {...restProps}
                ref={ref}
            >
                {children}
            </ModalCard>
        </Center>
    );
});
