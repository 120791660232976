/* istanbul ignore file */
// Testing this has a large scope, ignoring coverage.
// If anyone wants the challenge checkout https://wix.github.io/sentry-testkit
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import type { Extras } from '@sentry/types';

import { handleFetchError } from '../../sentry/handle-fetch-error';
import { fetcher, FetcherOptions } from './fetcher';

type Options = {
    extras?: Extras
    severity?: Severity
};

/**
 * Standalone means you can use the fetcher outside of React render.
 * This function will also wrap any errors to be intercepted and logged with
 * Sentry logging.
 *
 * eg useGatewayFetcher requires a React hook for the url, this is not possible
 * if needing to use this for prefetching or integration with other libraries.
 */
export function createStandaloneFetcher<TData, TVariables> (options: Options = {}) {
    const {
        extras,
        severity = Severity.Critical
    } = options;

    return async (fetcherOptions: FetcherOptions<TVariables>) => {
        try {
            // noinspection UnnecessaryLocalVariableJS // leave this for try/catch
            const response = await fetcher<TData, TVariables>(fetcherOptions);

            return response;
        } catch (error) {
            console.error('fetcher error', error);

            Sentry.withScope((scope) => {
                scope.setLevel(severity);

                if (extras) {
                    scope.setExtras(extras);
                }

                handleFetchError(error);
            });

            throw error;
        }
    };
}
