/* istanbul ignore file */
// Testing this has a large scope, ignoring coverage.
// If anyone wants the challenge checkout https://wix.github.io/sentry-testkit
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import type { Extras } from '@sentry/types';

import { handleFetchError } from './handle-fetch-error';

type Options = {
    extras?: Extras
    severity?: Severity
};

export function createQueryErrorHandler (options: Options = {}) {
    const {
        extras,
        severity = Severity.Critical
    } = options;

    return (error: unknown) => {
        console.error('query onError', error);

        Sentry.withScope((scope) => {
            scope.setLevel(severity);

            if (extras) {
                scope.setExtras(extras);
            }

            handleFetchError(error);
        });
    };
}
