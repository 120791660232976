import type { ImageProps } from '@chakra-ui/react';
import React from 'react';

import { MathleticsLogo } from '../../components/mathletics-logo/mathletics-logo';
import { useThemeBreakpointMinWidth } from '../../hooks/use-breakpoints';

export function ModalMathleticsLogo (props: ImageProps) {
    const smallestBreakpoint = useThemeBreakpointMinWidth('modalCardTablet');

    if (smallestBreakpoint) {
        return (
            <MathleticsLogo
                marginBottom={'20px'}
                {...props}
            />
        );
    }

    return null;
}
