/* istanbul ignore file */
import { chakra, Skeleton } from '@chakra-ui/react';
import type { SystemStyleObject } from '@chakra-ui/styled-system';
// @ts-ignore there are no typescript types for chargebee-js-react
import { CardComponent, CardCVV, CardExpiry, CardNumber } from '@chargebee/chargebee-js-react-wrapper';
import React, { forwardRef } from 'react';

import type { ChargebeeInstance } from '../chargebee.types';
import { useCreditCardField } from './hooks/use-credit-card-field';

type Props = {
    wrapperStyles?: SystemStyleObject
};

/**
 * Themed version of Chargebee hosted component fields.
 *
 * @see https://www.chargebee.com/docs/2.0/hosted-components-and-fields.html
 */
export const CreditCardField = forwardRef<ChargebeeInstance, Props>((props, cardRef) => {
    const {
        isLoading,
        isRowStackMode,
        onChange,
        onReady,
        onFocus,
        onBlur,
        cardComponentWrapperStyles,
        cardComponentStyles,
        cardComponentClasses,
        validationState,
        errorLabelStyles,
        formFieldError
    } = useCreditCardField({
        wrapperStyles: props.wrapperStyles
    });

    return (
        <chakra.div
            data-testid={'credit-card-field'}
            data-is-complete-all={validationState.isAllComplete}
            data-is-valid-all={validationState.isAllValid}
            sx={cardComponentWrapperStyles}
        >
            {isLoading ?
                <Skeleton height={'52px'} w={'full'} mb={'36px'} /> : null}

            <CardComponent
                ref={cardRef}
                className={'fieldset field'}
                styles={cardComponentStyles}
                classes={cardComponentClasses}
                onChange={onChange}
                onReady={onReady}
            >
                <CardNumber
                    onFocus={onFocus('CardNumber')}
                    onBlur={onBlur}
                    className={`ex1-input card ${formFieldError?.fields?.number ? 'error' : ''}`}
                    placeholder={'XXXX XXXX XXXX XXXX'}
                />

                <label className={'error-helper-text'} style={errorLabelStyles}>
                    {(formFieldError?.fields?.number) ? formFieldError.fields.number : null}
                </label>

                <CardExpiry
                    onFocus={onFocus('CardExpiry')}
                    onBlur={onBlur}
                    className={`ex1-input expiry ${formFieldError?.fields?.expiry ? 'error' : ''}`}
                />

                <label className={'error-helper-text'} style={errorLabelStyles}>
                    {(formFieldError?.fields?.expiry) ? formFieldError.fields.expiry : null}
                </label>

                <CardCVV
                    onFocus={onFocus('CardCVV')}
                    onBlur={onBlur}
                    className={`ex1-input cvv ${formFieldError?.fields?.cvv ? 'error' : ''}`}
                />

                <label className={'error-helper-text'} style={errorLabelStyles}>
                    {(formFieldError?.fields?.cvv) ? formFieldError.fields.cvv : null}
                </label>
            </CardComponent>

            {isRowStackMode ?
                <label
                    className={'error-helper-text'}
                    style={errorLabelStyles}
                >
                    {formFieldError.combinedMessage}
                </label> : null}
        </chakra.div>
    );
});
