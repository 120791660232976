import {
    Box, chakra, Text
} from '@chakra-ui/react';

export const RootWrapper = chakra(Box, {
    baseStyle: {
        position: 'relative',
        minW: '200px',
        minH: '192px',
        marginTop: '24px',
        marginBottom: '40px'
    }
});

export const ContentWrapper = chakra(Box, {
    baseStyle: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        minHeight: '192px',
        maxWidth: '816px',
        overflow: 'hidden',
        borderRadius: '20px',
        backgroundColor: 'app.white',
        borderWidth: '1px',
        borderColor: 'neutral.200',
        borderStyle: 'solid',
        flexDirection: {
            mobile: 'column',
            testimonialsTablet: 'row'
        },
        paddingBottom: {
            mobile: '30px',
            testimonialsTablet: 0
        }
    }
});

export const TestimonialText = chakra(Text, {
    baseStyle: {
        zIndex: 1,
        paddingTop: {
            mobile: '32px',
            testimonialsTablet: '10px'
        },
        paddingBottom: {
            mobile: '14px',
            testimonialsTablet: '10px'
        },
        paddingLeft: {
            mobile: '40px',
            testimonialsTablet: '32px',
            desktop: '72px'
        },
        paddingRight: {
            mobile: '40px',
            testimonialsTablet: '32px',
            desktop: '40px'
        },
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '150%',
        color: 'neutral.700',
        letterSpacing: '-0.1px',
        fontSize: '16px'
    }
});

export const AuthorWrapper = chakra(Box, {
    baseStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: {
            mobile: 'row-reverse',
            testimonialsTablet: 'column'
        },
        minWidth: {
            mobile: '100%',
            testimonialsTablet: '160px'
        },
        paddingRight: {
            mobile: '44px',
            testimonialsTablet: 0
        }
    }
});

export const AuthorLabel = chakra(Text, {
    baseStyle: {
        position: { testimonialsTablet: 'absolute' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: { testimonialsTablet: 0 },
        height: { testimonialsTablet: '47px' },
        width: { testimonialsTablet: '100%' },
        paddingRight: {
            mobile: '12px',
            desktop: 0
        },
        color: 'neutral.700',
        backgroundColor: { testimonialsTablet: 'app.white' },
        opacity: 0.9,
        fontStyle: 'normal',
        fontSize: '14px'
    }
});
