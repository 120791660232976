import { useIntlPolyfillContext } from './intl-polyfill-provider';

/**
 * This is just a wrapper for handling price text display for user with the
 * browser standard api Intl.NumberFormat.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#examples
 */
export function useTPrice () {
    const { tPrice } = useIntlPolyfillContext();

    return tPrice;
}
