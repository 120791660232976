import { PersistConfig } from 'redux-persist/es/types';
import sessionStorageDriver from 'redux-persist/lib/storage/session';

import { RootState } from '../state.types';

/**
 * This contains the business rules about persisting the Redux state to localstorage for eg refreshing the browser.
 * The application has all it's state in Redux and so there are some things that we don't want to
 * store between sessions and some that we do. Here we can write conditions for what is needed in the in and out transforms
 * that end up in the local storage and then restored to the session.
 * We are not using every feature of this library and you could make a smaller bundle by porting this to custom
 * middleware using localStorage apis directly.
 *
 * @see https://github.com/rt2zz/redux-persist#transforms
 */
export const persistConfig: PersistConfig<RootState> = {
    key: 'root',
    storage: sessionStorageDriver,
    // Specify what properties in the store to persist here
    whitelist: [
        'subscription'
    ]
};
