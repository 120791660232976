import React, {
    type PropsWithChildren,
    useContext, useState, createContext
} from 'react';

import { LoadingView } from '../../components/loading-view/loading-view';
import {
    needsNarrowSymbolPolyfill, type TPriceOptions, useTPricePolyFiller
} from './use-t-price-polyfiller';

type Context = {
    isReady: boolean
    tPrice: (options: TPriceOptions) => any
};

const IntlPolyfillContext = createContext<Context>(null as any);
const isPolyfillRequired = needsNarrowSymbolPolyfill();

export function IntlPolyfillProvider ({ children }: PropsWithChildren) {
    const [isReady, setIsReady] = useState(!isPolyfillRequired);
    const tPrice = useTPricePolyFiller({
        setIsReady,
        isPolyfillRequired
    });
    const context: Context = isPolyfillRequired ? {
        isReady,
        tPrice
    } : {
        isReady: true,
        tPrice
    };

    if (!isReady) {
        return <LoadingView />;
    }

    return (
        <IntlPolyfillContext.Provider value={context}>
            {children}
        </IntlPolyfillContext.Provider>
    );
}

export function useIntlPolyfillContext () {
    return useContext(IntlPolyfillContext);
}
