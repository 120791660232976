import React from 'react';
import { Navigate } from 'react-router-dom';

import { ErrorBoundary } from '../../routes/error/errors/error-boundary';
import { useAppSelector } from '../../store/store';
import { SalesforceURLData } from '../../subscription-model/subscription.types';

type SalesforceDataProps = {
    render: (salesforce: SalesforceURLData) => JSX.Element
};

export function SalesforceDataRenderGate ({ render }: SalesforceDataProps) {
    const salesforce = useAppSelector(state => state.subscription.salesforce);

    if (!salesforce || salesforce.userId === null || salesforce.token === null) {
        return (
            <Navigate to={'/error'} />
        );
    }

    return (
        <ErrorBoundary context={'SalesforceDataGate'}>
            {render(salesforce)}
        </ErrorBoundary>
    );
}
