import { useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { HeaderLoginButton } from './header-login-button';

// There are ux decisions about when to show the login button
const hasLoginButtonPaths = [
    // For the new user Figma shows the button present all here
    // https://www.figma.com/file/AVVZO09XE8fZ0uuOaJYcQg/B2C-Onboarding---for-Dev?node-id=7339%3A50841
    '/subscription/create/select',
    '/subscription/create/success',
    '/error',
    '/error/not-found',
    '/error/under-maintenance'
];
// The visibility of the button is determined by the form wizard stepper being
// present as it won't fit on mobile. The UX decision was to just hide it if mobile.
const hasStepperAndLoginButtonPaths = [
    '/subscription/create/create-account',
    '/subscription/create/checkout'
    // The other paths now have a Close button instead of a login button
];

export function LoginButton () {
    const [isMobileVersion] = useMediaQuery([
        '(max-width: 678px)'
    ]);
    const { pathname } = useLocation();

    // Don't show it if it's not in any of the lists
    if (
        !hasLoginButtonPaths.includes(pathname) &&
        !hasStepperAndLoginButtonPaths.includes(pathname)
    ) {
        return null;
    }

    // Don't show it if it's mobile, and it has a stepper in the header
    if (
        hasStepperAndLoginButtonPaths.includes(pathname) &&
        isMobileVersion
    ) {
        return null;
    }

    return <HeaderLoginButton />;
}
