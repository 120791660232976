// no value in covering this
/* istanbul ignore file */
import { useBoolean } from '@chakra-ui/react';
import { useEffect } from 'react';

import { useEnvConfig } from '../../env-config';

/**
 * Custom hook based on example in mswjs repo.
 * Can be switched on or off in *.env.* configuration files with flag MOCK_API_ENABLED.
 *
 * @see https://github.com/mswjs/examples/tree/master/examples/rest-react
 */
export function useApiMocking () {
    const {
        MOCK_API_ENABLED
    } = useEnvConfig();
    const [isReady, readySwitch] = useBoolean(
        // normally this should just follow through with no mocking no,
        // need for re-render switch in useEffect.
        !MOCK_API_ENABLED
    );

    useEffect(() => {
        if (isReady) return;

        if (!MOCK_API_ENABLED) {
            return;
        }

        /**
         * @see https://mswjs.io/docs/api/setup-worker/start
         */
        import(
            /* webpackChunkName: "api-mocking"*/
            '../../__msw__/browser'
        ).then(({ setupWorker }) => {
            setupWorker().start({
                quiet: true,
                onUnhandledRequest: 'bypass'
            }).finally(() => {
                readySwitch.on();
            }).catch((error: unknown) => {
                console.error('unable to start mock worker', error);
            });
        });
    }, [
        readySwitch,
        isReady,
        MOCK_API_ENABLED
    ]);

    return { isReady };
}
