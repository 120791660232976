import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';

import {
    VerifyEmailMutation,
    VerifyEmailMutationVariables
} from '../../../../__generated__/gateway-graphql';
import { assert } from '../../../../__helpers__';
import { isBoolean } from '../../../../__utils__/utils';

type Options = {
    emailIdentifier: string
    verifyEmail: (input: VerifyEmailMutationVariables) => Promise<VerifyEmailMutation>
};

export async function verifyEmailQuery ({ emailIdentifier, verifyEmail }: Options) {
    const data = await verifyEmail({
        input: {
            emailIdentifier
        }
    }).then(onResolve, onReject);

    return data;
}

export function onResolve (data: any) {
    const result = data.inputObject;

    assert(
        isBoolean(result),
        'data must be boolean value'
    );

    return result;
}

export function onReject (error: unknown) {
    console.error('Not able to verify the user', error);

    Sentry.captureException(error, {
        level: Severity.Fatal
    });
}
