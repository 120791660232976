import React from 'react';

import { useSelectedProductSalesforceQuery } from '../../hooks/selected-product/use-selected-product-salesforce-query';
import { HeaderDetails } from './header-details';

export function ExistingCustomerDetails () {
    const {
        isLoading, data
    } = useSelectedProductSalesforceQuery();

    return (
        <HeaderDetails
            isLoading={isLoading}
            data={data}
        />
    );
}
