import { type RefObject, useEffect, useState } from 'react';

/**
 * Hook to monitor the visibility state of an element in the dom through the
 * Intersection Observer API:
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
export function useIsVisible (elementReference: RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        if (!elementReference.current) return;

        const observer = new IntersectionObserver(([entry]) =>
            setIntersecting(entry.isIntersecting)
        );

        observer.observe(elementReference.current);

        return () => {
            observer.disconnect();
        };
    }, [elementReference]);

    return isIntersecting;
}
