import { isStringDefined } from '../../__utils__/utils';
import { GraphQLError, StatusCodeError } from './errors';

export type FetcherOptions<TVariables> = {
    url: string
    query: string
    variables?: TVariables
    headers?: RequestInit['headers']
};

export async function fetcher<TData, TVariables> ({
    query,
    variables,
    url,
    headers
}: FetcherOptions<TVariables>): Promise<TData> {
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...(headers ?? {})
        },
        body: JSON.stringify({
            query,
            variables
        })
    });

    if (res.status !== 200) {
        throw new StatusCodeError(
            res.statusText || 'Unknown fetcher status message',
            {
                url: res.url,
                status: res.status,
                query,
                variables
            }
        );
    }

    const json = await res.json();

    if (Array.isArray(json.errors) && json.errors.length > 0) {
        const possibleGraphQLError = json.errors?.[0];
        const message = isStringDefined(possibleGraphQLError?.message) ?
            possibleGraphQLError?.message :
            'Unknown fetcher GraphQL Error';

        throw new GraphQLError(
            message,
            {
                url: res.url,
                errors: json.errors,
                query,
                variables
            }
        );
    }

    return json.data;
}
