import {
    Box,
    BoxProps,
    Center,
    CenterProps
} from '@chakra-ui/react';
import React, { type PropsWithChildren } from 'react';

type PageCenterProps =
    & {
        innerBoxProps?: BoxProps | null
    }
    & CenterProps;

/**
 * General purpose component to wrap the contents of a page to the center.
 * Includes an `innerBoxProps` to override the inner space for custom design.
 */
export function PageCenter ({
    children,
    innerBoxProps = null,
    ...restProps
}: PageCenterProps) {
    return (
        <Center
            {...restProps}
            sx={{
                label: 'page-center'
            }}
        >
            <Box
                sx={{ label: 'page-center-inner-box' }}
                width={{
                    desktop: 'calc(100% - 100px)',
                    mobile: 'calc(100% - 48px)'
                }}
                maxW={'816px'}
                {...innerBoxProps}
            >
                {children}
            </Box>
        </Center>
    );
}

/** To assign full widths to containers for mobile version without margin and padding */
export function PageCenterForm ({ children }: PropsWithChildren) {
    return (
        <PageCenter
            innerBoxProps={{
                position: 'relative',
                width: {
                    addChildrenTabsTablet: 'calc(100% - 100px)',
                    mobile: '100%'
                }
            }}
            children={children}
        />
    );
}
