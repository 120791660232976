import { useDispatch } from 'react-redux';

import { useEffectOnce } from '../../__utils__/use-effect-once';
import { subscriptionActions } from '../../subscription-model/subscription.slice';

/**
 * When a user completes a purchase clear the persisted data in session storage
 * with redux action and the middleware.
 *
 * This allows the checkout process to happen a second time in the same browser tab.
 */
export function usePurchaseSuccess () {
    const dispatch = useDispatch();

    useEffectOnce(() => {
        dispatch(subscriptionActions.clearData());
    });
}
