import { Link, LinkProps } from '@chakra-ui/react';
import React from 'react';

import { useUrlQuery } from '../../api/contentful/urls/use-url-query';

type ContentfulLinkProps =
    & Omit<LinkProps, 'href'>
    & {
    /**
     * Name used for the Content type Url in contentful.
     */
    contentName: string
    /**
     * Fallback element to show if url is loading or url fails to load.
     */
    fallback?: React.ReactElement | null
};

export function ContentfulLink ({
    children, contentName, fallback = null, ...restProps
}: ContentfulLinkProps) {
    const query = useUrlQuery(contentName);
    const applicationUrl = typeof query.data?.url === 'string' ? query.data : null;
    const isSuccessWithNoData = !applicationUrl && query.isSuccess;

    if (isSuccessWithNoData) {
        console.error('Unable to find Url content with name', contentName);
    }

    if (isSuccessWithNoData || !applicationUrl) {
        return fallback;
    }

    const contentfulId = applicationUrl?.sys?.id || null;

    return (
        <Link
            {...restProps}
            {...contentfulId && { ['data-contentfulid']: contentfulId }}
            href={applicationUrl.url}
        >
            {children}
        </Link>
    );
}

