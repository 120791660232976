import { useMediaQuery, useTheme } from '@chakra-ui/react';
import { ThemeTypings } from '@chakra-ui/system';

type CurrentBreakpoints = {
    current: 'mobile' | 'tablet' | 'desktop'
    isDesktop: boolean
    isTablet: boolean
    isMobile: boolean
};

export function useBreakpoints (): CurrentBreakpoints {
    const { breakpoints } = useTheme();
    const [isMobile, isTablet, isDesktop] = useMediaQuery([
        `(min-width: ${breakpoints.mobile})`,
        `(min-width: ${breakpoints.tablet})`,
        `(min-width: ${breakpoints.desktop})`
    ]);
    // Order by priority for min-width to work
    const current = isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile';

    return {
        current,
        isMobile,
        isTablet,
        isDesktop
    };
}

type Breakpoints = ThemeTypings['breakpoints'];

export function useThemeBreakpointMinWidth (breakpoint: Breakpoints) {
    const { breakpoints } = useTheme();
    const minWidth = breakpoints[breakpoint];

    return useMediaQuery(`(min-width: ${minWidth})`)[0];
}
