import {
    chakra, Box, SystemStyleObject
} from '@chakra-ui/react';

export const CardContainer = chakra(Box, {
    baseStyle: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'borderRadius': '10px',
        'flexDirection': {
            mobile: 'row',
            subscriptionInclusionsTablet: 'column'
        },
        'h': {
            mobile: '48px',
            subscriptionInclusionsTablet: '228px'
        },
        'maxWidth': {
            mobile: '100%',
            subscriptionInclusionsTablet: '286px'
        },
        'm': {
            mobile: '8px !important',
            desktop: '0 16px !important'
        },
        '@media screen and (max-width: 1304px)': { m: '10px' },
        'p': {
            mobile: '0',
            subscriptionInclusionsTablet: '0 24px'
        },
        'bg': {
            mobile: 'transparent',
            subscriptionInclusionsTablet: 'secondary.0'
        },
        'boxShadow': {
            mobile: 'none',
            subscriptionInclusionsTablet: '0px 4px 16px rgb(0 0 0 / 10%)'
        }
    }
});

export const SubscriptionText = chakra(Box, {
    baseStyle: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '145%',
        alignItems: 'center',
        letterSpacing: '0.15px',
        color: 'neutral.700',
        width: { mobile: '100%' },
        fontSize: {
            mobile: '14px',
            subscriptionInclusionsTablet: '20px'
        },
        mt: {
            mobile: 0,
            subscriptionInclusionsTablet: '10px'
        },
        textAlign: {
            mobile: 'left',
            subscriptionInclusionsTablet: 'center'
        }
    }
});

export const SubscriptionCardImageStyles: SystemStyleObject = {
    h: {
        mobile: '40px',
        subscriptionInclusionsTablet: '80px'
    },
    w: {
        mobile: '60px',
        subscriptionInclusionsTablet: '120px'
    },
    mr: {
        mobile: '40px',
        tablet: 0
    }
};
