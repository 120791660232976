/* eslint-disable max-len */
import { chakra } from '@chakra-ui/react';
import React from 'react';

const SuccessIcon = () => {
    return (
        <chakra.svg
            position={'relative'}
            width= {'376px'}
            height= {'193px'}
            viewBox= {'0 0 376 193'}
            fill= {'none'}
            xmlns= {'http://www.w3.org/2000/svg'}
        >
            <path d={'M309.708 144.267C304.961 160.889 292.553 174.831 276.327 180.793C260.101 186.755 242.226 186.29 225.08 184.09C201.958 181.124 179.225 175.139 157.641 166.333C148.176 162.714 139.181 158.712 130.306 154.458C120.912 149.954 111.59 145.128 103.385 138.708C95.1795 132.288 88.0851 124.148 84.2557 114.46C78.7848 100.619 80.6827 84.1021 88.8808 72.3325C92.9819 65.3761 99.6234 60.2981 106.493 56.0535C113.363 51.8088 120.656 48.1913 127.02 43.2206C136.349 35.9353 143.357 25.9968 152.772 18.8216C165.475 9.14222 182.345 5.13865 197.08 7.81002C209.68 10.7448 221.736 16.3843 231.567 24.7956C251.083 41.4941 255.218 60.0097 279.753 84.5467C288.096 92.8897 296.838 100.957 303.301 110.828C305.489 114.17 307.479 117.662 308.9 121.395C312.079 129.748 311.053 139.558 309.708 144.267Z'} fill={'#E4F3FC'} />
            <g opacity={'0.9'}>
                <path d={'M212.143 12.8912L191.646 101.48C191.646 101.48 187.048 6.96736 186.957 7.00638C195.13 6.87817 203.325 8.68556 210.686 12.239C211.157 12.4676 212.054 12.7323 212.143 12.8912Z'} fill={'url(#paint0_linear_3867_115569)'} />
                <path d={'M249.938 46.215L192.758 100.65C192.758 100.65 234.763 27.7746 234.881 27.9613C240.584 33.5033 245.659 39.6891 249.938 46.215Z'} fill={'url(#paint1_linear_3867_115569)'} />
                <path d={'M282.053 86.9348L192.758 101.478C192.758 101.478 264.326 66.9713 264.248 67.1177C269.703 74.1465 275.665 80.7811 282.053 86.9348Z'} fill={'url(#paint2_linear_3867_115569)'} />
                <path d={'M307.023 117.406L194.438 102.875C194.438 102.875 306.825 151.77 306.87 151.632C309.266 146.2 310.773 140.338 310.902 134.401C311.033 128.465 309.751 122.455 307.023 117.406Z'} fill={'url(#paint3_linear_3867_115569)'} />
                <path d={'M124.682 44.8242L190.528 101.194C190.528 101.194 102.384 58.5991 102.281 58.5754C107.025 55.7187 111.768 52.8606 116.51 50.0025C119.278 48.3359 122.05 46.6651 124.682 44.8242Z'} fill={'url(#paint4_linear_3867_115569)'} />
                <path d={'M82.5055 109.477L190.809 102.588C190.809 102.588 84.4773 80.1407 84.547 80.2898C80.6466 89.4327 79.9581 99.9105 82.5055 109.477Z'} fill={'url(#paint5_linear_3867_115569)'} />
                <path d={'M123.315 151.051L190.523 103.707C190.523 103.707 99.4972 135.551 99.5195 135.545C106.744 141.749 114.799 146.985 123.315 151.051Z'} fill={'url(#paint6_linear_3867_115569)'} />
                <path d={'M242.517 185.563L195.553 104.822C195.553 104.822 279.292 179.504 279.255 179.443C267.665 184.124 255.041 186.223 242.517 185.563Z'} fill={'url(#paint7_linear_3867_115569)'} />
                <path d={'M210.783 181.852L192.487 103.707C192.487 103.707 184.161 175.57 184.341 175.595C193.074 178.202 201.963 180.291 210.783 181.852Z'} fill={'white'} fillOpacity={'0.57'} />
                <path d={'M161.71 13.168L191.362 100.931C191.362 100.931 141.983 28.5998 142 28.7503C145.208 25.2985 147.973 22.7553 151.189 20.0087C154.405 17.2621 157.933 14.8179 161.71 13.168Z'} fill={'url(#paint8_linear_3867_115569)'} />
            </g>
            <path d={'M309.442 81.5547L254.492 92.094L267.351 101.507L309.442 81.5547Z'} fill={'#EBC463'} />
            <path d={'M271.92 104.495L309.636 81.627L289.75 115.101L271.92 104.495Z'} fill={'#EBC463'} />
            <path d={'M267.359 101.511L266.072 119.698L271.933 104.493L309.449 81.7213L309.546 81.623L267.359 101.511Z'} fill={'#DBA725'} />
            <path d={'M279.952 109.264L266.096 119.874L271.915 104.529L279.952 109.264Z'} fill={'#BC8909'} />
            <path d={'M249.186 104.08V171.512C249.189 172.186 249.058 172.853 248.801 173.476C248.544 174.099 248.167 174.665 247.691 175.142C247.215 175.618 246.649 175.996 246.027 176.253C245.405 176.509 244.738 176.641 244.065 176.639H144.778C143.42 176.639 142.117 176.098 141.156 175.137C140.196 174.176 139.656 172.872 139.656 171.512V104.08L154.628 115.508V154.556H233.427V115.91L249.186 104.08Z'} fill={'#48A8EE'} />
            <path d={'M249.194 104.08L198.495 142.236C197.215 143.207 195.653 143.732 194.047 143.732C192.441 143.732 190.879 143.207 189.599 142.236L139.664 104.08L190.647 65.5373C191.738 64.7182 193.065 64.2754 194.429 64.2754C195.793 64.2754 197.12 64.7182 198.212 65.5373L249.194 104.08Z'} fill={'#0E336F'} />
            <path d={'M233.422 115.947V154.593H154.623V115.521L189.562 142.202C190.85 143.177 192.42 143.704 194.034 143.704C195.648 143.704 197.218 143.177 198.506 142.202L233.422 115.947Z'} fill={'#48A8EE'} />
            <path d={'M233.422 85.9368V115.907L198.506 142.185C197.218 143.16 195.648 143.687 194.034 143.687C192.42 143.687 190.85 143.16 189.562 142.185L154.623 115.52V73.1287C154.622 72.5328 154.738 71.9427 154.966 71.392C155.193 70.8413 155.527 70.341 155.948 69.9197C156.369 69.4984 156.869 69.1644 157.419 68.9368C157.969 68.7093 158.559 68.5927 159.154 68.5938H214.975V80.4239C214.975 81.8881 215.556 83.2924 216.591 84.3277C217.625 85.3631 219.028 85.9447 220.491 85.9447L233.422 85.9368Z'} fill={'white'} />
            <path d={'M233.425 85.9368H219.242C218.107 85.9368 217.019 85.4856 216.216 84.6825C215.414 83.8794 214.963 82.7901 214.963 81.6543V68.5938L233.425 85.9368Z'} fill={'#9DCAEB'} />
            <path d={'M249.186 171.505C249.189 172.179 249.058 172.846 248.801 173.469C248.544 174.092 248.167 174.658 247.691 175.135C247.215 175.611 246.649 175.989 246.027 176.245C245.405 176.502 244.738 176.633 244.065 176.631H144.778C143.42 176.631 142.117 176.091 141.156 175.13C140.196 174.168 139.656 172.865 139.656 171.505L183.941 138.27L189.394 142.434C190.736 143.451 192.372 144.001 194.055 144.001C195.738 144.001 197.374 143.451 198.716 142.434L204.594 138.01L249.186 171.505Z'} fill={'#2662A7'} />
            <path d={'M178.543 105.314L191.602 118.372L213.449 96.5249'} stroke={'#48A8EE'} strokeWidth={'7'} />
            <defs>
                <linearGradient id={'paint0_linear_3867_115569'} x1={'199.55'} y1={'7'} x2={'199.55'} y2={'101.48'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'white'} stopOpacity={'0'} />
                    <stop offset={'1'} stopColor={'white'} />
                </linearGradient>
                <linearGradient id={'paint1_linear_3867_115569'} x1={'204.463'} y1={'87.5514'} x2={'264.942'} y2={'18.9905'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'white'} />
                    <stop offset={'1'} stopColor={'white'} stopOpacity={'0'} />
                </linearGradient>
                <linearGradient id={'paint2_linear_3867_115569'} x1={'210.595'} y1={'96.4614'} x2={'291.14'} y2={'69.7059'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'white'} />
                    <stop offset={'1'} stopColor={'white'} stopOpacity={'0'} />
                </linearGradient>
                <linearGradient id={'paint3_linear_3867_115569'} x1={'212.553'} y1={'110.121'} x2={'320.969'} y2={'139.664'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'white'} />
                    <stop offset={'1'} stopColor={'white'} stopOpacity={'0'} />
                </linearGradient>
                <linearGradient id={'paint4_linear_3867_115569'} x1={'171.019'} y1={'89.4884'} x2={'89.3587'} y2={'41.5515'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'white'} />
                    <stop offset={'1'} stopColor={'white'} stopOpacity={'0'} />
                </linearGradient>
                <linearGradient id={'paint5_linear_3867_115569'} x1={'174.365'} y1={'101.473'} x2={'65.3926'} y2={'94.9237'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'white'} />
                    <stop offset={'1'} stopColor={'white'} stopOpacity={'0'} />
                </linearGradient>
                <linearGradient id={'paint6_linear_3867_115569'} x1={'158.193'} y1={'119.593'} x2={'92.6978'} y2={'151.087'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'white'} />
                    <stop offset={'1'} stopColor={'white'} stopOpacity={'0'} />
                </linearGradient>
                <linearGradient id={'paint7_linear_3867_115569'} x1={'206.422'} y1={'123.217'} x2={'273.59'} y2={'198.188'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'white'} />
                    <stop offset={'1'} stopColor={'white'} stopOpacity={'0'} />
                </linearGradient>
                <linearGradient id={'paint8_linear_3867_115569'} x1={'185.788'} y1={'88.6677'} x2={'139.802'} y2={'13.1393'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'white'} />
                    <stop offset={'1'} stopColor={'white'} stopOpacity={'0'} />
                </linearGradient>
            </defs>
        </chakra.svg>
    );
};

export default SuccessIcon;
