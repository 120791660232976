import React, { PropsWithChildren } from 'react';

import { PageCenter } from '../../page/page-content/page-content.styles';

export function PageCenterPricingCards ({ children }: PropsWithChildren<unknown>) {
    return (
        <PageCenter
            innerBoxProps={{
                width: {
                    pricingTablet: 'calc(100% - 100px)',
                    mobile: 'calc(100% - 48px)'
                }
            }}
            children={children}
        />
    );
}
