import { RippleButton } from '@3plearning/chakra-mathletics-theme';
import {
    Text,
    Stack,
    VStack,
    Flex,
    chakra
} from '@chakra-ui/react';
import {
    Form, FormikProvider
} from 'formik';
import React from 'react';

import { ContentfulLink } from '../../../../../../components/contentful-link/contentful-link';
import { PasswordInputControlField } from '../../../../../../components/formik/fields/password-input-control-field';
import { TextInputControlField } from '../../../../../../components/formik/fields/text-input-control-field';
import { TurnstileContainer } from '../../../../../../components/turnstile/turnstile-container';
import { useEnvConfig } from '../../../../../../env-config';
import { EmailErrorNotices } from '../email-validation/email-error-notices';
import { EMAIL_VALIDATION_ERRORS } from '../email-validation/email-validation-errors';
import { CheckBoxField } from './components/checkbox-field';
import { useCreateAccountForm } from './hooks/use-create-account-form';

/**
 * Form to create a new b2c customer account.
 *
 * @see https://mathletics.atlassian.net/browse/FRAME-5327
 */
export function CreateAccountForm () {
    const {
        formValue,
        passwordValidation,
        onPasswordChange,
        checkboxValues,
        setCheckboxValues,
        currencyCode,
        isCreateAccountButtonDisabled
    } = useCreateAccountForm();
    const { ENABLE_TURNSTILE } = useEnvConfig();

    return (
        <FormikProvider value={formValue}>
            <Flex
                data-testid={'create-account-form'}
                layerStyle={'card'}
                w={'100%'}
                justifyContent={'center'}
                px={{
                    mobile: '24px',
                    tablet: '56px',
                    desktop: '74px'
                }}
                mb={{
                    mobile: '16px',
                    tablet: '40px'
                }}
            >
                <VStack
                    as={Form}
                    noValidate={true}
                    w={'100%'}
                    spacing={{
                        mobile: '24px',
                        createAccountFieldRow: '32px'
                    }}
                    maxW={'668px'}
                    paddingTop={{
                        mobile: '40px',
                        tablet: '40px',
                        desktop: '56px'
                    }}
                    paddingBottom={{
                        mobile: '24px',
                        tablet: '40px'
                    }}
                    alignItems={'flex-start'}
                >
                    <Stack
                        direction={{
                            mobile: 'column',
                            createAccountFieldRow: 'row'
                        }}
                        spacing={{
                            mobile: '24px',
                            createAccountFieldRow: '32px'
                        }}
                        w={'100%'}
                    >
                        <TextInputControlField
                            id={'firstName'}
                            labelText={'Your First Name'}
                            isRequired={true}
                        />

                        <TextInputControlField
                            id={'lastName'}
                            labelText={'Your Last Name'}
                            isRequired={true}
                        />
                    </Stack>

                    <EmailErrorNotices />

                    <TextInputControlField
                        id={'email'}
                        labelText={'Email'}
                        isRequired={true}
                        customErrors={EMAIL_VALIDATION_ERRORS}
                    />

                    <PasswordInputControlField
                        id={'password'}
                        labelText={'Password'}
                        isRequired={true}
                        validationState={passwordValidation}
                        controlProps={{ onChange: onPasswordChange }}
                    />

                    {currencyCode === 'EUR' || currencyCode === 'GBP' ?
                        <CheckBoxField
                            checkboxValues={checkboxValues}
                            setCheckboxValues={setCheckboxValues}
                        /> :

                        <Text
                            textStyle={'createAccountTerms'}
                            color={'neutral.700'}
                            mb={{
                                mobile: '22px !important',
                                tablet: '30px !important'
                            }}
                        >
                            {'By creating an account, you agree to 3P Learning’s '}

                            <ContentfulLink
                                contentName={'Terms and Conditions'}
                                isExternal={true}
                                textStyle={'createAccountTermsLink'}
                            >
                                Terms and Conditions
                            </ContentfulLink>

                            <chakra.span
                                sx={{
                                    color: 'neutral.700',
                                    fontSize: '14px'
                                }}
                            >
                                {' & '}
                            </chakra.span>

                            <ContentfulLink
                                contentName={'Privacy Policy'}
                                isExternal={true}
                                textStyle={'createAccountTermsLink'}
                            >
                                Privacy Policy
                            </ContentfulLink>
                        </Text>
                    }

                    <RippleButton
                        isLoading={
                            formValue.isSubmitting || formValue.isValidating
                        }
                        isDisabled = {isCreateAccountButtonDisabled}
                        data-testid={'submit-button'}
                        size={'large'}
                        type={'submit'}
                        w={{
                            mobile: '100%',
                            tablet: 'auto'
                        }}
                        mb={{
                            mobile: '16px !important',
                            tablet: '30px !important'
                        }}
                    >
                        Create my account
                    </RippleButton>

                    <Text
                        textStyle={'createAccountSignIn'}
                        w={'100%'}
                        textAlign={'center'}
                    >
                        Already have an account?

                        {' '}

                        <ContentfulLink
                            contentName={'Sign In'}
                            textStyle={'createAccountSignInLink'}
                        >
                            Sign in here
                        </ContentfulLink>.
                    </Text>
                    {ENABLE_TURNSTILE && <TurnstileContainer />}
                </VStack>
            </Flex>
        </FormikProvider>
    );
}
