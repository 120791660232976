import { i18n } from '@lingui/core';
import { en } from 'make-plural';
import { useRef, useState } from 'react';

import { useEffectOnce } from '../../__utils__/use-effect-once';
import { messages } from '../../locales/en-AU/messages';

export const defaultLocale = 'en-AU';

export function initDefaultLocale () {
    // @ts-ignore // For debugging
    window.__i18n__ = i18n;

    i18n.loadLocaleData({
        'en-AU': { plurals: en }
    });
    // Workaround on did not render warning as per issue:
    // https://github.com/lingui/js-lingui/issues/1194#issuecomment-1068488619
    i18n.load(defaultLocale, {});
    i18n.activate(defaultLocale);
}

/**
 * Load the message catalog dynamically, in this case its Contentful.
 *
 * @see https://lingui.js.org/guides/dynamic-loading-catalogs.html
 */
export function useLinguiActivate () {
    const hasInvoked = useRef(false);
    const [isReady, setReady] = useState(false);

    useEffectOnce(() => {
        if (hasInvoked.current === false) {
            initDefaultLocale();

            hasInvoked.current = true;

            // Note for this to work in production builds, you also need to run
            // Lingui compile for message interpolations to work.
            i18n.load(defaultLocale, messages);
            i18n.activate(defaultLocale);

            setReady(true);
        }
    });

    return { isReady };
}
