/* eslint-disable max-len */
import { chakra } from '@chakra-ui/react';
import React from 'react';

export default function CloseIcon () {
    return (
        <chakra.svg
            width= {'14px'}
            height= {'14px'}
            viewBox= {'0 0 14 14'}
            fill= {'none'}
            xmlns= {'http://www.w3.org/2000/svg'}
        >
            <path d={'M13.2155 0.526642C13.6361 0.935425 13.6499 1.60782 13.2463 2.02848L8.54431 6.92854L13.4317 11.6779C13.8874 12.1208 13.9023 12.8492 13.465 13.3049C13.0277 13.7606 12.3038 13.7711 11.8481 13.3282L6.96074 8.57882L2.28313 13.4535C1.87948 13.8742 1.21124 13.8838 0.790586 13.475C0.369928 13.0662 0.356142 12.3938 0.759794 11.9732L5.4374 7.09849L0.550033 2.3491C0.0943206 1.90625 0.0793859 1.17782 0.516675 0.72211C0.953965 0.266397 1.67789 0.255968 2.1336 0.698815L7.02097 5.44821L11.7229 0.548145C12.1266 0.127486 12.7948 0.11786 13.2155 0.526642Z'} fill={'#007ACC'} />
        </chakra.svg>
    );
}
