/**
 * Based on https://github.com/sindresorhus/unique-random/blob/main/license
 * Current issue with jest and ecmascript-modules so inlining these functions.
 */
export function uniqueRandom (minimum: number, maximum: number) {
    let previousValue: number;

    return function random () {
        const number = Math.floor(
            (Math.random() * (maximum - minimum + 1)) + minimum
        );

        previousValue = number === previousValue && minimum !== maximum ? random() : number;

        return previousValue;
    };
}

export function getUniqueFromArray <T> (array: T[]): T {
    const random = uniqueRandom(0, array.length - 1);

    return array[random()];
}
