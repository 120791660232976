import React, { useMemo } from 'react';
import {
    matchPath, useLocation
} from 'react-router-dom';

import { ProgressStep } from '../../stepper';

const determineState = (activeIndex: number, index: number) => {
    if (activeIndex > index) return 'complete';
    if (activeIndex === index) return 'active';

    return 'incomplete';
};

export const useProgressState = (steps: ProgressStep[]) => {
    const { pathname } = useLocation();
    const [activeStep, setActiveStep] = React.useState(0);
    const factor = steps.length - 1;
    const stepsStatus = useMemo(() =>
        steps.map((step: ProgressStep, index: number) => {
            if (matchPath(pathname, <string>step.path)) {
                setActiveStep(index);
            }

            return {
                ...step,
                status: determineState(activeStep, index)
            };
        }),
    [activeStep, pathname, steps]
    );

    return {
        value: (100 / factor) * (activeStep),
        status: stepsStatus
    };
};
