import { ChangeEvent, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import type { AnySchema } from 'yup';

import {
    INITIAL_PASSWORD_STATE, passwordValidationNames, VALID_PASSWORD_STATE
} from '../../../routes/subscription/create/create-account/components/create-account-form/__utils__/form-validation';
import { PasswordInputControlProps } from '../../password-input-control/password-input-control';

/**
 * Work around the non-standard requirement in the formik validation api for the UX.
 * Eg each time the password input changes, run the validation rules so the
 * result can be immediately rendered to the user before submit.
 */
export function useOnPasswordChange (formSchema: AnySchema) {
    const [passwordValidation, setPasswordValidation] = useState<PasswordInputControlProps['validationState']>(
        INITIAL_PASSWORD_STATE
    );

    function handlePasswordChange ({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        try {
            formSchema.validateSyncAt('password', { password: value }, { abortEarly: false });

            setPasswordValidation(VALID_PASSWORD_STATE);
        } catch (error: any) {
            // evaluate error for custom use case in real time validation popup.
            const passwordValidationErrors = [...error.inner]
                .filter(yupError => yupError.path === 'password')
                .map(yupError => yupError.message);
            const state = passwordValidationNames.map(labelText => {
                return {
                    labelText,
                    isValid: !passwordValidationErrors.includes(labelText)
                };
            });

            setPasswordValidation(state);
        }
    }

    const handlePasswordChangeDebounced = useDebouncedCallback(
        handlePasswordChange,
        500,
        { maxWait: 500 }
    );

    return {
        passwordValidation,
        onPasswordChange: handlePasswordChangeDebounced
    };
}
