import { useTPrice } from '../../i18n/t-price/use-t-price';
import { TPriceOptions } from '../../i18n/t-price/use-t-price-polyfiller';
import { useAppSelector } from '../../store/store';
import { useCurrentSubscriptionSalesforceQuery } from './use-current-salesforce-subscription-query';
import { resolveSelectedProductData } from './use-selected-product';

export function useSelectedProductSalesforceQuery () {
    const tPrice = useTPrice();
    const childrenCount = useAppSelector(
        rootState => rootState.subscription.childrenCount
    );
    const subscription = useAppSelector(rootState => rootState.subscription);
    const { quoteAndPricingData } = subscription;
    const query = useCurrentSubscriptionSalesforceQuery({ childrenCount });
    const {
        data: originalQueryData,
        isLoading,
        country
    } = query;
    const discountAmount = subscription?.quoteAndPricingData?.coupon
        ? tPrice({
            amount: quoteAndPricingData?.coupon?.discountAmount,
            countryCode: country?.countryCode,
            currencyCode: country?.currencyCode
        } as TPriceOptions)
        : null;

    if (!originalQueryData) {
        return {
            ...query,
            isLoading,
            data: null,
            discountAmount: null
        };
    }

    const data = resolveSelectedProductData({
        tPrice,
        data: originalQueryData,
        childrenCount
    });

    return {
        ...query,
        isLoading,
        data,
        discountAmount
    };
}
