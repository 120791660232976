import {
    chakra, SystemStyleObject
} from '@chakra-ui/react';

export const ContentWrapper = chakra('div', {
    baseStyle: {
        position: 'relative',
        width: '100%',
        padding: {
            desktop: '40px',
            tablet: '32px',
            mobile: '24px'
        },
        borderRadius: '0px 20px 20px 20px',
        backgroundColor: 'primary.50',
        opacity: 0.8
    }
});

export const LogoStyles: SystemStyleObject = {
    position: 'absolute',
    top: {
        tablet: '-20px',
        mobile: '-10px'
    },
    left: {
        tablet: '-20px',
        mobile: '-10px'
    },
    width: {
        tablet: '40px',
        mobile: '32px'
    },
    height: {
        tablet: '40px',
        mobile: '32px'
    }
};

export const CopyStyles: SystemStyleObject = {
    fontSize: {
        desktop: '18px',
        tablet: '20px',
        mobile: '16px'
    },
    lineHeight: {
        desktop: '27px',
        tablet: '30px',
        mobile: '26px'
    }
};
