import { RippleButton, TextInputControl } from '@3plearning/chakra-mathletics-theme';
import { Flex, Stack } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React from 'react';

import { isStringDefined } from '../__utils__/utils';
import { useIsTrialProductSelected, useQuoteAndPricingSelector } from '../subscription-model/subscription.selectors';
import CloseIcon from './components/close-icon';
import { SuccessNotice } from './components/success-notice';
import { useCoupon } from './hooks/use-coupon';

export enum CouponStates {
    addCoupon= 'ADD',
    applyCoupon= 'APPLY',
    valid='VALID',
    invalid='INVALID',
    validationError='VALIDATION ERROR',
    removeCoupon= 'REMOVE'
}

export function Coupon () {
    const quoteAndPricingData = useQuoteAndPricingSelector();
    const internalCoupon = quoteAndPricingData?.productItem?.internalCoupon || null;
    const isCampaignActive = isStringDefined(internalCoupon);
    const { addCoupon, valid } = CouponStates;
    const {
        buttonProps,
        inputTextProps,
        couponDataDisplayText,
        inputProps,
        couponState,
        inputRef,
        isLoading
    } = useCoupon();
    const isTrial = useIsTrialProductSelected();
    const hideCouponButton = isCampaignActive && !isTrial;

    return (
        <Stack width={'100%'}>
            {(couponState === valid && couponDataDisplayText) &&
            <SuccessNotice couponDataDisplayText={couponDataDisplayText} />
            }
            <Flex
                alignItems={'top'}
                flexDirection={{
                    mobile: 'column',
                    desktop: 'row'
                }}
                width={{
                    mobile: '100%',
                    desktop: 'auto'
                }}
            >
                {(couponState !== addCoupon) &&
                <TextInputControl
                    mb={{
                        mobile: '24px',
                        desktop: 0
                    }}
                    mr={{
                        mobile: 0,
                        desktop: '20px'
                    }}
                    data-testid={'coupon-input'}
                    id={'coupon-input-id'}
                    labelText={t`Coupon-code`}
                    isRequired={false}
                    minWidth={'232px'}
                    maxWidth={'400px'}
                    ref={inputRef}
                    {...inputTextProps}
                    {...inputProps}
                />
                }
                {hideCouponButton ?
                    null :
                    <RippleButton
                        disabled={isLoading}
                        data-testid={`btn-${couponState}`}
                        size={'large'}
                        px={'80px'}
                        variant={'secondary'}
                        width={{
                            mobile: '100%',
                            desktop: 'auto'
                        }}
                        fontStyle={'normal'}
                        fontWeight={500}
                        fontSize={'18px'}
                        children={t`Apply`}
                        leftIcon={couponState === valid ? <CloseIcon /> : <br />}
                        {...buttonProps}
                    />
                }
            </Flex>
        </Stack>
    );
}
