import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import { useAppSelector } from '../../../../../store/store';

export function FormAccountView () {
    const {
        name,
        email
    } = useSelectNameAndEmail();

    return (
        <Flex
            w={'calc(100% - 16px)'}
            h={'72px'}
            border={'solid 1px'}
            borderRadius={'16px'}
            borderColor={'primary.200'}
            m={'8px 12px 0 12px'}
            align={'center'}
            wrap={'wrap'}
        >
            <Box
                marginLeft={{
                    mobile: '16px',
                    fieldRowTablet: '55px',
                    desktop: '65px'
                }}
            >
                {name}
            </Box>

            <Box
                marginLeft={{
                    mobile: '20px',
                    fieldRowTablet: '65px'
                }}
            >
                {email}
            </Box>
        </Flex>
    );
}

function useSelectNameAndEmail () {
    const selectedSubscription = useAppSelector(state => state.subscription);

    return {
        name: `${selectedSubscription?.profile?.firstName} ${selectedSubscription?.profile?.lastName}`,
        email: selectedSubscription?.profile?.email
    };
}
