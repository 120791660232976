import { SkeletonText } from '@chakra-ui/react';
import React from 'react';

import {
    ContentWrapper,
    FAQText,
    FAQTitle
} from '../faq.styles';

// eslint-disable-next-line max-len
const PLACEHOLDER_TEXT = 'Mathletics is brilliant and I have seen with my own eyes what a solid foundation in maths it has given both my children. They are confident and comfortable with the subject and I believe that Mathletics is the reason.';

export function FAQSkeleton () {
    return (
        <ContentWrapper data-testid={'faq-loading-fallback'}>
            <FAQTitle>
                Pricing FAQs
            </FAQTitle>
            <FAQText as={'div'}>
                <SkeletonText
                    startColor={'neutral.100'}
                    endColor={'neutral.300'}
                    fadeDuration={1.2}
                    noOfLines={5}
                    skeletonHeight={'73px'}
                    spacing={'4'}
                >
                    {PLACEHOLDER_TEXT}
                </SkeletonText>
            </FAQText>
        </ContentWrapper>
    );
}
