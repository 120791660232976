import React from 'react';
import {
    Navigate,
    Route, Routes
} from 'react-router-dom';

import { CreateUserAccountError } from './errors/create-user-account-error';
import { UnexpectedError } from './errors/unexpected-error';
import { NotFoundError } from './not-found-error/not-found-error';
import { UnderMaintenanceError } from './under-maintenance/under-maintenance';

export function ErrorRoute () {
    return (
        <Routes>
            <Route
                index={true}
                element={<UnexpectedError />}
            />

            <Route
                path={'/not-found'}
                element={<NotFoundError />}
            />

            <Route
                path={'/user-not-created'}
                element={<CreateUserAccountError />}
            />

            <Route
                path={'/under-maintenance'}
                element={<UnderMaintenanceError />}
            />

            <Route
                path={'*'}
                element={<Navigate to={'/not-found'} />}
            />
        </Routes>
    );
}
