/* eslint-disable max-len */
import { chakra, Flex, Center, Stack, FlexProps } from '@chakra-ui/react';
import React from 'react';

export type NoticePanelType = {
    errorType: string
    helperText: string
    messageHtml: string
    background: string
    icon: React.ReactNode
};

type Props =
    & NoticePanelType
    & { wrapperProps?: FlexProps };

export function NoticePanel ({
    icon, messageHtml, background, wrapperProps
}: Props) {
    return (
        <Flex
            className={'wrapper'}
            backgroundColor={background}
            borderRadius={'12px'}
            p={{
                mobile: '16px',
                desktop: '26px'
            }}
            justify={'center'}
            w={'100%'}
            {...wrapperProps}
        >
            <Stack
                direction={'row'}
                align={'center'}
                spacing={'20px'}
                maxW={'572px'}
                w={'100%'}
                color={'neutral.900'}
            >
                <Center
                    w={'32px'}
                    h={'32px'}
                >
                    {icon}
                </Center>

                <chakra.div
                    dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: messageHtml
                    }}
                />
            </Stack>
        </Flex>
    );
}
