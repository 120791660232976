import { pickCountryFromCurrencyCode } from '../../location/__utils__/pick-country-from-currency-code';
import { useGetCountriesQuery } from '../../location/use-get-countries-query';
import { useStoredPlans, UseStoredPlansProps } from '../../plans/use-stored-plans';
import { useAppSelector } from '../../store/store';
import { CurrentSubscriptionData } from './use-selected-product';

// override the data type
type Query = Omit<ReturnType<typeof useStoredPlans>, 'data'>;
type Hook =
    & Query
    & {
    data: CurrentSubscriptionData | null
    isLoading: boolean
    country: any
};

type Options = {
    // Used to override children count in a form
    childrenCount?: number | null
};

export function useCurrentSubscriptionSalesforceQuery ({ childrenCount }: Options = { childrenCount: null }): Hook {
    const selectedSubscription = useAppSelector(state => state.subscription);
    const currencyCode = selectedSubscription?.salesforce?.currencyCode;
    const countriesQuery = useGetCountriesQuery();
    const countries = countriesQuery.data?.countries || [];
    const country = typeof currencyCode === 'string' ? pickCountryFromCurrencyCode({ currencyCode }, countries) : null;
    const isDataReady = Array.isArray(countriesQuery.data?.countries);

    childrenCount = typeof childrenCount === 'number' ? childrenCount : selectedSubscription.childrenCount;

    const query = useStoredPlans(<UseStoredPlansProps>{
        excludeTrials: true,
        country
    });

    let data: CurrentSubscriptionData | null = null;

    const { productItem } = query;

    if (
        typeof selectedSubscription.productCode === 'string'
    ) {
        if (!productItem) {
            return {
                ...query,
                data,
                isLoading: !isDataReady,
                country
            };
        }

        const [currentPrice] = productItem.priceList;

        if (!currentPrice) {
            throw new Error('Unable to determine price for children count ' + childrenCount);
        }

        data = {
            currentPrice,
            subscription: selectedSubscription,
            productItem
        };
    }

    return {
        ...query,
        data,
        isLoading: !isDataReady,
        country
    };
}
