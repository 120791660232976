import { Skeleton } from '@chakra-ui/react';
import React from 'react';

export function RippleButtonSkeleton ({ ...restProps }) {
    return (
        <Skeleton
            data-testid={'ripple-button-skeleton'}
            fadeDuration={1.2}
            m={'24px'}
            {...restProps}
        />
    );
}
