// TODO move upstream to chakra theme.
import type { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
    cursor: 'pointer',
    textDecoration: 'underline',
    outline: 'none',
    color: 'primary.600',
    fontWeight: 'medium',
    _hover: { color: 'primary.700' }
};

export const Link = { baseStyle };
