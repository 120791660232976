import React from 'react';
import {
    Navigate,
    Route,
    Routes
} from 'react-router-dom';

import { NavigateToNotFoundRoute } from '../../error/not-found-error/components/navigate-to-not-found-route';
import { RedirectIfNoPlanOnceGate } from '../__utils__/redirect-if-no-plan-once.gate';
import { RedirectIfNoPlanOrAccountGate } from '../__utils__/redirect-if-no-plan-or-account.gate';
import { CreateCheckoutRoute } from './checkout/create-checkout';
import { CreateAccountRoute } from './create-account/create-account';
import { SelectRoute } from './select/select';
import { CreateSuccessRoute } from './success/create-success-route';

export function CreateRoute () {
    return (
        <Routes>
            <Route
                path={'select'}
                element={<SelectRoute />}
            />

            <Route
                path={'create-account'}
                element={<CreateAccountRoute />}
            />

            <Route
                path={'checkout'}
                element={
                    <RedirectIfNoPlanOrAccountGate to={'/subscription/create/select'}>
                        <CreateCheckoutRoute />
                    </RedirectIfNoPlanOrAccountGate>
                }
            />

            <Route
                path={'success'}
                element={
                    <RedirectIfNoPlanOnceGate to={'/subscription/create/select'}>
                        <CreateSuccessRoute />
                    </RedirectIfNoPlanOnceGate>
                }
            />

            <Route
                index={true}
                element={<Navigate to={'select'} />}
            />

            <Route
                path={'*'}
                element={<NavigateToNotFoundRoute />}
            />
        </Routes>
    );
}
