import { Box, Flex, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb-text.css';
import React from 'react';

import { SubscriptionReminderTextBtn } from './subscription-reminder-style';

type Props = {
    google: any | null
    outlook: any | null
    ical: any | null
};

export const SubscriptionReminderView = ({
    google, outlook, ical
}: Props) => {
    return (
        <Flex data-testid={'reminder'}>
            <Text>{t`Add a reminder`}</Text>

            <Flex flexWrap={'wrap'}>
                <SubscriptionReminderTextBtn
                    onClick={e => {
                        e.preventDefault();
                        atcb_action({ ...google });
                    }}
                >
                    Google
                </SubscriptionReminderTextBtn>

                <Separator />
                <SubscriptionReminderTextBtn
                    onClick={e => {
                        e.preventDefault();
                        atcb_action({ ...outlook });
                    }}
                >
                    Outlook
                </SubscriptionReminderTextBtn>
                <Separator />
                <SubscriptionReminderTextBtn
                    onClick={e => {
                        e.preventDefault();
                        atcb_action({ ...ical });
                    }}
                >
                    iCal
                </SubscriptionReminderTextBtn>

            </Flex>
        </Flex>
    );
};

function Separator () {
    return (
        <Box
            ml={'10px'}
            color={'gray.400'}
        >|</Box>
    );
}
