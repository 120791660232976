import { t } from '@lingui/macro';
import { useState } from 'react';

import { isCyberWeekCampaignActive, isStringDefined } from '../__utils__/utils';
import { useAppSelector } from '../store/store';
import { usePlansDataWithTextQuery } from './use-plans-data-with-text-query';

/**
 * This gets the currencyCode from salesforce url searchParams,
 * then returns usePlansDataWithTextQuery.
 */
export function usePlansSalesforce () {
    const salesforce = useAppSelector(
        state => state.subscription.salesforce
    );
    // as string here won't matter as enabled flag won't query it.
    const currencyCode = salesforce?.currencyCode as string;
    const enabled = isStringDefined(currencyCode);
    const subscription = useAppSelector(
        rootState => rootState.subscription
    );
    const [childrenCount, setChildrenCount] = useState<number>(
        subscription.childrenCount
    );
    const {
        data,
        ...query
    } = usePlansDataWithTextQuery({
        excludeTrials: true,
        currencyCode,
        enabled,
        childrenCount
    });
    const productItems = data?.plan?.productItems || [];
    const internalCoupon = data?.plan?.internalCoupon || null;
    const isCampaignActive = isStringDefined(internalCoupon);
    const isCyberMondayCampaign = isCyberWeekCampaignActive(internalCoupon);
    const campaignText = isCyberMondayCampaign
        ? t`Pricing Page - Card Heading Cyber Monday Campaign Text`
        : t`Pricing Page - Card Heading Campaign Text`;
    const cardHeadingText = isCampaignActive ? campaignText : t`Pricing Page - Card Heading`;

    return {
        ...query,
        internalCoupon,
        currencyCode,
        productItems,
        childrenCount,
        cardHeadingText,
        onSetChildrenCount: setChildrenCount
    };
}
