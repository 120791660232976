import { Box, chakra, Stack, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React from 'react';

import ChildIcon from './components/child-icon';
import { HeaderSubscriptionDetailsSkeleton } from './components/header-subscription-details-skeleton';

type Props = {
    isLoading?: boolean
    data: any | null
};

export function HeaderDetails ({ data, isLoading }: Props) {
    if (isLoading || !data) {
        return <HeaderSubscriptionDetailsSkeleton />;
    }

    const {
        isTrial,
        productItem: {
            subscriptionDuration,
            durationType,
            paymentDisplay
        },
        childText,
        childrenCount
    } = data;
    const headerSubscriptionText = t({
        id: 'Your free trial ends in {trialDuration} {trialDurationType}, cancel anytime.',
        values: {
            trialDuration: subscriptionDuration,
            trialDurationType: durationType
        }
    });

    return (
        <Stack
            data-testid={'header-subscription-details'}
            mb={{
                mobile: '16px',
                tablet: '8px'
            }}
            spacing={'8px'}
            color={'neutral.700'}
            fontSize={{
                mobile: '14px',
                tablet: '16px'
            }}
            lineHeight={{
                mobile: '20px',
                tablet: '22px'
            }}
            fontWeight={'normal'}
            letterSpacing={'-0.15px'}
        >
            <Box display={'inline-flex'}>
                <Box
                    data-testid={'header-subscription-type'}
                    borderRightWidth={'1px'}
                    borderRightStyle={'solid'}
                    borderRightColor={'neutral.200'}
                    paddingRight={'8px'}
                >
                    {paymentDisplay}
                </Box>
                <Box
                    data-testid={'header-subscription-child-details'}
                    display={'inline-flex'}
                    padding={'0 8px'}
                    alignItems={'center'}
                >
                    <ChildIcon />
                    <Text
                        padding={'0 8px'}
                        pr={{
                            tablet: '16px'
                        }}
                    >
                        <chakra.span
                            pr={'5px'}
                            display={'inline'}
                        >
                            {childrenCount}
                        </chakra.span>
                        {childText}
                    </Text>
                </Box>
            </Box>
            {isTrial ?
                <Box data-testid={'header-subscription-trial-text'}>
                    {headerSubscriptionText}
                </Box> : null
            }
        </Stack>
    );
}
