import type { ActiveCountry } from '../use-active-location';
import type { CountryItem } from '../use-get-countries-query';

type Options = {
    countryCode: string
    state?: null | string
};

export function pickCountryFromCountryCode ({
    countryCode,
    state
}: Options,
countries: Array<CountryItem>
): ActiveCountry | null {
    const existing = countries.find(
        country => country.countryCode === countryCode
    ) || {
        currencyCode: 'AUD',
        countryCode: 'AUS',
        countryName: 'Australia',
        statesName: 'States',
        states: [
            'Australian Capital Territory',
            'New South Wales',
            'Northern Territory',
            'Queensland',
            'South Australia',
            'Tasmania',
            'Victoria',
            'Western Australia'
        ],
        state: typeof state === 'string' ? state : null
    };

    if (!existing) return null;

    return {
        currencyCode: existing.currencyCode,
        countryCode: existing.countryCode,
        countryName: existing.countryName,
        ...existing?.statesName && {
            statesName: existing?.statesName
        },
        states: existing.states,
        state: typeof state === 'string' ? state : null
    };
}
