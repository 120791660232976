// no value in covering this
/* istanbul ignore file */
/**
 * In a vanilla javascript way, add the build number to the lower corner of the screen.
 * This helps with reporting bugs, QA and dev testing.
 *
 * The REACT_APP_BUILD_NUMBER is needs to be provided for CRA, see docs.
 * This value needs to come from CI eg BUILDKITE_BUILD_NUMBER.
 *
 * In development dev is just shown.
 *
 * @see https://create-react-app.dev/docs/adding-custom-environment-variables/
 */
import * as Sentry from '@sentry/react';

export function addBuildNumber () {
    const BUILD_NUMBER = process.env.NODE_ENV !== 'production' ?
        'dev' :
        process.env.REACT_APP_BUILD_NUMBER || null;

    Sentry.setTag('build_number', BUILD_NUMBER);
    if (!BUILD_NUMBER) return;

    console.log('BUILD_NUMBER', BUILD_NUMBER);

    const buildVersion = document.createElement('div');

    buildVersion.innerText = BUILD_NUMBER;
    buildVersion.style.position = 'fixed';
    buildVersion.style.bottom = '0';
    buildVersion.style.right = '5px';
    buildVersion.style.zIndex = '9999';
    buildVersion.style.fontSize = '8px';
    buildVersion.style.color = '#6A767E';

    document.body.append(buildVersion);
}
