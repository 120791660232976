/* eslint-disable max-len */
import { RippleButtonProps, RippleIconButton } from '@3plearning/chakra-mathletics-theme';
import { Box, chakra } from '@chakra-ui/react';
import React from 'react';

export function BackButton (props: RippleButtonProps) {
    return (
        <RippleIconButton
            aria-label={'Back'}
            icon={<BackArrow />}
            variant={'unstyled'}
            {...props}
        />
    );
}

function BackArrow () {
    return (
        <Box
            sx={{
                'svg path': {
                    fill: 'neutral.900'
                },
                'svg circle': {
                    fill: 'neutral.100'
                },
                '_hover': {
                    'svg circle': {
                        fill: 'neutral.300'
                    }
                }
            }}
        >
            <chakra.svg width={'40px'} height={'40px'} viewBox={'0 0 40 40'}>
                <circle opacity={'0.4'} cx={'20'} cy={'20'} r={'20'} />
                <path d={'M19.7071 13.2929C20.0976 13.6834 20.0976 14.3166 19.7071 14.7071L15.4142 19H27C27.5523 19 28 19.4477 28 20C28 20.5523 27.5523 21 27 21H15.4142L19.7071 25.2929C20.0976 25.6834 20.0976 26.3166 19.7071 26.7071C19.3166 27.0976 18.6834 27.0976 18.2929 26.7071L12.2929 20.7071C12.1054 20.5196 12 20.2652 12 20C12 19.7348 12.1054 19.4804 12.2929 19.2929L18.2929 13.2929C18.6834 12.9024 19.3166 12.9024 19.7071 13.2929Z'} />
            </chakra.svg>
        </Box>
    );
}
