import { SkeletonText } from '@chakra-ui/react';
import React from 'react';

import {
    RootWrapper,
    ContentWrapper,
    TrialDurationText
} from '../../subscription-inclusions/subscription-inclusions.styled';

const PLACEHOLDER_TEXT = 'Free trial days appear here';

export function SubscriptionInclusionsSkeleton () {
    return (
        <RootWrapper data-testid={'subscription-inclusions-loading-fallback'}>
            <ContentWrapper>
                <TrialDurationText as={'div'}>
                    <SkeletonText
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        noOfLines={2}
                        skeletonHeight={'18px'}
                        spacing={{
                            mobile: '10px',
                            subscriptionInclusionsTablet: '24px'
                        }}
                    >
                        {PLACEHOLDER_TEXT}

                    </SkeletonText>
                </TrialDurationText>
            </ContentWrapper>
        </RootWrapper>
    );
}
