import { AbsoluteCenter, AbsoluteCenterProps, Box } from '@chakra-ui/react';
import React from 'react';

type ProgressbarProps = {
    value: number
} & AbsoluteCenterProps;

export const ProgressBar = (props: ProgressbarProps) => {
    const { value, ...rest } = props;

    return (
        <AbsoluteCenter
            role={'progressbar'}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={value}
            aria-valuetext={`Progress: ${value}%`}
            position={'absolute'}
            height={'2px'}
            axis={'vertical'}
            bg={'neutral.100'}
            width={'full'}
            {...rest}
        >
            <Box bg={'primary.500'} height={'inherit'} width={`${value}%`} />
        </AbsoluteCenter>
    );
};
