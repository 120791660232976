import { t } from '@lingui/macro';
import React, { type PropsWithChildren } from 'react';

import { isEmpty, isString } from '../../../__utils__/utils';
import { useQueryParams } from '../../../hooks/use-query-params';
import { PageHeading } from '../../../page/page-heading/page-heading';
import { useCustomerCurrencyQuery } from '../../../plans/use-customer-currency';
import { PageCenterPricingCards } from '../../../pricing/components/page-center-pricing-cards';
import { PricingSkeleton } from '../../../pricing/components/pricing-skeleton/pricing-skeleton';

export function CurrencyFallbackGate ({ children }: PropsWithChildren) {
    const params = useQueryParams();
    const shouldLoadFallbackCurrency = (
        isString(params?.token) &&
        /^\d+$/.test(params?.userid || '') &&
        isEmpty(params?.currency)
    );
    const {
        isLoading,
        data
    } = useCustomerCurrencyQuery({
        excludeTrials: true,
        userId: Number(params?.userid),
        enabled: shouldLoadFallbackCurrency
    });

    if (shouldLoadFallbackCurrency) {
        const currencyCodeForUrl = data?.customerPlans?.currencyCode;

        if (isLoading || !isString(currencyCodeForUrl)) {
            return (
                <PageCenterPricingCards>
                    <PageHeading
                        headingText={t`Select Subscription`}
                    />
                    <PricingSkeleton />;
                </PageCenterPricingCards>
            );
        } else {
            setTokenInUrl(currencyCodeForUrl);
        }
    }

    return (
        <>
            {children}
        </>
    );
}

function setTokenInUrl (currency: string) {
    const updatedURL = new URL(window.location.href);

    updatedURL.searchParams.set('currency', currency);
    window.history.replaceState(null, '', updatedURL.toString());
}
