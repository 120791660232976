import { useQuery } from '@tanstack/react-query';

import type { UrlsData, Url } from '../../../fetch-static-content/contentful/urls';

export function useUrlQuery (contentName: string) {
    const query = useQueryUrls();
    const urls = query.data?.items || [];

    return useQuery<Url | null>(['application-url', { contentName }], () => {
        return urls.find(
            url => url.name === contentName
        ) || null as Url | null;
    }, {
        enabled: Array.isArray(query.data?.items)
    });
}

export function useQueryUrls () {
    return useQuery(['application-urls'], () => {
        return import('../../../__static-content__/urls.json') as any as UrlsData;
    });
}
