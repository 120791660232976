import * as Sentry from '@sentry/react';

import type {
    CreatePaymentIntentFromQuoteMutation,
    CreatePaymentIntentFromQuoteInput,
    CreatePaymentIntentFromQuoteMutationVariables
} from '../../__generated__/gateway-graphql';
import { isStringDefined } from '../../__utils__/utils';
import type { PaymentIntent } from '../../chargebee/chargebee.types';
import { __DEV_ENV__, __LOCAL__, __QA_ENV__ } from '../../env-config';

// We don't want to log intents in production, they are however useful in QA, DEV or LOCAL
const SHOULD_LOG_INFO = __LOCAL__ || __DEV_ENV__ || __QA_ENV__;

type Options = {
    input: CreatePaymentIntentFromQuoteInput
    createPaymentIntentFromQuote: (
        variables: CreatePaymentIntentFromQuoteMutationVariables
    ) => Promise<CreatePaymentIntentFromQuoteMutation>
};
type IntentData = {
    paymentIntent: PaymentIntent | null
    errorMessage?: string | null
};

export async function createGatewayPaymentIntent ({
    createPaymentIntentFromQuote,
    input
}: Options): Promise<IntentData> {
    const intentData = await createPaymentIntentFromQuote({ input });

    if (!intentData.intentObject) {
        throw new Error('unable to resolve CreatePaymentIntentPayload from createPaymentIntent');
    }

    const serializedIntentObject = intentData.intentObject.paymentIntent;
    const { errorMessage } = intentData.intentObject;

    let paymentIntent: PaymentIntent | null = null;

    try {
        if(isStringDefined(serializedIntentObject)) {
            paymentIntent = JSON.parse(serializedIntentObject) as PaymentIntent;
        }
    } catch (error) {
        // If this happens the expected payload of CreatePaymentIntentMutation
        // did not return a valid serialized JSON object of a payment intent,
        // this is needed for authorizeWith3ds to pass onto Chargebee.
        // throw new Error('Unable to parse JSON string from CreatePaymentIntentPayload.paymentIntent');
        console.log('error in payment intent', errorMessage);
    }

    if (SHOULD_LOG_INFO) {
        console.info('paymentIntent', paymentIntent);
    }

    Sentry.setExtra('paymentIntentId', paymentIntent?.id);

    return {
        paymentIntent,
        errorMessage
    };
}
