import { isObject, isStringDefined } from '../__utils__/utils';
import { useStoredPlans } from '../plans/use-stored-plans';
import { useAppSelector } from '../store/store';

export function useChosenSubscriptionSelector (excludeTrials: boolean) {
    const productCode = useSelectedProductCodeSelector();
    const query = useStoredPlans({ excludeTrials });
    const { productItem } = query;

    if (!productCode || !productItem) {
        return null;
    }

    return productItem;
}

export function useSalesforceDataSelector () {
    return useAppSelector(
        state => state.subscription?.salesforce
    );
}

export function useSelectedProductCodeSelector () {
    return useAppSelector(
        state => state.subscription.productCode
    );
}

export function useHasSelectedProductCode () {
    return useAppSelector(
        state => isStringDefined(state.subscription.productCode)
    );
}

export function useHasAccountCreated () {
    return useAppSelector(
        state => isObject(state.subscription.profile)
    );
}

export function useIsTrialProductSelected () {
    const quoteAndPricingData = useAppSelector(rootState => rootState.subscription.quoteAndPricingData);

    return quoteAndPricingData?.productItem?.isTrial || false;
}

export function useQuoteAndPricingSelector () {
    return useAppSelector(
        state => state.subscription?.quoteAndPricingData
    );
}
