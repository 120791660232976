import React, {
    ComponentType, PropsWithChildren
} from 'react';

/**
 * Utility to wrap a provider component with a functional api,
 * to be use with the library @testing-library/react-hooks.
 */
export function wrapProvider <P> (
    Provider: ComponentType<P>,
    props: P
) {
    return ({ children }: PropsWithChildren<P>) => {
        return (
            <Provider {...props}>
                {children}
            </Provider>
        );
    };
}

/**
 * Basic Typescript condition assertion for narrowing types.
 *
 * @see https://exploringjs.com/tackling-ts/ch_type-guards-assertion-functions.html#asserting-a-boolean-argument-asserts-cond
 */
export function assert (
    condition: any,
    msg?: string
): asserts condition {
    if (!condition) {
        throw new Error(msg);
    }
}
