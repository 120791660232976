import React, { Suspense } from 'react';

/**
 * Load the svg components in a javascript webpack chunk.
 * This prevents large main bundles, using dynamic import().
 *
 * @see https://reactjs.org/docs/code-splitting.html#reactlazy
 */
const BackgroundCircle = React.lazy(() => import(
    /* webpackChunkName: "circle-background"*/
    './circle-background'
));
const BackgroundDottedLine = React.lazy(() => import(
    /* webpackChunkName: "dotted-circle-background"*/
    './dotted-line-background'
));

export function FreeTrialBackground () {
    return (
        // perhaps the skeleton looks bad here a big grey circle?
        <Suspense fallback={null}>
            <BackgroundCircle />
            <BackgroundDottedLine />
        </Suspense>
    );
}
