import React from 'react';

import { useSelectedProductIpQuery } from '../../hooks/selected-product/use-selected-product-ip-query';
import { HeaderDetails } from './header-details';

export function NewCustomerDetails () {
    const {
        data
    } = useSelectedProductIpQuery();

    return (
        <HeaderDetails
            isLoading={false}
            data={data}
        />
    );
}
