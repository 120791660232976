/* eslint-disable jsx-a11y/no-autofocus */
import {
    TextInputControl, TextInputControlProps
} from '@3plearning/chakra-mathletics-theme';
import {
    InputProps,
    Popover,
    PopoverProps,
    PopoverTrigger,
    useBoolean,
    useMediaQuery
} from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';
import React from 'react';

import { RevealPasswordButton } from './components/buttons/reveal-password-button';
import {
    PopoverValidationHelper,
    ValidationItem
} from './components/popover-validation-helper/popover-validation-helper';

export type PasswordInputControlProps =
    & Omit<TextInputControlProps, 'id' | 'RightAdornment' | 'controlProps'>
    & {
    /**
     * The unique id given to the control for usage in the form.
     * The By default "password" is used.
     */
    id?: string
    /**
     * The validation helper popup helper props.
     */
    popoverProps?: PopoverProps
    /**
     * The Chakra <Input/> element props to pass down through this control.
     */
    controlProps?: Omit<InputProps, 'type'>
    /**
     * An array of validation requirements to show the user,
     * the idea is to update the icons in real time onChange of the input.
     * The validation implementation is determined by the form eg libraries like Formik & Yup.
     */
    validationState: Array<ValidationItem>
};

/**
 * A general use password input with real time validation popover behaviour.
 *
 * @see https://mathletics.atlassian.net/browse/FRAME-5316
 * @see https://mathletics.atlassian.net/browse/FRAME-5318
 */
export function PasswordInputControl ({
    popoverProps, validationState, controlProps, ...restProps
}: PasswordInputControlProps) {
    const {
        VisibilityStateIcon,
        isVisible,
        open,
        close,
        isOpen
    } = useValidationHelper();
    const [isLarge] = useMediaQuery('(min-width: 1368px)');

    return (
        <Popover
            placement={isLarge ? 'right' : 'top'}
            autoFocus={false}
            returnFocusOnClose={false}
            isOpen={isOpen}
            arrowSize={16}
            offset={isLarge ? [20, 56] : [20, 20]}
            {...popoverProps}
        >
            <PopoverTrigger>
                <TextInputControl
                    id={'password'}
                    isRequired={true}
                    labelText={'Password'}
                    {...restProps}
                    controlProps={{
                        ...controlProps,
                        marginRight: '40px',
                        type: isVisible ? 'text' :'password'
                    }}
                    onFocus={open}
                    onBlur={close}
                    RightAdornment={VisibilityStateIcon}
                />
            </PopoverTrigger>

            <PopoverValidationHelper state={validationState} />
        </Popover>
    );
}

if (__DEV__) {
    PasswordInputControl.displayName = 'PasswordInputControl';
}

function useValidationHelper () {
    const [isOpen, {
        on: open, off: close
    }] = useBoolean(false);
    const [isVisible, { toggle: toggleVisible }] = useBoolean(false);
    const VisibilityStateIcon = (
        <RevealPasswordButton
            isVisible={isVisible}
            onClick={toggleVisible}
        />
    );

    return {
        VisibilityStateIcon,
        isVisible,
        open,
        close,
        isOpen
    };
}
