import {
    chakra, Center
} from '@chakra-ui/react';

export const Card = chakra(Center, {
    baseStyle: {
        width: '100%',
        background: 'white',
        boxShadow: '0px 2px 20px rgba(0, 25, 55, 0.08)',
        borderRadius: '20px',
        flexDirection: 'column'
    }
});
