import React from 'react';
import {
    Route, Routes, Navigate
} from 'react-router-dom';

import { AccountRoute } from './account/account-route';
import { ErrorRoute } from './error/error-route';
import { ErrorBoundary } from './error/errors/error-boundary';
import { SubscriptionRoute } from './subscription/subscription-route';

/**
 * Top level application router, add all the main urls here.
 *
 * @see docs https://reactrouterdotcom.fly.dev/docs/en/v6/getting-started/overview
 */
export function AppRoutes () {
    return (
        <Routes>
            <Route
                path={'error/*'}
                element={<ErrorRoute />}
            />

            <Route
                path={'account/*'}
                element={<AccountRoute />}
            />

            <Route
                path={'subscription/*'}
                element={
                    <ErrorBoundary context={'/subscription/*'}>
                        <SubscriptionRoute />
                    </ErrorBoundary>
                }
            />

            <Route
                path={'*'}
                element={
                    // This is a temporary default route while the actual app is not implemented,
                    // Needs a business rule here instead to decide what to show.
                    <Navigate to={'/subscription/create/select'} />
                }
            />
        </Routes>
    );
}

