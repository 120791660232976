import { useQuery } from '@tanstack/react-query';

import type { FaqData } from '../../../fetch-static-content/contentful/faqs';

export function useFaqsDataQuery () {
    return useQuery(['faqs'], () => {
        return import(
            /* webpackChunkName: "faqs"*/
            '../../../__static-content__/faqs.json'
        ) as any as FaqData;
    });
}
