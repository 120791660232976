/* eslint-disable max-len */
import { chakra } from '@chakra-ui/react';
import React from 'react';

export function ValidationInvalidIcon () {
    return (
        <chakra.svg data-id={'invalid'} width={'20px'} height={'20px'} viewBox={'0 0 20 20'} fill={'none'}>
            <ellipse cx={'10.0003'} cy={'9.99996'} rx={'3.33333'} ry={'3.33333'} fill={'#CBD3DC'} />
        </chakra.svg>
    );
}
