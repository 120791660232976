import styled from '@emotion/styled';

export const SubscriptionReminderTextBtn = styled.button`
  height: 20px;
  display: inline-block;
  border: none;
  padding: 0;
  text-decoration: underline;
  background: none;
  color: #007ACC;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  max-width: unset;
  min-width: unset;
  margin-right: 0;
  margin-left: .8em;
  font-weight: normal;
  
  :hover {
    color: #0063A7;
  }
`;

