import React from 'react';

import { usePlansIpLocation } from '../../../plans/use-plans-ip-location';
import { Pricing } from '../../pricing';

type Props = {
    subscribeChosenRoute: string
};

export function PricingIpLocation ({ subscribeChosenRoute }: Props) {
    const pricingProps = usePlansIpLocation();

    return (
        <Pricing
            {...pricingProps}
            subscribeChosenRoute={subscribeChosenRoute}
        />
    );
}
