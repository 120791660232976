/* eslint-disable max-len */
import { chakra } from '@chakra-ui/react';
import React from 'react';

export function ValidationIsValidIcon () {
    return (
        <chakra.svg data-id={'valid'} width={'20px'} height={'20px'} viewBox={'0 0 20 20'} fill={'none'}>
            <path
                d={'M4.1665 10.8334L7.49984 14.1667L15.8332 5.83337'}
                stroke={'#1D9B37'}
                strokeWidth={'2'}
                strokeLinecap={'round'}
                strokeLinejoin={'round'}
            />
        </chakra.svg>
    );
}
