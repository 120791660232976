import { useField } from 'formik';
import React from 'react';

import { INVALID_EMAIL, REQUIRED_EMAIL } from '../../../../../../__utils__/utils';
import { NoticePanel } from '../../../../../../components/notice-panel/notice-panel';
import { NOTICE_PANEL_TYPES } from '../../../../../../components/notice-panel/notice-panel-types';

export function EmailErrorNotices () {
    const [_, meta] = useField('email');
    const error = meta?.error || '';
    const notice = NOTICE_PANEL_TYPES({ type: error }) || null;

    if (!error || error === INVALID_EMAIL || error === REQUIRED_EMAIL || !notice) {
        return null;
    }

    return (
        <NoticePanel
            {...notice}
            wrapperProps={{
                // important needed for stack spacing override
                mb: '20px !important'
            }}
        />
    );
}
