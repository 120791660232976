import React from 'react';

import type { ChargebeeInstance } from '../../chargebee/chargebee.types';
import { useChargebeeInit } from '../../chargebee/use-chargebee-init';
import { ErrorBoundary } from '../../routes/error/errors/error-boundary';

type SalesforceDataProps = {
    render: (chargebeeInstance: ChargebeeInstance) => JSX.Element
};

export function ChargebeeRenderGate ({ render }: SalesforceDataProps) {
    const chargebeeInstance = useChargebeeInit();

    if (!chargebeeInstance) {
        return null;
    }

    return (
        <ErrorBoundary context={'SalesforceDataGate'}>
            {render(chargebeeInstance)}
        </ErrorBoundary>
    );
}
