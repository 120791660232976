import { Box, Stack } from '@chakra-ui/react';
import { FormikProvider } from 'formik';
import React from 'react';
import { Navigate } from 'react-router-dom';

import type { ChargebeeInstance } from '../../../chargebee/chargebee.types';
import { ComposeRenderProps } from '../../../components/compose/compose';
import { useEnvConfig } from '../../../env-config';
import { PageCenterForm } from '../../../page/page-content/page-content.styles';
import { PageHeading } from '../../../page/page-heading/page-heading';
import { useChosenSubscriptionSelector } from '../../../subscription-model/subscription.selectors';
import type { SalesforceURLData } from '../../../subscription-model/subscription.types';
import { ChargebeeRenderGate } from '../../__utils__/chargebee-render-gate';
import { ContactUsRenderGate } from '../../__utils__/contact-us-render-gate';
import { SalesforceDataRenderGate } from '../../__utils__/salesforce-data-render-gate';
import { CheckoutFooter } from '../../checkout-footer/checkout-footer';
import { ExistingCustomerDetails } from '../../header-details/existing-customer-details';
import { useExistingCustomerCheckoutPage } from '../existing-customer-checkout-form/use-existing-customer-checkout-page';
import { ExistingCustomerCheckoutView } from '../existing-customer-payment-details/existing-customer-checkout-view';

type RenderPropsComposedData = [
    ChargebeeInstance,
    SalesforceURLData,
    { contactUsUrl: string },
];

const components = [
    ChargebeeRenderGate,
    SalesforceDataRenderGate,
    ContactUsRenderGate
];

export function ExistingCustomerCheckoutPage ({ successRedirectTo }: {
    successRedirectTo: string
}) {
    return (
        <ComposeRenderProps<RenderPropsComposedData>
            components={components}
            composed={([chargebeeInstance, salesforce, { contactUsUrl }]) => (
                <ExistingCustomerCheckoutPageView
                    salesforce={salesforce}
                    chargebeeInstance={chargebeeInstance}
                    contactUsUrl={contactUsUrl}
                    successRedirectTo={successRedirectTo}
                />
            )}
        />
    );
}

type Props = {
    successRedirectTo: string
    contactUsUrl: string
    salesforce: SalesforceURLData
    chargebeeInstance: ChargebeeInstance
};

export function ExistingCustomerCheckoutPageView ({
    successRedirectTo, salesforce, chargebeeInstance, contactUsUrl
}: Props) {
    const {
        formValue,
        cardRef,
        onBack
    } = useExistingCustomerCheckoutPage({
        contactUsUrl,
        successRedirectTo,
        salesforce,
        chargebeeInstance
    });
    const { ENABLE_MAINTENANCE_PAGE } = useEnvConfig();
    const subscription = useChosenSubscriptionSelector(false);
    const ribbonText = subscription?.ribbonText || null;

    if (ENABLE_MAINTENANCE_PAGE) {
        return (
            <Navigate to={'/error/under-maintenance'} />
        );
    }

    return (
        <div data-testid={'existing-customer-checkout-page-view'}>
            <PageCenterForm>
                <PageHeading
                    headingText={'Finish setup'}
                    onBack={onBack}
                    ribbonText={ribbonText}
                >
                    <ExistingCustomerDetails />
                </PageHeading>
            </PageCenterForm>

            <PageCenterForm>
                <FormikProvider value={formValue}>
                    <Stack
                        data-testid={'renew-checkout-form-container'}
                        layerStyle={'card'}
                        w={'100%'}
                        align={'center'}
                        mb={'32px'}
                        spacing={0}
                    >
                        <Box
                            data-testid={'renew-checkout-form'}
                            w={'100%'}
                            px={{
                                mobile: '24px',
                                fieldRowTablet: '64px',
                                desktop: '74px'
                            }}
                        >
                            <ExistingCustomerCheckoutView cardRef={cardRef} />
                        </Box>
                    </Stack>

                    <CheckoutFooter />
                </FormikProvider>
            </PageCenterForm>
        </div>
    );
}
