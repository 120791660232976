/* eslint-disable max-len */
import { chakra } from '@chakra-ui/react';
import React from 'react';

export function IconTick () {
    return (
        <chakra.svg
            width={'8px'}
            height={'8px'}
            viewBox={'0 0 9 6'}
        >
            <path fillRule={'evenodd'} clipRule={'evenodd'} d={'M3.34402 5.68522L0.805559 3.13137C0.713251 3.03906 0.713251 2.88522 0.805559 2.79291L1.14402 2.45445C1.23633 2.36214 1.39017 2.36214 1.48248 2.45445L3.39017 4.37752C3.45171 4.43906 3.5594 4.43906 3.62094 4.37752L7.3594 0.608293C7.45171 0.515986 7.60556 0.515986 7.69787 0.608293L8.03633 0.946755C8.12864 1.03906 8.12864 1.19291 8.03633 1.28522L3.68248 5.68522C3.59017 5.79291 3.43633 5.79291 3.34402 5.68522Z'} fill={'white'} />

        </chakra.svg>
    );
}
