import { Box, chakra } from '@chakra-ui/react';

import iconPlus from './assets/icon_plus.svg';
import iconSwish from './assets/icon_swishLine.svg';

export const MonthlySubText = chakra(Box, {
    baseStyle: {
        mt: {
            mobile: 0,
            subscriptionInclusionsTablet: '-2px'
        }
    }
});

export const FreeTrialPlus = chakra(Box, {
    baseStyle: {
        position: 'relative',
        display: 'inline-block',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: iconPlus,
        verticalAlign: 'middle',
        mr: '10px',
        w: {
            mobile: '18px',
            subscriptionInclusionsTablet: '24px'
        },
        h: {
            mobile: '18px',
            subscriptionInclusionsTablet: '24px'
        }
    }
});

export const FreeTrialSwishImage = chakra(Box, {
    baseStyle: {
        position: 'relative',
        display: 'block',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: iconSwish,
        mt: {
            mobile: '-5px',
            subscriptionInclusionsTablet: '-2px'
        },
        ml: {
            mobile: '22px',
            subscriptionInclusionsTablet: '6px'
        },
        w: {
            mobile: '90%',
            subscriptionInclusionsTablet: '100%'
        },
        h: {
            mobile: '9px',
            subscriptionInclusionsTablet: '17px'
        }
    }
});
