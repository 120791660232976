import { Box, Stack, useMediaQuery, VStack } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React from 'react';

import { isCyberWeekCampaignActive, isStringDefined } from '../../__utils__/utils';
import type { ProductItemWithText } from '../../api/gateway/onboarding-data-contract';
import { useQuoteAndPricingSelector } from '../../subscription-model/subscription.selectors';
import { CheckoutPricePanelSkeleton } from './components/checkout-price-panel-skeleton';
import {
    CouponDiscountSubscriptionText,
    FreeTrialTotalPriceText
} from './components/free-trial-and-discount-text';
import TotalAfterTrialIcon from './components/total-after-trial-icon';

export type CheckoutPricePanelProps = {
    discountAmount: string
    data?: {
        baseAmountPrice: string
        totalDueTodayPrice: string
        totalPriceAfterTrial: string
        childrenCount: number
        isTrial: boolean
        productItem: ProductItemWithText
        perChildPrice: string
    } | null
};

export const CheckoutPricePanelView = ({ data, discountAmount }: CheckoutPricePanelProps) => {
    const [isMobileVersion] = useMediaQuery([
        '(max-width: 520px)'
    ]);
    const quoteAndPricingData = useQuoteAndPricingSelector();
    const internalCoupon = quoteAndPricingData?.productItem?.internalCoupon || null;
    const isCampaignActive = isStringDefined(internalCoupon);
    const isCyberMondayCampaign = isCyberWeekCampaignActive(internalCoupon);
    const campaignHeading = isCyberMondayCampaign
        ? t`Product - Cyber Monday - Campaign - Annual - Highlight`
        : t`Product - Campaign - Annual - Highlight`;

    if (!data) {
        return <CheckoutPricePanelSkeleton />;
    }

    const {
        baseAmountPrice,
        totalDueTodayPrice,
        totalPriceAfterTrial,
        childrenCount,
        isTrial,
        productItem,
        perChildPrice
    } = data;
    const isMonthlyPlan = quoteAndPricingData?.productItem?.subscriptionType === 'Monthly';

    return (
        <Stack
            data-testid={'checkout-price-panel'}
            borderRadius={'16px'}
            borderColor={'secondary.500'}
            borderWidth={'1px'}
            width={'100%'}
            spacing={0}
            fontWeight={{
                mobile: 'normal',
                tablet: 'medium'
            }}
        >
            <Stack
                spacing={0}
                backgroundColor={'secondary.500'}
                borderRadius={'14px 14px 0 0'}
            >
                {isTrial ?
                    <VStack
                        data-testid={'checkout-price-panel-trial-heading'}
                        color={'neutral.900'}
                        fontWeight={'medium'}
                        fontSize={{
                            mobile: '14px',
                            tablet: '18px'
                        }}
                        lineHeight={{
                            mobile: '21px',
                            tablet: '27px'
                        }}
                        letterSpacing={{
                            mobile: '-0.25px'
                        }}
                        padding={'8px'}
                    >
                        <p>{productItem.ribbonText}</p>
                    </VStack> : null
                }

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    fontSize={{
                        mobile: '16px',
                        tablet: '18px'
                    }}
                    lineHeight={{
                        mobile: '24px',
                        tablet: '26px'
                    }}
                    letterSpacing={{
                        mobile: '-0.1px'
                    }}
                    borderRadius={'16px 16px 0 0'}
                    zIndex={1}
                    color={'neutral.700'}
                    backgroundColor={'secondary.0'}
                    borderColor={'secondary.500'}
                >
                    <Box
                        data-testid={'checkout-price-panel-subscription-type'}
                        display={'inline-flex'}
                        justifyContent={'space-between'}
                        padding={{
                            mobile: '15px 8px 0 8px',
                            tablet: '36px 32px 0 32px'
                        }}
                        marginBottom={{
                            mobile: '16px',
                            tablet: '32px'
                        }}
                    >
                        <div>
                            {productItem.paymentDisplay} ({perChildPrice})
                            {childrenCount > 1 ?
                                <span> X {childrenCount}</span>
                                : null
                            }
                            {
                                isCampaignActive && !isMonthlyPlan ?
                                    <p>({campaignHeading})</p> :
                                    null
                            }
                        </div>
                        <p>{baseAmountPrice}</p>
                    </Box>

                    {quoteAndPricingData?.coupon?.isValid && <CouponDiscountSubscriptionText
                        isValid={quoteAndPricingData?.coupon?.isValid || false}
                        isTrial={isTrial}
                        discountAmount={discountAmount}
                        childrenCount={childrenCount}
                    />}

                    {isTrial && <FreeTrialTotalPriceText
                        totalPriceAfterTrial={totalPriceAfterTrial}
                    />}
                </Box>
            </Stack>

            <Box
                data-testid={'checkout-price-panel-total-due'}
                display={'inline-flex'}
                justifyContent={'space-between'}
                padding={{
                    mobile: '12px 6px 16px 12px',
                    tablet: '32px'
                }}
                color={'neutral.900'}
                backgroundColor={'neutral.50'}
                borderRadius={'0 0 16px 16px'}
                fontSize={{
                    mobile: '18px',
                    tablet: '24px'
                }}
                lineHeight={{
                    mobile: '30px',
                    tablet: '32px'
                }}
            >
                <p>{t`Total Due Today`}</p>
                <p>
                    {totalDueTodayPrice} {!isMobileVersion ? productItem.currencyCode : null}
                    {isTrial ?
                        <span>
                            <TotalAfterTrialIcon />
                        </span> : null
                    }
                </p>
            </Box>
        </Stack>
    );
};
