import { RippleButton, RippleButtonProps } from '@3plearning/chakra-mathletics-theme';
import {
    Center,
    chakra,
    Flex,
    Text
} from '@chakra-ui/react';
import React from 'react';

export const CounterContainer = chakra(Flex, {
    label: 'counter-container',
    baseStyle: {
        width: '100%',
        height: '40px',
        borderRadius: '20px',
        border: 'solid 2px',
        borderColor: 'primary.500'
    }
});

export const CounterNumHolder = chakra(Center, {
    label: 'counter-num-holder',
    baseStyle: {
        width: {
            mobile: '134px',
            pricingTablet: '164px'
        },
        height: '100%',
        borderRight: 'solid 1px',
        borderLeft: 'solid 1px',
        borderColor: 'primary.500'
    }
});

export const CounterNum = chakra(Text, {
    label: 'counter-num',
    baseStyle: {
        fontSize: '18px',
        lineHeight: '27px',
        color: 'neutral.700',
        fontWeight: 600
    }
});

type CounterBtnProps = {
    btnType: 'minus' | 'plus'
} & RippleButtonProps;

export const CounterBtn = ({ btnType, ...restProps }: CounterBtnProps) => (
    <RippleButton
        {...restProps}
        variant={'ghost'}
        sx={{
            label: `${btnType}-counter-btn-holder`,
            width: {
                mobile: '96px',
                pricingTablet: '114px'
            },
            height: '100%',
            borderTopLeftRadius: btnType === 'minus' ? '20px' : '0',
            borderBottomLeftRadius: btnType === 'minus' ? '20px' : '0',
            borderTopRightRadius: btnType === 'plus' ? '20px' : '0',
            borderBottomRightRadius: btnType === 'plus' ? '20px' : '0'
        }}
    />
);
