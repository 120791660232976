import React from 'react';
import {
    Route, Routes
} from 'react-router-dom';

import { Stepper } from '../../components/stepper/stepper';
import { createRouteSteps } from '../__utils__/stepper-route-data';

export function CreateRouteSteps () {
    return (
        <Routes>
            <Route
                path={'create-account'}
                element={<Stepper steps={createRouteSteps} />}
            />

            {/* TODO : this is required for phase 2. Uncomment when needed */}
            {/*<Route*/}
            {/*    path={CREATE_ROUTES.addChildren}*/}
            {/*    element={<Stepper steps={createRouteSteps} />}*/}
            {/*/>*/}

            <Route
                path={'checkout'}
                element={<Stepper steps={createRouteSteps} />}
            />
        </Routes>
    );
}
