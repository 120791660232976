import { HStack, Image, SkeletonCircle, Text, VStack } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React, { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ContentfulUrlButton, ContentfulUrlButtonProps } from '../../../components/contentful-url-button/contentful-url-button';
import { useThemeBreakpointMinWidth } from '../../../hooks/use-breakpoints';
import { subscriptionActions } from '../../../subscription-model/subscription.slice';
import { PageModal } from '../page-modal';
import backgroundsSrc from './images/backgrounds.svg';
import ProblemBird from './images/Problem Bird.svg';

type Props = PropsWithChildren<{
    headingText: string
    bodyText1?: string|null
    bodyText2?: string|null
    ['data-testid']?: string
}>;

export function ErrorPageModal ({
    'data-testid': dataTestId = 'error-page-modal',
    headingText,
    bodyText1 = '',
    bodyText2,
    children
}: Props) {
    return (
        <PageModal
            data-testid={dataTestId}
            maxW={{
                base: '100%',
                modalCardTablet: '816px'
            }}
            mx={{
                base: 0,
                modalCardTablet: '56px'
            }}
            Body={<UnexpectedErrorBody
                bodyText1={bodyText1}
                bodyText2={bodyText2}
                headingText={headingText}
            />}
            backgroundProps={{
                src: backgroundsSrc,
                top: '-947px',
                left: '-991px',
                minWidth: '2330px',
                minHeight: '2330px'
            }}
        >
            {children}
        </PageModal>
    );
}

function UnexpectedErrorBody ({
    headingText,
    bodyText1,
    bodyText2
}: Props) {
    const isCardTablet = useThemeBreakpointMinWidth('modalCardTablet');
    const isDesktop = useThemeBreakpointMinWidth('desktop');
    const combinedBody1Text = `${bodyText1} ${bodyText2||''}`;
    const { pathname } = useLocation();
    const userNotCreatedRoute = pathname.includes('user-not-created');

    if (isDesktop) {
        return (
            <HStack data-testid={'error-body'}>
                <VStack
                    maxW={'424px'}
                    align={'start'}
                    spacing={'32px'}
                >
                    <Text
                        textStyle={'cardHeading'}
                        dangerouslySetInnerHTML={{ __html: headingText }}
                    />

                    {bodyText1 && <Text
                        pb={'12px'}
                        textStyle={'cardBody'}
                        dangerouslySetInnerHTML={{ __html: combinedBody1Text }}
                    />}

                    { !userNotCreatedRoute ? <GoToMathleticsForHomeButton /> : null }
                </VStack>

                <Image
                    width={'278px'}
                    paddingTop={'92px'}
                    src={ProblemBird}
                    fallback={<SkeletonCircle
                        width={'278px'}
                        height={'254px'}
                        paddingTop={'92px'}
                    />}
                />
            </HStack>
        );
    }

    if (isCardTablet) {
        return (
            <VStack
                data-testid={'error-body'}
                spacing={'32px'}
                alignItems={'flex-start'}
            >
                <HStack alignItems={'flex-end'}>
                    <Text
                        maxW={'267px'}
                        textStyle={'cardHeading'}
                        dangerouslySetInnerHTML={{ __html: headingText }}
                    />

                    <Image
                        width={'261px'}
                        src={ProblemBird}
                        fallback={<SkeletonCircle
                            width={'261px'}
                            height={'239px'}
                        />}
                    />
                </HStack>

                {bodyText1 && <Text
                    pb={'12px'}
                    textStyle={'cardBody'}
                    dangerouslySetInnerHTML={{ __html: combinedBody1Text }}
                />}

                { !userNotCreatedRoute ?
                    <GoToMathleticsForHomeButton
                        marginTop={'12px'}
                    /> : null
                }
            </VStack>
        );
    }

    return (
        <VStack
            data-testid={'error-body'}
            spacing={'20px'}
        >
            <HStack>
                <Text
                    textStyle={'cardHeading'}
                    dangerouslySetInnerHTML={{ __html: headingText }}
                />

                <Image
                    width={'112px'}
                    src={ProblemBird}
                    fallback={<SkeletonCircle
                        width={'112px'}
                        height={'102px'}
                    />}
                />
            </HStack>

            {bodyText1 && <Text
                pb={'12px'}
                textStyle={'cardBody'}
                dangerouslySetInnerHTML={{ __html: bodyText1 }}
            />}

            {bodyText2 && <Text
                pb={'12px'}
                textStyle={'cardBody'}
                dangerouslySetInnerHTML={{ __html: bodyText2 }}
            />}

            {!userNotCreatedRoute ?
                <GoToMathleticsForHomeButton
                    marginTop={'12px'}
                    width={'100%'}
                /> : null
            }
        </VStack>
    );
}

function GoToMathleticsForHomeButton (props: Omit<ContentfulUrlButtonProps, 'contentfulUrlName'>) {
    const dispatch = useDispatch();
    const onClick = () => {
        // Intentionally clear data, this is a mitigation
        // for corrupted sessionStorage value, otherwise it's possible to be stuck
        // if there is corrupt data in sessionStorage.
        dispatch(subscriptionActions.clearData());
    };

    return (
        <ContentfulUrlButton
            height={'54px'}
            size={'lg'}
            contentfulUrlName={'Mathletics Home'}
            onClick={onClick}
            {...props}
        >
            {t`Mathletics for home`}
        </ContentfulUrlButton>
    );
}
