import {
    Box, chakra, SystemStyleObject, Flex
} from '@chakra-ui/react';

export const FreeTrialInfoContainer = chakra(Box, {
    baseStyle: {
        mt: {
            mobile: '50px',
            tablet: '98px',
            desktop: '200px'
        },
        mb: '24px',
        minHeight: '282px',
        boxShadow: 'baseShadow',
        borderRadius: '20px',
        position: 'relative',
        display: 'flex',
        flexDirection: {
            mobile: 'column',
            freeTrialInfoColumn: 'row'
        },
        backgroundColor: 'secondary.0'
    }
});

export const FreeTrialText = chakra(Flex, {
    baseStyle: {
        margin: {
            mobile: '56px 0',
            freeTrialInfoColumn: '56px 0 56px 58px'
        },
        width: {
            mobile: 'unset',
            desktop: '363px'
        },
        fontSize: {
            mobile: '32px',
            tablet: '28px',
            desktop: '40px'
        },
        lineHeight: '123%',
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: -0.6,
        color: 'neutral.900',
        flexDirection: 'column',
        alignItems: {
            mobile: 'center',
            freeTrialInfoColumn: 'start'
        }
    }
});

export const RootWrapper = chakra(Box, {
    baseStyle: {
        position: 'relative',
        minH: '282px',
        maxW: '816px'
    }
});

export const ContentWrapper = chakra(Box, {
    baseStyle: {
        mt: {
            mobile: '50px',
            tablet: '98px',
            desktop: '200px'
        },
        mb: '24px',
        minW: '420px',
        minHeight: '282px',
        boxShadow: 'baseShadow',
        borderRadius: '20px',
        position: 'relative',
        display: 'flex',
        flexDirection: {
            mobile: 'column',
            freeTrialInfoColumn: 'row'
        },
        backgroundColor: 'secondary.0'
    }
});

export const ButtonWrapper = chakra(Box, {
    baseStyle: {
        mt: '8px',
        h: '52px',
        w: '275px'
    }
});

export const CircleBackgroundStyles: SystemStyleObject = {
    position: 'absolute',
    top: {
        mobile: '-20px',
        tablet: '200px',
        desktop: '100px'
    },
    right: {
        mobile: '115px',
        tablet: '338px',
        desktop: '146px'
    },
    h: {
        mobile: '325px',
        tablet: '400px',
        desktop: '728px'
    },
    zIndex: '-1'
};

export const DottedLineBackgroundStyles: SystemStyleObject = {
    position: 'absolute',
    top: {
        tablet: '226px',
        desktop: '250px'
    },
    right: {
        tablet: '306px',
        desktop: '258px'
    },
    h: {
        mobile: '325px',
        tablet: '400px',
        desktop: '540px'
    },
    zIndex: '-1'
};
