import { Box, Center, Image, Flex, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React, { useState } from 'react';

import { isCyberWeekCampaignActive, isStringDefined } from '../__utils__/utils';
import type { GetPlansDataQueryData } from '../api/gateway/onboarding-data-contract';
import { RibbonTextWrapper } from '../page/page-heading/components/specials-ribbon';
import { campaignTheme } from '../styles/theme/foundations/colors';
import campaignBottomRibbon from './assets/campaignBottomRibbon.svg';
import campaignRibbon from './assets/campaignRibbon.svg';
import { FormBackgrounds } from './components/form-backgrounds/form-backgrounds';
import { NumSubscriptionCounter } from './components/num-subscription-counter/num-subscription-counter';
import { PricingCard } from './components/pricing-card/pricing-card';
import { PricingSkeleton } from './components/pricing-skeleton/pricing-skeleton';
import {
    FootLabel, Note,
    PricingCardHolderStyled, PricingCards,
    SaveLabel
} from './pricing.styles';

export type PricingProps = {
    productItems: GetPlansDataQueryData['plan']['productItems']
    childrenCount: number
    cardHeadingText: string
    onSetChildrenCount: (childrenCount: number) => any
    subscribeChosenRoute: string
    isLoading: boolean
    currencyCode: string
    internalCoupon: string | null
};

export function Pricing ({
    internalCoupon,
    currencyCode,
    productItems,
    childrenCount,
    cardHeadingText,
    onSetChildrenCount,
    isLoading,
    subscribeChosenRoute
}: PricingProps) {
    if (isLoading) {
        return (
            <PricingSkeleton />
        );
    }

    return (
        <PricingView
            currencyCode={currencyCode}
            productItems={productItems}
            childrenCount={childrenCount}
            cardHeadingText={cardHeadingText}
            onSetChildrenCount={onSetChildrenCount}
            subscribeChosenRoute={subscribeChosenRoute}
            internalCoupon={internalCoupon}
        />
    );
}

type PricingViewProps = {
    currencyCode: string
    productItems: GetPlansDataQueryData['plan']['productItems']
    childrenCount: number
    cardHeadingText: string
    onSetChildrenCount: (childrenCount: number) => any
    subscribeChosenRoute: string
    internalCoupon: string | null
};

export function PricingView ({
    currencyCode,
    productItems,
    childrenCount,
    cardHeadingText,
    onSetChildrenCount,
    subscribeChosenRoute,
    internalCoupon
}: PricingViewProps) {
    const [showSavings, setShowSavings] = useState(null);
    const [rRPValue, setRRPValue] = useState(0);
    const isCampaignActive = isStringDefined(internalCoupon);
    const isCyberMondayCampaign = isCyberWeekCampaignActive(internalCoupon);
    const campaignbg = isCyberMondayCampaign
        ? campaignTheme.cyberMonday
        : campaignTheme.primary;

    return (
        <Box position={'relative'}>
            <FormBackgrounds />

            <Center
                width={'100%'}
                flexDirection={'column'}
            >
                <PricingCardHolderStyled data-testid={'pricing-card-holder'}>
                    <Center
                        data-testid={'card-heading'}
                        bg={isCampaignActive ? campaignbg : 'primary.50'}
                        border={isCampaignActive ? '4px solid white' : '6px solid white'}
                        boxSizing={'border-box'}
                        w={'100%'}
                        h={'64px'}
                        borderRadius={'20px 20px 0 0'}
                        display={{
                            pricingTablet: 'flex',
                            mobile: 'none'
                        }}
                    >

                        <SaveLabel
                            color={isCampaignActive ? campaignTheme.tertiary : 'neutral.700'}
                            fontWeight={isCampaignActive ? 800 : 400}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                    __html: cardHeadingText
                                }}
                            />
                        </SaveLabel>

                        { isCampaignActive ?
                            <Flex
                                zIndex={1}
                                position={'absolute'}
                                top={'4px'}
                                right={'3px'}
                            >
                                <ShowSavingsBanner isCampaignActive={isCampaignActive} showSavings={showSavings} />
                                <Image
                                    src={campaignRibbon}
                                    alt={'Campaign Ribbon'}
                                />
                            </Flex>:
                            null
                        }
                    </Center>

                    <Center>
                        <NumSubscriptionCounter
                            numSubscriptions={childrenCount}
                            setNumSubscriptions={onSetChildrenCount}
                        />
                    </Center>

                    <PricingCards
                        data-testid={'pricing-cards'}
                    >
                        {productItems.map(product => (
                            <PricingCard
                                isTrial={product.isTrial}
                                key={product.productCode}
                                product={product}
                                childrenCount={childrenCount}
                                subscribeChosenRoute={subscribeChosenRoute}
                                setRRPValue={setRRPValue}
                                setShowSavings={setShowSavings}
                                isCampaignActive={isCampaignActive}
                            />
                        ))}
                    </PricingCards>

                    <FootLabel data-testid={'footer-label'}>
                        All plans auto renew but you can cancel anytime.
                    </FootLabel>

                    {cardHeadingText ?
                        <Box
                            data-testid={'mobile-save-label'}
                            color={isCampaignActive ? campaignTheme.tertiary : 'neutral.700'}
                            background={isCampaignActive ? campaignTheme.primary : 'primary.50'}
                            border={isCampaignActive ? '4px solid white' : '6px solid white'}
                            boxSizing={'border-box'}
                            width={'100%'}
                            borderRadius={'0 0 20px 20px'}
                            textAlign={{
                                base: 'left',
                                modalCardTablet: 'center'
                            }}
                            display={{
                                pricingTablet: 'none',
                                mobile: 'block'
                            }}
                        >
                            <SaveLabel>
                                {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                                <div dangerouslySetInnerHTML={{ __html: cardHeadingText }} />
                            </SaveLabel>

                            { isCampaignActive ?
                                <Flex
                                    zIndex={1}
                                    position={'absolute'}
                                    bottom={'110px'}
                                    right={'-20px'}
                                >
                                    <ShowSavingsBanner isCampaignActive={isCampaignActive} showSavings={showSavings} />
                                    <Image
                                        src={campaignBottomRibbon}
                                        alt={'Campaign Bottom Ribbon'}
                                    />
                                </Flex>:
                                null
                            }
                        </Box> :
                        null
                    }
                </PricingCardHolderStyled>

                <Note>
                    * Savings are calculated by comparing the yearly subscription rate to the monthly subscription
                    rate over 12 months <strong>({t`RRP`} {rRPValue} {currencyCode})</strong>.
                </Note>
            </Center>
        </Box>
    );
}

type ShowSavingsBannerProps = {
    isCampaignActive: boolean
    showSavings: string | null
};
function ShowSavingsBanner ({ isCampaignActive, showSavings }: ShowSavingsBannerProps) {
    return (
        <RibbonTextWrapper
            sx={{
                label: 'campaign-banner-text-holder'
            }}
        >
            <Text
                noOfLines={1}
                fontWeight={600}
                color={isCampaignActive ? campaignTheme.tertiary : 'neutral.900'}
                fontSize={{
                    mobile: '14px',
                    pageHeaderTablet: '16px'
                }}
                letterSpacing={{
                    mobile: '-0.24px',
                    pageHeaderTablet: '0.15px'

                }}
            >
                {showSavings}<span>*</span>
            </Text>
        </RibbonTextWrapper>
    );
}
