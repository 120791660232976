import { Box, SkeletonText } from '@chakra-ui/react';
import React from 'react';

// eslint-disable-next-line max-len
const PLACEHOLDER_TEXT = 'Mathletics is brilliant and I have seen with my own eyes what a solid foundation in maths it has given both my children. They are confident and comfortable with the subject and I believe that Mathletics is the reason.';

export function CheckoutTermsPanelSkeleton () {
    return (
        <Box data-testid={'checkout-terms-panel-loading-fallback'}>
            <Box
                minHeight={'230px'}
                width={'100%'}
                borderRadius={'20px'}
                position={'relative'}
            >
                <SkeletonText
                    startColor={'neutral.100'}
                    endColor={'neutral.300'}
                    fadeDuration={1.2}
                    noOfLines={1}
                    skeletonHeight={'26px'}
                    my={'20px'}
                >
                    {PLACEHOLDER_TEXT}
                </SkeletonText>
                <SkeletonText
                    startColor={'neutral.100'}
                    endColor={'neutral.300'}
                    fadeDuration={1.2}
                    noOfLines={1}
                    skeletonHeight={'26px'}
                    mb={'20px'}
                >
                    {PLACEHOLDER_TEXT}
                </SkeletonText>

                <SkeletonText
                    startColor={'neutral.100'}
                    endColor={'neutral.300'}
                    fadeDuration={1.2}
                    noOfLines={1}
                    skeletonHeight={'26px'}
                >
                    {PLACEHOLDER_TEXT}
                </SkeletonText>

                <SkeletonText
                    startColor={'neutral.100'}
                    endColor={'neutral.300'}
                    fadeDuration={1.2}
                    noOfLines={1}
                    skeletonHeight={'26px'}
                >
                    {PLACEHOLDER_TEXT}
                </SkeletonText>
            </Box>
        </Box>
    );
}
