import { Box } from '@chakra-ui/react';
import React from 'react';
import {
    Route, Routes
} from 'react-router-dom';

import { MathleticsLogoLink } from '../components/mathletics-logo/mathletics-logo';
import { Stepper } from '../components/stepper/stepper';
import {
    addRouteSteps,
    renewRouteSteps
} from './__utils__/stepper-route-data';
import { CreateRouteSteps } from './components/create-route-steps';
import { LoginButton } from './components/login-button';

export function Header () {
    return (
        <Box
            data-testid={'header'}
            sx={{
                width: '100%',
                height: {
                    mobile: '68px',
                    desktop: '72px'
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'relative',
                zIndex: 1,
                boxShadow: 'var(--3p-shadows-baseShadow)',
                background: 'white',
                px: '24px'
            }}
        >
            <MathleticsLogoLink />

            <Routes>
                <Route
                    path={'/subscription/create/*'}
                    element={<CreateRouteSteps />}
                />
                <Route
                    path={'/subscription/add/select'}
                    element={<Stepper steps={addRouteSteps} />}
                />
                <Route
                    path={'/subscription/add/checkout'}
                    element={<Stepper steps={addRouteSteps} />}
                />
                <Route
                    path={'/subscription/renew/select'}
                    element={<Stepper steps={renewRouteSteps} />}
                />
                <Route
                    path={'/subscription/renew/checkout'}
                    element={<Stepper steps={renewRouteSteps} />}
                />
            </Routes>

            <LoginButton />
        </Box>
    );
}
