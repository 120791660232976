import { Text, VStack, type StackProps, Image, type ImageProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

export type ModalCardProps =
    & {
    Header?: JSX.Element | null
    headingText?: string | null
    bodyText?: string | null
    Body?: JSX.Element | null
    Footer?: JSX.Element | null
    backgroundProps?: ImageProps | null
} & StackProps;

export const ModalCard = forwardRef<HTMLDivElement, ModalCardProps>(({
    Header = null,
    headingText = null,
    bodyText = null,
    Body = null,
    Footer = null,
    backgroundProps = null,
    children,
    ...restProps
}, elementRef) => {
    return (
        <VStack
            ref={elementRef}
            position={'relative'}
            layerStyle={'card'}
            borderTopRadius={{
                mobile: 0,
                modalCardTablet: '20px'
            }}
            maxW={{
                base: '100%',
                modalCardTablet: '472px'
            }}
            paddingTop={'40px'}
            paddingBottom={{
                base: '24px',
                modalCardTablet: '40px'
            }}
            px={{
                mobile: '24px',
                modalCardTablet: '48px'
            }}
            alignItems={'start'}
            spacing={'20px'}
            {...restProps}
        >
            {Header}

            {headingText && <Text
                textStyle={'cardHeading'}
                dangerouslySetInnerHTML={{ __html: headingText }}
                zIndex={2}
            />}

            {bodyText && <Text
                pb={'12px'}
                textStyle={'cardBody'}
                dangerouslySetInnerHTML={{ __html: bodyText }}
                zIndex={2}
            />}

            {Body}

            {Footer}

            {backgroundProps && <Image
                position={'absolute'}
                zIndex={-1}
                {...backgroundProps}
            />}

            {children}
        </VStack>
    );
});
