import { type EffectCallback, useEffect, useRef, useState } from 'react';

/**
 * Handle React 18 useEffect behaviour change in Strict mode.
 *
 * @see https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-strict-mode
 */
export const useEffectOnce = (effect: EffectCallback) => {
    const effectRef = useRef<EffectCallback>(effect);
    const effectDestructorRef = useRef<ReturnType<EffectCallback>>();
    const effectCalled = useRef(false);
    const hasRendered = useRef(false);
    const [, setRenderCount] = useState(0);

    if (effectCalled.current) {
        hasRendered.current = true;
    }

    useEffect(() => {
        // capture the first effect
        if (!effectCalled.current) {
            effectDestructorRef.current = effectRef.current();
            effectCalled.current = true;
        }

        setRenderCount((renderCount) => renderCount + 1);

        return () => {
            if (!hasRendered.current) {
                return;
            }

            // invoke the Destructor
            if (effectDestructorRef.current) {
                effectDestructorRef.current();
            }
        };
    }, []);
};
