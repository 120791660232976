import { Image } from '@chakra-ui/react';
import React, { useState } from 'react';

import minusSymbolSrc from '../../assets/minus.svg';
import plusSymbolSrc from '../../assets/plus.svg';
import { NumSubscriptionCounterProps } from '../num-subscription-counter/num-subscription-counter';
import {
    CounterBtn, CounterContainer, CounterNum, CounterNumHolder
} from './counter.styles';

type CountRange = {
    minCount: number
    maxCount: number
};

export function Counter (props: CountRange & NumSubscriptionCounterProps) {
    const {
        minCount, maxCount, numSubscriptions, setNumSubscriptions
    } = props;
    const [numCount, setNumCount] = useState<number>(numSubscriptions);
    const updateSubscriptionNum = (num: number) => {
        const tempNum = num + numCount;

        if(tempNum > (minCount - 1) && tempNum < (maxCount + 1)) {
            setNumCount(tempNum);
            setNumSubscriptions(tempNum);
        }
    };

    return (
        <CounterContainer data-testid={'counter-container'}>
            <CounterBtn
                disabled={numCount === minCount}
                aria-label={'minus-counter-btn'}
                data-testid={'minus-counter-btn'}
                btnType={'minus'}
                onClick={() => updateSubscriptionNum(-1)}
                children={<Image src={minusSymbolSrc} alt={'minus-icon'} w={'12px'} h={'12px'} />}
            />
            <CounterNumHolder>
                <CounterNum data-testid={'num-count'}>
                    {numCount}
                </CounterNum>
            </CounterNumHolder>
            <CounterBtn
                disabled={numCount === maxCount}
                aria-label={'plus-counter-btn'}
                data-testid={'plus-counter-btn'}
                btnType={'plus'}
                onClick={() => updateSubscriptionNum(1)}
                children={<Image src={plusSymbolSrc} alt={'plus-icon'} w={'12px'} h={'12px'} />}
            />
        </CounterContainer>
    );
}
