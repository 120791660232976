import * as Sentry from '@sentry/react';

import type {
    AuthorizedPaymentIntent, ChargebeeInstance, PaymentIntent
} from '../../chargebee/chargebee.types';

type Options = {
    paymentIntent: PaymentIntent
    chargebeeInstance: ChargebeeInstance
};

/**
 * For paying with an existing payment source.
 * Note this intent must already be created in the BE with the required referenceId.
 *
 * @see https://www.chargebee.com/checkout-portal-docs/3ds-helper-integration.html#_3ds-for-existing-cards
 */
export async function authorizeWith3dsIntent ({
    paymentIntent, chargebeeInstance
}: Options): Promise<AuthorizedPaymentIntent> {
    try {
        const threeDSHandler = await chargebeeInstance.load3DSHandler();

        threeDSHandler.setPaymentIntent(paymentIntent);

        const authorizedPaymentIntent = await threeDSHandler.handleCardPayment();

        Sentry.setExtra('authorizedPaymentIntent', authorizedPaymentIntent.id);

        console.log('authorizedPaymentIntent', authorizedPaymentIntent);

        return authorizedPaymentIntent;
    } catch (error) {
        console.error('Error authorizing intent with 3DSHandler', error);

        throw error;
    }
}
