import { TextInputControlProps, useMathleticsToast } from '@3plearning/chakra-mathletics-theme';
import { ButtonProps } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useVerifyAndApplyCouponMutation } from '../../__generated__/gateway-graphql';
import { useContentfulUrl } from '../../components/contentful-url-button/use-contentful-url';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { subscriptionActions } from '../../subscription-model/subscription.slice';
import { CouponStates } from '../coupon';

type InputProps = Partial<TextInputControlProps>;

export function useCoupon () {
    const contactUsUrl = useContentfulUrl('Contact Us');
    const subscription = useAppSelector(rootState => rootState.subscription);
    const { isCountryChanged } = subscription;
    const { addCoupon, applyCoupon, valid, invalid, validationError, removeCoupon } = CouponStates;
    const [couponState, setCouponState] = useState('ADD');
    const [buttonProps, setButtonProps] = useState<ButtonProps>();
    const [inputTextProps, setInputTextProps] = useState<InputProps>();
    const [couponDataDisplayText, setCouponDataDisplayText] = useState('');
    const [inputProps, setInputProps] = useState<InputProps>();
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null as any);
    const dispatch = useAppDispatch();
    const toast = useMathleticsToast();
    const addDiscountCoupon = useCallback(() => {
        setCouponState(applyCoupon);
        setInputTextProps({
            helperText: ''
        });
    }, [applyCoupon]);
    const { mutateAsync: VerifyAndApplyCoupon } = useVerifyAndApplyCouponMutation();
    const couponValidationError = t({
        id: 'Error - Coupon Validation Error',
        values: { contactUsUrl }
    });
    const validateCoupon = async () => {
        /**
         * Use this to manage BE request
         */
        if(inputRef && inputRef.current) {
            const trimmedValue = inputRef.current.value.trim();

            setIsLoading(true);
            try {
                const couponValid = await VerifyAndApplyCoupon({
                    input: {
                        couponCode: trimmedValue,
                        quoteId: subscription?.quoteAndPricingData?.quoteId,
                        sessionId: subscription.salesforce?.token,
                        userId: subscription.salesforce?.userId
                    }
                });

                setIsLoading(false);
                if (!couponValid.inputObject?.coupon) {
                    setCouponState(invalid);
                    setInputProps({
                        isSuccess: false
                    });
                }

                if (couponValid?.inputObject?.productItem &&
                    couponValid.inputObject.coupon &&
                    couponValid?.inputObject?.quoteId
                ) {
                    setCouponState(couponValid.inputObject.coupon.isValid ? valid : invalid);
                    setCouponDataDisplayText(couponValid.inputObject.coupon.description || '');
                    setInputProps({
                        isSuccess: couponValid.inputObject.coupon.isValid
                    });
                    dispatch(subscriptionActions.updatePricingData({
                        productItem: couponValid.inputObject.productItem,
                        quoteId: couponValid.inputObject.quoteId,
                        coupon: couponValid.inputObject.coupon
                    }));
                }
            } catch (error: any) {
                setIsLoading(false);
                setCouponState(validationError);
                setInputProps({
                    isSuccess: false
                });
                toast({
                    description: couponValidationError,
                    status: 'error',
                    duration: 9000
                });
                console.error('Error validating coupon', error);
                Sentry.setExtra('Coupon Validation Error', error);
            }
        }
    };
    const removeCouponCode = async () => {
        const couponValid = await VerifyAndApplyCoupon({
            input: {
                couponCode: '',
                quoteId: subscription?.quoteAndPricingData?.quoteId,
                sessionId: subscription.salesforce?.token,
                userId: subscription.salesforce?.userId
            }
        });

        dispatch(subscriptionActions.updatePricingData({
            productItem: couponValid?.inputObject?.productItem || null,
            quoteId: couponValid?.inputObject?.quoteId || '',
            coupon: couponValid?.inputObject?.coupon || null
        }));
        setInputProps({
            isSuccess: false
        });
        setCouponState(removeCoupon);
        inputRef.current.value = '';
    };

    useEffect(() => {
        switch(couponState) {
        case addCoupon:
            setButtonProps({
                onClick: addDiscountCoupon,
                children: t`Add Coupon`,
                marginLeft: '0'
            });
            break;
        case applyCoupon:
            setButtonProps({
                onClick: validateCoupon
            });
            break;
        case valid:
            setButtonProps({
                onClick: removeCouponCode,
                children: t`Remove Coupon`

            });
            setInputTextProps({
                helperText: t`Coupon-applied`
            });
            break;
        case invalid:
            setButtonProps({
                onClick: validateCoupon,
                children: t`Add Coupon`,
                disabled: false
            });
            setInputTextProps({
                helperText: t`Coupon-invalid`,
                isInvalid: true
            });
            break;
        case validationError:
            setButtonProps({
                onClick: validateCoupon,
                children: t`Add Coupon`
            });
            setInputTextProps({
                helperText: t`Coupon-validation-error`,
                isInvalid: true
            });
            break;
        case removeCoupon:
            setButtonProps({
                onClick: validateCoupon,
                children: t`Add Coupon`
            });
            setInputTextProps({
                helperText: t`Coupon-removed`
            });
            break;
        }
        /** When user changes the country in checkout page, remove the already applied coupon
         * along with the helper text and message
         * */
        if (isCountryChanged) {
            setCouponState(addCoupon);
            setInputProps({
                isSuccess: false
            });

            dispatch(subscriptionActions.isCountryChanged({ isCountryChanged: false }));
        }
    }, [isCountryChanged, addCoupon, addDiscountCoupon, applyCoupon, removeCoupon, couponState, invalid, valid, validationError]);

    return {
        buttonProps,
        inputTextProps,
        couponDataDisplayText,
        inputProps,
        couponState,
        inputRef,
        isLoading
    };
}
