import React, { type PropsWithChildren } from 'react';
import { Navigate, type To } from 'react-router-dom';

import {
    useHasAccountCreated,
    useHasSelectedProductCode
} from '../../../subscription-model/subscription.selectors';

/**
 * If a subscription plan is not chosen from the user,
 * this will "Gate" the child components from rendering and apply a redirect.
 */
export function RedirectIfNoPlanOrAccountGate ({
    to,
    children
}: PropsWithChildren<{ to: To }>) {
    const hasProductCodeSelected = useHasSelectedProductCode();
    const hasAccountCreated = useHasAccountCreated();

    if (!hasProductCodeSelected || !hasAccountCreated) {
        return <Navigate to={to} />;
    }

    return (
        <>
            {children}
        </>
    );
}
