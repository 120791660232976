import React from 'react';
import {
    Navigate, Route, Routes
} from 'react-router-dom';

import { NavigateToNotFoundRoute } from '../../error/not-found-error/components/navigate-to-not-found-route';
import { RedirectIfNoPlanOnceGate } from '../__utils__/redirect-if-no-plan-once.gate';
import { RedirectIfNoPlanGate } from '../__utils__/redirect-if-no-plan.gate';
import { RenewCheckoutRoute } from './checkout/renew-checkout';
import { RenewSelectRoute } from './select/select';
import { RenewSuccessRoute } from './success/renew-success-route';

export function RenewRoute () {
    return (
        <Routes>
            <Route
                path={'select'}
                element={<RenewSelectRoute />}
            />

            <Route
                path={'checkout'}
                element={
                    <RedirectIfNoPlanGate to={'/subscription/renew/select'}>
                        <RenewCheckoutRoute />
                    </RedirectIfNoPlanGate>
                }
            />

            <Route
                path={'success'}
                element={
                    <RedirectIfNoPlanOnceGate to={'/subscription/renew/select'}>
                        <RenewSuccessRoute />
                    </RedirectIfNoPlanOnceGate>
                }
            />

            <Route
                index={true}
                element={<Navigate to={'select'} />}
            />

            <Route
                path={'*'}
                element={<NavigateToNotFoundRoute />}
            />
        </Routes>
    );
}
