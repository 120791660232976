import { t } from '@lingui/macro';

import type { PriceList } from '../../__generated__/gateway-graphql';
import type { ProductItemWithText } from '../../api/gateway/onboarding-data-contract';
import { useTPrice } from '../../i18n/t-price/use-t-price';
import type { SubscriptionState } from '../../subscription-model/subscription.types';

export type CurrentSubscriptionData = {
    currentPrice: PriceList
    productItem: ProductItemWithText
    subscription: SubscriptionState
};

export function resolveSelectedProductData ({
    data,
    tPrice,
    childrenCount
}: {
    data: CurrentSubscriptionData
    tPrice: ReturnType<typeof useTPrice>
    childrenCount: number
}) {
    const {
        currentPrice,
        productItem
    } = data;
    const {
        isTrial,
        subscriptionPaymentOption,
        currencyCode,
        countryCode
    } = productItem;
    const childText = childrenCount > 1 ? t`SummaryTab children text` : t`SummaryTab child text`;
    const subscriptionText = isTrial ? t`SummaryTab text after trial` : subscriptionPaymentOption;
    const perChildPrice = tPrice({
        amount: currentPrice.baseAmount/childrenCount,
        currencyCode,
        countryCode
    });
    const baseAmountPrice = tPrice({
        amount: currentPrice.baseAmount,
        currencyCode,
        countryCode
    });
    const discountedPrice = tPrice({
        amount: currentPrice.baseAmount,
        countryCode,
        currencyCode
    });
    const totalPriceAfterTrial = tPrice({
        amount: currentPrice.totalAfterTrialPrice,
        countryCode,
        currencyCode
    });
    const totalDueTodayPrice = tPrice({
        amount: currentPrice.totalDueTodayPrice,
        countryCode,
        currencyCode
    });
    const { discount } = currentPrice;
    const calenderReminderTitle = isTrial ?
        t`Reminder - Home Free Trial` :
        t`Reminder - Home Auto-renewal`;
    const calendarReminderDescription = isTrial ?
        t`Reminder - Home Free Trial Description` :
        t`Reminder - Home Auto-renewal Description`;

    return {
        perChildPrice,
        subscriptionText,
        baseAmountPrice,
        discountedPrice,
        childText,
        childrenCount,
        totalPriceAfterTrial,
        totalDueTodayPrice,
        isTrial,
        discount,
        productItem,
        calenderReminderTitle,
        calendarReminderDescription
    };
}
