import { t } from '@lingui/macro';
import React from 'react';

import { useContentfulUrl } from '../../../components/contentful-url-button/use-contentful-url';
import { ErrorPageModal } from '../../../page/page-modal/error-page/error-page-modal';

// TODO work with UX to decide what to put here, probably different to main error screen
export function NotFoundError () {
    const headingText = t`Uh oh...<br/>Something doesn't add up`;
    const mathleticsHome = useContentfulUrl('Mathletics Home');
    const bodyText = t({
        id: 'Error - Page - Not - Found',
        values: { mathleticsHome }
    });

    return (
        <ErrorPageModal
            data-testid={'not-found-route'}
            headingText={headingText}
            bodyText1={bodyText}
        />
    );
}
