import { chakra, useMediaQuery } from '@chakra-ui/react';
import React from 'react';

const TotalAfterTrialIcon = () => {
    const [isMobileVersion] = useMediaQuery([
        '(max-width: 520px)'
    ]);
    const width = isMobileVersion ? '52' : '124';

    return (
        <chakra.svg
            position={'relative'}
            marginTop={'-5px'}
            width= {`${width}px`}
            height= {'9px'}
            viewBox= {'0 0 124 9'}
            fill= {'none'}
            xmlns= {'http://www.w3.org/2000/svg'}
        >
            <path d={'M0.522954 7.76571C0.522954 7.76571 50.0957 -2.81684 123.759 3.79346'} stroke={'#F4BC22'} strokeWidth={'2'} />
        </chakra.svg>
    );
};

export default TotalAfterTrialIcon;
