import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import type { ErrorBoundaryProps } from '@sentry/react/types/errorboundary';
import React, { type PropsWithChildren } from 'react';

import { UnexpectedError } from './unexpected-error';

type Props = PropsWithChildren<
    & Omit<ErrorBoundaryProps, 'children'>
    & {
    context?: string
}>;

export function ErrorBoundary ({ beforeCapture, ...props }: Props) {
    return (
        <SentryErrorBoundary
            fallback={(fallbackProps) =>
                <UnexpectedError {...fallbackProps} context={props.context} />
            }
            {...props}
            beforeCapture={(scope, error, componentStack) => {
                scope.setTag('context', props?.context || 'Unknown ErrorBoundary context');
                beforeCapture && beforeCapture(scope, error, componentStack);
            }}
        />
    );
}
