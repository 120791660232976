import { Box } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React from 'react';

type FreeTrialTotalPriceTextProps = {
    totalPriceAfterTrial: string
};

export function FreeTrialTotalPriceText ({ totalPriceAfterTrial }: FreeTrialTotalPriceTextProps) {
    return (
        <Box
            display={'inline-flex'}
            justifyContent={'space-between'}
            padding={{
                mobile: '0 8px',
                tablet: '0 32px'
            }}
            mb={{
                mobile: '24px',
                tablet: '26px'
            }}
        >
            <p>{t`Total after trial`}</p>
            <p>{totalPriceAfterTrial}</p>
        </Box>
    );
}

type CouponDiscountSubscriptionTextProps = {
    isTrial: boolean
    isValid: boolean
    discountAmount: string
    childrenCount: number
};

export function CouponDiscountSubscriptionText ({ isTrial, discountAmount, childrenCount, isValid }: CouponDiscountSubscriptionTextProps) {
    return(
        <Box
            display={'inline-flex'}
            justifyContent={'space-between'}
            mb={{
                mobile: isValid ? '16px' : isTrial ? 0 : '16px',
                tablet: isValid ? '32px' : isTrial ? 0 : '26px'
            }}
            padding={{
                mobile: isValid ? '0 8px' : childrenCount > 1 ? '15px 8px 0 8px' : '0 32px',
                tablet: isValid ? '0 32px' : childrenCount > 1 ? '32px 32px 0 32px' : '0 32px'
            }}
        >
            <p>{t`Coupon`}</p>
            <p>- {discountAmount}</p>
        </Box>
    );
}
