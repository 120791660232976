import { usePlansIpLocation } from '../plans/use-plans-ip-location';

/**
 * Basic abstraction that assumes the first product with isTrial true
 * is the only trial product.
 */
export function useTrialDataQuery () {
    const planDataQuery = usePlansIpLocation();
    const data = planDataQuery.productItems.find(
        product => product.isTrial
    ) || null;

    return {
        ...planDataQuery,
        data
    };
}
