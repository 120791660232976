import { t } from '@lingui/macro';
import React from 'react';

import { SuccessView } from '../../../../checkout/success/success-view';

/**
 * Story https://mathletics.atlassian.net/browse/FRAME-5712
 */
export function RenewSuccessRoute () {
    return (
        <SuccessView
            data-testid={'subscription-renew-purchase-success-route'}
            headingText={t`Purchase successful`}
            bodyText={t`You’re almost there.  Return to the Parent Dashboard and activate the subscription.`}
            buttonText={t`Return to Parent Dashboard`}
        />
    );
}
