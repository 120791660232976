// no value in covering this
/* istanbul ignore file */
import * as Sentry from '@sentry/react';

// @ts-ignore
window.__Sentry__ = Sentry;

const SENTRY_RELEASE = process.env.REACT_APP_BUILD_NUMBER || 'local';

console.info('SENTRY_RELEASE', SENTRY_RELEASE);

export function initSentry () {
    Sentry.init({
        dsn: window._env_.SENTRY_DSN,
        environment: window._env_.ENVIRONMENT,
        release: SENTRY_RELEASE,
        // DEBUG mode pollutes the browser console but is useful to debug Sentry itself
        // debug: process.env.NODE_ENV === 'development',
        ignoreErrors: [
            // fetch cancelled errors to ignore
            // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
            'TypeError: Failed to fetch',
            'TypeError: NetworkError when attempting to fetch resource.',
            'TypeError: cancelled'
        ],
        // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-namebefore-send-
        beforeSend (event) {
            // No need to send errors while in developing at local
            if (SENTRY_RELEASE === 'local') {
                return null;
            }

            if (event.extra?.password) {
                delete event.extra?.password;
            }

            return event;
        },
        // More examples here to denyUrls
        // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i
        ]
    });
}
