import { useField } from 'formik';
import React, { type ChangeEvent } from 'react';

import {
    PasswordInputControl, PasswordInputControlProps
} from '../../password-input-control/password-input-control';

type Props = Omit<PasswordInputControlProps, 'id'> & { id: string };

export function PasswordInputControlField (props: Props) {
    const [field, meta] = useField({
        type: 'password',
        id: props.id,
        name: props.id
    });
    const isInvalid = !!meta.error;
    const {
        onBlur,
        onChange: formikOnChange,
        ...controlFieldProps
    } = field;
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        formikOnChange(event);
        if (props.controlProps && props.controlProps?.onChange) {
            props.controlProps.onChange(event);
        }
    };
    const controlProps = {
        ...props.controlProps,
        ...controlFieldProps,
        onChange: handleOnChange
    };

    return (
        <PasswordInputControl
            {...props}
            data-testid={props['data-testid'] || props.id}
            controlProps={controlProps}
            isInvalid={isInvalid}
            helperText={meta.error}
            onBlur={onBlur}
        />
    );
}
