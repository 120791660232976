/* eslint-disable max-len */
import {
    Button, type ButtonProps, chakra
} from '@chakra-ui/react';
import React from 'react';

const HideIcon = () => (
    <chakra.svg width={'24px'} height={'24px'} viewBox={'0 0 24 24'} fill={'none'}>
        <path
            fillRule={'evenodd'} clipRule={'evenodd'}
            d={'M4.44872 2.75137C3.98009 2.28275 3.2203 2.28275 2.75167 2.75137C2.28304 3.22 2.28304 3.9798 2.75167 4.44843L19.5517 21.2484C20.0203 21.7171 20.7801 21.7171 21.2487 21.2484C21.7174 20.7798 21.7174 20.02 21.2487 19.5514L19.4809 17.7836C21.326 16.3108 22.727 14.3052 23.4511 11.9999C21.9219 7.13136 17.3736 3.5999 12.0005 3.5999C10.0522 3.5999 8.21233 4.0642 6.58554 4.88819L4.44872 2.75137ZM9.56195 7.8646L11.3786 9.68126C11.577 9.62819 11.7854 9.5999 12.0005 9.5999C13.326 9.5999 14.4005 10.6744 14.4005 11.9999C14.4005 12.215 14.3722 12.4234 14.3191 12.6218L16.1358 14.4385C16.5581 13.7238 16.8005 12.8902 16.8005 11.9999C16.8005 9.34894 14.6515 7.1999 12.0005 7.1999C11.1102 7.1999 10.2766 7.44226 9.56195 7.8646Z'}
            fill={'#5C6C7F'}
        />
        <path
            d={'M14.9451 20.0359L11.6998 16.7906C9.28835 16.6416 7.35883 14.712 7.20977 12.3006L2.8021 7.89296C1.80095 9.08658 1.02784 10.4779 0.549805 11.9999C2.07894 16.8685 6.62727 20.3999 12.0004 20.3999C13.0164 20.3999 14.0029 20.2736 14.9451 20.0359Z'}
            fill={'#5C6C7F'}
        />
    </chakra.svg>
);
const RevealIcon = () => (
    <chakra.svg width={'24px'} height={'24px'} viewBox={'0 0 24 24'} fill={'none'}>
        <path d={'M12 14.4001C13.3255 14.4001 14.4 13.3256 14.4 12.0001C14.4 10.6746 13.3255 9.6001 12 9.6001C10.6745 9.6001 9.60001 10.6746 9.60001 12.0001C9.60001 13.3256 10.6745 14.4001 12 14.4001Z'} fill={'#5C6C7F'} />
        <path fillRule={'evenodd'} clipRule={'evenodd'} d={'M0.549316 12.0001C2.07842 7.13159 6.62678 3.6001 12 3.6001C17.3731 3.6001 21.9215 7.13155 23.4506 12.0001C21.9215 16.8686 17.3731 20.4001 11.9999 20.4001C6.62679 20.4001 2.07845 16.8686 0.549316 12.0001ZM16.8 12.0001C16.8 14.6511 14.651 16.8001 12 16.8001C9.34904 16.8001 7.20001 14.6511 7.20001 12.0001C7.20001 9.34913 9.34904 7.2001 12 7.2001C14.651 7.2001 16.8 9.34913 16.8 12.0001Z'} fill={'#5C6C7F'} />
    </chakra.svg>
);

export function RevealPasswordButton ({ isVisible, ...restProps }: ButtonProps & { isVisible: boolean }) {
    return (
        <Button
            variant={'unstyled'}
            width={'24px'}
            minW={'24px'}
            height={'24px'}
            cursor={'pointer'}
            pointerEvents={'all'}
            data-testid={'toggle-password-visibility'}
            {...restProps}
        >
            {isVisible ? <HideIcon /> : <RevealIcon />}
        </Button>
    );
}
