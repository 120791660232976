import React, { type PropsWithChildren, useMemo } from 'react';
import { Navigate, type To } from 'react-router-dom';

import { useHasSelectedProductCode } from '../../../subscription-model/subscription.selectors';

/**
 * If a subscription plan is not chosen from the user,
 * this will "Gate" the child components from rendering and apply a redirect.
 *
 * This will only happen once and will not react to changes in subscription state.
 */
export function RedirectIfNoPlanOnceGate ({
    to,
    children
}: PropsWithChildren<{ to: To }>) {
    const hasSelectedProductCode = useHasSelectedProductCode();
    // intentionally ignore dep so that this does not react to changes from state.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hasProductCodeSelected = useMemo(() => hasSelectedProductCode, []);

    if (!hasProductCodeSelected) {
        return <Navigate to={to} />;
    }

    return (
        <>
            {children}
        </>
    );
}
