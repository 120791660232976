import { t } from '@lingui/macro';
import { useState } from 'react';

import { isStringDefined } from '../__utils__/utils';
import { useAppSelector } from '../store/store';
import { useQuoteAndPricingDataWithText } from './use-quote-and-pricing-data-with-text';

export type UseStoredPlansProps = {
    excludeTrials: boolean
    country?: any
};

/**
 * This gets the pricing and quote data from redux store.
 */
export function useStoredPlans ({ excludeTrials, country }: UseStoredPlansProps) {
    // Allow this query to be "enabled" only when currencyCode is available
    // the query will be marked as inactive and Gray in the devtools initially.
    const subscription = useAppSelector(
        state => state.subscription
    );
    const location = useAppSelector(
        state => state.subscription.location
    );
    const enabled = isStringDefined(location?.currencyCode);
    // as string here won't matter as enabled flag won't query it.
    const countryCode = location?.countryCode || 'AUS';
    const currencyCode = location?.currencyCode as string || country?.currencyCode;
    const [childrenCount, setChildrenCount] = useState<number>(
        subscription.childrenCount
    );
    const {
        data
    } = useQuoteAndPricingDataWithText({
        excludeTrials,
        enabled,
        currencyCode,
        countryCode,
        childrenCount
    });
    const productItem = data?.plan?.productItems || [];
    const internalCoupon = data?.plan?.internalCoupon || null;
    const isCampaignActive = isStringDefined(internalCoupon);
    const cardHeadingText = isCampaignActive ? t`Pricing Page - Card Heading Campaign Text` : t`Pricing Page - Card Heading`;

    return {
        productItem,
        childrenCount,
        cardHeadingText,
        onSetChildrenCount: setChildrenCount
    };
}
