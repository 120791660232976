import { Box, Center } from '@chakra-ui/react';
import React from 'react';

import { useTrialDataQuery } from '../use-trial-data-query';
import { FreeTrialPlus, FreeTrialSwishImage, MonthlySubText } from './free-trial-swish.styles';
import { SubscriptionInclusionsSkeleton } from './subscription-inclusions-skeleton';

export function FreeTrialSwish () {
    const {
        data,
        isLoading
    } = useTrialDataQuery();

    if (isLoading || !data) {
        return <SubscriptionInclusionsSkeleton />;
    }

    if (!data.trialInclusionsLine1 || !data.trialInclusionsLine2) {
        return null;
    }

    return (
        <Center>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: {
                        mobile: '18px',
                        subscriptionInclusionsTablet: '28px'
                    },
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: {
                        mobile: '29px',
                        tablet: '35px'
                    },
                    textAlign: 'center',
                    letterSpacing: {
                        mobile: '0.15px',
                        tablet: '-0.25px'
                    },
                    color: 'neutral.700',
                    w: {
                        mobile: '264px',
                        subscriptionInclusionsTablet: '390px'
                    },
                    h: {
                        mobile: '63px',
                        subscriptionInclusionsTablet: '124px'
                    }
                }}
            >
                <Box>
                    <FreeTrialPlus />
                    <span data-testid={'free-trial-text'}>
                        <span>{data.trialInclusionsLine1}</span>
                        <FreeTrialSwishImage />
                    </span>
                </Box>

                <MonthlySubText>
                    {data.trialInclusionsLine2}
                </MonthlySubText>
            </Box>
        </Center>
    );
}
