import { Center, Skeleton, Box } from '@chakra-ui/react';
import React from 'react';

import { PageCenterForm } from '../../../../../../../page/page-content/page-content.styles';

// eslint-disable-next-line max-len
const PLACEHOLDER_TEXT = 'Mathletics is brilliant and I have seen with my own eyes what a solid foundation in maths it has given both my children. They are confident and comfortable with the subject and I believe that Mathletics is the reason.';

export function CreateAccountFormSkeleton () {
    return (
        <PageCenterForm data-testid={'crate-account-form-loading-fallback'}>
            <Center
                width={'initial'}
                mx={{
                    mobile: '24px',
                    desktop: '0'
                }}
                mt={'70px'}
                mb={'32px'}
                display={'flex'}
                alignItems={'center'}
                borderRadius={'20px'}
            >
                <Skeleton
                    startColor={'neutral.100'}
                    endColor={'neutral.300'}
                    fadeDuration={1.2}
                    mb={'32px'}
                    width= {'100%'}
                    height={{
                        mobile: '32px',
                        desktop: '64px'
                    }}
                >{PLACEHOLDER_TEXT}
                </Skeleton>
            </Center>

            <Center
                p={{
                    mobile: '24px',
                    desktop: '74px'
                }}
                marginBottom={'32px'}
                width={'100%'}
                background={'white'}
                boxShadow={'0px 2px 20px rgba(0, 25, 55, 0.08)'}
                borderRadius={'20px'}
                flexDirection={'column'}
                display={'flex'}
            >
                <Box
                    display={'flex'}
                    flexDirection={{
                        mobile: 'column',
                        tablet: 'row'
                    }}
                    justifyContent={'space-between'}
                    width={'100%'}
                >
                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        mr={{
                            mobile: '0',
                            tablet: '20px'
                        }}
                        mb={{
                            mobile: '24px',
                            desktop: '32px'
                        }}
                        width= {'100%'}
                        height= {{
                            mobile: '56px',
                            pricingTablet: '64px'
                        } as any}
                    >
                        {PLACEHOLDER_TEXT}
                    </Skeleton>

                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        ml={{
                            mobile: '0',
                            tablet: '20px'
                        }}
                        mb={{
                            mobile: '24px',
                            desktop: '32px'
                        }}
                        width= {'100%'}
                        height= {{
                            mobile: '56px',
                            pricingTablet: '64px'
                        } as any}
                    >
                        {PLACEHOLDER_TEXT}
                    </Skeleton>
                </Box>

                <Skeleton
                    startColor={'neutral.100'}
                    endColor={'neutral.300'}
                    fadeDuration={1.2}
                    mb={'32px'}
                    width= {'100%'}
                    height= {'54px'}
                >
                    {PLACEHOLDER_TEXT}
                </Skeleton>

                <Skeleton
                    startColor={'neutral.100'}
                    endColor={'neutral.300'}
                    fadeDuration={1.2}
                    mb={'32px'}
                    width= {'100%'}
                    height= {'54px'}
                >
                    {PLACEHOLDER_TEXT}
                </Skeleton>
            </Center>
        </PageCenterForm>
    );
}
