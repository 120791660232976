import {
    Box, Circle, Text, useMediaQuery, useTheme
} from '@chakra-ui/react';
import React from 'react';

import { IconTick } from './tick-icon';

export type StepProps = {
    status: string
    totalSteps: number
    label?: string
    children?: React.ReactNode
};

export const Step = ({ label, status, children, totalSteps }: StepProps) => {
    const theme = useTheme();
    // eslint-disable-next-line prefer-destructuring
    const defaultColor = theme.colors.secondary[0];
    const isComplete = status === 'complete';
    const isActive = status === 'active';
    const [isMobileVersion] = useMediaQuery([
        '(max-width: 678px)'
    ]);

    let labelAnimation: string;

    if (children === 1) {
        labelAnimation = 'translate(0%, 100%)';
    } else if(children === totalSteps) {
        labelAnimation = 'translate(-90%, 100%)';
    } else {
        labelAnimation = 'translate(-45%, 100%)';
    }

    function renderLabel () {
        if (isMobileVersion && isActive || !isMobileVersion) {
            return label;
        }

        return null;
    }

    return (
        <Box display={'inline-flex'} h={'20px'}>
            <Text
                noOfLines={1}
                as={'div'}
                position={'absolute'}
                transform={`${labelAnimation}`}
                textAlign={children===1 ? 'left' : children===totalSteps ? 'right' : 'center'}
                width={'138px'}
                fontSize={'13px'}
                fontWeight={isActive ? 600 : 400}
                lineHeight={'20px'}
                color={isActive ? 'neutral.900' : 'neutral.600'}
            >
                {renderLabel()}
            </Text>

            <Circle
                aria-hidden
                zIndex={1}
                mt={isComplete ? '0' : '2px'}
                position={'relative'}
                size={isComplete ? '22px' : '16px'}
                bg={isComplete ? 'primary.500' : isActive ? 'secondary.0' : 'neutral.100'}
                borderWidth={'4px'}
                borderColor={isActive ? 'primary.500' : 'secondary.0'}
                boxShadow={isActive ? `0 0 0 3px ${defaultColor}` : '0'}
            >
                {isComplete ? <IconTick /> : null}
            </Circle>
        </Box>
    );
};
