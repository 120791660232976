import { Center, Skeleton, SkeletonText } from '@chakra-ui/react';
import React from 'react';

import {
    CardHeading,
    FootLabel,
    MobileSaveLabel,
    Note,
    PricingCardHolderStyled,
    PricingCards
} from '../../pricing.styles';
import { StyleVars } from '../../style-vars';

export function PricingSkeleton () {
    return (
        <Center
            data-testid={'pricing-loading-fallback'}
            width={'100%'}
            flexDirection={'column'}
        >
            <PricingCardHolderStyled>
                <CardHeading >
                    <SkeletonText
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        skeletonHeight={'24px'}
                        noOfLines={1}
                    >
                        <span>Save 20%</span> - when subscribing 2 or more children at the same time.
                    </SkeletonText>
                </CardHeading>
                <Center>
                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        h={'100px'}
                        w={{
                            mobile: StyleVars.pricingCardMobileWidth,
                            pricingTablet: '392px'
                        } as any}
                        margin= {'16px 0'}
                    />
                </Center>
                <PricingCards>
                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        width= {{
                            mobile: StyleVars.pricingCardMobileWidth,
                            pricingTablet: StyleVars.pricingCardWidth
                        }as any}
                        height= {{
                            mobile: StyleVars.pricingCardMobileHeight,
                            pricingTablet: StyleVars.pricingCardHeight
                        } as any}
                    />
                    <Skeleton
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        width= {{
                            mobile: StyleVars.pricingCardMobileWidth,
                            pricingTablet: StyleVars.pricingCardWidth
                        }as any}
                        height= {{
                            mobile: StyleVars.pricingCardMobileHeight,
                            pricingTablet: StyleVars.pricingCardHeight
                        } as any}
                    />
                </PricingCards>
                <FootLabel>
                    <SkeletonText
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        skeletonHeight={'24px'}
                        noOfLines={1}
                    >
                        All plans auto renew but you can cancel anytime.
                    </SkeletonText>
                </FootLabel>
                <MobileSaveLabel>
                    <SkeletonText
                        startColor={'neutral.100'}
                        endColor={'neutral.300'}
                        fadeDuration={1.2}
                        skeletonHeight={'24px'}
                        noOfLines={1}
                    >
                        <span>Save 20%</span> - when subscribing 2 or more children at the same time.
                    </SkeletonText>
                </MobileSaveLabel>
            </PricingCardHolderStyled>
            <Note>
                <SkeletonText
                    startColor={'neutral.100'}
                    endColor={'neutral.300'}
                    fadeDuration={1.2}
                    skeletonHeight={'24px'}
                    noOfLines={1}
                >
                    * Savings are calculated by comparing the yearly subscription rate to the monthly subscription
                    rate over 12 months.
                </SkeletonText>
            </Note>
        </Center>
    );
}
