/* istanbul ignore file */
// Testing this has a large scope, ignoring coverage.
// If anyone wants the challenge checkout https://wix.github.io/sentry-testkit
import * as Sentry from '@sentry/react';

import { isEmpty } from '../__utils__/utils';

/**
 * Generic method to handle different error types from the fetcher.
 * This lets us log Sentry extra data to hopefully understand errors better.
 */
export function handleFetchError (error: any) {
    if (isEmpty(error)) {
        const message = 'handleFetchError isEmpty';

        Sentry.captureException(new Error(message));

        return;
    }

    Sentry.captureException(error, {
        extra: {
            info: 'handleFetchError'
        }
    });

    console.error('handleFetchError unhandled error type thrown', error);

    // Intentionally redirect the user to an error screen
    window.location.replace('/error');
}
