import { useQuery } from '@tanstack/react-query';

import { getUniqueFromArray } from '../../../__utils__/random';
import type { TestimonialsData } from '../../../fetch-static-content/contentful/testimonials';

/**
 * Testimonials data hook @see.
 *
 * @see https://mathletics.atlassian.net/browse/FRAME-5299
 */
export function useTestimonialsDataQuery () {
    const query = useQuery(['testimonials'], () => {
        return import (
            /* webpackChunkName: "testimonials"*/
            '../../../__static-content__/testimonials.json'
        ) as any as TestimonialsData;
    });
    // Business rule to display a random testimonial with array,
    // could be improved by checking the length of all and selecting one from total
    // also localstorage could help prevent random showing same between renders.
    const data = !query.data ? null : getUniqueFromArray(query.data.items);

    return {
        ...query,
        data
    };
}
