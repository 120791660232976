import {
    Image, Link, SkeletonCircle, Text
} from '@chakra-ui/react';
import React from 'react';

import { CONFIG } from '../../../../../../config';
import MathleticsMLogoSrc from '../../assets/MathleticsMLogo.svg';
import {
    LogoStyles, ContentWrapper, CopyStyles
} from './school-subscription.styles';

export function SchoolSubscription () {
    return (
        <ContentWrapper data-testid={'school-subscription-comp'}>
            <Text sx={CopyStyles} color={'neutral.900'}>
                If you are a teacher looking for a school subscription,
            </Text>

            <Link
                data-testid={'find-more-link'}
                href={CONFIG.mathleticsForSchoolsUrl}
                target={'_self'}
                sx={CopyStyles}
                color={'primary.600'}
            >
                find out more here.
            </Link>

            <Image
                sx={LogoStyles}
                src={MathleticsMLogoSrc}
                alt={'Mathletics Logo'}
                fallback={<SkeletonCircle sx={LogoStyles} />}
            />
        </ContentWrapper>
    );
}
