/* istanbul ignore file */
// Testing canvas apis is not practical
import { chakra } from '@chakra-ui/react';
import confetti, { type Options as CanvasOptions } from 'canvas-confetti';
import React, { useRef } from 'react';

import { useEffectOnce } from '../../../__utils__/use-effect-once';

type CreateCanvasFunction = (options: CanvasOptions) => any;

type Props = {
    width: number
    height: number
    options?: CanvasOptions
};

const defaultOptions: CanvasOptions = {
    disableForReducedMotion: false,
    particleCount: 100,
    spread: 70,
    origin: { y: 0.8 }
};

export function ConfettiCanvas ({
    width,
    height,
    options = defaultOptions
}: Props) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const startConfetti = useRef<CreateCanvasFunction | null>(null);

    useEffectOnce(() => {
        if (!canvasRef.current) return;

        startConfetti.current = confetti.create(canvasRef.current, {
            resize: true,
            useWorker: false
        });
    });

    useEffectOnce(() => {
        setTimeout(() => {
            if (!startConfetti.current) return;

            startConfetti.current(options);
            // Intentionally wait for a delay so the component is rendered
            // without this it starts while the component is not ready
        }, 800);
    });

    return (
        <chakra.canvas
            zIndex={1}
            position={'absolute'}
            top={'-20px'}
            left={0}
            width={`${width}px`}
            height={`${height}px`}
            ref={canvasRef}
        />
    );
}
