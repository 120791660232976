import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../../store/store';
import { useChosenSubscriptionSelector } from '../../../../../subscription-model/subscription.selectors';

export function useCreateAccountData () {
    let subtitleText = null;

    const navigate = useNavigate();
    const subscription = useChosenSubscriptionSelector(false);
    const childrenCount = useAppSelector(
        rootState => rootState.subscription.childrenCount
    );
    const ribbonText = subscription?.ribbonText || null;
    const handleBack = () => {
        navigate('/subscription/create/select');
    };

    if (subscription?.headerTextForSingleChild || subscription?.headerTextForMultipleChild) {
        subtitleText = childrenCount > 1 ? subscription.headerTextForMultipleChild : subscription.headerTextForSingleChild;
    }

    return {
        onBack: handleBack,
        subtitleText,
        ribbonText
    };
}
