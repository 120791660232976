export const DEFAULT_DATE_FORMAT: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
};

/**
 * Default Date formatting for user text.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 */
export function tFormatDate (
    value: number | string | Date,
    locales: Intl.LocalesArgument = undefined,
    options: Intl.DateTimeFormatOptions = DEFAULT_DATE_FORMAT
): string {
    return new Date(value).toLocaleString(locales, options);
}
