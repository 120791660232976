import React from 'react';

import { SubscriptionReminderView } from './subscription-reminder-view';
import { useSubscriptionReminder, type UseSubscriptionReminderOptions } from './use-subscription-reminder';

type Props = UseSubscriptionReminderOptions;

export const SubscriptionReminder = (props: Props) => {
    const {
        google, outlook, ical
    } = useSubscriptionReminder(props);

    return (
        <SubscriptionReminderView
            google={google}
            outlook={outlook}
            ical={ical}
        />
    );
};
