import React from 'react';

import { Counter } from '../counter/counter';
import {
    CounterLabel, NumSubscriptionCounterHolder
} from './num-subscription-counter.styles';

export type NumSubscriptionCounterProps = {
    setNumSubscriptions: (numberOfChildren: number) => any
    numSubscriptions: number
};

export function NumSubscriptionCounter ({ numSubscriptions, setNumSubscriptions }: NumSubscriptionCounterProps) {
    return (
        <NumSubscriptionCounterHolder data-testid={'num-subscription-counter'}>
            <CounterLabel>Choose number of children</CounterLabel>
            <Counter
                minCount={1}
                maxCount={5}
                numSubscriptions={numSubscriptions}
                setNumSubscriptions={setNumSubscriptions}
            />
        </NumSubscriptionCounterHolder>
    );
}
