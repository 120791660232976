import React from 'react';
import {
    Route, Routes
} from 'react-router-dom';

import { NavigateToNotFoundRoute } from '../error/not-found-error/components/navigate-to-not-found-route';
import { CurrencyFallbackGate } from './__utils__/currency-fallback-gate';
import { SalesforceParamsGate } from './__utils__/salesforce-params.gate';
import { AddRoute } from './add/add-route';
import { CreateRoute } from './create/create-route';
import { RenewRoute } from './renew/renew-route';

export function SubscriptionRoute () {
    return (
        <Routes>
            <Route
                path={'create/*'}
                element={<CreateRoute />}
            />

            <Route
                path={'add/*'}
                element={
                    <CurrencyFallbackGate>
                        <SalesforceParamsGate>
                            <AddRoute />
                        </SalesforceParamsGate>
                    </CurrencyFallbackGate>
                }
            />

            <Route
                path={'renew/*'}
                element={
                    <CurrencyFallbackGate>
                        <SalesforceParamsGate>
                            <RenewRoute />
                        </SalesforceParamsGate>
                    </CurrencyFallbackGate>
                }
            />

            <Route
                path={'*'}
                element={<NavigateToNotFoundRoute />}
            />
        </Routes>
    );
}
