import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useGeneratedQueryFetcher } from '../api/gateway/use-fetcher';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
};

export enum BrandEnum {
  AmericanExpress = 'AmericanExpress',
  Bancontact = 'Bancontact',
  DinersClub = 'DinersClub',
  Discover = 'Discover',
  Jcb = 'Jcb',
  Mastercard = 'Mastercard',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
  Visa = 'Visa'
}

export type CardBillingAddressInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type ContactInput = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  marketingInfo?: InputMaybe<MarketingInfoInput>;
  quoteId: Scalars['String'];
};

export type CountryInfo = {
  __typename?: 'CountryInfo';
  Plan?: Maybe<Plan>;
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  currencyCode: Scalars['String'];
  currencySymbol?: Maybe<Scalars['String']>;
  states: Array<Scalars['String']>;
  statesName?: Maybe<Scalars['String']>;
};

export type CouponDetails = {
  __typename?: 'CouponDetails';
  couponCode?: Maybe<Scalars['String']>;
  createDateTime?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discountAmount: Scalars['Decimal'];
  discountName?: Maybe<Scalars['String']>;
  discountPercentage: Scalars['Decimal'];
  duration?: Maybe<Scalars['String']>;
  finePrintKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  validTill?: Maybe<Scalars['DateTime']>;
};

export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  errorMessage?: Maybe<Scalars['String']>;
  isSuccessful: Scalars['Boolean'];
};

export type CreateCustomerSubscriptionInput = {
  payment: PaymentInput;
};

export type CreateNewSubscriptionInput = {
  customerDetails: CustomerDetailsInput;
  marketingInfo?: InputMaybe<MarketingInfoInput>;
  payment: PaymentInput;
};

export type CreatePaymentIntentFromQuoteInput = {
  email?: InputMaybe<Scalars['String']>;
  paymentSourceId?: InputMaybe<Scalars['String']>;
  quoteId: Scalars['String'];
  sessionId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type CreatePaymentIntentPayload = {
  __typename?: 'CreatePaymentIntentPayload';
  errorMessage?: Maybe<Scalars['String']>;
  isSuccessful: Scalars['Boolean'];
  paymentIntent?: Maybe<Scalars['String']>;
  quoteId: Scalars['String'];
};

export type CreateQuoteInput = {
  planId: Scalars['String'];
  quantity: Scalars['Int'];
  sessionId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type CreateSubscriptionPayload = {
  __typename?: 'CreateSubscriptionPayload';
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CustomerDetailsInput = {
  countryCode: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CustomerPlanOptionsInput = {
  excludeTrials?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type EmailStatusPayload = {
  __typename?: 'EmailStatusPayload';
  errorCode: Status;
  errorMessage?: Maybe<Scalars['String']>;
  isEmailAvailable: Scalars['Boolean'];
};

export type ExceptionsDetailInput = {
  code?: InputMaybe<Scalars['String']>;
  displayMessage?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LogExceptionInput = {
  cardBillingAddress?: InputMaybe<CardBillingAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  exceptionsDetail?: InputMaybe<Array<InputMaybe<ExceptionsDetailInput>>>;
  paymentIntentId?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['String']>;
};

export type MarketingInfoInput = {
  description?: InputMaybe<Scalars['String']>;
  emailOptIn?: Scalars['Boolean'];
  gclid?: InputMaybe<Scalars['String']>;
  marketingOptIn?: Scalars['Boolean'];
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createContact?: Maybe<CreateContactPayload>;
  createCustomerSubscription?: Maybe<CreateSubscriptionPayload>;
  createPaymentIntentFromQuote?: Maybe<CreatePaymentIntentPayload>;
  createQuote?: Maybe<QuoteAndPricingPayload>;
  createSubscription?: Maybe<CreateSubscriptionPayload>;
  logException: Scalars['Boolean'];
  updateQuote?: Maybe<QuoteAndPricingPayload>;
  verifyAndApplyCoupon?: Maybe<QuoteAndPricingPayload>;
  verifyEmail: Scalars['Boolean'];
  verifyTurnstileToken: Scalars['Boolean'];
};


export type MutationCreateContactArgs = {
  contact?: InputMaybe<ContactInput>;
};


export type MutationCreateCustomerSubscriptionArgs = {
  input?: InputMaybe<CreateCustomerSubscriptionInput>;
};


export type MutationCreatePaymentIntentFromQuoteArgs = {
  input?: InputMaybe<CreatePaymentIntentFromQuoteInput>;
};


export type MutationCreateQuoteArgs = {
  payment?: InputMaybe<CreateQuoteInput>;
};


export type MutationCreateSubscriptionArgs = {
  input?: InputMaybe<CreateNewSubscriptionInput>;
};


export type MutationLogExceptionArgs = {
  input?: InputMaybe<LogExceptionInput>;
};


export type MutationUpdateQuoteArgs = {
  quoteUpdateInput?: InputMaybe<UpdateQuoteInput>;
};


export type MutationVerifyAndApplyCouponArgs = {
  input?: InputMaybe<VerifyAndApplyCouponInput>;
};


export type MutationVerifyEmailArgs = {
  input?: InputMaybe<VerifyEmailInput>;
};


export type MutationVerifyTurnstileTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type PaymentInput = {
  intentId: Scalars['String'];
  paymentSourceId?: InputMaybe<Scalars['String']>;
  quoteId: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type PaymentSource = {
  __typename?: 'PaymentSource';
  brand: BrandEnum;
  deleted: Scalars['Boolean'];
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  id: Scalars['String'];
  lastFour: Scalars['String'];
  status: StatusEnum;
};

export type PaymentSourceInput = {
  token: Scalars['String'];
  userId: Scalars['Int'];
};

export type Plan = {
  __typename?: 'Plan';
  currencyCode: Scalars['String'];
  internalCoupon?: Maybe<Scalars['String']>;
  productItems: Array<ProductItem>;
};

export type PlanOptionsInput = {
  currencyCode: Scalars['String'];
  excludeTrials?: InputMaybe<Scalars['Boolean']>;
};

export type PriceList = {
  __typename?: 'PriceList';
  baseAmount: Scalars['Decimal'];
  discount: Scalars['Decimal'];
  discountedAmount: Scalars['Decimal'];
  quantity: Scalars['Int'];
  rRP?: Maybe<Scalars['Decimal']>;
  savings?: Maybe<Scalars['Decimal']>;
  totalAfterTrialPrice: Scalars['Float'];
  totalDueTodayPrice: Scalars['Float'];
};

export type ProductItem = {
  __typename?: 'ProductItem';
  durationType?: Maybe<Scalars['String']>;
  internalCoupon?: Maybe<Scalars['String']>;
  isTrial: Scalars['Boolean'];
  nextPaymentDate: Scalars['DateTime'];
  priceList: Array<PriceList>;
  productCode: Scalars['String'];
  subscriptionDuration: Scalars['Int'];
  subscriptionType: SubscriptionTypeEnum;
};

export type Query = {
  __typename?: 'Query';
  countries?: Maybe<Array<Maybe<CountryInfo>>>;
  customerPlans?: Maybe<Plan>;
  isEmailAvailable?: Maybe<EmailStatusPayload>;
  isValidZipCode?: Maybe<ZipCodeValidationPayload>;
  paymentSources?: Maybe<Array<PaymentSource>>;
  plan?: Maybe<Plan>;
  userCountry?: Maybe<CountryInfo>;
};


export type QueryCustomerPlansArgs = {
  customerPlanOptionsInput: CustomerPlanOptionsInput;
};


export type QueryIsEmailAvailableArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryIsValidZipCodeArgs = {
  zipcode?: InputMaybe<Scalars['String']>;
};


export type QueryPaymentSourcesArgs = {
  paymentSourceInput: PaymentSourceInput;
};


export type QueryPlanArgs = {
  planOptionsInput: PlanOptionsInput;
};

export type QuoteAndPricingPayload = {
  __typename?: 'QuoteAndPricingPayload';
  coupon?: Maybe<CouponDetails>;
  productItem?: Maybe<ProductItem>;
  quoteId?: Maybe<Scalars['String']>;
};

export enum Status {
  InvalidFormat = 'INVALID_FORMAT',
  InUseBy3PParentAccount = 'IN_USE_BY3P_PARENT_ACCOUNT',
  InUseBy3PProductAccount = 'IN_USE_BY3P_PRODUCT_ACCOUNT',
  NoError = 'NO_ERROR'
}

export enum StatusEnum {
  Expired = 'Expired',
  Expiring = 'Expiring',
  Invalid = 'Invalid',
  PendingVerification = 'PendingVerification',
  Valid = 'Valid'
}

export enum SubscriptionTypeEnum {
  Monthly = 'Monthly',
  Unknown = 'Unknown',
  Yearly = 'Yearly'
}

export type UpdateQuoteInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['String']>;
};

export type VerifyAndApplyCouponInput = {
  couponCode?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type VerifyEmailInput = {
  emailIdentifier: Scalars['String'];
};

export enum ZipCodeStatus {
  ConnectionError = 'CONNECTION_ERROR',
  Invalid = 'INVALID',
  Valid = 'VALID'
}

export type ZipCodeValidationPayload = {
  __typename?: 'ZipCodeValidationPayload';
  errorCode: ZipCodeStatus;
  errorMessage?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  stateCode?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
};

export type GetUserLocationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserLocationQuery = { __typename?: 'Query', userCountry?: { __typename?: 'CountryInfo', countryCode: string, currencyCode: string } | null };

export type GetAllCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCountriesQuery = { __typename?: 'Query', countries?: Array<{ __typename?: 'CountryInfo', countryName: string, currencyCode: string, countryCode: string, statesName?: string | null, states: Array<string> } | null> | null };

export type GetPlanDataQueryVariables = Exact<{
  options: PlanOptionsInput;
}>;


export type GetPlanDataQuery = { __typename?: 'Query', plan?: { __typename?: 'Plan', internalCoupon?: string | null, currencyCode: string, productItems: Array<{ __typename?: 'ProductItem', internalCoupon?: string | null, durationType?: string | null, isTrial: boolean, nextPaymentDate: any, productCode: string, subscriptionDuration: number, subscriptionType: SubscriptionTypeEnum, priceList: Array<{ __typename?: 'PriceList', baseAmount: any, discount: any, discountedAmount: any, quantity: number, totalAfterTrialPrice: number, totalDueTodayPrice: number, rRP?: any | null, savings?: any | null }> }> } | null };

export type CheckEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckEmailQuery = { __typename?: 'Query', validation?: { __typename?: 'EmailStatusPayload', isEmailAvailable: boolean, errorMessage?: string | null, errorCode: Status } | null };

export type CheckZipCodeQueryVariables = Exact<{
  zipcode: Scalars['String'];
}>;


export type CheckZipCodeQuery = { __typename?: 'Query', validation?: { __typename?: 'ZipCodeValidationPayload', isValid: boolean, errorMessage?: string | null, errorCode: ZipCodeStatus, stateCode?: string | null, stateName?: string | null } | null };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateNewSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', result?: { __typename?: 'CreateSubscriptionPayload', success: boolean, errorMessage?: string | null } | null };

export type CreateCustomerSubscriptionMutationVariables = Exact<{
  input: CreateCustomerSubscriptionInput;
}>;


export type CreateCustomerSubscriptionMutation = { __typename?: 'Mutation', result?: { __typename?: 'CreateSubscriptionPayload', success: boolean, errorMessage?: string | null } | null };

export type CreateContactMutationVariables = Exact<{
  contact: ContactInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', result?: { __typename?: 'CreateContactPayload', isSuccessful: boolean, errorMessage?: string | null } | null };

export type GetPaymentSourcesQueryVariables = Exact<{
  userId: Scalars['Int'];
  token: Scalars['String'];
}>;


export type GetPaymentSourcesQuery = { __typename?: 'Query', paymentSources?: Array<{ __typename?: 'PaymentSource', id: string, lastFour: string, brand: BrandEnum, expiryMonth: number, expiryYear: number, deleted: boolean, status: StatusEnum }> | null };

export type GetCustomerCurrencyQueryVariables = Exact<{
  options: CustomerPlanOptionsInput;
}>;


export type GetCustomerCurrencyQuery = { __typename?: 'Query', customerPlans?: { __typename?: 'Plan', currencyCode: string } | null };

export type VerifyEmailMutationVariables = Exact<{
  input: VerifyEmailInput;
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', inputObject: boolean };

export type VerifyTurnstileTokenMutationVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type VerifyTurnstileTokenMutation = { __typename?: 'Mutation', verifyTurnstileToken: boolean };

export type CreateQuoteMutationVariables = Exact<{
  payment: CreateQuoteInput;
}>;


export type CreateQuoteMutation = { __typename?: 'Mutation', result?: { __typename?: 'QuoteAndPricingPayload', quoteId?: string | null, productItem?: { __typename?: 'ProductItem', internalCoupon?: string | null, productCode: string, durationType?: string | null, isTrial: boolean, nextPaymentDate: any, subscriptionType: SubscriptionTypeEnum, subscriptionDuration: number, priceList: Array<{ __typename?: 'PriceList', quantity: number, discount: any, baseAmount: any, discountedAmount: any, totalAfterTrialPrice: number, totalDueTodayPrice: number }> } | null, coupon?: { __typename?: 'CouponDetails', isValid: boolean, description?: string | null, discountName?: string | null, discountAmount: any } | null } | null };

export type CreatePaymentIntentFromQuoteMutationVariables = Exact<{
  input: CreatePaymentIntentFromQuoteInput;
}>;


export type CreatePaymentIntentFromQuoteMutation = { __typename?: 'Mutation', intentObject?: { __typename?: 'CreatePaymentIntentPayload', paymentIntent?: string | null, errorMessage?: string | null } | null };

export type VerifyAndApplyCouponMutationVariables = Exact<{
  input?: InputMaybe<VerifyAndApplyCouponInput>;
}>;


export type VerifyAndApplyCouponMutation = { __typename?: 'Mutation', inputObject?: { __typename?: 'QuoteAndPricingPayload', quoteId?: string | null, productItem?: { __typename?: 'ProductItem', isTrial: boolean, productCode: string, durationType?: string | null, subscriptionDuration: number, subscriptionType: SubscriptionTypeEnum, nextPaymentDate: any, priceList: Array<{ __typename?: 'PriceList', quantity: number, discount: any, baseAmount: any, discountedAmount: any, totalAfterTrialPrice: number, totalDueTodayPrice: number }> } | null, coupon?: { __typename?: 'CouponDetails', isValid: boolean, description?: string | null, discountName?: string | null, discountAmount: any, finePrintKey?: string | null, discountPercentage: any } | null } | null };

export type UpdateQuoteMutationVariables = Exact<{
  quoteInput: UpdateQuoteInput;
}>;


export type UpdateQuoteMutation = { __typename?: 'Mutation', intentObject?: { __typename?: 'QuoteAndPricingPayload', quoteId?: string | null, productItem?: { __typename?: 'ProductItem', internalCoupon?: string | null, productCode: string, durationType?: string | null, subscriptionDuration: number, subscriptionType: SubscriptionTypeEnum, nextPaymentDate: any, isTrial: boolean, priceList: Array<{ __typename?: 'PriceList', quantity: number, discount: any, baseAmount: any, discountedAmount: any, totalAfterTrialPrice: number, totalDueTodayPrice: number }> } | null, coupon?: { __typename?: 'CouponDetails', isValid: boolean, description?: string | null, discountName?: string | null, discountAmount: any, finePrintKey?: string | null } | null } | null };

export type LogExceptionMutationVariables = Exact<{
  exceptionsDetail: LogExceptionInput;
}>;


export type LogExceptionMutation = { __typename?: 'Mutation', subObject: boolean };


export const GetUserLocationDocument = `
    query GetUserLocation {
  userCountry {
    countryCode
    currencyCode
  }
}
    `;
export const useGetUserLocationQuery = <
      TData = GetUserLocationQuery,
      TError = unknown
    >(
      variables?: GetUserLocationQueryVariables,
      options?: UseQueryOptions<GetUserLocationQuery, TError, TData>
    ) =>
    useQuery<GetUserLocationQuery, TError, TData>(
      variables === undefined ? ['GetUserLocation'] : ['GetUserLocation', variables],
      useGeneratedQueryFetcher<GetUserLocationQuery, GetUserLocationQueryVariables>(GetUserLocationDocument).bind(null, variables),
      options
    );
export const GetAllCountriesDocument = `
    query GetAllCountries {
  countries {
    countryName
    currencyCode
    countryCode
    statesName
    states
  }
}
    `;
export const useGetAllCountriesQuery = <
      TData = GetAllCountriesQuery,
      TError = unknown
    >(
      variables?: GetAllCountriesQueryVariables,
      options?: UseQueryOptions<GetAllCountriesQuery, TError, TData>
    ) =>
    useQuery<GetAllCountriesQuery, TError, TData>(
      variables === undefined ? ['GetAllCountries'] : ['GetAllCountries', variables],
      useGeneratedQueryFetcher<GetAllCountriesQuery, GetAllCountriesQueryVariables>(GetAllCountriesDocument).bind(null, variables),
      options
    );
export const GetPlanDataDocument = `
    query GetPlanData($options: PlanOptionsInput!) {
  plan(planOptionsInput: $options) {
    internalCoupon
    currencyCode
    productItems {
      internalCoupon
      durationType
      isTrial
      nextPaymentDate
      productCode
      subscriptionDuration
      subscriptionType
      priceList {
        baseAmount
        discount
        discountedAmount
        quantity
        totalAfterTrialPrice
        totalDueTodayPrice
        rRP
        savings
      }
    }
  }
}
    `;
export const useGetPlanDataQuery = <
      TData = GetPlanDataQuery,
      TError = unknown
    >(
      variables: GetPlanDataQueryVariables,
      options?: UseQueryOptions<GetPlanDataQuery, TError, TData>
    ) =>
    useQuery<GetPlanDataQuery, TError, TData>(
      ['GetPlanData', variables],
      useGeneratedQueryFetcher<GetPlanDataQuery, GetPlanDataQueryVariables>(GetPlanDataDocument).bind(null, variables),
      options
    );
export const CheckEmailDocument = `
    query CheckEmail($email: String!) {
  validation: isEmailAvailable(email: $email) {
    isEmailAvailable
    errorMessage
    errorCode
  }
}
    `;
export const useCheckEmailQuery = <
      TData = CheckEmailQuery,
      TError = unknown
    >(
      variables: CheckEmailQueryVariables,
      options?: UseQueryOptions<CheckEmailQuery, TError, TData>
    ) =>
    useQuery<CheckEmailQuery, TError, TData>(
      ['CheckEmail', variables],
      useGeneratedQueryFetcher<CheckEmailQuery, CheckEmailQueryVariables>(CheckEmailDocument).bind(null, variables),
      options
    );
export const CheckZipCodeDocument = `
    query CheckZipCode($zipcode: String!) {
  validation: isValidZipCode(zipcode: $zipcode) {
    isValid
    errorMessage
    errorCode
    stateCode
    stateName
  }
}
    `;
export const useCheckZipCodeQuery = <
      TData = CheckZipCodeQuery,
      TError = unknown
    >(
      variables: CheckZipCodeQueryVariables,
      options?: UseQueryOptions<CheckZipCodeQuery, TError, TData>
    ) =>
    useQuery<CheckZipCodeQuery, TError, TData>(
      ['CheckZipCode', variables],
      useGeneratedQueryFetcher<CheckZipCodeQuery, CheckZipCodeQueryVariables>(CheckZipCodeDocument).bind(null, variables),
      options
    );
export const CreateSubscriptionDocument = `
    mutation CreateSubscription($input: CreateNewSubscriptionInput!) {
  result: createSubscription(input: $input) {
    success
    errorMessage
  }
}
    `;
export const useCreateSubscriptionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSubscriptionMutation, TError, CreateSubscriptionMutationVariables, TContext>) =>
    useMutation<CreateSubscriptionMutation, TError, CreateSubscriptionMutationVariables, TContext>(
      ['CreateSubscription'],
      useGeneratedQueryFetcher<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument),
      options
    );
export const CreateCustomerSubscriptionDocument = `
    mutation CreateCustomerSubscription($input: CreateCustomerSubscriptionInput!) {
  result: createCustomerSubscription(input: $input) {
    success
    errorMessage
  }
}
    `;
export const useCreateCustomerSubscriptionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCustomerSubscriptionMutation, TError, CreateCustomerSubscriptionMutationVariables, TContext>) =>
    useMutation<CreateCustomerSubscriptionMutation, TError, CreateCustomerSubscriptionMutationVariables, TContext>(
      ['CreateCustomerSubscription'],
      useGeneratedQueryFetcher<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>(CreateCustomerSubscriptionDocument),
      options
    );
export const CreateContactDocument = `
    mutation CreateContact($contact: ContactInput!) {
  result: createContact(contact: $contact) {
    isSuccessful
    errorMessage
  }
}
    `;
export const useCreateContactMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateContactMutation, TError, CreateContactMutationVariables, TContext>) =>
    useMutation<CreateContactMutation, TError, CreateContactMutationVariables, TContext>(
      ['CreateContact'],
      useGeneratedQueryFetcher<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument),
      options
    );
export const GetPaymentSourcesDocument = `
    query GetPaymentSources($userId: Int!, $token: String!) {
  paymentSources(paymentSourceInput: {userId: $userId, token: $token}) {
    id
    lastFour
    brand
    expiryMonth
    expiryYear
    deleted
    status
  }
}
    `;
export const useGetPaymentSourcesQuery = <
      TData = GetPaymentSourcesQuery,
      TError = unknown
    >(
      variables: GetPaymentSourcesQueryVariables,
      options?: UseQueryOptions<GetPaymentSourcesQuery, TError, TData>
    ) =>
    useQuery<GetPaymentSourcesQuery, TError, TData>(
      ['GetPaymentSources', variables],
      useGeneratedQueryFetcher<GetPaymentSourcesQuery, GetPaymentSourcesQueryVariables>(GetPaymentSourcesDocument).bind(null, variables),
      options
    );
export const GetCustomerCurrencyDocument = `
    query GetCustomerCurrency($options: CustomerPlanOptionsInput!) {
  customerPlans(customerPlanOptionsInput: $options) {
    currencyCode
  }
}
    `;
export const useGetCustomerCurrencyQuery = <
      TData = GetCustomerCurrencyQuery,
      TError = unknown
    >(
      variables: GetCustomerCurrencyQueryVariables,
      options?: UseQueryOptions<GetCustomerCurrencyQuery, TError, TData>
    ) =>
    useQuery<GetCustomerCurrencyQuery, TError, TData>(
      ['GetCustomerCurrency', variables],
      useGeneratedQueryFetcher<GetCustomerCurrencyQuery, GetCustomerCurrencyQueryVariables>(GetCustomerCurrencyDocument).bind(null, variables),
      options
    );
export const VerifyEmailDocument = `
    mutation VerifyEmail($input: VerifyEmailInput!) {
  inputObject: verifyEmail(input: $input)
}
    `;
export const useVerifyEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>) =>
    useMutation<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>(
      ['VerifyEmail'],
      useGeneratedQueryFetcher<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument),
      options
    );
export const VerifyTurnstileTokenDocument = `
    mutation VerifyTurnstileToken($input: String) {
  verifyTurnstileToken: verifyTurnstileToken(token: $input)
}
    `;
export const useVerifyTurnstileTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyTurnstileTokenMutation, TError, VerifyTurnstileTokenMutationVariables, TContext>) =>
    useMutation<VerifyTurnstileTokenMutation, TError, VerifyTurnstileTokenMutationVariables, TContext>(
      ['VerifyTurnstileToken'],
      useGeneratedQueryFetcher<VerifyTurnstileTokenMutation, VerifyTurnstileTokenMutationVariables>(VerifyTurnstileTokenDocument),
      options
    );
export const CreateQuoteDocument = `
    mutation CreateQuote($payment: CreateQuoteInput!) {
  result: createQuote(payment: $payment) {
    quoteId
    productItem {
      internalCoupon
      productCode
      durationType
      isTrial
      nextPaymentDate
      subscriptionType
      subscriptionDuration
      priceList {
        quantity
        discount
        baseAmount
        discountedAmount
        totalAfterTrialPrice
        totalDueTodayPrice
      }
    }
    coupon {
      isValid
      description
      discountName
      discountAmount
    }
  }
}
    `;
export const useCreateQuoteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateQuoteMutation, TError, CreateQuoteMutationVariables, TContext>) =>
    useMutation<CreateQuoteMutation, TError, CreateQuoteMutationVariables, TContext>(
      ['CreateQuote'],
      useGeneratedQueryFetcher<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument),
      options
    );
export const CreatePaymentIntentFromQuoteDocument = `
    mutation CreatePaymentIntentFromQuote($input: CreatePaymentIntentFromQuoteInput!) {
  intentObject: createPaymentIntentFromQuote(input: $input) {
    paymentIntent
    errorMessage
  }
}
    `;
export const useCreatePaymentIntentFromQuoteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePaymentIntentFromQuoteMutation, TError, CreatePaymentIntentFromQuoteMutationVariables, TContext>) =>
    useMutation<CreatePaymentIntentFromQuoteMutation, TError, CreatePaymentIntentFromQuoteMutationVariables, TContext>(
      ['CreatePaymentIntentFromQuote'],
      useGeneratedQueryFetcher<CreatePaymentIntentFromQuoteMutation, CreatePaymentIntentFromQuoteMutationVariables>(CreatePaymentIntentFromQuoteDocument),
      options
    );
export const VerifyAndApplyCouponDocument = `
    mutation VerifyAndApplyCoupon($input: VerifyAndApplyCouponInput) {
  inputObject: verifyAndApplyCoupon(input: $input) {
    quoteId
    productItem {
      isTrial
      productCode
      durationType
      subscriptionDuration
      subscriptionType
      nextPaymentDate
      priceList {
        quantity
        discount
        baseAmount
        discountedAmount
        totalAfterTrialPrice
        totalDueTodayPrice
      }
    }
    coupon {
      isValid
      description
      discountName
      discountAmount
      finePrintKey
      discountPercentage
    }
  }
}
    `;
export const useVerifyAndApplyCouponMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyAndApplyCouponMutation, TError, VerifyAndApplyCouponMutationVariables, TContext>) =>
    useMutation<VerifyAndApplyCouponMutation, TError, VerifyAndApplyCouponMutationVariables, TContext>(
      ['VerifyAndApplyCoupon'],
      useGeneratedQueryFetcher<VerifyAndApplyCouponMutation, VerifyAndApplyCouponMutationVariables>(VerifyAndApplyCouponDocument),
      options
    );
export const UpdateQuoteDocument = `
    mutation updateQuote($quoteInput: UpdateQuoteInput!) {
  intentObject: updateQuote(quoteUpdateInput: $quoteInput) {
    quoteId
    productItem {
      internalCoupon
      productCode
      durationType
      subscriptionDuration
      subscriptionType
      nextPaymentDate
      isTrial
      priceList {
        quantity
        discount
        baseAmount
        discountedAmount
        totalAfterTrialPrice
        totalDueTodayPrice
      }
    }
    coupon {
      isValid
      description
      discountName
      discountAmount
      finePrintKey
    }
  }
}
    `;
export const useUpdateQuoteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateQuoteMutation, TError, UpdateQuoteMutationVariables, TContext>) =>
    useMutation<UpdateQuoteMutation, TError, UpdateQuoteMutationVariables, TContext>(
      ['updateQuote'],
      useGeneratedQueryFetcher<UpdateQuoteMutation, UpdateQuoteMutationVariables>(UpdateQuoteDocument),
      options
    );
export const LogExceptionDocument = `
    mutation LogException($exceptionsDetail: LogExceptionInput!) {
  subObject: logException(input: $exceptionsDetail)
}
    `;
export const useLogExceptionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LogExceptionMutation, TError, LogExceptionMutationVariables, TContext>) =>
    useMutation<LogExceptionMutation, TError, LogExceptionMutationVariables, TContext>(
      ['LogException'],
      useGeneratedQueryFetcher<LogExceptionMutation, LogExceptionMutationVariables>(LogExceptionDocument),
      options
    );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUserLocationQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ userCountry })
 *   )
 * })
 */
export const mockGetUserLocationQuery = (resolver: ResponseResolver<GraphQLRequest<GetUserLocationQueryVariables>, GraphQLContext<GetUserLocationQuery>, any>) =>
  graphql.query<GetUserLocationQuery, GetUserLocationQueryVariables>(
    'GetUserLocation',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllCountriesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ countries })
 *   )
 * })
 */
export const mockGetAllCountriesQuery = (resolver: ResponseResolver<GraphQLRequest<GetAllCountriesQueryVariables>, GraphQLContext<GetAllCountriesQuery>, any>) =>
  graphql.query<GetAllCountriesQuery, GetAllCountriesQueryVariables>(
    'GetAllCountries',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPlanDataQuery((req, res, ctx) => {
 *   const { options } = req.variables;
 *   return res(
 *     ctx.data({ plan })
 *   )
 * })
 */
export const mockGetPlanDataQuery = (resolver: ResponseResolver<GraphQLRequest<GetPlanDataQueryVariables>, GraphQLContext<GetPlanDataQuery>, any>) =>
  graphql.query<GetPlanDataQuery, GetPlanDataQueryVariables>(
    'GetPlanData',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCheckEmailQuery((req, res, ctx) => {
 *   const { email } = req.variables;
 *   return res(
 *     ctx.data({ isEmailAvailable })
 *   )
 * })
 */
export const mockCheckEmailQuery = (resolver: ResponseResolver<GraphQLRequest<CheckEmailQueryVariables>, GraphQLContext<CheckEmailQuery>, any>) =>
  graphql.query<CheckEmailQuery, CheckEmailQueryVariables>(
    'CheckEmail',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCheckZipCodeQuery((req, res, ctx) => {
 *   const { zipcode } = req.variables;
 *   return res(
 *     ctx.data({ isValidZipCode })
 *   )
 * })
 */
export const mockCheckZipCodeQuery = (resolver: ResponseResolver<GraphQLRequest<CheckZipCodeQueryVariables>, GraphQLContext<CheckZipCodeQuery>, any>) =>
  graphql.query<CheckZipCodeQuery, CheckZipCodeQueryVariables>(
    'CheckZipCode',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateSubscriptionMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createSubscription })
 *   )
 * })
 */
export const mockCreateSubscriptionMutation = (resolver: ResponseResolver<GraphQLRequest<CreateSubscriptionMutationVariables>, GraphQLContext<CreateSubscriptionMutation>, any>) =>
  graphql.mutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(
    'CreateSubscription',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCustomerSubscriptionMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createCustomerSubscription })
 *   )
 * })
 */
export const mockCreateCustomerSubscriptionMutation = (resolver: ResponseResolver<GraphQLRequest<CreateCustomerSubscriptionMutationVariables>, GraphQLContext<CreateCustomerSubscriptionMutation>, any>) =>
  graphql.mutation<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>(
    'CreateCustomerSubscription',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateContactMutation((req, res, ctx) => {
 *   const { contact } = req.variables;
 *   return res(
 *     ctx.data({ createContact })
 *   )
 * })
 */
export const mockCreateContactMutation = (resolver: ResponseResolver<GraphQLRequest<CreateContactMutationVariables>, GraphQLContext<CreateContactMutation>, any>) =>
  graphql.mutation<CreateContactMutation, CreateContactMutationVariables>(
    'CreateContact',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPaymentSourcesQuery((req, res, ctx) => {
 *   const { userId, token } = req.variables;
 *   return res(
 *     ctx.data({ paymentSources })
 *   )
 * })
 */
export const mockGetPaymentSourcesQuery = (resolver: ResponseResolver<GraphQLRequest<GetPaymentSourcesQueryVariables>, GraphQLContext<GetPaymentSourcesQuery>, any>) =>
  graphql.query<GetPaymentSourcesQuery, GetPaymentSourcesQueryVariables>(
    'GetPaymentSources',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCustomerCurrencyQuery((req, res, ctx) => {
 *   const { options } = req.variables;
 *   return res(
 *     ctx.data({ customerPlans })
 *   )
 * })
 */
export const mockGetCustomerCurrencyQuery = (resolver: ResponseResolver<GraphQLRequest<GetCustomerCurrencyQueryVariables>, GraphQLContext<GetCustomerCurrencyQuery>, any>) =>
  graphql.query<GetCustomerCurrencyQuery, GetCustomerCurrencyQueryVariables>(
    'GetCustomerCurrency',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyEmailMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ verifyEmail })
 *   )
 * })
 */
export const mockVerifyEmailMutation = (resolver: ResponseResolver<GraphQLRequest<VerifyEmailMutationVariables>, GraphQLContext<VerifyEmailMutation>, any>) =>
  graphql.mutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    'VerifyEmail',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyTurnstileTokenMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ verifyTurnstileToken })
 *   )
 * })
 */
export const mockVerifyTurnstileTokenMutation = (resolver: ResponseResolver<GraphQLRequest<VerifyTurnstileTokenMutationVariables>, GraphQLContext<VerifyTurnstileTokenMutation>, any>) =>
  graphql.mutation<VerifyTurnstileTokenMutation, VerifyTurnstileTokenMutationVariables>(
    'VerifyTurnstileToken',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateQuoteMutation((req, res, ctx) => {
 *   const { payment } = req.variables;
 *   return res(
 *     ctx.data({ createQuote })
 *   )
 * })
 */
export const mockCreateQuoteMutation = (resolver: ResponseResolver<GraphQLRequest<CreateQuoteMutationVariables>, GraphQLContext<CreateQuoteMutation>, any>) =>
  graphql.mutation<CreateQuoteMutation, CreateQuoteMutationVariables>(
    'CreateQuote',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePaymentIntentFromQuoteMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createPaymentIntentFromQuote })
 *   )
 * })
 */
export const mockCreatePaymentIntentFromQuoteMutation = (resolver: ResponseResolver<GraphQLRequest<CreatePaymentIntentFromQuoteMutationVariables>, GraphQLContext<CreatePaymentIntentFromQuoteMutation>, any>) =>
  graphql.mutation<CreatePaymentIntentFromQuoteMutation, CreatePaymentIntentFromQuoteMutationVariables>(
    'CreatePaymentIntentFromQuote',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyAndApplyCouponMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ verifyAndApplyCoupon })
 *   )
 * })
 */
export const mockVerifyAndApplyCouponMutation = (resolver: ResponseResolver<GraphQLRequest<VerifyAndApplyCouponMutationVariables>, GraphQLContext<VerifyAndApplyCouponMutation>, any>) =>
  graphql.mutation<VerifyAndApplyCouponMutation, VerifyAndApplyCouponMutationVariables>(
    'VerifyAndApplyCoupon',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateQuoteMutation((req, res, ctx) => {
 *   const { quoteInput } = req.variables;
 *   return res(
 *     ctx.data({ updateQuote })
 *   )
 * })
 */
export const mockUpdateQuoteMutation = (resolver: ResponseResolver<GraphQLRequest<UpdateQuoteMutationVariables>, GraphQLContext<UpdateQuoteMutation>, any>) =>
  graphql.mutation<UpdateQuoteMutation, UpdateQuoteMutationVariables>(
    'updateQuote',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLogExceptionMutation((req, res, ctx) => {
 *   const { exceptionsDetail } = req.variables;
 *   return res(
 *     ctx.data({ logException })
 *   )
 * })
 */
export const mockLogExceptionMutation = (resolver: ResponseResolver<GraphQLRequest<LogExceptionMutationVariables>, GraphQLContext<LogExceptionMutation>, any>) =>
  graphql.mutation<LogExceptionMutation, LogExceptionMutationVariables>(
    'LogException',
    resolver
  )
