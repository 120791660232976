import { equals } from 'ramda';
import React, { type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isString, isStringDefined } from '../../../__utils__/utils';
import { useQueryParams } from '../../../hooks/use-query-params';
import { useAppSelector } from '../../../store/store';
import { subscriptionActions } from '../../../subscription-model/subscription.slice';
import { type SalesforceURLData } from '../../../subscription-model/subscription.types';

/**
 * This is part of the "hybrid integration" with Salesforce.
 * This Gate enforces the search params required in the url contract.
 *
 * Note in the url `userid` is lowercase where in graphql it's a camelCase Int.
 *
 * @see https://mathletics.atlassian.net/browse/FRAME-5677
 */
export function SalesforceParamsGate ({ children }: PropsWithChildren) {
    const {
        contractInUrl: contractInUrlCanChange,
        hasSalesforceParams: hasSalesforceParamsCanChange
    } = useContractInUrl();
    const isContractInStoreFirst = useAppSelector(
        state => state.subscription?.salesforce
    );
    // Conditionally don't react to changes, this is needed to show the
    // success page after we clear the Redux store and the sessionStorage.
    // When it changes we need to ignore the automatic changes with useMemo
    // as useAppSelector will trigger React to rerender without it.
    const shouldReactToChange = !/success/.test(window.location.href);
    /* eslint-disable react-hooks/exhaustive-deps */
    const contractInUrlUnchanged = useMemo(() => contractInUrlCanChange, []);
    const contractInUrl = shouldReactToChange ? contractInUrlCanChange : contractInUrlUnchanged;
    const hasSalesforceParamsUnchanged = useMemo(() => hasSalesforceParamsCanChange, []);
    const hasSalesforceParams = shouldReactToChange ? hasSalesforceParamsCanChange : hasSalesforceParamsUnchanged;
    const isContractInStoreUnchanged = useMemo(() => isContractInStoreFirst, []);
    const isContractInStore = shouldReactToChange ? isContractInStoreFirst : isContractInStoreUnchanged;
    /* eslint-enable react-hooks/exhaustive-deps */
    const [isReady, setIsReady] = useState(
        contractInUrl !== null && isContractInStore === null
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        console.info('effect state', {
            contractInUrl,
            contractInStore: isContractInStore,
            isReady
        });

        if (hasSalesforceParams && !contractInUrl) {
            const errorMessage = 'was unable to get the contract from the current params, please recheck the url for token and userid.';

            console.error(errorMessage);
            navigate('/error', {
                state: { errorMessage }
            });
            dispatch(subscriptionActions.clearData());

            return;
        }

        if (isReady && (!contractInUrl && !isContractInStore)) {
            const errorMessage = 'required contract not found, token and userid are required search params.';

            console.error(errorMessage);
            navigate('/error', {
                state: { errorMessage }
            });
            dispatch(subscriptionActions.clearData());

            return;
        }

        const isSameContract = equals(contractInUrl, isContractInStore);

        if (contractInUrl && isSameContract) {
            console.info('this url contract is already applied in the store');

            cleanupUrl();

            if (!isReady) {
                setIsReady(true);
            }

            return;
        }

        if (contractInUrl && !isSameContract) {
            console.info('new url contract found', contractInUrl);

            applyNewContract({
                dispatch,
                setIsReady,
                contractInUrl
            });

            return;
        }

        if (!isReady && isContractInStore && !contractInUrl) {
            console.info('assuming its ready since there is a current contract but none in the url');

            setIsReady(true);

            return;
        }

        if (!isReady && !contractInUrl && !isContractInStore) {
            const errorMessage = 'initial required contract not found, token and userid are required search params.';

            console.error(errorMessage);
            navigate('/error', {
                state: { errorMessage }
            });
            dispatch(subscriptionActions.clearData());
        }
    }, [contractInUrl, isContractInStore, isReady, setIsReady, hasSalesforceParams, navigate, dispatch]);

    if (!isReady) {
        return null;
    }

    return (
        <>
            {children}
        </>
    );
}

function applyNewContract ({ contractInUrl, dispatch, setIsReady }: {
    dispatch: ReturnType<typeof useDispatch>
    setIsReady: (isReady: boolean) => any
    contractInUrl: SalesforceURLData
}) {
    console.log('apply new contract', contractInUrl);

    dispatch(subscriptionActions.updateSalesforceContract(contractInUrl));
    setIsReady(true);

    cleanupUrl();
}

function cleanupUrl () {
    const updatedURL = new URL(window.location.href);

    updatedURL.searchParams.delete('userid');
    updatedURL.searchParams.delete('token');
    updatedURL.searchParams.delete('currency');

    window.history.replaceState(null, '', updatedURL.toString());
}

function useContractInUrl (): {
    contractInUrl: SalesforceURLData | null
    hasSalesforceParams: boolean
    } {
    const params = useQueryParams();
    const contractInUrl = resolveUrlContract(params);
    const hasSalesforceParams = urlHasSalesforceParams(params);

    return {
        contractInUrl,
        hasSalesforceParams
    };
}

function resolveUrlContract (
    params: Record<string, string | undefined>
): SalesforceURLData | null {
    let userId;
    let currencyCode;

    try {
        if (!isStringDefined(params.userid)) {
            throw new Error('unable to parse int from params.userid');
        }

        // handle Int and casing of userid to camelCase for GraphQL
        userId = parseInt(params.userid);
        currencyCode = params.currency?.toUpperCase();
    } catch {
        userId = null;
    }

    const data = {
        token: params.token,
        currencyCode,
        userId
    };

    if (isSalesforceUrlContract(data)) {
        return data;
    }

    return null;
}

function isSalesforceUrlContract (value: any): value is SalesforceURLData {
    return (
        isStringDefined(value?.currencyCode) &&
        isStringDefined(value?.token) &&
        typeof value?.userId === 'number' &&
        !isNaN(value.userId) &&
        value.userId > 0
    );
}

function urlHasSalesforceParams (params: any): boolean {
    // case here matters eg no camelCase is provided in the url for userid,
    // the graphql contract requires it.
    return (
        isString(params?.currency) &&
        isString(params?.token) &&
        isString(params?.userid)
    );
}
