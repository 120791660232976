import React, { PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import type { Store } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';

import { RootState } from './state.types';
import {
    store as defaultStore, persistor
} from './store';

export function ReduxStoreProvider ({
    children, store = null
}: PropsWithChildren<{
    store?: Store<RootState> | null
}>) {
    store = store || defaultStore;
    // @ts-ignore for debugging
    window.__store__ = store;

    return (
        <ReduxProvider store={store}>
            <PersistGate persistor={persistor}>
                {children}
            </PersistGate>
        </ReduxProvider>
    );
}
