/* istanbul ignore file */
import { t } from '@lingui/macro';

import type {
    ChargebeeValidationFields, ErrorMessages, ErrorMessagesState, ValidationError, ValidationState
} from './use-credit-card-field.types';

export function resolveFieldsError (
    state: ValidationState,
    formikMessage: string | null,
    isRowStackMode: boolean,
    hasCalledSubmit: boolean
): ErrorMessagesState {
    const errors = resolveErrorMessageText();
    const combinedMessage = isRowStackMode ?
        resolveCombinedMessage(state, hasCalledSubmit, errors) :
        null;

    return {
        hasCalledSubmit,
        combinedMessage,
        ...isRowStackMode ? { fields: null } : {
            fields: {
                number: resolveFieldError('number', state, hasCalledSubmit, errors),
                expiry: resolveFieldError('expiry', state, hasCalledSubmit, errors),
                cvv: resolveFieldError('cvv', state, hasCalledSubmit, errors)
            }
        }
    };
}

export function resolveErrorMessageText (): ErrorMessages {
    return {
        fieldNames: {
            cvv: t`CVV`,
            expiry: t`Expiry`,
            number: t`Credit card number`
        },
        validation: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            card_cvv_invalid: t`Invalid card verification code (CVV).`
        }
    };
}

export function resolveFieldError (
    fieldName: ChargebeeValidationFields,
    state: ValidationState,
    hasCalledSubmit: boolean,
    errors: ErrorMessages
): string | null {
    const fieldState = state.fields[fieldName];

    if (typeof fieldState.error?.errorCode === 'string') {
        return errorValidationMessage(errors, fieldState.error);
    }

    if (hasCalledSubmit && fieldState.isEmpty) {
        return t({
            id: '{fieldName} is required',
            values: { fieldName: errors.fieldNames[fieldName] }
        });
    }

    return null;
}

function errorValidationMessage (errors: ErrorMessages, error: ValidationError | null) {
    if (!error) return null;

    return errors.validation[error?.errorCode] || error?.message;
}

function resolveCombinedMessage (
    state: ValidationState,
    hasCalledSubmit: boolean,
    errors: ErrorMessages
) {
    const firstErrorMessage = errorValidationMessage(errors, state.fields.number.error) ||
        errorValidationMessage(errors, state.fields.expiry.error) ||
        errorValidationMessage(errors, state.fields.cvv.error) ||
        null;

    if (hasCalledSubmit && !firstErrorMessage) {
        return (
            resolveFieldError('number', state, hasCalledSubmit, errors) ||
            resolveFieldError('expiry', state, hasCalledSubmit, errors) ||
            resolveFieldError('cvv', state, hasCalledSubmit, errors)
        );
    }

    return firstErrorMessage;
}
