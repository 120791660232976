import type { ActiveCountry } from '../use-active-location';
import type { CountryItem } from '../use-get-countries-query';

type Options = {
    currencyCode: string
    state?: string
};

export function pickCountryFromCurrencyCode ({
    currencyCode,
    state
}: Options,
countries: Array<CountryItem>
): ActiveCountry | null {
    const existing = countries.find(
        country => country.currencyCode === currencyCode
    ) || null;

    if (!existing) return null;

    return {
        currencyCode: existing.currencyCode,
        countryName: existing.countryName,
        countryCode: existing.countryCode,
        statesName: existing.statesName,
        state: typeof state === 'string' ? state : null,
        states: existing.states
    };
}
