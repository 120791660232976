import React, {
    cloneElement,
    ComponentType, PropsWithChildren, ReactNode, useMemo
} from 'react';

export type WrappedElement = ComponentType<PropsWithChildren<any>>;

export type WrappedElements = Array<WrappedElement>;

type ComposeProps = {
    wrappers: WrappedElements
    children: ReactNode
};

export function Compose ({
    wrappers = [], children
}: ComposeProps) {
    const components = useMemo(() => wrappers.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
    }, children), [wrappers, children]);

    return (<>{components}</>);
}

export function composeWrapper<TProps> (
    wrappers: WrappedElements
): ComponentType<TProps> {
    // eslint-disable-next-line react/display-name
    return ({ children }: PropsWithChildren<any>) => (
        <Compose wrappers={wrappers as any}>
            {children}
        </Compose>
    );
}

export type RenderProps<T=any> = {
    render: (data: T) => JSX.Element
};

type RenderPropComposeProps<T=any> = {
  composed: (data: T) => JSX.Element
  components: (({ render }: RenderProps) => (null | JSX.Element))[]
};

export function ComposeRenderProps<T> (props: RenderPropComposeProps<T>) {
    return renderComponents(
        props.composed,
        props.components
    );
}

function renderComponents (
    render: RenderProps['render'],
    // open to a good way to type any here?
    remaining: any[],
    results?: any[]
) {
    // results are the collection for the props composed together
    results = results || [];

    if (!remaining[0]) {
        return render(results);
    }

    function tickRenderNextComponent (value: any) {
        const next = remaining.slice(1);

        return renderComponents(
            render, next, results && results.concat([value])
        );
    }

    return typeof remaining[0] === 'function' ?
        remaining[0]({
            results,
            render: tickRenderNextComponent
        }) : cloneElement(remaining[0], {
            children: tickRenderNextComponent
        });
}
