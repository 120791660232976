import React from 'react';
import { Navigate } from 'react-router-dom';

export function NavigateToNotFoundRoute () {
    return (
        <Navigate
            replace={false}
            to={'/error/not-found'}
        />
    );
}
