import { Box, VStack, Stack } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React from 'react';

import { isStringDefined } from '../../__utils__/utils';
import { tFormatDate } from '../../i18n/t-date';
import {
    SubscriptionReminder
} from '../../routes/subscription/create/create-account/components/subscription-reminder/subscription-reminder';
import { useQuoteAndPricingSelector } from '../../subscription-model/subscription.selectors';
import { CheckoutTermsPanelSkeleton } from './components/checkout-terms-panel-skeleton';

export type CheckoutTermsPanelProps = {
    data?: {
        isTrial: boolean
        totalPriceAfterTrial: string
        productItem: {
            subscriptionDuration: number
            nextPaymentDate: string
            currencyCode: string
            subscriptionType: string
        }
        calendarReminderDescription: string
        calenderReminderTitle: string
        childrenCount: number
    } | null
};

export const CheckoutTermsPanelView = ({
    data
}: CheckoutTermsPanelProps) => {
    if (!data) {
        return <CheckoutTermsPanelSkeleton />;
    }

    const {
        isTrial,
        totalPriceAfterTrial,
        productItem: {
            subscriptionDuration,
            nextPaymentDate,
            currencyCode,
            subscriptionType
        },
        calendarReminderDescription,
        calenderReminderTitle,
        childrenCount
    } = data;

    return (
        <Box
            data-testid={'checkout-terms-panel'}
            maxWidth={'558px'}
            alignSelf={'self-start'}
            fontSize={'14px'}
            fontWeight={'400'}
            lineHeight={'20px'}
            letterSpacing={'-0.15px'}
            color={'neutral.700'}
        >
            <VStack spacing={'20px'}>
                <PricingText
                    calendarReminderDescription={calendarReminderDescription}
                    calenderReminderTitle={calenderReminderTitle}
                    isTrial={isTrial}
                    currencyCode={currencyCode}
                    totalPriceAfterTrial={totalPriceAfterTrial}
                    nextPaymentDate={nextPaymentDate}
                    subscriptionDuration={subscriptionDuration}
                    subscriptionType={subscriptionType}
                    childrenCount={childrenCount}
                />
                <Box>
                    {t`Subscription-Panel-Description-Text`} {t`Subscription-Panel-Description-Cancel-Subscription-Text`}
                </Box>
            </VStack>
        </Box>
    );
};

type PricingTextProps = {
    isTrial: boolean
    currencyCode: string
    totalPriceAfterTrial: string
    nextPaymentDate: string
    subscriptionDuration: number
    subscriptionType: string
    calenderReminderTitle: string
    calendarReminderDescription: string
    childrenCount: number
};

function PricingText ({
    isTrial,
    currencyCode,
    totalPriceAfterTrial,
    nextPaymentDate,
    subscriptionDuration,
    subscriptionType,
    calenderReminderTitle,
    calendarReminderDescription,
    childrenCount
}: PricingTextProps) {
    const quoteAndPricingData = useQuoteAndPricingSelector();
    const internalCoupon = quoteAndPricingData?.productItem?.internalCoupon;
    const isCampaignActive = isStringDefined(internalCoupon);
    const couponData = quoteAndPricingData?.coupon;
    const freeTrialDescriptionText = t({
        id: 'Your free trial end in {trialDuration} days after which your Monthly Subscription will start.',
        values: {
            trialDuration: subscriptionDuration
        }
    });
    const preAuthDisplayText = t({
        // eslint-disable-next-line max-len
        id: 'To validate your credit card, a transaction equivalent to $0.50 (USD) will be processed in {currencyCode} and then refunded in full, to the same card immediately',
        values: {
            currencyCode
        }
    });
    const subscriptionNonTrialText = t({
        id: 'Your {subscriptionType} Subscription will start today',
        values: {
            subscriptionType
        }
    });
    const subsequentPaymentsSingleChildText = subscriptionType === 'Monthly' ?
        t`Subsequent payments monthly` :
        t`Subsequent payments yearly`;
    /** Show subsequent text only if coupon is applied
     */
    const subsequentPaymentsText = couponData?.isValid || isCampaignActive ? subsequentPaymentsSingleChildText : null;

    if (isTrial) {
        return (
            <Stack alignSelf={'baseline'} spacing={'20px'}>
                <Box>{freeTrialDescriptionText}</Box>
                <Box>
                    <span>{t`Subscription-Panel-Description-Free-Trial-Subscription-Text1`} </span>
                    {couponData?.isValid && <span>{t`the discounted price of`} </span>}
                    <span>{totalPriceAfterTrial} </span>
                    <span data-testid={'checkout-terms-panel-free-trial-currencyCode'}>{currencyCode} </span>
                    <span>{t`on`} </span>
                    <span data-testid={'checkout-terms-panel-free-trial-dueDate'}>{tFormatDate(nextPaymentDate)} </span>
                    <span>{t`Subscription-Panel-Description-Free-Trial-Subscription-Text2`} </span>
                    <span>{subsequentPaymentsText}</span>
                </Box>

                {currencyCode === 'USD' ?
                    <Box>
                        <span>{t`Subscription-Panel-Description-PreAuth-Display-Text`}</span>
                    </Box> :
                    <Box>
                        <span>{preAuthDisplayText}</span>
                    </Box>
                }

                <SubscriptionReminder
                    nextPaymentDate={nextPaymentDate}
                    calendarReminderDescription={calendarReminderDescription}
                    calenderReminderTitle={calenderReminderTitle}
                />
            </Stack>
        );
    }

    return(
        <Stack alignSelf={'baseline'} spacing={'20px'}>
            <Box>
                <span>{subscriptionNonTrialText}, </span>
                <span>{tFormatDate(new Date().toUTCString())} </span>
                <span>{t`Subscription-Panel-Description-Yearly-Subscription-Text2`} </span>
                {couponData?.isValid && <span>{t`the-discounted-price-of`} </span>}
                <span data-testid={'checkout-terms-panel-yearly-totalPrice'}>{totalPriceAfterTrial} </span>
                <span>{currencyCode}. </span>
                <span>{subsequentPaymentsText} </span>
                <span>{t`Subscription-Panel-Description-Yearly-Subscription-Text3`},     </span>
                <span data-testid={'checkout-terms-panel-yearly-dueDate'}>{tFormatDate(nextPaymentDate)}. </span>
            </Box>

            {currencyCode === 'USD' ?
                <Box>
                    <span>{t`Subscription-Panel-Description-PreAuth-Display-Text`}</span>
                </Box> :
                <Box>
                    <span>{preAuthDisplayText}</span>
                </Box>
            }

            <SubscriptionReminder
                nextPaymentDate={nextPaymentDate}
                calendarReminderDescription={calendarReminderDescription}
                calenderReminderTitle={calenderReminderTitle}
            />
        </Stack>
    );
}
