import { Box, type BoxProps } from '@chakra-ui/react';
import { LottieOptions, useLottie } from 'lottie-react';
import React, { useEffect, useRef } from 'react';

import { useIsVisible } from '../__utils__/use-is-visible';

type LottieWhenVisibleProps =
    & BoxProps
    & {
    animationData: LottieOptions['animationData']
    // Options extends https://lottiereact.com/components/Lottie#props
    // and provides https://lottiereact.com/hooks/useLottie#params
    options?: Omit<LottieOptions, 'animationData'>
    // Replay the animation each time it's not visible and visible.
    replay?: boolean
};

const defaultOptions: Omit<LottieOptions, 'animationData'> = {
    loop: false,
    autoplay: false,
    renderer: 'svg',
    rendererSettings: {
        progressiveLoad: true,
        preserveAspectRatio: 'xMidYMid meet',
        imagePreserveAspectRatio: 'xMidYMid meet'
    }
};

export function LottieWhenVisible ({
    replay = false,
    animationData,
    options,
    ...restProps
}: LottieWhenVisibleProps) {
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useIsVisible(ref);
    const { View, play, goToAndStop } = useLottie({
        ...defaultOptions,
        ...options,
        rendererSettings: {
            ...defaultOptions.rendererSettings,
            ...options?.rendererSettings
        },
        animationData
    });

    useEffect(() => {
        if (isVisible) {
            play();
        } else if (replay) {
            goToAndStop(0);
        }
    }, [isVisible, play, goToAndStop, replay]);

    return (
        <Box ref={ref} {...restProps}>
            {View}
        </Box>
    );
}
