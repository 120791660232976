import React from 'react';

import { useUrlQuery } from '../../api/contentful/urls/use-url-query';
import { RenderProps } from '../../components/compose/compose';
import { ErrorBoundary } from '../../routes/error/errors/error-boundary';

export function ContactUsRenderGate ({ render }: RenderProps) {
    const {
        data,
        isLoading
    } = useUrlQuery('Contact Us');
    const contactUsUrl = data?.url || null;

    if (isLoading || !contactUsUrl) {
        return null;
    }

    return (
        <ErrorBoundary context={'CheckoutRouteUrlsRenderGate'}>
            {render({ contactUsUrl })}
        </ErrorBoundary>
    );
}
