import { MathleticsThemeProvider } from '@3plearning/chakra-mathletics-theme';
import { createStandaloneToast } from '@chakra-ui/toast';
import React, { PropsWithChildren } from 'react';

import theme from './theme';

export type AppStylesProps = PropsWithChildren<unknown>;

export const {
    ToastContainer,
    toast
} = createStandaloneToast({ theme });

export const AppStylesProvider = ({ children }: AppStylesProps) => {
    return (
        <MathleticsThemeProvider theme={theme}>
            {children}
            <ToastContainer />
        </MathleticsThemeProvider>
    );
};
