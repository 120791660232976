import {
    SubscriptionState,
    INITIAL_SUBSCRIPTION_STATE
} from '../subscription-model/subscription.types';

export type RootState = {
    subscription: SubscriptionState
};

export const INITIAL_ROOT_STATE: RootState = {
    subscription: INITIAL_SUBSCRIPTION_STATE
};
