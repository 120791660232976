import { Flex, Text, FlexProps } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import { campaignTheme } from '../../../styles/theme/foundations/colors';
import { useIsTrialProductSelected } from '../../../subscription-model/subscription.selectors';

type Props =
    & FlexProps
    & {
    sloganText?: string
    isCampaignActive: boolean
    isCyberMondayCampaign?: boolean
};

type RibbonTextWrapperProps =
    & FlexProps
    & PropsWithChildren;

export function RibbonTextWrapper ({ children, ...restProps }: RibbonTextWrapperProps) {
    return (
        <Flex
            {...restProps}
            align={'center'}
            justify={'center'}
            h={'100%'}
            w={'100%'}
            position={'absolute'}
            bottom={{
                mobile: '-5px',
                pageHeaderTablet: 0,
                desktop: '6px'
            }}
            left={'5px'}
        >
            {children}
        </Flex>
    );
}

export function SpecialsRibbon ({ sloganText, isCampaignActive, isCyberMondayCampaign, ...restProps }: Props) {
    const isTrial = useIsTrialProductSelected();

    return (
        <Flex
            {...restProps}
            position={'absolute'}
            top={{
                mobile: '-4px',
                tablet: '0',
                desktop: '-4px'
            }}
            right={{
                mobile: '24px',
                tablet: '10px',
                desktop: 0
            }}
            w={{
                mobile: '152px',
                pageHeaderTablet: '180px',
                desktop: '208px'
            }}
            h={{
                mobile: '40px',
                pageHeaderTablet: '48px',
                desktop: '56px'
            }}
            sx={{
                label: 'specials-ribbon'
            }}
        >
            <Ribbon isCampaignActive={isCampaignActive} isCyberMondayCampaign={isCyberMondayCampaign} isTrial={isTrial} />
            <RibbonTextWrapper
                sx={{
                    label: 'text-holder-specials'
                }}
            >
                <Text
                    noOfLines={1}
                    fontWeight={'medium'}
                    color={isCampaignActive && !isTrial ? campaignTheme.tertiary : 'neutral.900'}
                    fontSize={{
                        mobile: '14px',
                        pageHeaderTablet: '16px',
                        desktop: '18px'
                    }}
                    letterSpacing={{
                        mobile: '-0.24px',
                        pageHeaderTablet: '0.15px',
                        desktop: '-0.36px'

                    }}
                >
                    {sloganText}
                </Text>
            </RibbonTextWrapper>
        </Flex>
    );
}

type RibbonProps = {
    isCampaignActive: boolean
    isTrial?: boolean
    isCyberMondayCampaign?: boolean
};
function Ribbon ({ isCampaignActive, isTrial, isCyberMondayCampaign }: RibbonProps) {
    const campaignColor = isCyberMondayCampaign ? '#038EB9' : '#303030';

    return (
        <svg width={'208'} height={'56'} viewBox={'0 0 208 56'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
            <path
                d={'M190 0C199.941 0 208 8.05887 208 18L208 56C208 56 206.029 46.4455 191.836 46.4455H0L23.4229 22.2938L0 0H190Z'}
                fill={isCampaignActive && !isTrial ? campaignColor : '#F4BC22'}
            />
        </svg>

    );
}
