/* istanbul ignore file */
// going to trust yup for this coverage
import pDebounce from 'p-debounce';
import pMemoize from 'p-memoize';
import type { TestContext } from 'yup';

import {
    CheckEmailDocument,
    type CheckEmailQuery,
    type CheckEmailQueryVariables,
    Status
} from '../../../../../../__generated__/gateway-graphql';
import type { FetcherOptions } from '../../../../../../api/gateway/fetcher';
import { createStandaloneFetcher } from '../../../../../../api/gateway/standalone-fetcher';
import type { TextInputControlError } from '../../../../../../components/formik/fields/text-input-control-field';
import { NOTICE_TYPES } from '../../../../../../components/notice-panel/notice-panel-types';
import type { SchemaOptions } from '../create-account-form/__utils__/form-validation';

/**
 * Required for the inline async email checks in the form.
 * The BS Team has required us to not have a form submission from the data in this form.
 * Instead a specific backend call should be made to only check the email for these two scenarios.
 *
 * @see https://mathletics.atlassian.net/browse/FRAME-5322
 */
export const EMAIL_VALIDATION_ERRORS: Record<string, TextInputControlError> = {
    ACCOUNT_CONFLICT: {
        helperText: 'Parent account already exists',
        props: {
            isInvalid: false,
            isUserWarning: true
        }
    },
    EMAIL_CONFLICT: {
        helperText: 'Email already in use'
    }
};

// Handle the validation "onChange behaviour" with basic debounce and memo
// this minimizes all the api calls made when validation is running every keypress/change/blur.
const verifyEmailWhenNecessary = pDebounce<[FetcherOptions<CheckEmailQueryVariables>], CheckEmailQuery>(
    pMemoize(createStandaloneFetcher(), { cacheKey: JSON.stringify }) as any,
    1000
);

export function verifyEmailYupTest ({ url }: SchemaOptions) {
    return async function (this: TestContext, value: unknown) {
        if (typeof value !== 'string' || value.length === 0) {
            return false;
        }

        const response = await verifyEmailWhenNecessary({
            query: CheckEmailDocument,
            url,
            variables: {
                email: value
            }
        });

        if (
            response.validation &&
            !response.validation.isEmailAvailable
        ) {
            const { errorCode } = response.validation;
            const { EMAIL_CONFLICT, ACCOUNT_CONFLICT } = NOTICE_TYPES;

            if (errorCode === Status.InUseBy3PProductAccount) {
                return this.createError({
                    message: EMAIL_CONFLICT
                });
            }

            if (errorCode === Status.InUseBy3PParentAccount) {
                return this.createError({
                    message: ACCOUNT_CONFLICT
                });
            }

            // Add more errorCode matchers here, otherwise fallback to generic
            return false;
        } else {
            return true;
        }
    };
}
