import { Severity } from '@sentry/types';

import { useGetPlanDataQuery } from '../__generated__/gateway-graphql';
import { createQueryErrorHandler } from '../sentry/on-query-error';

type Options = {
    enabled?: boolean
    excludeTrials?: boolean
    currencyCode: string
};

export function usePlansDataQuery ({
    excludeTrials = false,
    currencyCode,
    enabled
}: Options) {
    return useGetPlanDataQuery({
        options: {
            // Cast to string as `enabled` flag check below will comply with string type requirement
            currencyCode,
            excludeTrials
        }
    }, {
        enabled,
        onError: createQueryErrorHandler({
            severity: Severity.Fatal
        })
    });
}
