import { t } from '@lingui/macro';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateQuoteMutation } from '../../../__generated__/gateway-graphql';
import type { ProductItemWithText } from '../../../api/gateway/onboarding-data-contract';
import { useTPrice } from '../../../i18n/t-price/use-t-price';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { subscriptionActions } from '../../../subscription-model/subscription.slice';

type Options = {
    product: ProductItemWithText
    childrenCount: number
    subscribeChosenRoute: string
    setRRPValue: (value: any) => any
    setShowSavings: (value: any) => any
};

export function usePricingCard ({
    product,
    childrenCount,
    subscribeChosenRoute,
    setRRPValue,
    setShowSavings
}: Options) {
    const tPrice = useTPrice();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { mutateAsync: createQuote } = useCreateQuoteMutation();
    const salesforce = useAppSelector(rootState => rootState.subscription.salesforce);
    const childrenCountText = childrenCount > 1 ?
        `${childrenCount} Children` :
        `${childrenCount} Child`;
    const { currencyCode, countryCode } = product;
    const { discountedAmount, rRP, savings } = product.priceList[childrenCount - 1];
    const basePrice = tPrice({
        amount: discountedAmount,
        countryCode,
        currencyCode
    });
    const rRPValue = tPrice({
        amount: rRP,
        countryCode,
        currencyCode
    });
    const savingsPrice = tPrice({
        amount: savings,
        countryCode,
        currencyCode
    });
    const showSavings = t({
        id: 'Save {savingsPrice}',
        values: {
            savingsPrice
        }
    });
    const onSubscribe = async () => {
        let quoteAndPricingData;

        if (!salesforce) {
            quoteAndPricingData = await createQuote({
                payment: {
                    quantity: childrenCount,
                    planId: product.productCode
                }
            });
        } else {
            quoteAndPricingData = await createQuote({
                payment: {
                    userId: salesforce.userId,
                    sessionId: salesforce.token,
                    quantity: childrenCount,
                    planId: product.productCode
                }
            });
        }

        if(!quoteAndPricingData.result?.productItem ||
            !quoteAndPricingData.result?.quoteId
        ) {
            throw new Error('create quote could not retrieve data');
        }

        dispatch(subscriptionActions.chooseSubscription({
            productCode: product.productCode,
            childrenCount
        }));

        dispatch(subscriptionActions.updatePricingData({
            productItem: quoteAndPricingData.result.productItem,
            quoteId: quoteAndPricingData.result.quoteId,
            coupon: null
        }));
        navigate(subscribeChosenRoute);
    };

    useEffect(() => {
        setRRPValue(rRPValue);
        setShowSavings(showSavings);
    }, [rRPValue, showSavings]);

    return {
        currencyCode,
        countryCode,
        heading: product.productHighlight,
        periodLabel: product.paymentDisplay,
        offerLabel: product.offer,
        basePrice,
        showSavings,
        childrenCountText,
        subscriptionType: product.subscriptionType,
        onSubscribe
    };
}
