// TODO implement when stories are ready, for now ignore coverage for this stub
/* istanbul ignore file */
import React from 'react';

export function LoginRoute () {
    return (
        <>
            <h2>Login</h2>
        </>
    );
}
