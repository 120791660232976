import { useMediaQuery, useTheme } from '@chakra-ui/react';
import React, { Suspense } from 'react';

/**
 * Load the svg components in a javascript webpack chunk.
 * This prevents large main bundles, using dynamic import().
 *
 * @see https://reactjs.org/docs/code-splitting.html#reactlazy
 */
const FormBackgroundStar = React.lazy(() => import(
    /* webpackChunkName: "form-background-star"*/
    './form-background-star'
));
const FormBackgroundBlue = React.lazy(() => import(
    /* webpackChunkName: "form-background-blue"*/
    './form-background-blue'
));

export function FormBackgrounds () {
    const theme = useTheme();
    const [shouldRenderBackgrounds] = useMediaQuery(
        `(min-width: ${theme.breakpoints.createAccountFieldRow})`
    );

    if (!shouldRenderBackgrounds) {
        return null;
    }

    return (
        // perhaps the skeleton looks bad here a big grey circle?
        <Suspense fallback={null}>
            <FormBackgroundStar />
            <FormBackgroundBlue />
        </Suspense>
    );
}
