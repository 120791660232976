import { useMediaQuery } from '@chakra-ui/react';
import React, { Suspense } from 'react';

/**
 * Load the svg components in a javascript webpack chunk.
 * This prevents large main bundles, using dynamic import().
 *
 * @see https://reactjs.org/docs/code-splitting.html#reactlazy
 */
const CheckoutSmallBackgroundStar = React.lazy(() => import(
    /* webpackChunkName: "star-background1"*/
    './assets/small-star-background'
));
const CheckoutSmallBackgroundBlue = React.lazy(() => import(
    /* webpackChunkName: "blue-background"*/
    './assets/small-blue-background'
));
const CheckoutBigBackgroundStar = React.lazy(() => import(
    /* webpackChunkName: "star-background1"*/
    './assets/big-star-background'
));
const CheckoutBackgroundBlue = React.lazy(() => import(
    /* webpackChunkName: "blue-background"*/
    './assets/big-blue-background'
));

export function CheckoutBackgrounds () {
    const [isMobileVersion] = useMediaQuery([
        '(max-width: 375px)'
    ]);

    if (isMobileVersion) {
        return null;
    }

    return (
        // perhaps the skeleton looks bad here a big grey circle?
        <Suspense fallback={null}>
            <CheckoutSmallBackgroundStar />
            <CheckoutSmallBackgroundBlue />
            <CheckoutBigBackgroundStar />
            <CheckoutBackgroundBlue />
        </Suspense>
    );
}
