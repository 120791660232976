import { t } from '@lingui/macro';
import React, { type PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useEnvConfig } from '../../../../env-config';
import { useScrollToTop } from '../../../../hooks';
import { useDefaultPlanQuery } from '../../../../hooks/use-default-plan-query';
import { PageCenter } from '../../../../page/page-content/page-content.styles';
import { PageHeading } from '../../../../page/page-heading/page-heading';
import { SubscriptionInclusions } from '../../../../subscription-inclusions';
import { Testimonial } from '../../../../testimonials/testimonial';
import { CreateAccountFormSkeleton } from './components/create-account-form/components/create-account-form-skeleton';
import { CreateAccountForm } from './components/create-account-form/create-account-form';
import { FormBackgrounds } from './components/form-backgrounds/form-backgrounds';
import { useCreateAccountData } from './hooks/use-create-account-data';

export function CreateAccountRoute () {
    const {
        isLoading,
        isCreateQuoteDataLoading,
        productItem
    } = useDefaultPlanQuery();
    const { ENABLE_MAINTENANCE_PAGE } = useEnvConfig();

    if (ENABLE_MAINTENANCE_PAGE) {
        return (
            <Navigate to={'/error/under-maintenance'} />
        );
    }
    /** Check is useQuery and useGetQuoteAndPricingData both have loaded and contains data */
    if (isLoading || isCreateQuoteDataLoading || !productItem) {
        return (
            <CreateAccountFormSkeleton />
        );
    }

    return (
        <CreateAccountView />
    );
}

export function CreateAccountView () {
    const {
        subtitleText,
        ribbonText,
        onBack
    } = useCreateAccountData();

    useScrollToTop();

    return (
        <div data-testid={'subscription-create-account-route'}>
            <PageCenterForm>
                <PageHeading
                    headingText={t`Create an account`}
                    subtitleText={subtitleText}
                    onBack={onBack}
                    ribbonText={ribbonText}
                />
            </PageCenterForm>

            <div data-testid={'background-create-account'}>
                <FormBackgrounds />
            </div>

            <PageCenterForm>
                <CreateAccountForm />
            </PageCenterForm>

            <SubscriptionInclusions disableBackground={true} />

            <PageCenter>
                <Testimonial />
            </PageCenter>
        </div>
    );
}

function PageCenterForm ({ children }: PropsWithChildren<unknown>) {
    return (
        <PageCenter
            innerBoxProps={{
                position: 'relative',
                width: {
                    createAccountPageTablet: 'calc(100% - 100px)',
                    mobile: '100%'
                }
            }}
            children={children}
        />
    );
}
