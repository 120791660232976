import React from 'react';

import { NoticePanel } from '../../components/notice-panel/notice-panel';
import { NOTICE_PANEL_TYPES, NOTICE_TYPES } from '../../components/notice-panel/notice-panel-types';

type SuccessNoticeProps = {
    couponDataDisplayText: string
};

export function SuccessNotice ({ couponDataDisplayText }: SuccessNoticeProps) {
    const { COUPON_SUCCESS } = NOTICE_TYPES;
    const notice = NOTICE_PANEL_TYPES(
        {
            type: COUPON_SUCCESS,
            couponDataDisplayText
        });

    return (
        <NoticePanel
            {...notice}
            wrapperProps={{
                // important needed for stack spacing override
                mb: '20px !important'
            }}
        />
    );
}
