import { t } from '@lingui/macro';
import React from 'react';

import { PageCenter } from '../../../../page/page-content/page-content.styles';
import { PageHeading } from '../../../../page/page-heading/page-heading';
import { PageCenterPricingCards } from '../../../../pricing/components/page-center-pricing-cards';
import {
    PricingSalesforceUrlContract
} from '../../../../pricing/components/use-pricing-url-location/pricing-salesforce-url-contract';
import { SubscriptionInclusions } from '../../../../subscription-inclusions';
import { Testimonial } from '../../../../testimonials/testimonial';
import { ErrorBoundary } from '../../../error/errors/error-boundary';

export function AddSelectRoute () {
    return (
        <ErrorBoundary context={'/subscription/add/select'}>
            <div data-testid={'subscription-renew-select-route'}>
                <PageCenterPricingCards>
                    <PageHeading
                        isSelectPage={true}
                        headingText={t`Select Subscription`}
                    />

                    <PricingSalesforceUrlContract
                        subscribeChosenRoute={'/subscription/add/checkout'}
                    />
                </PageCenterPricingCards>

                <SubscriptionInclusions
                    marginBottom={'80px !important'}
                />

                <PageCenter>
                    <Testimonial />
                </PageCenter>
            </div>
        </ErrorBoundary>
    );
}
