import { RippleButton } from '@3plearning/chakra-mathletics-theme';
import {
    Box,
    Center,
    Flex
} from '@chakra-ui/react';
import React from 'react';

import { FreeTrialBackground } from './components/free-trial-background';
import { FreeTrialInfoSkeleton } from './components/free-trial-info-skeleton';
import { StepsAnim } from './components/steps-anim';
import {
    FreeTrialInfoContainer,
    FreeTrialText
} from './free-trial-info.styled';
import { useFreeTrialInfo } from './use-free-trial-info';

export function FreeTrialInfo () {
    const { isLoading, buttonText, onStartFreeTrial } = useFreeTrialInfo();

    return (
        <Center
            mx={{
                mobile: '24px',
                tablet: '50px'
            }}
        >
            {isLoading || !buttonText ?
                <FreeTrialInfoSkeleton /> :
                <FreeTrialInfoView
                    onStartFreeTrial={onStartFreeTrial}
                    buttonText={buttonText}
                />
            }
        </Center>
    );
}

type Props = {
    onStartFreeTrial: () => any
    buttonText: string
};

function FreeTrialInfoView ({ onStartFreeTrial, buttonText }: Props) {
    return (
        <Box position={'relative'} w={'100%'} maxWidth={'816px'}>
            <FreeTrialBackground />
            <FreeTrialInfoContainer data-testid={'free-trial-info'}>
                <Flex
                    mt={{
                        mobile: '250px',
                        freeTrialInfoColumnMobile: '330px',
                        freeTrialInfoColumn: 0
                    }}
                    alignItems={{
                        mobile: 'center'
                    }}
                    justifyContent={{
                        mobile: 'center',
                        freeTrialInfoColumn: 'left'
                    }}
                >
                    <FreeTrialText>
                        <div>
                            Take the next step<br />to your child&apos;s<br />success
                        </div>
                        <RippleButton
                            data-testid={'start-free-trial-button'}
                            sx={{
                                fontSize: '18px',
                                mt: {
                                    mobile: '32px',
                                    tablet: '48px'
                                }
                            }}
                            onClick={onStartFreeTrial}
                        >
                            {buttonText}
                        </RippleButton>
                    </FreeTrialText>
                </Flex>
                <Box
                    pos={'absolute'}
                    right={'0'}
                    width={'480px'}
                    maxWidth={'100%'}
                >
                    <StepsAnim />
                </Box>
            </FreeTrialInfoContainer>
        </Box>
    );
}
