import { Box, useRadioGroup, type UseRadioGroupProps } from '@chakra-ui/react';
import { Severity } from '@sentry/types';
import { useField } from 'formik';
import React, { type MutableRefObject, useState } from 'react';
import { Navigate } from 'react-router-dom';

import {
    type PaymentSource,
    useGetPaymentSourcesQuery
} from '../../__generated__/gateway-graphql';
import type { ChargebeeInstance } from '../../chargebee/chargebee.types';
import { ErrorBoundary } from '../../routes/error/errors/error-boundary';
import { createQueryErrorHandler } from '../../sentry/on-query-error';
import type { SalesforceURLData } from '../../subscription-model/subscription.types';
import { SalesforceDataRenderGate } from '../__utils__/salesforce-data-render-gate';
import { NEW_CARD_PAYMENT_SOURCE_ID, NewCard } from './components/new-card/new-card';
import { PaymentSourcesSkeleton } from './components/payment-sources-skeleton';
import { SavedCard } from './components/saved-card/saved-card';
import { SavedCardsRadioButton } from './components/saved-cards-radio-button';

type PaymentSourcesProps = {
    cardRef: MutableRefObject<ChargebeeInstance>
};

export function PaymentSources ({ cardRef }: PaymentSourcesProps) {
    return (
        <ErrorBoundary context={'PaymentSources'}>
            <SalesforceDataRenderGate
                render={
                    (salesforce) => (
                        <PaymentSourcesContainer
                            {...salesforce}
                            cardRef={cardRef}
                        />
                    )
                }
            />
        </ErrorBoundary>
    );
}

export function PaymentSourcesContainer ({ userId, token, cardRef }: SalesforceURLData & PaymentSourcesProps) {
    const {
        data,
        isLoading,
        isError
    } = useGetPaymentSourcesQuery({
        userId,
        token
    }, {
        onError: createQueryErrorHandler({
            severity: Severity.Fatal
        })
    });
    // the graphql schema gives an undefined array for no reason hence the cast
    const paymentSources: Array<PaymentSource> = Array.isArray(data?.paymentSources) ?
        data?.paymentSources : [] as any;

    if (isLoading) {
        return (
            <PaymentSourcesSkeleton />
        );
    }

    if (isError) {
        return <Navigate to={'/error'} />;
    }

    return (
        <PaymentSourcesView
            cardRef={cardRef}
            paymentSources={paymentSources}
        />
    );
}

type PaymentSourcesViewProps = {
    paymentSources: Array<PaymentSource>
    cardRef: MutableRefObject<ChargebeeInstance>
};

export function PaymentSourcesView ({ paymentSources, cardRef }: PaymentSourcesViewProps) {
    const {
        radioGroup: { getRadioProps, setValue }, setIsNewCard, isNewCard
    } = usePaymentSourcesCheckbox({
        options: {
            // TODO discuss default value an assumption here was made to use
            // the first one, however there could be expired cards (needs extra handling why should you be allowed to click one)
            // seems to make more sense to force the user to make a choice.
            defaultValue: undefined
        },
        paymentSources
    });

    return (
        <Box data-testid={'payment-sources-container'} width={'100%'}>
            {paymentSources.map((paymentSource, index) => {
                return (
                    <SavedCardsRadioButton
                        key={paymentSource.id}
                        index={index}
                        status={paymentSource.status}
                        {...getRadioProps({
                            value: paymentSource.id
                        })}
                    >
                        <SavedCard {...paymentSource} />
                    </SavedCardsRadioButton>
                );
            })}

            <NewCard
                setIsNewCard={setIsNewCard}
                isNewCard={isNewCard}
                cardRef={cardRef}
                setValue={setValue}
                getRadioProps={getRadioProps}
            />
        </Box>
    );
}

type Props = {
    options: UseRadioGroupProps
    paymentSources: Array<PaymentSource>
};

function usePaymentSourcesCheckbox ({ options, paymentSources }: Props) {
    const [isNewCard, setIsNewCard] = useState(paymentSources.length === 0);
    const [current, __, { setValue }] = useField('customerPaymentSource');
    const radioGroup = useRadioGroup({
        ...options,
        onChange: handleChange
    });

    function handleChange (paymentSourceId: string) {
        if (current.value === NEW_CARD_PAYMENT_SOURCE_ID) {
            setIsNewCard(false);
        }
        setValue(paymentSourceId);
    }

    return {
        radioGroup,
        setIsNewCard,
        isNewCard
    };
}
