import { Box, Image } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React from 'react';

import { campaignTheme } from '../../../../styles/theme/foundations/colors';
import yellowRibbonSrc from '../../../assets/Free trial.svg';

type SweetenerLabelProps = {
    isTrial: boolean
    isCampaignActive?: boolean
    offerLabel: string
    subscriptionType: string
};

export function SweetenerLabel ({ isTrial, offerLabel, subscriptionType, isCampaignActive }: SweetenerLabelProps){
    if(isTrial) {
        // show the ribbon
        return (
            <>
                <Image
                    position={'absolute'}
                    maxWidth={'unset'}
                    width={{
                        mobile: '356px',
                        pricingTablet: '311px'
                    }}
                    alt={offerLabel}
                    src={yellowRibbonSrc}
                />

                <Box
                    as={'div'}
                    fontSize={'16px'}
                    lineHeight={'24px'}
                    fontWeight={'medium'}
                    color={'neutral.900'}
                    zIndex={0}
                >
                    {offerLabel}
                </Box>
            </>
        );
    }

    if (subscriptionType === 'Monthly') {
        // show yellow banner
        return (
            <Box
                as={'div'}
                fontSize={'16px'}
                lineHeight={'24px'}
                fontWeight={'medium'}
                color={'neutral.900'}
                zIndex={0}
            >
                {t`Renews Monthly`}
            </Box>
        );
    }

    // show blue banner
    return (
        <Box
            as={'div'}
            fontSize={'16px'}
            letterSpacing={'0.15px'}
            lineHeight={'24px'}
            fontWeight={'medium'}
            color={isCampaignActive ? campaignTheme.tertiary : 'neutral.900'}
            zIndex={0}
        >
            {offerLabel}<span>*</span>
        </Box>
    );
}
