/**
 * These breakpoints are specific to this application's components.
 * They are prefixed to the feature that uses them.
 *
 * @see for usage in props and hooks https://chakra-ui.com/docs/styled-system/features/responsive-styles
 *
 */
export const breakpoints = {
    subscriptionInclusionsTablet: '656px',
    createAccountFieldRow: '690px',
    testimonialsTablet: '638px',
    pricingTablet: '768px',
    footerTablet: '680px',
    footerDesktop: '1264px',
    pageHeaderTablet: '690px',
    addChildrenTabsTablet: '496px',
    fieldRowTablet: '607px',
    freeTrialInfoColumn: '880px',
    freeTrialInfoColumnMobile: '520px',
    modalCardTablet: '546px',
    checkoutFooterTablet: '580px',
    createAccountPageTablet: '446px'
};
