import {
    chakra,
    Box,
    type SystemStyleObject
} from '@chakra-ui/react';

export const FontStyles: SystemStyleObject = {
    fontSize: {
        mobile: '12px',
        footerTablet: '13px',
        footerDesktop: '16px'
    }
};

export const FooterContainer = chakra(Box, {
    label: 'FooterContainer',
    baseStyle: {
        height: {
            mobile: '142px',
            footerTablet: '80px',
            desktop: '90px'
        },
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: {
            mobile: 'center',
            tablet: 'unset'
        },
        mt: {
            mobile: '40px',
            footerTablet: '72px'
        },
        px: {
            mobile: 0,
            footerTablet: '50px'
        },
        borderTopWidth: { footerTablet: '1px' },
        borderTopColor: { footerTablet: 'neutral.100' }
    }
});

export const LinksAndImagesSection = chakra(Box, {
    label: 'LinksAndImages',
    baseStyle: {
        display: 'flex',
        width: '100%',
        flexDirection: {
            mobile: 'column',
            footerTablet: 'row'
        },
        justifyContent: 'space-between',
        alignItems: {
            mobile: 'center',
            footerTablet: 'baseline'
        },
        pt: {
            footerTablet: '15px',
            footerDesktop: '24px'
        },
        borderBottomWidth: {
            mobile: '1px',
            footerTablet: 0
        },
        borderBottomColor: 'neutral.100',
        borderBottomStyle: 'solid'
    }
});

export const MenuSection = chakra(Box, {
    label: 'MenuSection',
    baseStyle: {
        ...FontStyles,
        display: 'flex',
        justifyContent: {
            mobile: 'space-evenly',
            footerTablet: 'flex-start'
        },
        fontWeight: '500',
        lineHeight: '26px',
        paddingTop: {
            mobile: '4px',
            footerTablet: 0
        },
        paddingBottom: {
            mobile: '4px',
            footerTablet: 0
        },
        w: {
            base: '100%',
            footerTablet: 'auto'
        },
        borderBottomWidth: {
            mobile: '1px',
            footerTablet: 0
        },
        borderBottomColor: 'neutral.100',
        borderBottomStyle: 'solid'
    }
});

export const LinkStyles: SystemStyleObject = {
    ...FontStyles,
    textDecoration: 'none',
    color: 'neutral.700',
    marginRight: {
        base: 0,
        footerTablet: '12px',
        footerDesktop: '24px'
    }
};

export const SocialMediaContainer = chakra(Box, {
    label: 'SocialMedia',
    baseStyle: {
        display: 'flex',
        justifyContent: 'center',
        pt: {
            mobile: '14px',
            footerTablet: 0
        },
        pb: {
            mobile: '16px',
            footerTablet: 0
        }
    }
});

export const TextStyles: SystemStyleObject = {
    ...FontStyles,
    fontWeight: '400',
    lineHeight: '26px',
    mr: {
        mobile: '8px',
        footerTablet: '6px',
        footerDesktop: '12px'
    }
};

export const LogoStyles: SystemStyleObject = {
    position: 'relative',
    width: '24px',
    height: '24px',
    cursor: 'pointer'
};

export const CopyrightsContainer = chakra(Box, {
    label: 'Copyrights',
    baseStyle: {
        color: 'neutral.600',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: {
            mobile: '26px',
            footerTablet: '18px'
        },
        display: 'flex',
        justifyContent: {
            mobile: 'center',
            footerTablet: 'start'
        },
        pt: {
            mobile: '16px',
            footerTablet: '12px'
        },
        pb: '8px'
    }
});
