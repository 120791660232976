/* istanbul ignore file */
/* eslint-disable @typescript-eslint/naming-convention */
import { useMediaQuery, useTheme } from '@chakra-ui/react';
import type { SystemStyleObject } from '@chakra-ui/styled-system';

import type { ValidationState, ChargebeeFields, ErrorMessagesState } from './use-credit-card-field.types';

const cardComponentClasses = {
    focus: 'focus',
    invalid: 'invalid',
    empty: 'empty',
    complete: 'complete'
};

type Options = {
    focus: ChargebeeFields | null
    isLoading: boolean
    validationState: ValidationState
    formFieldError: ErrorMessagesState
    wrapperStyles?: SystemStyleObject
};

/**
 * Reuse and extend the existing input style
 * for the custom Chargebee iframe hosted field.
 */
export function useChargebeeFieldStyles ({
    focus, formFieldError, validationState, isLoading, wrapperStyles = { }
}: Options) {
    const theme = useTheme();
    const isRowStackMode = useCheckoutFormRowStackMode();
    const cardComponentStyles = {
        base: {
            color: theme.colors.neutral[900],
            fontFamily: 'Poppins, Arial',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '16px',
            iconColor: theme.colors.neutral[400]
        },
        invalid: {
            color: theme.colors.neutral[900]
        }
    };
    const errorLabelStyles = {
        color: theme.colors.error[700],
        h: '36px',
        display: 'flex',
        alignItems: 'center',
        ...!isRowStackMode && { paddingLeft: '12px' } || {}
    };
    // as any unable to determine better way to type this:
    const commonModeStyles = {
        display: 'flex',
        w: '100%'
    };
    const showRowError = !validationState.isAllValid ||
        typeof formFieldError.combinedMessage === 'string';
    const cardComponentWrapperStyles = {
        'width': '100%',
        'mb': {
            mobile: 0,
            desktop: '24px !important'
        },
        ...isRowStackMode && {
            height: '56px'
        } || {
            height: '242px'
        },
        '.ex1-input': {
            position: 'relative',
            h: '56px',
            w: '100%',
            display: 'flex',
            alignItems: 'center',
            // white space for the card type icon
            pr: '10px'
        },
        '.expiry': {
            maxW: '100px'
        },
        '.cvv': {
            maxW: '100px'
        },
        '.error': {
            boxShadow: `inset 0 0 0 1px ${theme.colors.error[700]} !important`
        },
        '.error-helper-text': {
            height: {
                mobile: '24px',
                tablet: '28px',
                desktop: '34px'
            },
            fontSize: {
                mobile: '12px',
                tablet: '14px',
                desktop: '16px'
            }
        },
        ...isRowStackMode ? {
            ...createIframeInputStyles('&', theme, isLoading),
            'h': '56px',
            '.fieldset': {
                ...commonModeStyles,
                flexDirection: 'row'
            },
            ...(focus && !showRowError) && {
                boxShadow: `inset 0 0 0 2px ${theme.colors.primary[500]} !important`
            },
            ...(showRowError) && {
                boxShadow: `inset 0 0 0 1px ${theme.colors.error[700]} !important`
            }
        } : {
            ...createIframeInputStyles('.card', theme, isLoading),
            ...createIframeInputStyles('.expiry', theme, isLoading),
            ...createIframeInputStyles('.cvv', theme, isLoading),
            '.fieldset': {
                ...commonModeStyles,
                flexDirection: 'column'
            },
            ['.invalid']: {
                borderColor: `${theme.colors.error[700]} !important`,
                borderWidth: '1px'
            }
        },
        ...wrapperStyles
    };

    return {
        cardComponentClasses,
        cardComponentStyles,
        cardComponentWrapperStyles,
        errorLabelStyles
    };
}

/**
 * Workaround for styling individual chargebee fields goes through the iframe
 * style abstraction.Regular charka-ui / system-ui prop styling is not possible.
 * There are some limitations such as `box-shadow` not supported which
 * is what the core theme uses. This uses border color instead.
 */
function createIframeInputStyles (className: string, theme: any, isLoading: boolean) {
    if (isLoading) {
        return {};
    }

    return {
        [className]: {
            padding: '1px',
            paddingLeft: '12px',
            paddingRight: '12px',
            boxShadow: `inset 0 0 0 1px ${theme.colors.neutral[300]}`,
            borderRadius: '12px',
            color: theme.colors.neutral[900]
        },
        [`${className}:hover:not(.invalid)`]: {
            paddingLeft: '12px',
            paddingRight: '12px',
            boxShadow: `inset 0 0 0 1px ${theme.colors.neutral[600]}`
        },
        [`${className}.focus:not(.invalid)`]: {
            boxShadow: `inset 0 0 0 2px ${theme.colors.primary[500]} !important`
        }
    };
}

export function useCheckoutFormRowStackMode () {
    // This also needs to include the size of the inline
    // chargebee icon right of the card number
    // eg the one that shows visa or invalid card icon.
    const [isRowStackMode] = useMediaQuery('(min-width: 600px)');

    return isRowStackMode;
}
