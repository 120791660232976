import { Box, BoxProps } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import activities_aligned from './assets/activities_aligned.svg';
import live_competitions from './assets/icon_live_competitions.svg';
import printable_resource from './assets/printable_resource.svg';
import weekly_reports from './assets/weekly_reports.svg';
import { BlueCircularBackground } from './components/blue-circular-background';
import {
    SubscriptionCard
} from './components/subscription-card';
import {
    CardWrapper,
    HeaderText
} from './subscription-inclusions.styled';

const subscriptionList = [
    {
        imgSrc: activities_aligned,
        altName: 'Activities aligned',
        subscriptionText: 'Activities aligned to your local curriculum'
    },
    {
        imgSrc: printable_resource,
        altName: 'Printable Resources',
        subscriptionText: 'Access to hundreds of printable resources'
    },
    {
        imgSrc: weekly_reports,
        altName: 'Weekly Reports',
        subscriptionText: 'Weekly reports emailed to your inbox'
    },
    {
        imgSrc: live_competitions,
        altName: 'Live Competitions',
        subscriptionText: 'Access to live competitions and games'
    }
];

type Props = PropsWithChildren<{
    disableBackground?: boolean
}> & BoxProps;

export function SubscriptionInclusions ({ disableBackground = false, children, ...restProps }: Props) {
    return (
        <Box
            data-testid={'subscription-inclusions'}
            position={'relative'}
            display={'flex'}
            flexDirection={'column'}
            flexWrap={'nowrap'}
            alignItems={'center'}
            justifyContent={'center'}
            bg={{
                mobile: 'neutral.50',
                subscriptionInclusionsTablet: 'transparent'
            }}
            border={{
                mobile: '6px solid white',
                subscriptionInclusionsTablet: 0
            }}
            borderRadius={{
                mobile: '20px',
                subscriptionInclusionsTablet: 'none'
            }}
            m={{
                mobile: '5px',
                subscriptionInclusionsTablet: '72px 0 0 0'
            }}
            p={{
                mobile: '15px',
                subscriptionInclusionsTablet: 0
            }}
            {...restProps}
        >
            <HeaderText
                data-testid={'subscription-header-text'}
                fontSize={{
                    mobile: '24px',
                    tablet: '22px',
                    desktop: '24px'
                }}
                fontWeight={'medium'}
                color={'neutral.700'}
                lineHeight={{
                    tablet: '29px',
                    desktop: '32px'
                }}
                mb={'12px'}
            >
                All Mathletics subscriptions include:
            </HeaderText>

            <CardWrapper>
                {disableBackground ? null : <BlueCircularBackground />}

                {subscriptionList.map((item, index) => {
                    return (
                        <SubscriptionCard
                            key={index}
                            {...item}
                        />
                    );
                })}
            </CardWrapper>

            {children}
        </Box>
    );
}
