import {
    Center,
    chakra, Stack
} from '@chakra-ui/react';

import { StyleVars } from '../../style-vars';

export const NumSubscriptionCounterHolder = chakra(Stack, {
    baseStyle: {
        width: {
            mobile: StyleVars.pricingCardMobileWidth,
            pricingTablet: '392px'
        },
        margin: '16px 0'
    }
});

export const CounterLabel = chakra(Center, {
    label: 'counter-label',
    baseStyle: {
        fontSize: '16px',
        lineHeight: '24px',
        color: 'neutral.700',
        fontWeight: 500,
        bottomMargin: '8px'
    }
});

