export const createRouteSteps = [
    {
        label: 'Create account',
        path: '/subscription/create/create-account'
    },
    // eslint-disable-next-line etc/no-commented-out-code
    // TODO : this is required for phase 2. Uncomment when needed
    // {
    //     label: 'Add children',
    //     path: '/subscription/create/add-children'
    // },
    {
        label: 'Finish setup',
        path: '/subscription/create/checkout'
    }
];

export const addRouteSteps = [
    {
        label: 'Select Subscription',
        path: '/subscription/add/select'
    },
    // eslint-disable-next-line etc/no-commented-out-code
    // TODO : this is required for phase 2. Uncomment when needed
    // {
    //     label: 'Add children',
    //     path: '/subscription/add/add-children'
    // },
    {
        label: 'Finish setup',
        path: '/subscription/add/checkout'
    }
];

export const renewRouteSteps = [
    {
        label: 'Select Subscription',
        path: '/subscription/renew/select'
    },
    // eslint-disable-next-line etc/no-commented-out-code
    // TODO : this is required for phase 2. Uncomment when needed
    // {
    //     label: 'Select children',
    //     path: '/subscription/renew/select-children'
    // },
    {
        label: 'Finish setup',
        path: '/subscription/renew/checkout'
    }
];

export const upgradeRouteSteps = [
    {
        label: 'Select children',
        path: '/subscription/upgrade/select'
    },
    {
        label: 'Finish setup',
        path: '/subscription/upgrade/checkout'
    }
];
