import React from 'react';

import { FAQ } from '../../../../faq/faq';
import { FreeTrialInfo } from '../../../../free-trial/free-trial-info/free-trial-info';
import { FreeTrialSwish } from '../../../../free-trial/free-trial-swish/free-trial-swish';
import { PageCenter } from '../../../../page/page-content/page-content.styles';
import { PageHeading } from '../../../../page/page-heading/page-heading';
import { PageCenterPricingCards } from '../../../../pricing/components/page-center-pricing-cards';
import { PricingIpLocation } from '../../../../pricing/components/pricing-ip-location/pricing-ip-location';
import { SubscriptionInclusions } from '../../../../subscription-inclusions';
import { Testimonial } from '../../../../testimonials/testimonial';
import { ErrorBoundary } from '../../../error/errors/error-boundary';
import { SchoolSubscription } from './components/school-subscription/school-subscription';

export function SelectRoute () {
    return (
        <ErrorBoundary context={'/subscription/create/select'}>
            <div data-testid={'subscription-create-select-route'}>
                <PageCenterPricingCards>
                    <PageHeading
                        isSelectPage={true}
                        headingText={'Home Subscription Pricing'}
                        subtitleText={'Loved by over 2 million students worldwide. Subscribe today and bring a love of learning home.'}
                    />

                    <PricingIpLocation
                        subscribeChosenRoute={'/subscription/create/create-account'}
                    />
                </PageCenterPricingCards>

                <SubscriptionInclusions>
                    <FreeTrialSwish />
                </SubscriptionInclusions>

                <PageCenter>
                    <Testimonial />

                    <SchoolSubscription />

                    <FAQ />
                </PageCenter>

                <FreeTrialInfo />
            </div>
        </ErrorBoundary>
    );
}
