import {
    Center, chakra, Flex
} from '@chakra-ui/react';

import { Card } from '../styles/component.styles';
import { StyleVars } from './style-vars';

export const PricingCardHolderStyled = chakra(Card, {
    label: 'pricing-cards-holder',
    baseStyle: {
        marginBottom: '16px',
        width: {
            mobile: '100vw',
            pricingTablet: '100%'
        }
    }
});

export const PricingCards = chakra(Flex, {
    label: 'pricing-cards',
    baseStyle: {
        justifyContent: 'space-between',
        height: 'max-content',
        width: {
            mobile: StyleVars.pricingCardMobileWidth,
            pricingTablet: StyleVars.pricingCardsWidth
        },
        flexDirection: {
            mobile: 'column',
            pricingTablet: 'row'
        }
    }
});

export const CardHeading = chakra(Center, {
    label: 'CardHeading',
    baseStyle: {
        background: 'primary.50',
        border: '6px solid white',
        boxSizing: 'border-box',
        width: '100%',
        height: '64px',
        borderRadius: '20px 20px 0 0',
        display: {
            pricingTablet: 'flex',
            mobile: 'none'
        }
    }
});

export const SaveLabel = chakra('div', {
    label: 'save-label',
    baseStyle: {
        span: { fontWeight: 700 },
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        margin: {
            mobile: '12px',
            pricingTablet: '0'
        }
    }
});

export const MobileSaveLabel = chakra('div', {
    label: 'MobileSaveLabel',
    baseStyle: {
        background: 'primary.50',
        border: '6px solid white',
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: '0 0 20px 20px',
        textAlign: 'center',
        display: {
            pricingTablet: 'none',
            mobile: 'block'
        }
    }
});

export const FootLabel = chakra('div', {
    label: 'foot-label',
    baseStyle: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
        mt: '20px',
        marginBottom: {
            pricingTablet: '40px',
            mobile: '24px'
        },
        width: {
            pricingTablet: 'auto',
            mobile: '320px' },
        textAlign: {
            pricingTablet: 'left',
            mobile: 'center' },
        color: 'neutral.700'
    }
});

export const Note = chakra('div', {
    label: 'note',
    baseStyle: {
        fontSize: '14px',
        lineHeight: '143%',
        fontWeight: 400,
        marginTop: {
            pricingTablet: '16px',
            mobile: '10px'
        },
        width: {
            mobile: StyleVars.pricingCardMobileWidth,
            pricingTablet: 'auto'
        },
        letterSpacing: '-0.15px',
        color: ' #586C81'
    }
});
