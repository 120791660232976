import React from 'react';

import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import { Body } from '../page/page-body/page-body';
import { AppRoutes } from '../routes/app-routes';
import { ErrorBoundary } from '../routes/error/errors/error-boundary';
import { AppProviders } from './providers/app-providers';

/**
 * The main app composition used in production.
 */
function App () {
    return (
        <AppProviders>
            <ErrorBoundary context={'App'}>
                <AppView />
            </ErrorBoundary>
        </AppProviders>
    );
}

/**
 * Components here will show on every route.
 */
export function AppView () {
    return (
        <>
            <Header />

            <Body>
                <ErrorBoundary context={'AppView'}>
                    <AppRoutes />
                </ErrorBoundary>
            </Body>

            <Footer />
        </>
    );
}

export default App;
