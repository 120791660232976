import {
    AccordionButton,
    AccordionPanel,
    Box,
    Text
} from '@chakra-ui/react';
import React from 'react';

export const ContentWrapper = ({ ...restProps }) => (
    <Box
        {...restProps}
        sx={{
            mt: {
                mobile: '40px',
                tablet: '80px',
                desktop: '112px'
            },
            mb: '24px',
            w: '100%',
            minHeight: '800px',
            maxWidth: '890px',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'secondary.0'
        }}
    />
);

export const FAQText = ({ ...restProps }) => (
    <Text
        {...restProps}
        sx={{
            width: '100%',
            py: '22px',
            fontSize: {
                mobile: '16px',
                tablet: '18px',
                desktop: '20px'
            },
            lineHeight: {
                tablet: '160%',
                desktop: '145%'
            },
            color: 'neutral.900'
        }}
    />
);

export const FAQContainer = ({ ...restProps }) => (
    <Box
        {...restProps}
        sx={{
            mt: {
                mobile: '40px',
                tablet: '80px',
                desktop: '112px'
            }
        }}
    />
);

export const FAQTitle = ({ ...restProps }) => (
    <Text
        {...restProps}
        sx={{
            fontWeight: '500',
            fontSize: {
                mobile: '26px',
                tablet: '30px'
            },
            lineHeight: {
                mobile: '32px',
                tablet: '45px',
                desktop: '37px'
            },
            w: '100%',
            pb: {
                mobile: '16px',
                tablet: '26px',
                desktop: '33px'
            },
            color: 'neutral.900'
        }}
    />
);

export const FAQAccordionButton = ({ ...restProps }) => (
    <AccordionButton
        {...restProps}
        sx={{
            py: '22px',
            fontWeight: 'medium',
            fontSize: {
                mobile: '16px',
                tablet: '18px',
                desktop: '20px'
            },
            lineHeight: {
                tablet: '160%',
                desktop: '145%'
            },
            letterSpacing: '0.15px',
            _hover: {
                backgroundColor: 'transparent'
            }
        }}
    />
);

export const FAQAccordionPanel = ({ ...restProps }) => (
    <AccordionPanel
        {...restProps}
        sx={{
            color: 'neutral.700',
            pb: 4,
            fontWeight: '400',
            fontSize: {
                mobile: '14px',
                tablet: '16px'
            },
            lineHeight: {
                mobile: '20px',
                tablet: '24px'
            },
            letterSpacing: '-0.15px'
        }}
    />
);
