export function initGlobals () {
    // This for backend to debug their Chargebee backend code locally,
    // FE is because the only way to authorise a payment intent,
    // this just exits early on checkout and instead of creating a subscription
    // To use it just open a console and set `__OVERRIDE_INTENT__ = true` and
    // watch the logs ono checkout purchase.
    // @ts-ignore
    window.__OVERRIDE_INTENT__ = null;

    // This will log the current window location search params for debugging
    // the salesforce url contract
    console.log('Params are', window.location.search);
}
