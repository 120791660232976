// no value in covering this
/* istanbul ignore file */
// Strict mode has known issues with libraries like Chargebee,
// It is a useful tool so this is a convenience to disable it with CRA without
// code changes from your local `.env` file.
//
// Just add REACT_APP_DISABLE_STRICT=true and rebuild.
import React, { PropsWithChildren } from 'react';

export function ReactStrictMode ({ children }: PropsWithChildren<any>) {
    if (process.env.REACT_APP_DISABLE_STRICT === 'true') {
        return children;
    }

    return (
        <React.StrictMode>
            {children}
        </React.StrictMode>
    );
}
