/* istanbul ignore file */
// Mocking Chargebee global is non-trivial
import { useMemo } from 'react';

import { useEnvConfig } from '../env-config';
import { ChargebeeInstance } from './chargebee.types';

/**
 * Main method to initialize the Chargebee instance.
 * Uses relevant env from public config.
 *
 * @see https://www.chargebee.com/checkout-portal-docs/components-fields-integrations.html#card-components.
 */
export function useChargebeeInit (): ChargebeeInstance | null {
    const { CHARGEBEE_PUBLISHABLE_KEY, CHARGEBEE_SITE } = useEnvConfig();
    const instance = useMemo(() => {
        const options = {
            site: CHARGEBEE_SITE,
            publishableKey: CHARGEBEE_PUBLISHABLE_KEY
        };

        // Only create a chargebee instance if one was not already created,
        // we only want this initialized on particular pages and
        // to support changes in the page when using this hook,
        // ensure only one is initialized.
        // @see the expected error https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-object
        try {
            return window.Chargebee?.getInstance(options);
        } catch (error) {
            return window.Chargebee?.init(options);
        }
    }, [CHARGEBEE_PUBLISHABLE_KEY, CHARGEBEE_SITE]);

    if (!instance) {
        return null;
    }

    // @ts-ignore
    window.__chargebee_init__ = instance;

    return instance;
}
