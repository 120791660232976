import { t } from '@lingui/macro';

import { CreateCustomerSubscriptionInput } from '../../__generated__/gateway-graphql';
import { assert } from '../../__helpers__';
import { USER_ATTEMPT_MAX_LIMIT_REACHED } from '../../__utils__/utils';
import { authorizeWith3dsIntent } from '../__utils__/authorize-with-3ds-intent';
import { createGatewayPaymentIntent } from '../__utils__/create-gateway-payment-intent';
import { assertExistingCustomerPaymentIntent } from '../__utils__/validation';
import { CreateExistingCustomerSubscriptionOptions } from './create-existing-customer-subscription';

type CreateCustomerPaymentSourceSubscriptionInputProps = CreateExistingCustomerSubscriptionOptions & {
    quoteId: string
};

export async function createCustomerPaymentSourceSubscriptionInput ({
    createPaymentIntentFromQuote, subscription, contactUsUrl,
    formValues, salesforce, chargebeeInstance, quoteId, toast
}: CreateCustomerPaymentSourceSubscriptionInputProps): Promise<CreateCustomerSubscriptionInput | null> {
    const paymentSourceId = formValues.customerPaymentSource;
    const userMaxAttemptError = t({
        id: 'Error - User - Max - Attempt',
        values: { contactUsUrl }
    });

    assert(
        typeof paymentSourceId === 'string',
        'A valid payment source id must be present'
    );

    const payment = {
        // Add the required data for a using a payment source,
        // note this is optional in graphql schema and things are named differently
        // and sessionId -> token.
        quoteId,
        sessionId: salesforce.token,
        userId: salesforce.userId,
        paymentSourceId
    };

    assertExistingCustomerPaymentIntent(payment);

    if (!subscription.quoteAndPricingData) {
        throw new Error('Unable to get quote Id from store');
    }

    console.log('CreatePaymentIntentInput', payment);

    const { paymentIntent, errorMessage } = await createGatewayPaymentIntent({
        createPaymentIntentFromQuote,
        input: payment
    });

    if(errorMessage !== USER_ATTEMPT_MAX_LIMIT_REACHED && paymentIntent !== null) {
        const authorizedPaymentIntent = await authorizeWith3dsIntent({
            paymentIntent,
            chargebeeInstance
        });

        return {
            payment: {
                intentId: authorizedPaymentIntent.id,
                quoteId: subscription.quoteAndPricingData.quoteId,
                paymentSourceId,
                userId: salesforce.userId
            }
        };
    } else {
        toast({
            description: userMaxAttemptError,
            status: 'error',
            duration: 9000
        });

        return null;
    }
}
