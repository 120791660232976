import { useStoredPlans, UseStoredPlansProps } from '../../plans/use-stored-plans';
import { useAppSelector } from '../../store/store';
import { CurrentSubscriptionData } from './use-selected-product';

type Options = {
    // Used to override children count in a form
    childrenCount?: number | null
};

export function useCurrentSubscriptionIpQuery ({ childrenCount }: Options = { childrenCount: null }) {
    const selectedSubscription = useAppSelector(state => state.subscription);

    childrenCount = typeof childrenCount === 'number' ? childrenCount : selectedSubscription.childrenCount;

    const query = useStoredPlans(<UseStoredPlansProps>{
        excludeTrials: true
    });

    let currentSubscriptionIPData: CurrentSubscriptionData | null = null;

    const { productItem } = query;

    if (
        typeof selectedSubscription.productCode === 'string'
    ) {
        if (!productItem) {
            return {
                ...query,
                data: currentSubscriptionIPData
            };
        }

        const [currentPrice] = productItem.priceList;

        if (!currentPrice) {
            throw new Error('Unable to determine price for children count ' + childrenCount);
        }

        currentSubscriptionIPData = {
            currentPrice,
            subscription: selectedSubscription,
            productItem
        };
    }

    return {
        ...query,
        data: currentSubscriptionIPData
    };
}
