import { useMathleticsToast } from '@3plearning/chakra-mathletics-theme';
import { t } from '@lingui/macro';
import type { MutableRefObject } from 'react';
import type { NavigateFunction } from 'react-router';
import type { To } from 'react-router-dom';

import type {
    CreatePaymentIntentFromQuoteMutation,
    CreatePaymentIntentFromQuoteMutationVariables,
    CreateSubscriptionMutationVariables,
    CreateSubscriptionMutation
} from '../../__generated__/gateway-graphql';
import { LogExceptionMutation, LogExceptionMutationVariables } from '../../__generated__/gateway-graphql';
import { USER_ATTEMPT_MAX_LIMIT_REACHED } from '../../__utils__/utils';
import type {
    ChargebeeInstance
} from '../../chargebee/chargebee.types';
import { __DEV_ENV__, __LOCAL__, __QA_ENV__ } from '../../env-config';
import {
    resolveMarketingCookieContract
} from '../../routes/subscription/create/create-account/components/create-account-form/hooks/use-create-account-form';
import type {
    SubscriptionState
} from '../../subscription-model/subscription.types';
import { authorizeWith3dsNewCard, resolveAdditionalData } from '../__utils__/authorize-with3ds-new-card';
import { createGatewayPaymentIntent } from '../__utils__/create-gateway-payment-intent';
import { handleSubscriptionError, handleSubscriptionPayload } from '../__utils__/subscription-handlers';
import { assertCreateNewSubscriptionInput, assertNewCustomerPaymentIntent } from '../__utils__/validation';

type Options = {
    cardRef: MutableRefObject<ChargebeeInstance>
    createSubscription: (variables: CreateSubscriptionMutationVariables) => Promise<CreateSubscriptionMutation>
    createPaymentIntentFromQuote: (
        variables: CreatePaymentIntentFromQuoteMutationVariables
    ) => Promise<CreatePaymentIntentFromQuoteMutation>
    logException: (
        variables: LogExceptionMutationVariables
    ) => Promise<LogExceptionMutation>
    navigate: NavigateFunction
    toast: ReturnType<typeof useMathleticsToast>
    contactUsUrl: string
    successRedirectTo: To
    subscription: SubscriptionState
};

// We don't want to log intents in production, they are however useful in QA, DEV or LOCAL
const SHOULD_LOG_INFO = __LOCAL__ || __DEV_ENV__ || __QA_ENV__;

export async function createNewCustomerSubscription ({
    createPaymentIntentFromQuote, createSubscription, navigate,
    subscription, cardRef, toast, contactUsUrl, successRedirectTo, logException
}: Options) {
    try {
        if (!subscription.quoteAndPricingData || !subscription.profile) {
            return null;
        }

        const payment = {
            quoteId: subscription.quoteAndPricingData.quoteId,
            email: subscription.profile?.email
        };
        const userMaxAttemptError = t({
            id: 'Error - User - Max - Attempt',
            values: { contactUsUrl }
        });

        assertNewCustomerPaymentIntent(payment);

        if (!cardRef.current) {
            throw new Error('Unable to get Chargebee component reference cardRef');
        }

        const { paymentIntent, errorMessage } = await createGatewayPaymentIntent({
            createPaymentIntentFromQuote,
            input: payment
        });
        const additionalData = resolveAdditionalData({
            planId: subscription.productCode,
            subscription
        });

        if(errorMessage !== USER_ATTEMPT_MAX_LIMIT_REACHED && paymentIntent !== null) {
            const authorizedPaymentIntent = await authorizeWith3dsNewCard({
                additionalData,
                cardRef,
                paymentIntent,
                logException,
                quoteId: subscription.quoteAndPricingData.quoteId
            });

            if (SHOULD_LOG_INFO || window.__OVERRIDE_INTENT__) {
                console.info('authorizedPaymentIntent', authorizedPaymentIntent);
            }

            // This is for BE devs debugging their local code
            if (window.__OVERRIDE_INTENT__) {
                console.info('returning early for __OVERRIDE_INTENT__ no subscription created');

                return null;
            }

            const {
                profile,
                location
            } = subscription;
            /** Get marketing cookie from setURLParamsCookie in browser*/
            const getMarketingCookie = document.cookie
                .split('; ')
                .find((row) => row.startsWith('setURLParamsCookie='))
                ?.split('=')[1];
            const marketingCookieRetrieved = getMarketingCookie ? decodeURIComponent(getMarketingCookie) : null;
            const marketingCookie = resolveMarketingCookieContract({ marketingCookieRetrieved });
            const createSubscriptionVariables = {
                customerDetails: {
                    email: profile?.email,
                    firstName: profile?.firstName,
                    lastName: profile?.lastName,
                    password: profile?.password,
                    countryCode: location?.countryCode,
                    ...(location?.state) && {
                        state: location?.state,
                        zipCode: location?.zipcode
                    }
                },
                payment: {
                    intentId: authorizedPaymentIntent.id,
                    quoteId: subscription.quoteAndPricingData?.quoteId
                },
                marketingInfo: marketingCookie
            };

            assertCreateNewSubscriptionInput(createSubscriptionVariables);

            const payload = await createSubscription({
                input: createSubscriptionVariables
            });

            handleSubscriptionPayload({
                redirectTo: successRedirectTo,
                payload,
                navigate,
                toast
            });
        } else {
            toast({
                description: userMaxAttemptError,
                status: 'error',
                duration: 9000
            });
        }
    } catch (error: any) {
        handleSubscriptionError({
            info: 'createNewCustomerSubscription',
            error,
            contactUsUrl,
            toast
        });
    }
}
