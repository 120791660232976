/**
 * App specific color tokens that don't belong in the global product theme.
 */
export const white = '#ffffff';

export const campaignTheme = {
    primary: 'linear-gradient(144deg, #5E5E5E -26.6%, #000 138.23%)',
    secondary: 'linear-gradient(266deg, #FF5757 4.46%, #D80213 44.92%, #FF5757 105.45%), #E22837',
    tertiary: white,
    cyberMonday: 'linear-gradient(83deg, #038EB9 -1.71%, #84DFFB 143.12%), linear-gradient(91deg, #E9A2FA -40.93%, #7C0499 104.67%)',
    hover: 'linear-gradient(144deg, #777 -26.6%, #1A1A1A 138.23%)',
    hover1: 'linear-gradient(83deg, #037FB9 -1.71%, #84CDFB 143.12%)'
};
