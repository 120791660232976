/* eslint-disable max-len */
import { t } from '@lingui/macro';
import React from 'react';

import { CONFIG } from '../../config';
import { ExistingAccountIcon, EmailConflictIcon, SuccessIcon } from './components/notice-assets';
import { NoticePanelType } from './notice-panel';

export const NOTICE_TYPES = {
    ACCOUNT_CONFLICT: 'ACCOUNT_CONFLICT',
    EMAIL_CONFLICT: 'EMAIL_CONFLICT',
    COUPON_SUCCESS: 'COUPON_SUCCESS'
};

type NoticeTypeProps = {
    type: string
    couponDataDisplayText?: string
};

export const NOTICE_PANEL_TYPES = ({ type, couponDataDisplayText }: NoticeTypeProps): NoticePanelType => {
    const { ACCOUNT_CONFLICT, EMAIL_CONFLICT, COUPON_SUCCESS } = NOTICE_TYPES;
    const { signInUrl, forgotPasswordUrl } = CONFIG;

    let dataObj = {};

    switch(type) {
    case ACCOUNT_CONFLICT:
        dataObj = {
            background: 'secondary.50',
            errorType: 'ACCOUNT_CONFLICT',
            helperText: t`Notice-Account-Conflict-helper-text`,
            messageHtml: t`Hello again! We noticed you already have an account. <br/> <a href="${signInUrl}">Log in here</a> or <a href="${forgotPasswordUrl}">reset your password</a>.`,
            icon: <ExistingAccountIcon />
        };
        break;
    case EMAIL_CONFLICT:
        dataObj = {
            background: 'error.50',
            errorType: 'EMAIL_CONFLICT',
            messageHtml: t`Sorry, this email is already in use.<br/>Please enter a different email address.`,
            icon: <EmailConflictIcon />,
            helperText: t`Notice-Email-Conflict-helper-text`
        };
        break;
    case COUPON_SUCCESS:
        dataObj = {
            background: 'success.50',
            errorType: '',
            helperText: '',
            messageHtml: t`Notice-coupon-success ${couponDataDisplayText}`,
            icon: <SuccessIcon fillColour={'success.700'} size={'12px'} />
        };
        break;
    }

    return dataObj as NoticePanelType;
};

export type IconTickProps = {
    fillColour?: string
    size?: string
};

