import { useTrialDataQuery } from '../free-trial/use-trial-data-query';
import { useAppSelector } from '../store/store';
import { useGetQuoteAndPricingData, UseGetQuoteAndPricingDataProps } from './use-quote-and-pricing-data.ts';

/**
 * Used for when we want to select a default plan on a route
 * when the user has not yet selected a plan.
 * Eg Salesforce version has a url param `?pcode=defaultPlanCode`.
 *
 * Currently, a default plan is 1 child and a trial monthly plan productCode
 */
export function useDefaultPlanQuery () {
    const subscription = useAppSelector(
        state => state.subscription
    );
    const { childrenCount, quoteAndPricingData, productCode } = subscription;
    /** Select default trial product if product is not selected.
     * Get trial product for user based on his location
     * and set it for creating new quote */
    const {
        data,
        isLoading
    } = useTrialDataQuery();
    const {
        isLoading: isCreateQuoteDataLoading,
        productItem
    } = useGetQuoteAndPricingData(<UseGetQuoteAndPricingDataProps>{
        childrenCount,
        productCode: productCode ? productCode : data?.productCode,
        productItemFromStore: quoteAndPricingData?.productItem
    });

    return {
        isLoading,
        isCreateQuoteDataLoading,
        productItem
    };
}
