/* eslint-disable max-len */
import {
    chakra, useMediaQuery
} from '@chakra-ui/react';
import React from 'react';

export function BurstBackground () {
    const [isMobileVersion, isTablet] = useMediaQuery([
        '(max-width: 375px)',
        '(max-width: 768px)'
    ]);

    if (isMobileVersion) {
        return null;
    }

    const size = isTablet ? '300px' : '400px';

    return (
        <chakra.svg
            sx={{
                position: 'absolute',
                top: {
                    testimonialsTablet: '-50px',
                    desktop: '-102px'
                },
                right: {
                    testimonialsTablet: '-70px',
                    desktop: '-118px'
                }
            }}
            width={size}
            height={size}
            viewBox={'0 0 400 400'}
            fill={'none'}
        >
            <path d={'M199.864 206.875C201.236 206.881 202.578 206.479 203.721 205.721C204.864 204.963 205.756 203.883 206.284 202.617C206.812 201.351 206.951 199.957 206.685 198.612C206.419 197.267 205.759 196.031 204.789 195.062C203.819 194.092 202.583 193.432 201.238 193.166C199.892 192.9 198.498 193.04 197.232 193.567C195.966 194.095 194.885 194.987 194.126 196.129C193.368 197.272 192.967 198.614 192.972 199.985C192.972 200.89 193.151 201.786 193.497 202.622C193.843 203.458 194.351 204.217 194.991 204.857C195.631 205.497 196.391 206.004 197.227 206.35C198.063 206.697 198.959 206.875 199.864 206.875Z'} fill={'#F4BC22'} />
            <path d={'M215.221 206.543C216.514 206.525 217.773 206.125 218.84 205.394C219.907 204.662 220.733 203.632 221.216 202.432C221.698 201.233 221.815 199.917 221.552 198.651C221.289 197.385 220.657 196.225 219.736 195.317C218.815 194.409 217.647 193.794 216.377 193.548C215.107 193.302 213.793 193.437 212.599 193.936C211.406 194.435 210.387 195.275 209.67 196.352C208.953 197.428 208.571 198.692 208.571 199.985C208.57 200.854 208.743 201.714 209.078 202.516C209.414 203.317 209.905 204.044 210.524 204.654C211.142 205.264 211.876 205.746 212.683 206.07C213.489 206.394 214.352 206.555 215.221 206.543Z'} fill={'#F4BC22'} />
            <path d={'M204.61 221.137C205.906 221.137 207.173 220.753 208.251 220.034C209.329 219.315 210.169 218.292 210.667 217.096C211.164 215.899 211.296 214.583 211.045 213.311C210.795 212.04 210.173 210.872 209.258 209.953C208.344 209.035 207.178 208.408 205.908 208.152C204.637 207.895 203.319 208.021 202.12 208.513C200.921 209.004 199.895 209.84 199.17 210.915C198.446 211.989 198.056 213.254 198.05 214.549C198.046 215.413 198.213 216.269 198.541 217.068C198.869 217.867 199.351 218.594 199.961 219.206C200.57 219.818 201.295 220.304 202.093 220.635C202.89 220.966 203.746 221.137 204.61 221.137Z'} fill={'#F4BC22'} />
            <path d={'M187.412 215.518C188.704 215.518 189.966 215.135 191.04 214.418C192.113 213.701 192.95 212.681 193.444 211.489C193.939 210.296 194.068 208.984 193.816 207.718C193.564 206.451 192.942 205.288 192.029 204.376C191.116 203.463 189.953 202.841 188.686 202.589C187.42 202.337 186.107 202.467 184.914 202.961C183.721 203.455 182.701 204.291 181.984 205.365C181.266 206.438 180.883 207.7 180.883 208.991C180.879 209.849 181.045 210.7 181.372 211.494C181.699 212.287 182.179 213.008 182.787 213.615C183.394 214.222 184.115 214.703 184.909 215.029C185.703 215.356 186.554 215.522 187.412 215.518Z'} fill={'#F4BC22'} />
            <path d={'M187.412 197.477C188.704 197.477 189.966 197.094 191.04 196.377C192.113 195.66 192.95 194.64 193.444 193.448C193.939 192.255 194.068 190.943 193.816 189.676C193.564 188.41 192.942 187.247 192.029 186.334C191.116 185.422 189.953 184.8 188.686 184.548C187.419 184.296 186.107 184.425 184.914 184.919C183.721 185.413 182.701 186.25 181.983 187.323C181.266 188.397 180.883 189.659 180.883 190.95C180.883 192.681 181.571 194.341 182.795 195.565C184.02 196.789 185.681 197.477 187.412 197.477Z'} fill={'#F4BC22'} />
            <path d={'M204.609 191.885C205.901 191.885 207.163 191.503 208.237 190.785C209.311 190.068 210.147 189.049 210.642 187.856C211.136 186.663 211.265 185.351 211.013 184.085C210.761 182.819 210.139 181.656 209.226 180.743C208.313 179.83 207.15 179.208 205.883 178.956C204.617 178.705 203.304 178.834 202.111 179.328C200.918 179.822 199.898 180.658 199.181 181.732C198.463 182.805 198.08 184.067 198.08 185.358C198.08 187.089 198.768 188.75 199.993 189.974C201.217 191.198 202.878 191.885 204.609 191.885Z'} fill={'#F4BC22'} />
            <path d={'M230.606 206.028C233.945 206.028 236.651 203.322 236.651 199.984C236.651 196.646 233.945 193.941 230.606 193.941C227.267 193.941 224.56 196.646 224.56 199.984C224.56 203.322 227.267 206.028 230.606 206.028Z'} fill={'#F4BC22'} />
            <path d={'M224.711 224.069C225.906 224.069 227.075 223.714 228.069 223.05C229.064 222.386 229.838 221.442 230.296 220.338C230.754 219.234 230.873 218.018 230.64 216.846C230.407 215.674 229.831 214.597 228.986 213.752C228.14 212.906 227.063 212.331 225.89 212.098C224.717 211.864 223.502 211.984 222.397 212.442C221.293 212.899 220.348 213.674 219.684 214.667C219.02 215.661 218.665 216.83 218.665 218.025C218.665 219.628 219.302 221.165 220.436 222.299C221.57 223.432 223.107 224.069 224.711 224.069Z'} fill={'#F4BC22'} />
            <path d={'M209.357 235.251C212.696 235.251 215.402 232.545 215.402 229.207C215.402 225.869 212.696 223.163 209.357 223.163C206.018 223.163 203.311 225.869 203.311 229.207C203.311 232.545 206.018 235.251 209.357 235.251Z'} fill={'#F4BC22'} />
            <path d={'M190.371 235.251C193.71 235.251 196.416 232.545 196.416 229.207C196.416 225.869 193.71 223.163 190.371 223.163C187.032 223.163 184.325 225.869 184.325 229.207C184.325 232.545 187.032 235.251 190.371 235.251Z'} fill={'#F4BC22'} />
            <path d={'M174.987 224.04C178.309 224.04 181.002 221.348 181.002 218.026C181.002 214.705 178.309 212.013 174.987 212.013C171.665 212.013 168.972 214.705 168.972 218.026C168.972 221.348 171.665 224.04 174.987 224.04Z'} fill={'#F4BC22'} />
            <path d={'M169.273 205.969C170.469 205.969 171.638 205.614 172.632 204.95C173.626 204.286 174.401 203.342 174.859 202.238C175.316 201.134 175.436 199.918 175.203 198.746C174.969 197.574 174.394 196.497 173.548 195.652C172.703 194.806 171.625 194.231 170.453 193.998C169.28 193.764 168.064 193.884 166.96 194.342C165.855 194.799 164.911 195.574 164.247 196.567C163.582 197.561 163.228 198.73 163.228 199.925C163.228 201.528 163.865 203.065 164.998 204.199C166.132 205.332 167.67 205.969 169.273 205.969Z'} fill={'#F4BC22'} />
            <path d={'M174.986 187.928C176.181 187.928 177.35 187.574 178.344 186.909C179.339 186.245 180.113 185.301 180.571 184.197C181.029 183.093 181.148 181.878 180.915 180.705C180.682 179.533 180.106 178.456 179.261 177.611C178.415 176.766 177.338 176.19 176.165 175.957C174.992 175.724 173.777 175.843 172.672 176.301C171.568 176.758 170.623 177.533 169.959 178.527C169.295 179.521 168.94 180.689 168.94 181.884C168.94 183.487 169.577 185.024 170.711 186.158C171.845 187.291 173.382 187.928 174.986 187.928Z'} fill={'#F4BC22'} />
            <path d={'M190.375 176.777C193.697 176.777 196.39 174.085 196.39 170.764C196.39 167.443 193.697 164.75 190.375 164.75C187.053 164.75 184.36 167.443 184.36 170.764C184.36 174.085 187.053 176.777 190.375 176.777Z'} fill={'#F4BC22'} />
            <path d={'M209.357 176.777C212.679 176.777 215.372 174.085 215.372 170.764C215.372 167.443 212.679 164.75 209.357 164.75C206.035 164.75 203.342 167.443 203.342 170.764C203.342 174.085 206.035 176.777 209.357 176.777Z'} fill={'#F4BC22'} />
            <path d={'M224.711 187.958C225.907 187.958 227.075 187.603 228.07 186.939C229.064 186.275 229.839 185.331 230.296 184.227C230.754 183.122 230.873 181.907 230.64 180.735C230.407 179.563 229.831 178.486 228.986 177.641C228.14 176.795 227.063 176.22 225.89 175.986C224.718 175.753 223.502 175.873 222.397 176.33C221.293 176.788 220.349 177.562 219.684 178.556C219.02 179.55 218.665 180.719 218.665 181.914C218.665 183.517 219.302 185.054 220.436 186.188C221.57 187.321 223.107 187.958 224.711 187.958Z'} fill={'#F4BC22'} />
            <path d={'M245.962 205.485C247.065 205.491 248.144 205.169 249.064 204.561C249.983 203.952 250.7 203.084 251.125 202.067C251.55 201.05 251.663 199.929 251.449 198.848C251.235 197.766 250.705 196.773 249.925 195.993C249.146 195.214 248.152 194.684 247.07 194.47C245.988 194.257 244.868 194.369 243.85 194.794C242.833 195.219 241.964 195.936 241.355 196.855C240.747 197.774 240.425 198.853 240.431 199.955C240.431 201.422 241.013 202.828 242.051 203.865C243.088 204.902 244.495 205.485 245.962 205.485Z'} fill={'#F4BC22'} />
            <path d={'M241.97 224.25C243.064 224.25 244.134 223.926 245.043 223.318C245.953 222.71 246.662 221.847 247.081 220.836C247.499 219.826 247.609 218.714 247.396 217.641C247.182 216.568 246.655 215.583 245.882 214.81C245.108 214.036 244.122 213.51 243.049 213.296C241.976 213.083 240.864 213.192 239.853 213.611C238.842 214.029 237.979 214.738 237.371 215.648C236.763 216.557 236.439 217.626 236.439 218.72C236.439 220.186 237.021 221.593 238.059 222.63C239.096 223.667 240.503 224.25 241.97 224.25Z'} fill={'#F4BC22'} />
            <path d={'M230.696 239.752C231.79 239.752 232.859 239.428 233.769 238.82C234.678 238.212 235.387 237.349 235.806 236.338C236.225 235.328 236.334 234.216 236.121 233.143C235.907 232.071 235.381 231.085 234.607 230.312C233.833 229.538 232.848 229.012 231.775 228.798C230.702 228.585 229.589 228.695 228.579 229.113C227.568 229.532 226.704 230.24 226.096 231.15C225.488 232.059 225.164 233.128 225.164 234.222C225.172 235.686 225.757 237.088 226.793 238.124C227.829 239.159 229.231 239.744 230.696 239.752Z'} fill={'#F4BC22'} />
            <path d={'M214.102 249.332C215.196 249.332 216.266 249.007 217.175 248.4C218.085 247.792 218.794 246.928 219.213 245.918C219.631 244.907 219.741 243.796 219.528 242.723C219.314 241.65 218.787 240.665 218.014 239.891C217.24 239.118 216.254 238.591 215.181 238.378C214.108 238.165 212.996 238.274 211.985 238.693C210.975 239.111 210.111 239.82 209.503 240.729C208.895 241.639 208.571 242.708 208.571 243.802C208.571 245.268 209.153 246.675 210.191 247.712C211.228 248.749 212.635 249.332 214.102 249.332Z'} fill={'#F4BC22'} />
            <path d={'M195.028 251.357C196.129 251.363 197.208 251.042 198.126 250.435C199.045 249.828 199.763 248.961 200.188 247.946C200.614 246.93 200.728 245.811 200.517 244.731C200.306 243.65 199.779 242.657 199.002 241.876C198.225 241.096 197.234 240.563 196.155 240.346C195.075 240.129 193.955 240.237 192.937 240.657C191.919 241.077 191.049 241.79 190.436 242.705C189.824 243.62 189.497 244.696 189.497 245.797C189.497 247.266 190.078 248.676 191.115 249.718C192.151 250.76 193.558 251.349 195.028 251.357Z'} fill={'#F4BC22'} />
            <path d={'M176.801 245.434C177.902 245.44 178.981 245.119 179.899 244.512C180.818 243.905 181.535 243.038 181.961 242.023C182.387 241.007 182.501 239.889 182.29 238.808C182.079 237.727 181.551 236.734 180.775 235.953C179.998 235.173 179.007 234.64 177.927 234.423C176.848 234.206 175.728 234.314 174.71 234.734C173.692 235.154 172.821 235.867 172.209 236.782C171.596 237.697 171.269 238.773 171.269 239.874C171.269 241.343 171.851 242.753 172.888 243.795C173.924 244.837 175.331 245.426 176.801 245.434Z'} fill={'#F4BC22'} />
            <path d={'M162.563 232.68C163.651 232.704 164.721 232.404 165.638 231.82C166.556 231.236 167.28 230.392 167.718 229.397C168.156 228.401 168.289 227.298 168.1 226.227C167.911 225.156 167.409 224.165 166.657 223.379C165.905 222.593 164.936 222.048 163.874 221.813C162.812 221.577 161.704 221.661 160.69 222.055C159.676 222.449 158.801 223.135 158.177 224.026C157.553 224.916 157.207 225.972 157.182 227.06C157.166 227.782 157.293 228.5 157.554 229.174C157.816 229.847 158.208 230.462 158.708 230.984C159.207 231.506 159.805 231.925 160.467 232.216C161.128 232.507 161.84 232.664 162.563 232.68Z'} fill={'#F4BC22'} />
            <path d={'M154.766 215.064C155.854 215.064 156.918 214.741 157.823 214.137C158.727 213.533 159.433 212.674 159.849 211.669C160.265 210.664 160.374 209.558 160.162 208.491C159.95 207.424 159.426 206.445 158.656 205.675C157.887 204.906 156.907 204.382 155.84 204.17C154.772 203.958 153.666 204.067 152.661 204.483C151.656 204.899 150.797 205.604 150.192 206.509C149.588 207.413 149.265 208.477 149.265 209.564C149.265 211.023 149.845 212.422 150.876 213.453C151.908 214.485 153.307 215.064 154.766 215.064Z'} fill={'#F4BC22'} />
            <path d={'M154.766 195.905C157.805 195.905 160.268 193.443 160.268 190.406C160.268 187.368 157.805 184.906 154.766 184.906C151.728 184.906 149.265 187.368 149.265 190.406C149.265 193.443 151.728 195.905 154.766 195.905Z'} fill={'#F4BC22'} />
            <path d={'M162.566 178.289C163.663 178.313 164.741 178.008 165.664 177.415C166.586 176.821 167.31 175.965 167.742 174.957C168.175 173.949 168.296 172.835 168.09 171.758C167.884 170.681 167.361 169.69 166.587 168.912C165.814 168.134 164.825 167.606 163.749 167.394C162.672 167.182 161.557 167.297 160.547 167.723C159.536 168.15 158.676 168.869 158.077 169.787C157.478 170.706 157.168 171.783 157.185 172.879C157.201 174.304 157.772 175.666 158.777 176.676C159.782 177.687 161.141 178.265 162.566 178.289Z'} fill={'#F4BC22'} />
            <path d={'M176.8 165.566C179.839 165.566 182.302 163.104 182.302 160.067C182.302 157.029 179.839 154.567 176.8 154.567C173.762 154.567 171.299 157.029 171.299 160.067C171.299 163.104 173.762 165.566 176.8 165.566Z'} fill={'#F4BC22'} />
            <path d={'M195.028 159.644C196.116 159.65 197.181 159.333 198.089 158.734C198.997 158.134 199.707 157.279 200.129 156.277C200.551 155.274 200.666 154.169 200.459 153.101C200.253 152.033 199.735 151.05 198.969 150.277C198.204 149.503 197.227 148.974 196.161 148.756C195.095 148.538 193.988 148.641 192.981 149.052C191.973 149.462 191.11 150.163 190.501 151.064C189.891 151.965 189.563 153.026 189.557 154.114C189.553 154.836 189.691 155.552 189.964 156.221C190.237 156.89 190.639 157.498 191.147 158.012C191.655 158.525 192.259 158.934 192.925 159.214C193.591 159.494 194.305 159.64 195.028 159.644Z'} fill={'#F4BC22'} />
            <path d={'M214.102 161.638C215.189 161.632 216.25 161.305 217.15 160.697C218.051 160.089 218.751 159.228 219.163 158.222C219.575 157.217 219.68 156.112 219.464 155.047C219.248 153.982 218.722 153.005 217.951 152.239C217.181 151.473 216.2 150.952 215.134 150.742C214.068 150.532 212.963 150.643 211.96 151.06C210.956 151.477 210.099 152.182 209.496 153.086C208.892 153.99 208.57 155.052 208.57 156.138C208.566 156.864 208.707 157.584 208.984 158.255C209.261 158.926 209.669 159.535 210.183 160.047C210.698 160.559 211.31 160.963 211.983 161.236C212.656 161.51 213.376 161.646 214.102 161.638Z'} fill={'#F4BC22'} />
            <path d={'M230.695 171.216C231.784 171.216 232.847 170.893 233.752 170.289C234.657 169.684 235.362 168.825 235.778 167.821C236.194 166.816 236.303 165.71 236.091 164.643C235.879 163.576 235.355 162.596 234.586 161.827C233.816 161.058 232.836 160.534 231.769 160.322C230.702 160.11 229.595 160.218 228.59 160.635C227.585 161.051 226.726 161.756 226.121 162.66C225.517 163.565 225.194 164.628 225.194 165.716C225.19 166.439 225.33 167.156 225.605 167.825C225.88 168.494 226.285 169.102 226.796 169.614C227.308 170.125 227.916 170.53 228.585 170.805C229.255 171.08 229.972 171.22 230.695 171.216Z'} fill={'#F4BC22'} />
            <path d={'M241.971 186.78C243.076 186.786 244.158 186.462 245.078 185.851C245.999 185.239 246.716 184.368 247.139 183.347C247.562 182.326 247.671 181.203 247.452 180.12C247.234 179.037 246.697 178.043 245.912 177.266C245.126 176.489 244.126 175.964 243.041 175.757C241.955 175.551 240.832 175.672 239.816 176.106C238.8 176.54 237.936 177.267 237.334 178.194C236.733 179.12 236.421 180.205 236.439 181.31C236.455 182.766 237.045 184.157 238.081 185.181C239.116 186.205 240.514 186.78 241.971 186.78Z'} fill={'#F4BC22'} />
            <path d={'M261.318 205C264.089 205 266.336 202.754 266.336 199.984C266.336 197.214 264.089 194.968 261.318 194.968C258.547 194.968 256.3 197.214 256.3 199.984C256.3 202.754 258.547 205 261.318 205Z'} fill={'#F4BC22'} />
            <path d={'M258.323 223.979C259.314 223.973 260.281 223.673 261.102 223.119C261.924 222.564 262.562 221.778 262.937 220.861C263.312 219.944 263.407 218.936 263.21 217.965C263.013 216.994 262.533 216.103 261.83 215.405C261.127 214.706 260.233 214.232 259.261 214.04C258.288 213.849 257.281 213.95 256.366 214.331C255.45 214.711 254.669 215.354 254.119 216.179C253.569 217.003 253.275 217.972 253.275 218.962C253.275 219.624 253.406 220.278 253.66 220.889C253.914 221.5 254.286 222.054 254.755 222.52C255.224 222.986 255.781 223.355 256.393 223.606C257.006 223.856 257.661 223.983 258.323 223.979Z'} fill={'#F4BC22'} />
            <path d={'M249.587 241.111C252.359 241.111 254.605 238.866 254.605 236.095C254.605 233.325 252.359 231.079 249.587 231.079C246.816 231.079 244.57 233.325 244.57 236.095C244.57 238.866 246.816 241.111 249.587 241.111Z'} fill={'#F4BC22'} />
            <path d={'M235.986 254.71C236.987 254.716 237.967 254.425 238.802 253.873C239.637 253.32 240.288 252.533 240.674 251.609C241.06 250.686 241.163 249.669 240.969 248.687C240.775 247.705 240.294 246.803 239.586 246.096C238.878 245.388 237.976 244.907 236.994 244.713C236.012 244.519 234.994 244.622 234.071 245.008C233.147 245.393 232.359 246.045 231.807 246.879C231.254 247.714 230.962 248.694 230.968 249.694C230.968 251.025 231.497 252.3 232.438 253.241C233.379 254.182 234.655 254.71 235.986 254.71Z'} fill={'#F4BC22'} />
            <path d={'M218.847 263.412C221.618 263.412 223.864 261.167 223.864 258.396C223.864 255.626 221.618 253.38 218.847 253.38C216.075 253.38 213.829 255.626 213.829 258.396C213.829 261.167 216.075 263.412 218.847 263.412Z'} fill={'#F4BC22'} />
            <path d={'M199.864 266.436C202.636 266.436 204.882 264.19 204.882 261.419C204.882 258.649 202.636 256.403 199.864 256.403C197.093 256.403 194.847 258.649 194.847 261.419C194.847 264.19 197.093 266.436 199.864 266.436Z'} fill={'#F4BC22'} />
            <path d={'M180.881 263.443C181.879 263.443 182.855 263.147 183.685 262.593C184.515 262.038 185.163 261.25 185.545 260.328C185.927 259.406 186.027 258.391 185.832 257.412C185.637 256.433 185.156 255.534 184.45 254.828C183.744 254.122 182.845 253.642 181.866 253.447C180.886 253.252 179.871 253.352 178.949 253.734C178.027 254.116 177.238 254.763 176.684 255.593C176.129 256.423 175.833 257.399 175.833 258.397C175.833 259.059 175.963 259.716 176.217 260.328C176.471 260.94 176.843 261.496 177.311 261.965C177.78 262.434 178.337 262.805 178.949 263.059C179.562 263.313 180.218 263.443 180.881 263.443Z'} fill={'#F4BC22'} />
            <path d={'M163.742 254.681C166.497 254.681 168.73 252.448 168.73 249.695C168.73 246.941 166.497 244.709 163.742 244.709C160.988 244.709 158.755 246.941 158.755 249.695C158.755 252.448 160.988 254.681 163.742 254.681Z'} fill={'#F4BC22'} />
            <path d={'M150.139 241.083C152.894 241.083 155.127 238.85 155.127 236.097C155.127 233.343 152.894 231.111 150.139 231.111C147.385 231.111 145.152 233.343 145.152 236.097C145.152 238.85 147.385 241.083 150.139 241.083Z'} fill={'#F4BC22'} />
            <path d={'M141.404 223.948C142.39 223.948 143.355 223.656 144.175 223.108C144.995 222.56 145.634 221.781 146.012 220.87C146.389 219.959 146.488 218.957 146.296 217.989C146.103 217.022 145.628 216.134 144.931 215.436C144.233 214.739 143.344 214.264 142.377 214.072C141.409 213.879 140.407 213.978 139.495 214.356C138.584 214.733 137.805 215.372 137.257 216.192C136.709 217.012 136.416 217.976 136.416 218.962C136.416 220.284 136.942 221.553 137.877 222.488C138.812 223.423 140.081 223.948 141.404 223.948Z'} fill={'#F4BC22'} />
            <path d={'M138.413 204.971C139.4 204.971 140.364 204.679 141.184 204.131C142.004 203.583 142.644 202.804 143.021 201.893C143.399 200.982 143.497 199.979 143.305 199.012C143.112 198.045 142.637 197.157 141.94 196.459C141.242 195.762 140.354 195.287 139.386 195.095C138.419 194.902 137.416 195.001 136.505 195.379C135.593 195.756 134.814 196.395 134.266 197.215C133.718 198.035 133.426 198.999 133.426 199.985C133.422 200.641 133.548 201.291 133.797 201.898C134.046 202.505 134.414 203.056 134.878 203.52C135.341 203.983 135.893 204.351 136.5 204.6C137.107 204.849 137.757 204.975 138.413 204.971Z'} fill={'#F4BC22'} />
            <path d={'M141.404 185.963C144.158 185.963 146.391 183.731 146.391 180.977C146.391 178.224 144.158 175.991 141.404 175.991C138.649 175.991 136.416 178.224 136.416 180.977C136.416 183.731 138.649 185.963 141.404 185.963Z'} fill={'#F4BC22'} />
            <path d={'M150.139 168.829C151.123 168.817 152.081 168.514 152.893 167.959C153.705 167.404 154.335 166.621 154.703 165.709C155.072 164.797 155.162 163.797 154.963 162.833C154.763 161.87 154.284 160.987 153.584 160.296C152.884 159.605 151.995 159.136 151.029 158.948C150.064 158.761 149.064 158.863 148.156 159.242C147.248 159.621 146.473 160.26 145.927 161.079C145.382 161.897 145.091 162.859 145.091 163.843C145.091 164.502 145.222 165.156 145.476 165.765C145.731 166.374 146.103 166.926 146.573 167.39C147.043 167.854 147.6 168.22 148.212 168.467C148.824 168.714 149.479 168.837 150.139 168.829Z'} fill={'#F4BC22'} />
            <path d={'M163.742 155.262C166.497 155.262 168.73 153.03 168.73 150.276C168.73 147.522 166.497 145.29 163.742 145.29C160.988 145.29 158.755 147.522 158.755 150.276C158.755 153.03 160.988 155.262 163.742 155.262Z'} fill={'#F4BC22'} />
            <path d={'M180.882 146.528C181.868 146.528 182.833 146.236 183.653 145.688C184.473 145.14 185.112 144.361 185.49 143.45C185.867 142.539 185.966 141.537 185.774 140.57C185.581 139.602 185.106 138.714 184.409 138.017C183.711 137.319 182.822 136.844 181.855 136.652C180.887 136.46 179.885 136.558 178.973 136.936C178.062 137.313 177.283 137.952 176.735 138.772C176.187 139.592 175.894 140.556 175.894 141.542C175.89 142.198 176.017 142.848 176.266 143.455C176.515 144.062 176.882 144.613 177.346 145.077C177.81 145.541 178.362 145.908 178.969 146.157C179.575 146.406 180.226 146.532 180.882 146.528Z'} fill={'#F4BC22'} />
            <path d={'M199.864 143.506C202.619 143.506 204.851 141.274 204.851 138.52C204.851 135.767 202.619 133.534 199.864 133.534C197.109 133.534 194.876 135.767 194.876 138.52C194.876 141.274 197.109 143.506 199.864 143.506Z'} fill={'#F4BC22'} />
            <path d={'M218.846 146.529C221.601 146.529 223.834 144.296 223.834 141.542C223.834 138.789 221.601 136.556 218.846 136.556C216.091 136.556 213.858 138.789 213.858 141.542C213.858 144.296 216.091 146.529 218.846 146.529Z'} fill={'#F4BC22'} />
            <path d={'M235.985 155.261C236.972 155.261 237.936 154.968 238.756 154.42C239.576 153.872 240.216 153.094 240.593 152.183C240.971 151.272 241.069 150.269 240.877 149.302C240.685 148.335 240.21 147.446 239.512 146.749C238.815 146.052 237.926 145.577 236.958 145.384C235.991 145.192 234.988 145.291 234.077 145.668C233.165 146.045 232.386 146.685 231.838 147.505C231.29 148.324 230.998 149.288 230.998 150.275C230.998 151.597 231.523 152.865 232.459 153.8C233.394 154.735 234.663 155.261 235.985 155.261Z'} fill={'#F4BC22'} />
            <path d={'M249.588 168.831C250.575 168.831 251.539 168.538 252.359 167.991C253.18 167.443 253.819 166.664 254.196 165.753C254.574 164.842 254.673 163.839 254.48 162.872C254.288 161.905 253.813 161.016 253.115 160.319C252.418 159.622 251.529 159.147 250.561 158.955C249.594 158.762 248.591 158.861 247.68 159.238C246.768 159.616 245.99 160.255 245.441 161.075C244.893 161.895 244.601 162.859 244.601 163.845C244.601 165.167 245.126 166.435 246.062 167.37C246.997 168.306 248.266 168.831 249.588 168.831Z'} fill={'#F4BC22'} />
            <path d={'M258.323 185.994C261.094 185.994 263.341 183.748 263.341 180.978C263.341 178.208 261.094 175.962 258.323 175.962C255.552 175.962 253.305 178.208 253.305 180.978C253.305 183.748 255.552 185.994 258.323 185.994Z'} fill={'#F4BC22'} />
            <path d={'M276.704 204.487C277.596 204.464 278.462 204.177 279.192 203.663C279.922 203.15 280.484 202.432 280.808 201.6C281.132 200.768 281.203 199.859 281.012 198.988C280.821 198.116 280.377 197.319 279.736 196.699C279.094 196.078 278.283 195.661 277.405 195.499C276.527 195.337 275.621 195.438 274.8 195.789C273.979 196.14 273.28 196.725 272.791 197.472C272.301 198.218 272.043 199.092 272.049 199.985C272.048 200.589 272.17 201.187 272.406 201.744C272.641 202.3 272.987 202.803 273.421 203.223C273.855 203.643 274.37 203.972 274.934 204.189C275.498 204.406 276.1 204.508 276.704 204.487Z'} fill={'#F4BC22'} />
            <path d={'M274.286 223.615C275.184 223.615 276.061 223.349 276.808 222.849C277.554 222.35 278.135 221.64 278.477 220.81C278.82 219.98 278.908 219.067 278.73 218.187C278.553 217.307 278.118 216.5 277.481 215.867C276.844 215.234 276.033 214.805 275.151 214.633C274.27 214.462 273.357 214.556 272.529 214.904C271.701 215.251 270.995 215.837 270.501 216.586C270.006 217.336 269.745 218.215 269.751 219.113C269.751 219.707 269.869 220.295 270.097 220.843C270.325 221.391 270.66 221.889 271.081 222.307C271.503 222.726 272.003 223.057 272.553 223.281C273.103 223.506 273.692 223.619 274.286 223.615Z'} fill={'#F4BC22'} />
            <path d={'M267.181 241.505C268.079 241.511 268.958 241.251 269.708 240.756C270.458 240.262 271.043 239.556 271.391 238.728C271.739 237.901 271.833 236.988 271.662 236.107C271.49 235.226 271.061 234.415 270.428 233.778C269.795 233.141 268.987 232.707 268.107 232.529C267.226 232.352 266.313 232.44 265.483 232.782C264.653 233.124 263.943 233.705 263.443 234.451C262.944 235.197 262.677 236.075 262.677 236.973C262.677 238.17 263.15 239.318 263.994 240.167C264.838 241.016 265.984 241.497 267.181 241.505Z'} fill={'#F4BC22'} />
            <path d={'M255.876 257.067C256.774 257.067 257.652 256.8 258.398 256.301C259.144 255.802 259.725 255.092 260.067 254.262C260.41 253.432 260.498 252.519 260.32 251.639C260.143 250.759 259.708 249.951 259.071 249.318C258.434 248.686 257.623 248.256 256.742 248.085C255.86 247.914 254.948 248.008 254.12 248.355C253.292 248.703 252.586 249.289 252.091 250.038C251.596 250.787 251.336 251.667 251.342 252.565C251.342 253.158 251.459 253.746 251.687 254.295C251.916 254.843 252.25 255.34 252.671 255.759C253.093 256.177 253.593 256.509 254.143 256.733C254.693 256.957 255.282 257.071 255.876 257.067Z'} fill={'#F4BC22'} />
            <path d={'M241.034 269.336C241.932 269.336 242.81 269.069 243.556 268.57C244.302 268.07 244.883 267.361 245.226 266.531C245.568 265.701 245.656 264.788 245.479 263.908C245.301 263.027 244.866 262.22 244.229 261.587C243.592 260.954 242.781 260.525 241.9 260.354C241.018 260.182 240.106 260.276 239.278 260.624C238.45 260.972 237.744 261.557 237.249 262.307C236.755 263.056 236.494 263.935 236.5 264.833C236.5 265.427 236.617 266.015 236.846 266.563C237.074 267.111 237.408 267.609 237.83 268.028C238.251 268.446 238.751 268.777 239.301 269.002C239.851 269.226 240.44 269.34 241.034 269.336Z'} fill={'#F4BC22'} />
            <path d={'M223.685 277.525C224.576 277.525 225.447 277.261 226.187 276.766C226.928 276.271 227.505 275.568 227.846 274.745C228.187 273.923 228.276 273.017 228.103 272.144C227.929 271.271 227.5 270.468 226.87 269.839C226.24 269.209 225.437 268.78 224.564 268.606C223.69 268.433 222.785 268.522 221.962 268.863C221.139 269.203 220.435 269.78 219.94 270.521C219.445 271.261 219.181 272.132 219.181 273.022C219.189 274.214 219.666 275.355 220.509 276.197C221.352 277.04 222.493 277.517 223.685 277.525Z'} fill={'#F4BC22'} />
            <path d={'M204.669 281.031C205.567 281.037 206.446 280.776 207.196 280.281C207.946 279.787 208.531 279.081 208.879 278.253C209.227 277.426 209.321 276.513 209.15 275.632C208.978 274.751 208.549 273.94 207.916 273.303C207.283 272.666 206.475 272.232 205.595 272.054C204.714 271.877 203.801 271.965 202.971 272.307C202.141 272.649 201.431 273.23 200.931 273.976C200.432 274.722 200.165 275.6 200.165 276.498C200.165 277.695 200.638 278.843 201.482 279.692C202.326 280.541 203.471 281.023 204.669 281.031Z'} fill={'#F4BC22'} />
            <path d={'M185.475 279.943C186.372 279.943 187.248 279.678 187.994 279.18C188.739 278.681 189.321 277.974 189.664 277.145C190.007 276.317 190.097 275.406 189.922 274.526C189.747 273.647 189.315 272.839 188.681 272.206C188.047 271.572 187.239 271.14 186.359 270.965C185.48 270.79 184.568 270.88 183.74 271.223C182.911 271.566 182.203 272.147 181.705 272.892C181.207 273.638 180.941 274.514 180.941 275.411C180.941 276.613 181.418 277.766 182.269 278.616C183.119 279.466 184.272 279.943 185.475 279.943Z'} fill={'#F4BC22'} />
            <path d={'M167.159 273.93C168.044 273.93 168.909 273.668 169.645 273.176C170.38 272.685 170.954 271.987 171.292 271.169C171.631 270.352 171.72 269.453 171.547 268.585C171.374 267.718 170.948 266.921 170.323 266.295C169.697 265.67 168.9 265.244 168.032 265.071C167.164 264.899 166.265 264.987 165.447 265.326C164.63 265.664 163.931 266.238 163.44 266.973C162.948 267.709 162.686 268.573 162.686 269.458C162.686 270.644 163.157 271.781 163.996 272.62C164.835 273.459 165.973 273.93 167.159 273.93Z'} fill={'#F4BC22'} />
            <path d={'M150.895 263.625C151.78 263.625 152.645 263.363 153.381 262.871C154.117 262.38 154.69 261.681 155.029 260.864C155.367 260.047 155.456 259.148 155.283 258.28C155.11 257.413 154.684 256.616 154.059 255.99C153.433 255.365 152.636 254.939 151.768 254.766C150.9 254.594 150.001 254.682 149.183 255.021C148.366 255.359 147.667 255.932 147.176 256.668C146.684 257.403 146.422 258.268 146.422 259.153C146.422 260.339 146.893 261.476 147.732 262.315C148.571 263.154 149.709 263.625 150.895 263.625Z'} fill={'#F4BC22'} />
            <path d={'M137.717 249.603C138.602 249.603 139.467 249.341 140.203 248.85C140.938 248.358 141.512 247.66 141.85 246.842C142.189 246.025 142.278 245.126 142.105 244.258C141.932 243.391 141.506 242.594 140.881 241.969C140.255 241.343 139.458 240.917 138.59 240.745C137.722 240.572 136.823 240.661 136.005 240.999C135.188 241.338 134.489 241.911 133.998 242.646C133.506 243.382 133.244 244.246 133.244 245.131C133.251 246.315 133.725 247.448 134.563 248.285C135.4 249.122 136.533 249.595 137.717 249.603Z'} fill={'#F4BC22'} />
            <path d={'M128.437 232.682C129.322 232.676 130.185 232.408 130.917 231.911C131.649 231.415 132.218 230.713 132.551 229.893C132.884 229.074 132.967 228.174 132.788 227.308C132.61 226.441 132.178 225.647 131.548 225.026C130.919 224.405 130.119 223.984 129.25 223.818C128.381 223.651 127.482 223.745 126.667 224.089C125.852 224.433 125.157 225.011 124.67 225.75C124.184 226.489 123.927 227.355 123.933 228.24C123.937 228.827 124.057 229.408 124.285 229.949C124.514 230.49 124.846 230.981 125.265 231.393C125.683 231.806 126.178 232.132 126.723 232.353C127.267 232.574 127.849 232.686 128.437 232.682Z'} fill={'#F4BC22'} />
            <path d={'M123.63 214.066C124.515 214.066 125.38 213.803 126.115 213.312C126.851 212.821 127.424 212.122 127.763 211.305C128.102 210.488 128.19 209.588 128.018 208.721C127.845 207.853 127.419 207.056 126.793 206.431C126.168 205.806 125.371 205.38 124.503 205.207C123.635 205.034 122.735 205.123 121.918 205.462C121.101 205.8 120.402 206.373 119.91 207.109C119.419 207.844 119.156 208.709 119.156 209.593C119.156 210.78 119.628 211.917 120.467 212.756C121.306 213.595 122.443 214.066 123.63 214.066Z'} fill={'#F4BC22'} />
            <path d={'M123.63 194.818C124.516 194.818 125.382 194.555 126.118 194.062C126.855 193.569 127.428 192.869 127.766 192.05C128.104 191.231 128.19 190.33 128.015 189.462C127.84 188.594 127.411 187.797 126.782 187.172C126.154 186.548 125.354 186.125 124.484 185.956C123.614 185.786 122.714 185.879 121.897 186.222C121.08 186.566 120.383 187.144 119.896 187.883C119.408 188.622 119.15 189.49 119.156 190.376C119.164 191.557 119.639 192.687 120.477 193.519C121.315 194.351 122.449 194.818 123.63 194.818Z'} fill={'#F4BC22'} />
            <path d={'M128.437 176.143C129.313 176.131 130.166 175.86 130.889 175.365C131.612 174.87 132.172 174.172 132.499 173.359C132.826 172.547 132.905 171.655 132.727 170.798C132.549 169.94 132.121 169.154 131.497 168.539C130.874 167.924 130.082 167.507 129.221 167.34C128.361 167.174 127.471 167.265 126.662 167.603C125.854 167.941 125.164 168.51 124.678 169.239C124.192 169.968 123.933 170.825 123.933 171.701C123.929 172.29 124.043 172.875 124.269 173.42C124.494 173.964 124.827 174.459 125.247 174.873C125.667 175.287 126.165 175.613 126.713 175.831C127.261 176.049 127.847 176.155 128.437 176.143Z'} fill={'#F4BC22'} />
            <path d={'M137.717 159.311C138.602 159.311 139.467 159.049 140.203 158.557C140.938 158.066 141.512 157.368 141.85 156.55C142.189 155.733 142.277 154.834 142.105 153.966C141.932 153.099 141.506 152.302 140.881 151.677C140.255 151.051 139.458 150.625 138.59 150.453C137.722 150.28 136.823 150.369 136.005 150.707C135.188 151.046 134.489 151.619 133.998 152.354C133.506 153.09 133.244 153.954 133.244 154.839C133.251 156.023 133.725 157.156 134.563 157.993C135.4 158.83 136.533 159.303 137.717 159.311Z'} fill={'#F4BC22'} />
            <path d={'M150.895 145.26C151.78 145.26 152.645 144.997 153.381 144.506C154.117 144.014 154.69 143.316 155.029 142.499C155.367 141.682 155.456 140.782 155.283 139.915C155.11 139.047 154.684 138.25 154.059 137.625C153.433 136.999 152.636 136.573 151.768 136.401C150.9 136.228 150.001 136.317 149.183 136.655C148.366 136.994 147.667 137.567 147.176 138.303C146.684 139.038 146.422 139.903 146.422 140.787C146.422 141.973 146.893 143.111 147.732 143.95C148.571 144.788 149.709 145.26 150.895 145.26Z'} fill={'#F4BC22'} />
            <path d={'M167.157 134.894C168.269 134.802 169.305 134.296 170.06 133.476C170.815 132.656 171.235 131.582 171.235 130.467C171.235 129.352 170.815 128.278 170.06 127.458C169.305 126.638 168.269 126.132 167.157 126.04C166.545 125.989 165.929 126.066 165.348 126.266C164.767 126.465 164.233 126.783 163.781 127.199C163.329 127.615 162.969 128.12 162.722 128.683C162.475 129.245 162.348 129.853 162.348 130.467C162.348 131.081 162.475 131.689 162.722 132.251C162.969 132.813 163.329 133.319 163.781 133.735C164.233 134.151 164.767 134.469 165.348 134.668C165.929 134.868 166.545 134.944 167.157 134.894Z'} fill={'#F4BC22'} />
            <path d={'M185.474 129.002C186.359 129.002 187.224 128.74 187.96 128.248C188.695 127.757 189.269 127.058 189.607 126.241C189.946 125.424 190.035 124.525 189.862 123.657C189.689 122.79 189.263 121.993 188.638 121.367C188.012 120.742 187.215 120.316 186.347 120.143C185.479 119.971 184.58 120.059 183.762 120.398C182.945 120.736 182.246 121.309 181.755 122.045C181.263 122.78 181.001 123.645 181.001 124.53C181.001 125.716 181.472 126.853 182.311 127.692C183.15 128.531 184.288 129.002 185.474 129.002Z'} fill={'#F4BC22'} />
            <path d={'M204.673 127.793C205.557 127.793 206.422 127.53 207.158 127.039C207.894 126.547 208.467 125.849 208.806 125.032C209.144 124.215 209.233 123.315 209.06 122.448C208.888 121.58 208.462 120.783 207.836 120.158C207.21 119.532 206.413 119.106 205.545 118.934C204.678 118.761 203.778 118.85 202.961 119.188C202.143 119.527 201.444 120.1 200.953 120.836C200.461 121.571 200.199 122.436 200.199 123.32C200.199 124.506 200.67 125.644 201.509 126.483C202.348 127.321 203.486 127.793 204.673 127.793Z'} fill={'#F4BC22'} />
            <path d={'M223.681 131.39C224.562 131.372 225.418 131.094 226.141 130.592C226.865 130.09 227.424 129.385 227.749 128.567C228.074 127.748 228.15 126.852 227.967 125.99C227.784 125.129 227.351 124.341 226.722 123.724C226.093 123.108 225.296 122.691 224.43 122.525C223.565 122.36 222.67 122.454 221.858 122.795C221.046 123.136 220.353 123.709 219.865 124.442C219.377 125.176 219.117 126.037 219.117 126.917C219.117 127.512 219.235 128.101 219.466 128.65C219.696 129.198 220.034 129.696 220.459 130.112C220.884 130.529 221.388 130.856 221.941 131.075C222.495 131.295 223.086 131.402 223.681 131.39Z'} fill={'#F4BC22'} />
            <path d={'M241.034 139.61C241.919 139.61 242.784 139.347 243.52 138.856C244.255 138.364 244.829 137.666 245.167 136.849C245.506 136.032 245.595 135.132 245.422 134.265C245.249 133.397 244.823 132.6 244.198 131.975C243.572 131.349 242.775 130.923 241.907 130.751C241.039 130.578 240.14 130.667 239.322 131.005C238.505 131.344 237.806 131.917 237.315 132.653C236.823 133.388 236.561 134.253 236.561 135.137C236.561 136.323 237.032 137.461 237.871 138.3C238.71 139.138 239.848 139.61 241.034 139.61Z'} fill={'#F4BC22'} />
            <path d={'M255.876 151.878C256.761 151.878 257.626 151.616 258.362 151.124C259.097 150.633 259.671 149.934 260.009 149.117C260.348 148.3 260.437 147.401 260.264 146.533C260.091 145.666 259.665 144.869 259.04 144.243C258.414 143.618 257.617 143.192 256.749 143.019C255.881 142.847 254.982 142.935 254.164 143.274C253.347 143.612 252.648 144.186 252.156 144.921C251.665 145.657 251.403 146.521 251.403 147.406C251.403 148.592 251.874 149.729 252.713 150.568C253.552 151.407 254.69 151.878 255.876 151.878Z'} fill={'#F4BC22'} />
            <path d={'M267.18 167.44C268.065 167.44 268.93 167.177 269.666 166.686C270.401 166.195 270.975 165.496 271.313 164.679C271.652 163.862 271.741 162.962 271.568 162.095C271.395 161.227 270.969 160.43 270.344 159.805C269.718 159.18 268.921 158.754 268.053 158.581C267.185 158.408 266.286 158.497 265.468 158.836C264.651 159.174 263.952 159.747 263.461 160.483C262.969 161.218 262.707 162.083 262.707 162.967C262.707 164.154 263.178 165.291 264.017 166.13C264.856 166.969 265.994 167.44 267.18 167.44Z'} fill={'#F4BC22'} />
            <path d={'M274.286 185.389C275.184 185.389 276.061 185.123 276.808 184.623C277.554 184.124 278.135 183.414 278.477 182.584C278.82 181.754 278.908 180.841 278.73 179.961C278.553 179.081 278.118 178.273 277.481 177.641C276.844 177.008 276.033 176.579 275.151 176.407C274.27 176.236 273.357 176.33 272.529 176.677C271.701 177.025 270.995 177.611 270.501 178.36C270.006 179.11 269.745 179.989 269.751 180.887C269.751 181.481 269.869 182.069 270.097 182.617C270.325 183.165 270.66 183.663 271.081 184.081C271.503 184.5 272.003 184.831 272.553 185.055C273.103 185.28 273.692 185.393 274.286 185.389Z'} fill={'#F4BC22'} />
            <path d={'M292.056 203.974C294.26 203.974 296.046 202.188 296.046 199.985C296.046 197.782 294.26 195.996 292.056 195.996C289.853 195.996 288.066 197.782 288.066 199.985C288.066 202.188 289.853 203.974 292.056 203.974Z'} fill={'#F4BC22'} />
            <path d={'M290.183 223.132C290.98 223.138 291.762 222.907 292.428 222.467C293.093 222.028 293.613 221.401 293.921 220.665C294.229 219.93 294.311 219.119 294.157 218.337C294.003 217.554 293.62 216.835 293.056 216.271C292.492 215.707 291.772 215.324 290.99 215.17C290.207 215.016 289.396 215.098 288.66 215.406C287.925 215.714 287.297 216.234 286.858 216.899C286.418 217.565 286.187 218.346 286.193 219.143C286.201 220.199 286.624 221.209 287.37 221.955C288.117 222.701 289.127 223.124 290.183 223.132Z'} fill={'#F4BC22'} />
            <path d={'M284.138 241.474C284.693 241.523 285.252 241.455 285.78 241.276C286.308 241.096 286.792 240.809 287.203 240.433C287.614 240.056 287.942 239.598 288.166 239.088C288.391 238.578 288.506 238.027 288.506 237.47C288.506 236.913 288.391 236.362 288.166 235.852C287.942 235.342 287.614 234.884 287.203 234.508C286.792 234.131 286.308 233.844 285.78 233.665C285.252 233.486 284.693 233.418 284.138 233.466C283.136 233.554 282.203 234.013 281.523 234.754C280.843 235.496 280.466 236.465 280.466 237.47C280.466 238.476 280.843 239.445 281.523 240.186C282.203 240.928 283.136 241.387 284.138 241.474Z'} fill={'#F4BC22'} />
            <path d={'M274.436 258.126C276.639 258.126 278.426 256.34 278.426 254.137C278.426 251.934 276.639 250.148 274.436 250.148C272.232 250.148 270.446 251.934 270.446 254.137C270.446 256.34 272.232 258.126 274.436 258.126Z'} fill={'#F4BC22'} />
            <path d={'M261.556 272.48C262.352 272.48 263.129 272.244 263.79 271.802C264.451 271.361 264.966 270.733 265.271 269.999C265.575 269.264 265.655 268.456 265.5 267.677C265.344 266.897 264.962 266.181 264.399 265.619C263.837 265.057 263.121 264.674 262.341 264.519C261.561 264.364 260.753 264.443 260.018 264.747C259.283 265.052 258.656 265.567 258.214 266.228C257.772 266.889 257.536 267.666 257.536 268.461C257.536 269.526 257.96 270.549 258.714 271.302C259.468 272.056 260.49 272.48 261.556 272.48Z'} fill={'#F4BC22'} />
            <path d={'M245.96 283.779C248.163 283.779 249.95 281.994 249.95 279.791C249.95 277.588 248.163 275.802 245.96 275.802C243.756 275.802 241.97 277.588 241.97 279.791C241.97 281.994 243.756 283.779 245.96 283.779Z'} fill={'#F4BC22'} />
            <path d={'M228.339 291.638C229.135 291.644 229.915 291.413 230.58 290.975C231.245 290.537 231.765 289.912 232.074 289.178C232.383 288.444 232.467 287.635 232.315 286.853C232.164 286.072 231.783 285.353 231.222 284.788C230.661 284.223 229.945 283.837 229.164 283.679C228.384 283.522 227.574 283.6 226.837 283.903C226.101 284.206 225.471 284.721 225.028 285.383C224.585 286.044 224.349 286.823 224.349 287.619C224.345 288.145 224.445 288.667 224.644 289.155C224.842 289.642 225.136 290.085 225.506 290.459C225.877 290.833 226.319 291.129 226.805 291.331C227.291 291.534 227.812 291.638 228.339 291.638Z'} fill={'#F4BC22'} />
            <path d={'M209.504 295.657C211.724 295.657 213.524 293.858 213.524 291.638C213.524 289.418 211.724 287.619 209.504 287.619C207.284 287.619 205.484 289.418 205.484 291.638C205.484 293.858 207.284 295.657 209.504 295.657Z'} fill={'#F4BC22'} />
            <path d={'M190.222 295.657C192.443 295.657 194.243 293.857 194.243 291.638C194.243 289.418 192.443 287.619 190.222 287.619C188.002 287.619 186.202 289.418 186.202 291.638C186.202 293.857 188.002 295.657 190.222 295.657Z'} fill={'#F4BC22'} />
            <path d={'M171.359 291.577C173.546 291.577 175.318 289.804 175.318 287.618C175.318 285.432 173.546 283.66 171.359 283.66C169.172 283.66 167.399 285.432 167.399 287.618C167.399 289.804 169.172 291.577 171.359 291.577Z'} fill={'#F4BC22'} />
            <path d={'M153.767 283.75C154.55 283.75 155.316 283.518 155.967 283.083C156.618 282.648 157.126 282.03 157.426 281.307C157.725 280.583 157.804 279.787 157.651 279.019C157.498 278.252 157.121 277.546 156.567 276.993C156.014 276.439 155.308 276.062 154.54 275.909C153.772 275.756 152.976 275.835 152.252 276.134C151.528 276.434 150.91 276.941 150.475 277.592C150.04 278.243 149.808 279.009 149.808 279.792C149.808 280.842 150.225 281.848 150.967 282.591C151.71 283.333 152.717 283.75 153.767 283.75Z'} fill={'#F4BC22'} />
            <path d={'M138.17 272.418C138.953 272.418 139.719 272.186 140.37 271.751C141.021 271.316 141.529 270.698 141.828 269.974C142.128 269.251 142.206 268.455 142.054 267.687C141.901 266.919 141.524 266.214 140.97 265.66C140.416 265.107 139.711 264.73 138.942 264.577C138.174 264.424 137.378 264.503 136.655 264.802C135.931 265.102 135.313 265.609 134.877 266.26C134.442 266.911 134.21 267.676 134.21 268.459C134.21 268.979 134.312 269.494 134.511 269.974C134.71 270.455 135.002 270.891 135.37 271.259C135.738 271.626 136.174 271.918 136.655 272.117C137.135 272.316 137.65 272.418 138.17 272.418Z'} fill={'#F4BC22'} />
            <path d={'M125.261 258.096C126.045 258.096 126.81 257.863 127.461 257.428C128.112 256.993 128.62 256.375 128.92 255.652C129.219 254.928 129.298 254.133 129.145 253.365C128.992 252.597 128.615 251.891 128.061 251.338C127.508 250.784 126.802 250.407 126.034 250.254C125.266 250.102 124.47 250.18 123.746 250.48C123.022 250.779 122.404 251.287 121.969 251.938C121.534 252.589 121.302 253.354 121.302 254.137C121.298 254.658 121.397 255.174 121.595 255.657C121.792 256.139 122.084 256.577 122.452 256.945C122.821 257.313 123.259 257.605 123.741 257.802C124.224 258 124.74 258.1 125.261 258.096Z'} fill={'#F4BC22'} />
            <path d={'M115.65 241.414C116.432 241.408 117.194 241.171 117.842 240.733C118.489 240.294 118.992 239.674 119.287 238.95C119.582 238.226 119.656 237.431 119.5 236.665C119.343 235.899 118.964 235.197 118.409 234.646C117.854 234.095 117.148 233.721 116.381 233.571C115.614 233.421 114.819 233.501 114.097 233.801C113.376 234.102 112.759 234.609 112.325 235.26C111.891 235.91 111.66 236.674 111.66 237.456C111.66 237.978 111.763 238.495 111.964 238.978C112.165 239.46 112.459 239.898 112.83 240.266C113.201 240.634 113.641 240.925 114.125 241.122C114.609 241.319 115.127 241.418 115.65 241.414Z'} fill={'#F4BC22'} />
            <path d={'M109.695 223.102C111.882 223.102 113.655 221.33 113.655 219.144C113.655 216.957 111.882 215.185 109.695 215.185C107.508 215.185 105.735 216.957 105.735 219.144C105.735 221.33 107.508 223.102 109.695 223.102Z'} fill={'#F4BC22'} />
            <path d={'M107.67 203.883C108.448 203.883 109.209 203.652 109.856 203.219C110.503 202.786 111.007 202.171 111.303 201.451C111.6 200.731 111.676 199.94 111.522 199.177C111.367 198.414 110.99 197.714 110.438 197.166C109.885 196.618 109.182 196.246 108.418 196.098C107.654 195.95 106.863 196.032 106.145 196.334C105.428 196.636 104.816 197.144 104.388 197.794C103.96 198.444 103.734 199.207 103.74 199.985C103.748 201.022 104.166 202.013 104.902 202.743C105.638 203.473 106.633 203.883 107.67 203.883Z'} fill={'#F4BC22'} />
            <path d={'M109.696 184.725C110.475 184.731 111.239 184.505 111.89 184.075C112.541 183.646 113.049 183.033 113.35 182.314C113.651 181.595 113.732 180.802 113.581 180.037C113.431 179.273 113.056 178.57 112.504 178.019C111.953 177.467 111.25 177.093 110.485 176.942C109.72 176.791 108.927 176.872 108.208 177.173C107.489 177.474 106.875 177.982 106.446 178.633C106.016 179.283 105.79 180.047 105.796 180.827C105.796 181.86 106.207 182.852 106.938 183.583C107.67 184.314 108.661 184.725 109.696 184.725Z'} fill={'#F4BC22'} />
            <path d={'M115.651 166.413C116.428 166.413 117.188 166.182 117.834 165.751C118.48 165.319 118.984 164.706 119.281 163.988C119.578 163.27 119.656 162.48 119.505 161.718C119.353 160.956 118.979 160.256 118.429 159.707C117.88 159.157 117.18 158.783 116.417 158.631C115.655 158.48 114.865 158.558 114.147 158.855C113.429 159.152 112.815 159.656 112.383 160.302C111.952 160.948 111.721 161.707 111.721 162.484C111.721 163.526 112.135 164.525 112.872 165.262C113.609 165.999 114.608 166.413 115.651 166.413Z'} fill={'#F4BC22'} />
            <path d={'M125.262 149.731C126.041 149.737 126.803 149.512 127.453 149.084C128.104 148.656 128.612 148.045 128.914 147.327C129.216 146.61 129.298 145.819 129.15 145.055C129.002 144.291 128.63 143.588 128.082 143.036C127.533 142.484 126.833 142.106 126.07 141.952C125.307 141.798 124.515 141.874 123.796 142.171C123.076 142.467 122.46 142.971 122.027 143.617C121.594 144.264 121.363 145.025 121.363 145.803C121.363 146.84 121.773 147.834 122.503 148.57C123.233 149.306 124.225 149.723 125.262 149.731Z'} fill={'#F4BC22'} />
            <path d={'M138.17 135.409C140.34 135.409 142.1 133.65 142.1 131.481C142.1 129.311 140.34 127.552 138.17 127.552C136 127.552 134.241 129.311 134.241 131.481C134.241 133.65 136 135.409 138.17 135.409Z'} fill={'#F4BC22'} />
            <path d={'M153.767 124.077C154.545 124.077 155.304 123.846 155.951 123.414C156.597 122.983 157.1 122.369 157.398 121.651C157.695 120.934 157.773 120.144 157.621 119.382C157.47 118.62 157.096 117.92 156.546 117.37C155.996 116.821 155.296 116.447 154.534 116.295C153.772 116.144 152.982 116.221 152.264 116.519C151.546 116.816 150.932 117.32 150.5 117.966C150.068 118.612 149.838 119.371 149.838 120.148C149.838 121.19 150.252 122.189 150.989 122.926C151.726 123.663 152.725 124.077 153.767 124.077Z'} fill={'#F4BC22'} />
            <path d={'M171.358 116.25C172.136 116.256 172.899 116.031 173.549 115.603C174.199 115.175 174.708 114.564 175.01 113.846C175.312 113.129 175.394 112.338 175.246 111.574C175.098 110.81 174.726 110.108 174.178 109.555C173.629 109.003 172.929 108.626 172.166 108.472C171.403 108.318 170.611 108.394 169.891 108.69C169.172 108.986 168.556 109.49 168.123 110.137C167.69 110.783 167.459 111.544 167.459 112.322C167.459 113.359 167.868 114.353 168.599 115.089C169.329 115.825 170.321 116.243 171.358 116.25Z'} fill={'#F4BC22'} />
            <path d={'M190.223 112.231C191 112.231 191.76 112.001 192.406 111.569C193.052 111.137 193.556 110.524 193.853 109.806C194.15 109.088 194.228 108.298 194.077 107.536C193.925 106.774 193.551 106.074 193.001 105.525C192.452 104.975 191.751 104.601 190.989 104.45C190.227 104.298 189.437 104.376 188.719 104.673C188.001 104.971 187.387 105.474 186.955 106.12C186.524 106.766 186.293 107.526 186.293 108.303C186.293 109.345 186.707 110.344 187.444 111.08C188.181 111.817 189.18 112.231 190.223 112.231Z'} fill={'#F4BC22'} />
            <path d={'M209.508 112.26C210.291 112.26 211.057 112.028 211.708 111.593C212.359 111.158 212.867 110.54 213.166 109.816C213.466 109.093 213.545 108.297 213.392 107.529C213.239 106.761 212.862 106.056 212.308 105.502C211.754 104.949 211.049 104.572 210.281 104.419C209.512 104.266 208.716 104.345 207.993 104.644C207.269 104.944 206.651 105.451 206.216 106.102C205.78 106.753 205.548 107.519 205.548 108.302C205.548 108.821 205.651 109.336 205.85 109.816C206.049 110.297 206.34 110.733 206.708 111.101C207.076 111.468 207.512 111.76 207.993 111.959C208.473 112.158 208.988 112.26 209.508 112.26Z'} fill={'#F4BC22'} />
            <path d={'M228.338 116.28C229.122 116.28 229.887 116.048 230.538 115.613C231.189 115.178 231.697 114.559 231.997 113.836C232.296 113.113 232.375 112.317 232.222 111.549C232.069 110.781 231.692 110.076 231.138 109.522C230.585 108.968 229.879 108.591 229.111 108.439C228.343 108.286 227.547 108.364 226.823 108.664C226.099 108.964 225.481 109.471 225.046 110.122C224.611 110.773 224.379 111.538 224.379 112.321C224.379 112.841 224.481 113.356 224.68 113.836C224.879 114.316 225.171 114.753 225.538 115.12C225.906 115.488 226.343 115.78 226.823 115.978C227.303 116.177 227.818 116.28 228.338 116.28Z'} fill={'#F4BC22'} />
            <path d={'M245.96 124.106C248.147 124.106 249.92 122.333 249.92 120.147C249.92 117.961 248.147 116.188 245.96 116.188C243.773 116.188 242 117.961 242 120.147C242 122.333 243.773 124.106 245.96 124.106Z'} fill={'#F4BC22'} />
            <path d={'M261.557 135.438C262.34 135.438 263.105 135.206 263.757 134.771C264.408 134.336 264.915 133.718 265.215 132.995C265.515 132.271 265.593 131.475 265.44 130.707C265.288 129.94 264.91 129.234 264.357 128.681C263.803 128.127 263.097 127.75 262.329 127.597C261.561 127.444 260.765 127.523 260.041 127.822C259.318 128.122 258.699 128.629 258.264 129.28C257.829 129.931 257.597 130.697 257.597 131.48C257.597 132.53 258.014 133.537 258.757 134.279C259.499 135.021 260.506 135.438 261.557 135.438Z'} fill={'#F4BC22'} />
            <path d={'M274.435 149.762C276.622 149.762 278.395 147.99 278.395 145.804C278.395 143.617 276.622 141.845 274.435 141.845C272.249 141.845 270.476 143.617 270.476 145.804C270.476 147.99 272.249 149.762 274.435 149.762Z'} fill={'#F4BC22'} />
            <path d={'M284.08 166.443C286.267 166.443 288.04 164.671 288.04 162.485C288.04 160.298 286.267 158.526 284.08 158.526C281.893 158.526 280.12 160.298 280.12 162.485C280.12 164.671 281.893 166.443 284.08 166.443Z'} fill={'#F4BC22'} />
            <path d={'M290.184 184.786C290.976 184.792 291.751 184.562 292.412 184.126C293.073 183.69 293.589 183.068 293.894 182.337C294.2 181.607 294.282 180.803 294.129 180.026C293.976 179.25 293.595 178.536 293.035 177.977C292.476 177.417 291.762 177.036 290.985 176.883C290.208 176.73 289.404 176.812 288.673 177.118C287.943 177.423 287.32 177.939 286.884 178.6C286.448 179.26 286.218 180.036 286.224 180.827C286.224 181.877 286.641 182.884 287.384 183.626C288.127 184.369 289.134 184.786 290.184 184.786Z'} fill={'#F4BC22'} />
            <path d={'M307.412 203.461C308.108 203.466 308.79 203.265 309.372 202.882C309.953 202.5 310.407 201.952 310.676 201.311C310.945 200.669 311.017 199.962 310.883 199.279C310.749 198.596 310.414 197.969 309.922 197.477C309.43 196.985 308.802 196.65 308.119 196.516C307.436 196.382 306.729 196.454 306.087 196.723C305.445 196.992 304.897 197.446 304.514 198.027C304.132 198.608 303.93 199.29 303.936 199.985C303.944 200.905 304.313 201.784 304.963 202.434C305.613 203.084 306.493 203.453 307.412 203.461Z'} fill={'#F4BC22'} />
            <path d={'M305.691 222.619C306.373 222.625 307.043 222.428 307.613 222.053C308.184 221.678 308.63 221.143 308.896 220.514C309.161 219.885 309.234 219.191 309.104 218.521C308.975 217.851 308.649 217.234 308.168 216.749C307.688 216.264 307.074 215.933 306.404 215.798C305.735 215.663 305.041 215.729 304.409 215.989C303.778 216.249 303.238 216.69 302.858 217.257C302.478 217.824 302.275 218.492 302.275 219.174C302.275 220.083 302.634 220.954 303.274 221.6C303.913 222.245 304.782 222.611 305.691 222.619Z'} fill={'#F4BC22'} />
            <path d={'M300.55 241.204C301.232 241.204 301.898 241.002 302.465 240.623C303.031 240.245 303.473 239.707 303.734 239.077C303.995 238.448 304.063 237.755 303.93 237.087C303.797 236.419 303.469 235.805 302.987 235.323C302.505 234.841 301.891 234.513 301.223 234.38C300.554 234.247 299.861 234.316 299.232 234.576C298.602 234.837 298.064 235.279 297.685 235.845C297.307 236.412 297.104 237.078 297.104 237.759C297.104 238.673 297.467 239.549 298.114 240.195C298.76 240.841 299.636 241.204 300.55 241.204Z'} fill={'#F4BC22'} />
            <path d={'M292.21 258.61C292.905 258.61 293.584 258.403 294.161 258.017C294.738 257.63 295.188 257.081 295.452 256.439C295.717 255.796 295.784 255.09 295.646 254.409C295.509 253.728 295.172 253.104 294.678 252.615C294.185 252.126 293.557 251.795 292.875 251.663C292.193 251.531 291.487 251.605 290.847 251.875C290.207 252.145 289.661 252.599 289.28 253.179C288.898 253.759 288.697 254.44 288.703 255.135C288.703 255.593 288.794 256.048 288.971 256.471C289.147 256.895 289.406 257.279 289.732 257.602C290.058 257.926 290.445 258.181 290.87 258.354C291.295 258.527 291.751 258.614 292.21 258.61Z'} fill={'#F4BC22'} />
            <path d={'M280.874 274.201C281.569 274.201 282.248 273.994 282.825 273.608C283.403 273.221 283.852 272.672 284.116 272.03C284.381 271.388 284.449 270.681 284.311 270C284.173 269.32 283.836 268.695 283.343 268.206C282.849 267.717 282.222 267.386 281.54 267.254C280.857 267.122 280.151 267.196 279.511 267.466C278.871 267.736 278.326 268.19 277.944 268.77C277.562 269.35 277.362 270.031 277.368 270.726C277.368 271.185 277.459 271.639 277.635 272.062C277.812 272.486 278.071 272.871 278.397 273.194C278.723 273.517 279.109 273.772 279.535 273.945C279.96 274.118 280.415 274.205 280.874 274.201Z'} fill={'#F4BC22'} />
            <path d={'M266.908 287.527C267.604 287.533 268.286 287.332 268.867 286.949C269.448 286.566 269.902 286.019 270.172 285.378C270.441 284.736 270.513 284.029 270.378 283.346C270.244 282.663 269.909 282.036 269.417 281.544C268.925 281.052 268.298 280.717 267.615 280.583C266.932 280.449 266.224 280.521 265.582 280.79C264.94 281.059 264.393 281.513 264.01 282.094C263.627 282.675 263.426 283.357 263.432 284.052C263.44 284.972 263.808 285.851 264.458 286.501C265.109 287.151 265.988 287.52 266.908 287.527Z'} fill={'#F4BC22'} />
            <path d={'M250.886 298.164C251.58 298.164 252.258 297.958 252.834 297.573C253.411 297.188 253.86 296.64 254.126 296C254.391 295.359 254.46 294.655 254.325 293.975C254.19 293.295 253.856 292.67 253.366 292.18C252.875 291.69 252.25 291.356 251.57 291.22C250.89 291.085 250.185 291.155 249.544 291.42C248.904 291.685 248.356 292.134 247.971 292.711C247.585 293.287 247.38 293.965 247.38 294.658C247.38 295.588 247.749 296.48 248.407 297.137C249.064 297.794 249.956 298.164 250.886 298.164Z'} fill={'#F4BC22'} />
            <path d={'M233.085 305.718C233.783 305.748 234.474 305.569 235.069 305.204C235.664 304.839 236.137 304.305 236.427 303.669C236.716 303.034 236.809 302.326 236.694 301.638C236.579 300.949 236.26 300.31 235.78 299.804C235.299 299.297 234.678 298.946 233.996 298.794C233.314 298.643 232.602 298.699 231.952 298.954C231.302 299.21 230.743 299.654 230.347 300.229C229.951 300.804 229.736 301.485 229.73 302.183C229.713 303.094 230.056 303.976 230.683 304.637C231.311 305.298 232.173 305.687 233.085 305.718Z'} fill={'#F4BC22'} />
            <path d={'M214.313 309.979C214.993 309.973 215.656 309.766 216.219 309.384C216.782 309.001 217.219 308.461 217.475 307.831C217.731 307.201 217.795 306.51 217.659 305.844C217.522 305.177 217.192 304.566 216.709 304.088C216.225 303.609 215.611 303.284 214.944 303.153C214.276 303.023 213.585 303.093 212.957 303.354C212.329 303.616 211.793 304.057 211.415 304.623C211.038 305.189 210.837 305.854 210.837 306.534C210.837 306.989 210.927 307.439 211.102 307.859C211.277 308.279 211.533 308.66 211.857 308.981C212.18 309.301 212.563 309.554 212.985 309.725C213.406 309.897 213.858 309.983 214.313 309.979Z'} fill={'#F4BC22'} />
            <path d={'M195.027 310.856C195.716 310.862 196.391 310.663 196.967 310.285C197.543 309.907 197.993 309.366 198.261 308.732C198.528 308.097 198.601 307.398 198.471 306.722C198.34 306.046 198.011 305.424 197.526 304.935C197.042 304.446 196.422 304.112 195.747 303.975C195.072 303.838 194.371 303.905 193.735 304.168C193.098 304.43 192.553 304.875 192.17 305.447C191.786 306.019 191.582 306.692 191.582 307.381C191.582 308.297 191.944 309.177 192.589 309.828C193.234 310.479 194.111 310.848 195.027 310.856Z'} fill={'#F4BC22'} />
            <path d={'M175.924 308.228C176.606 308.228 177.272 308.026 177.839 307.647C178.405 307.269 178.847 306.731 179.108 306.101C179.369 305.472 179.437 304.779 179.304 304.111C179.171 303.443 178.843 302.829 178.361 302.347C177.879 301.865 177.265 301.537 176.596 301.404C175.928 301.271 175.235 301.34 174.605 301.6C173.976 301.861 173.437 302.303 173.059 302.869C172.68 303.436 172.478 304.102 172.478 304.783C172.478 305.235 172.567 305.683 172.74 306.101C172.914 306.519 173.167 306.899 173.487 307.219C173.807 307.539 174.187 307.792 174.605 307.966C175.023 308.139 175.472 308.228 175.924 308.228Z'} fill={'#F4BC22'} />
            <path d={'M157.574 302.183C158.271 302.195 158.954 301.997 159.537 301.615C160.119 301.234 160.574 300.686 160.841 300.043C161.109 299.4 161.177 298.692 161.037 298.01C160.897 297.328 160.555 296.704 160.056 296.218C159.557 295.733 158.924 295.408 158.238 295.287C157.553 295.165 156.846 295.252 156.211 295.537C155.575 295.821 155.04 296.29 154.674 296.883C154.308 297.475 154.129 298.164 154.159 298.859C154.197 299.744 154.572 300.581 155.207 301.199C155.842 301.816 156.689 302.169 157.574 302.183Z'} fill={'#F4BC22'} />
            <path d={'M140.617 293.149C142.52 293.149 144.063 291.607 144.063 289.705C144.063 287.802 142.52 286.26 140.617 286.26C138.714 286.26 137.171 287.802 137.171 289.705C137.171 291.607 138.714 293.149 140.617 293.149Z'} fill={'#F4BC22'} />
            <path d={'M125.535 281.03C126.225 281.036 126.901 280.837 127.478 280.457C128.054 280.078 128.504 279.535 128.771 278.899C129.038 278.263 129.109 277.562 128.976 276.885C128.843 276.208 128.511 275.586 128.023 275.098C127.535 274.61 126.913 274.279 126.236 274.145C125.559 274.012 124.858 274.084 124.221 274.351C123.585 274.617 123.042 275.067 122.663 275.643C122.283 276.22 122.083 276.896 122.089 277.585C122.089 278.499 122.452 279.375 123.099 280.021C123.745 280.667 124.621 281.03 125.535 281.03Z'} fill={'#F4BC22'} />
            <path d={'M112.839 266.616C113.521 266.616 114.187 266.414 114.754 266.035C115.321 265.657 115.762 265.119 116.023 264.489C116.284 263.86 116.352 263.167 116.219 262.499C116.086 261.831 115.758 261.217 115.276 260.735C114.794 260.253 114.18 259.925 113.512 259.792C112.843 259.659 112.15 259.727 111.521 259.988C110.891 260.249 110.353 260.69 109.974 261.257C109.596 261.823 109.394 262.489 109.394 263.171C109.394 264.084 109.757 264.961 110.403 265.607C111.049 266.253 111.926 266.616 112.839 266.616Z'} fill={'#F4BC22'} />
            <path d={'M102.956 250.087C103.646 250.093 104.322 249.894 104.899 249.514C105.475 249.135 105.925 248.592 106.192 247.956C106.459 247.32 106.53 246.619 106.397 245.942C106.264 245.265 105.932 244.643 105.444 244.155C104.956 243.667 104.334 243.336 103.657 243.202C102.98 243.069 102.278 243.141 101.642 243.407C101.006 243.674 100.463 244.124 100.083 244.7C99.7037 245.276 99.5041 245.953 99.5101 246.642C99.5101 247.556 99.8732 248.432 100.519 249.078C101.166 249.724 102.042 250.087 102.956 250.087Z'} fill={'#F4BC22'} />
            <path d={'M96.1849 232.017C96.8665 232.017 97.5327 231.815 98.0994 231.436C98.6661 231.058 99.1077 230.52 99.3686 229.89C99.6294 229.261 99.6976 228.568 99.5646 227.9C99.4317 227.232 99.1035 226.618 98.6216 226.136C98.1396 225.654 97.5256 225.326 96.8572 225.193C96.1887 225.061 95.4959 225.129 94.8662 225.39C94.2366 225.65 93.6984 226.092 93.3197 226.658C92.9411 227.225 92.739 227.891 92.739 228.572C92.739 229.486 93.102 230.362 93.7483 231.008C94.3945 231.654 95.271 232.017 96.1849 232.017Z'} fill={'#F4BC22'} />
            <path d={'M92.7381 213.07C93.4282 213.076 94.1044 212.877 94.6807 212.497C95.2569 212.118 95.7071 211.575 95.974 210.939C96.2408 210.303 96.3122 209.602 96.179 208.925C96.0459 208.248 95.7142 207.626 95.2262 207.138C94.7383 206.65 94.1161 206.319 93.439 206.185C92.7619 206.052 92.0605 206.124 91.4241 206.39C90.7877 206.657 90.2451 207.107 89.8654 207.683C89.4858 208.259 89.2862 208.935 89.2922 209.625C89.2922 210.539 89.6552 211.415 90.3015 212.061C90.9477 212.707 91.8242 213.07 92.7381 213.07Z'} fill={'#F4BC22'} />
            <path d={'M92.7394 193.729C93.4175 193.729 94.0803 193.527 94.6432 193.149C95.2062 192.772 95.6438 192.235 95.9005 191.607C96.1571 190.98 96.2212 190.29 96.0844 189.626C95.9476 188.962 95.6162 188.354 95.1325 187.879C94.6487 187.404 94.0345 187.083 93.3679 186.959C92.7014 186.834 92.0128 186.91 91.3898 187.178C90.7669 187.445 90.2377 187.892 89.8697 188.462C89.5018 189.031 89.3117 189.697 89.3237 190.375C89.3395 191.27 89.7064 192.123 90.3453 192.751C90.9841 193.378 91.8439 193.73 92.7394 193.729Z'} fill={'#F4BC22'} />
            <path d={'M96.1812 174.783C98.0677 174.783 99.5969 173.254 99.5969 171.368C99.5969 169.483 98.0677 167.954 96.1812 167.954C94.2948 167.954 92.7655 169.483 92.7655 171.368C92.7655 173.254 94.2948 174.783 96.1812 174.783Z'} fill={'#F4BC22'} />
            <path d={'M102.956 156.742C103.632 156.742 104.292 156.542 104.854 156.167C105.415 155.791 105.853 155.258 106.112 154.634C106.37 154.01 106.438 153.324 106.306 152.661C106.174 151.999 105.849 151.39 105.371 150.913C104.894 150.435 104.285 150.11 103.622 149.978C102.96 149.847 102.273 149.914 101.649 150.173C101.025 150.431 100.491 150.869 100.116 151.43C99.7406 151.992 99.5403 152.652 99.5403 153.327C99.5482 154.231 99.9106 155.094 100.549 155.733C101.188 156.372 102.053 156.734 102.956 156.742Z'} fill={'#F4BC22'} />
            <path d={'M112.839 140.184C113.515 140.184 114.175 139.983 114.737 139.608C115.299 139.233 115.736 138.7 115.995 138.076C116.253 137.452 116.321 136.765 116.189 136.103C116.057 135.44 115.732 134.832 115.254 134.354C114.777 133.877 114.168 133.552 113.506 133.42C112.843 133.288 112.156 133.356 111.532 133.614C110.908 133.873 110.374 134.31 109.999 134.872C109.624 135.433 109.423 136.093 109.423 136.769C109.431 137.672 109.794 138.536 110.433 139.175C111.071 139.813 111.936 140.176 112.839 140.184Z'} fill={'#F4BC22'} />
            <path d={'M125.536 125.647C126.213 125.647 126.875 125.446 127.437 125.069C127.999 124.693 128.437 124.158 128.695 123.532C128.952 122.906 129.018 122.218 128.884 121.555C128.75 120.892 128.421 120.283 127.941 119.807C127.46 119.331 126.848 119.008 126.184 118.88C125.519 118.751 124.832 118.823 124.208 119.086C123.584 119.349 123.053 119.792 122.681 120.357C122.31 120.923 122.115 121.586 122.12 122.263C122.128 123.163 122.492 124.024 123.132 124.658C123.771 125.291 124.635 125.647 125.536 125.647Z'} fill={'#F4BC22'} />
            <path d={'M140.618 113.65C141.293 113.65 141.954 113.45 142.515 113.075C143.077 112.7 143.515 112.166 143.774 111.542C144.032 110.918 144.1 110.232 143.968 109.569C143.836 108.907 143.511 108.299 143.033 107.821C142.555 107.344 141.947 107.018 141.284 106.887C140.622 106.755 139.935 106.822 139.311 107.081C138.687 107.339 138.153 107.777 137.778 108.339C137.402 108.9 137.202 109.56 137.202 110.236C137.202 111.141 137.562 112.01 138.203 112.65C138.843 113.291 139.712 113.65 140.618 113.65Z'} fill={'#F4BC22'} />
            <path d={'M157.576 104.526C159.462 104.526 160.991 102.997 160.991 101.111C160.991 99.225 159.462 97.6962 157.576 97.6962C155.689 97.6962 154.16 99.225 154.16 101.111C154.16 102.997 155.689 104.526 157.576 104.526Z'} fill={'#F4BC22'} />
            <path d={'M175.924 98.5418C176.601 98.5418 177.262 98.3407 177.825 97.9642C178.387 97.5876 178.825 97.0525 179.083 96.4268C179.34 95.8011 179.406 95.113 179.272 94.4498C179.137 93.7866 178.809 93.1782 178.328 92.7019C177.848 92.2255 177.236 91.9027 176.572 91.7744C175.907 91.6461 175.219 91.718 174.596 91.9811C173.972 92.2442 173.441 92.6866 173.069 93.2521C172.698 93.8176 172.502 94.4807 172.508 95.1573C172.516 96.0577 172.88 96.9185 173.519 97.5524C174.159 98.1862 175.023 98.5419 175.924 98.5418Z'} fill={'#F4BC22'} />
            <path d={'M195.027 95.9733C195.702 95.9733 196.363 95.773 196.924 95.3978C197.486 95.0226 197.924 94.4893 198.182 93.8654C198.441 93.2414 198.509 92.5549 198.377 91.8925C198.245 91.2301 197.92 90.6216 197.442 90.1441C196.964 89.6665 196.356 89.3413 195.693 89.2096C195.031 89.0778 194.344 89.1454 193.72 89.4039C193.096 89.6623 192.562 90.1 192.187 90.6615C191.811 91.2231 191.611 91.8833 191.611 92.5586C191.611 93.0071 191.699 93.4511 191.871 93.8654C192.043 94.2797 192.294 94.6561 192.612 94.9732C192.929 95.2903 193.305 95.5418 193.72 95.7134C194.134 95.885 194.578 95.9733 195.027 95.9733Z'} fill={'#F4BC22'} />
            <path d={'M214.313 96.8797C215.002 96.8796 215.675 96.675 216.247 96.2918C216.82 95.9086 217.265 95.3641 217.527 94.7273C217.789 94.0906 217.856 93.3903 217.72 92.7154C217.583 92.0405 217.249 91.4213 216.76 90.9366C216.271 90.4518 215.649 90.1232 214.972 89.9925C214.296 89.8619 213.596 89.935 212.962 90.2027C212.327 90.4704 211.786 90.9205 211.408 91.4959C211.03 92.0714 210.831 92.7462 210.837 93.4348C210.837 93.8897 210.927 94.3401 211.102 94.7601C211.277 95.18 211.534 95.5611 211.857 95.8814C212.18 96.2017 212.563 96.4548 212.985 96.6262C213.407 96.7975 213.858 96.8837 214.313 96.8797Z'} fill={'#F4BC22'} />
            <path d={'M233.084 101.171C233.776 101.195 234.459 101.012 235.047 100.645C235.634 100.278 236.098 99.7438 236.38 99.1113C236.662 98.4788 236.748 97.7766 236.628 97.0947C236.508 96.4128 236.187 95.7824 235.706 95.2842C235.225 94.786 234.606 94.4428 233.928 94.2987C233.251 94.1545 232.546 94.2159 231.904 94.475C231.261 94.7341 230.711 95.1791 230.324 95.7529C229.936 96.3267 229.729 97.0031 229.728 97.6955C229.72 98.5989 230.067 99.4693 230.695 100.119C231.322 100.769 232.18 101.147 233.084 101.171Z'} fill={'#F4BC22'} />
            <path d={'M250.89 108.786C251.572 108.786 252.238 108.584 252.805 108.205C253.371 107.826 253.813 107.288 254.074 106.659C254.335 106.03 254.403 105.337 254.27 104.669C254.137 104 253.809 103.387 253.327 102.905C252.845 102.423 252.231 102.095 251.562 101.962C250.894 101.829 250.201 101.897 249.571 102.158C248.942 102.419 248.404 102.86 248.025 103.427C247.646 103.993 247.444 104.659 247.444 105.341C247.444 106.254 247.807 107.131 248.454 107.777C249.1 108.423 249.976 108.786 250.89 108.786Z'} fill={'#F4BC22'} />
            <path d={'M266.909 119.362C267.599 119.368 268.275 119.169 268.851 118.789C269.427 118.41 269.878 117.867 270.144 117.231C270.411 116.595 270.483 115.894 270.35 115.217C270.216 114.54 269.885 113.918 269.397 113.43C268.909 112.942 268.287 112.611 267.609 112.478C266.932 112.345 266.231 112.416 265.595 112.683C264.958 112.949 264.416 113.4 264.036 113.976C263.656 114.552 263.457 115.228 263.463 115.918C263.463 116.831 263.826 117.707 264.472 118.353C265.118 119 265.995 119.362 266.909 119.362Z'} fill={'#F4BC22'} />
            <path d={'M280.874 132.688C281.563 132.688 282.236 132.484 282.809 132.1C283.381 131.717 283.826 131.173 284.089 130.536C284.351 129.899 284.418 129.199 284.281 128.524C284.145 127.849 283.81 127.23 283.321 126.745C282.832 126.26 282.21 125.932 281.534 125.801C280.857 125.67 280.158 125.744 279.523 126.011C278.888 126.279 278.348 126.729 277.969 127.304C277.591 127.88 277.392 128.555 277.398 129.243C277.398 129.698 277.488 130.149 277.663 130.569C277.838 130.989 278.095 131.37 278.418 131.69C278.741 132.01 279.125 132.263 279.546 132.435C279.968 132.606 280.419 132.692 280.874 132.688Z'} fill={'#F4BC22'} />
            <path d={'M292.209 148.281C292.898 148.281 293.571 148.077 294.144 147.694C294.716 147.31 295.162 146.766 295.424 146.129C295.686 145.492 295.753 144.792 295.616 144.117C295.48 143.442 295.146 142.823 294.657 142.338C294.167 141.854 293.545 141.525 292.869 141.394C292.193 141.264 291.493 141.337 290.858 141.604C290.223 141.872 289.683 142.322 289.304 142.898C288.926 143.473 288.727 144.148 288.733 144.837C288.733 145.291 288.823 145.742 288.998 146.162C289.173 146.582 289.43 146.963 289.753 147.283C290.076 147.603 290.46 147.857 290.881 148.028C291.303 148.199 291.754 148.285 292.209 148.281Z'} fill={'#F4BC22'} />
            <path d={'M300.55 165.657C301.239 165.663 301.914 165.465 302.49 165.086C303.065 164.708 303.516 164.168 303.783 163.533C304.051 162.899 304.124 162.199 303.994 161.523C303.863 160.847 303.534 160.225 303.049 159.736C302.564 159.247 301.945 158.913 301.27 158.776C300.595 158.64 299.894 158.707 299.257 158.969C298.62 159.231 298.076 159.676 297.692 160.248C297.309 160.82 297.104 161.494 297.104 162.182C297.104 163.099 297.466 163.978 298.112 164.629C298.757 165.28 299.634 165.649 300.55 165.657Z'} fill={'#F4BC22'} />
            <path d={'M305.69 184.332C306.367 184.337 307.031 184.143 307.599 183.773C308.167 183.403 308.613 182.874 308.881 182.253C309.15 181.631 309.229 180.943 309.109 180.277C308.989 179.61 308.675 178.994 308.207 178.504C307.738 178.015 307.135 177.675 306.474 177.526C305.813 177.377 305.123 177.427 304.49 177.668C303.857 177.91 303.309 178.332 302.915 178.883C302.521 179.434 302.298 180.089 302.274 180.766C302.258 181.226 302.334 181.684 302.498 182.114C302.662 182.544 302.91 182.937 303.229 183.27C303.547 183.602 303.929 183.867 304.352 184.05C304.774 184.232 305.229 184.328 305.69 184.332Z'} fill={'#F4BC22'} />
            <path d={'M322.798 202.917C323.396 202.917 323.98 202.74 324.478 202.408C324.975 202.076 325.362 201.604 325.591 201.052C325.82 200.5 325.879 199.892 325.763 199.306C325.646 198.72 325.358 198.181 324.936 197.759C324.513 197.336 323.974 197.048 323.388 196.932C322.802 196.815 322.194 196.875 321.641 197.104C321.089 197.332 320.617 197.72 320.285 198.217C319.953 198.714 319.775 199.298 319.775 199.896C319.775 200.697 320.094 201.466 320.661 202.032C321.228 202.599 321.996 202.917 322.798 202.917Z'} fill={'#F4BC22'} />
            <path d={'M321.256 222.135C322.875 222.135 324.188 220.823 324.188 219.204C324.188 217.585 322.875 216.273 321.256 216.273C319.636 216.273 318.323 217.585 318.323 219.204C318.323 220.823 319.636 222.135 321.256 222.135Z'} fill={'#F4BC22'} />
            <path d={'M316.755 240.87C318.375 240.87 319.688 239.558 319.688 237.939C319.688 236.32 318.375 235.008 316.755 235.008C315.136 235.008 313.823 236.32 313.823 237.939C313.823 239.558 315.136 240.87 316.755 240.87Z'} fill={'#F4BC22'} />
            <path d={'M309.379 258.7C310.998 258.7 312.311 257.388 312.311 255.769C312.311 254.15 310.998 252.838 309.379 252.838C307.759 252.838 306.447 254.15 306.447 255.769C306.447 257.388 307.759 258.7 309.379 258.7Z'} fill={'#F4BC22'} />
            <path d={'M299.252 275.137C299.85 275.137 300.434 274.96 300.932 274.628C301.429 274.296 301.816 273.824 302.045 273.272C302.274 272.719 302.333 272.112 302.217 271.526C302.1 270.94 301.812 270.401 301.39 269.979C300.967 269.556 300.428 269.268 299.842 269.151C299.256 269.035 298.648 269.095 298.095 269.323C297.543 269.552 297.071 269.939 296.739 270.436C296.407 270.933 296.229 271.518 296.229 272.115C296.229 272.917 296.548 273.685 297.115 274.252C297.682 274.819 298.45 275.137 299.252 275.137Z'} fill={'#F4BC22'} />
            <path d={'M286.768 289.793C288.387 289.793 289.7 288.48 289.7 286.861C289.7 285.243 288.387 283.93 286.768 283.93C285.149 283.93 283.836 285.243 283.836 286.861C283.836 288.48 285.149 289.793 286.768 289.793Z'} fill={'#F4BC22'} />
            <path d={'M272.108 302.335C273.727 302.335 275.04 301.022 275.04 299.403C275.04 297.785 273.727 296.472 272.108 296.472C270.488 296.472 269.175 297.785 269.175 299.403C269.175 301.022 270.488 302.335 272.108 302.335Z'} fill={'#F4BC22'} />
            <path d={'M255.664 312.398C256.262 312.398 256.846 312.22 257.343 311.888C257.84 311.556 258.228 311.084 258.457 310.532C258.685 309.98 258.745 309.372 258.629 308.786C258.512 308.2 258.224 307.662 257.801 307.239C257.379 306.816 256.84 306.529 256.254 306.412C255.667 306.295 255.06 306.355 254.507 306.584C253.955 306.813 253.483 307.2 253.151 307.697C252.818 308.194 252.641 308.778 252.641 309.376C252.641 310.177 252.96 310.946 253.527 311.513C254.093 312.079 254.862 312.398 255.664 312.398Z'} fill={'#F4BC22'} />
            <path d={'M237.861 319.801C238.459 319.801 239.044 319.623 239.541 319.291C240.038 318.959 240.425 318.487 240.654 317.935C240.883 317.383 240.943 316.775 240.826 316.189C240.709 315.603 240.422 315.065 239.999 314.642C239.576 314.219 239.037 313.932 238.451 313.815C237.865 313.698 237.257 313.758 236.705 313.987C236.152 314.216 235.68 314.603 235.348 315.1C235.016 315.597 234.839 316.181 234.839 316.779C234.839 317.58 235.157 318.349 235.724 318.915C236.291 319.482 237.06 319.801 237.861 319.801Z'} fill={'#F4BC22'} />
            <path d={'M219.088 324.274C219.685 324.274 220.27 324.097 220.767 323.765C221.264 323.433 221.651 322.961 221.88 322.409C222.109 321.857 222.169 321.249 222.052 320.663C221.936 320.077 221.648 319.538 221.225 319.115C220.802 318.693 220.264 318.405 219.677 318.288C219.091 318.172 218.483 318.232 217.931 318.46C217.379 318.689 216.906 319.076 216.574 319.573C216.242 320.07 216.065 320.655 216.065 321.252C216.065 322.054 216.383 322.822 216.95 323.389C217.517 323.956 218.286 324.274 219.088 324.274Z'} fill={'#F4BC22'} />
            <path d={'M199.863 325.785C201.482 325.785 202.795 324.472 202.795 322.853C202.795 321.235 201.482 319.922 199.863 319.922C198.244 319.922 196.931 321.235 196.931 322.853C196.931 324.472 198.244 325.785 199.863 325.785Z'} fill={'#F4BC22'} />
            <path d={'M180.638 324.272C181.236 324.272 181.82 324.095 182.317 323.763C182.814 323.431 183.202 322.959 183.43 322.407C183.659 321.855 183.719 321.247 183.603 320.661C183.486 320.075 183.198 319.536 182.775 319.114C182.353 318.691 181.814 318.403 181.228 318.287C180.641 318.17 180.033 318.23 179.481 318.459C178.929 318.687 178.457 319.075 178.125 319.572C177.792 320.068 177.615 320.653 177.615 321.25C177.615 322.052 177.934 322.82 178.5 323.387C179.067 323.954 179.836 324.272 180.638 324.272Z'} fill={'#F4BC22'} />
            <path d={'M161.868 319.8C162.466 319.8 163.051 319.623 163.548 319.291C164.045 318.959 164.432 318.487 164.661 317.935C164.89 317.383 164.95 316.775 164.833 316.189C164.716 315.603 164.429 315.064 164.006 314.642C163.583 314.219 163.044 313.931 162.458 313.815C161.872 313.698 161.264 313.758 160.712 313.987C160.159 314.215 159.687 314.603 159.355 315.1C159.023 315.596 158.846 316.181 158.846 316.778C158.846 317.58 159.164 318.348 159.731 318.915C160.298 319.482 161.067 319.8 161.868 319.8Z'} fill={'#F4BC22'} />
            <path d={'M144.064 312.398C144.662 312.398 145.247 312.22 145.744 311.888C146.241 311.556 146.628 311.084 146.857 310.532C147.086 309.98 147.146 309.372 147.029 308.786C146.912 308.2 146.624 307.662 146.202 307.239C145.779 306.816 145.24 306.529 144.654 306.412C144.068 306.295 143.46 306.355 142.908 306.584C142.355 306.813 141.883 307.2 141.551 307.697C141.219 308.194 141.042 308.778 141.042 309.376C141.042 310.177 141.36 310.946 141.927 311.513C142.494 312.079 143.263 312.398 144.064 312.398Z'} fill={'#F4BC22'} />
            <path d={'M127.619 302.334C129.238 302.334 130.551 301.022 130.551 299.403C130.551 297.784 129.238 296.472 127.619 296.472C126 296.472 124.687 297.784 124.687 299.403C124.687 301.022 126 302.334 127.619 302.334Z'} fill={'#F4BC22'} />
            <path d={'M112.928 289.794C114.548 289.794 115.86 288.482 115.86 286.863C115.86 285.244 114.548 283.932 112.928 283.932C111.309 283.932 109.996 285.244 109.996 286.863C109.996 288.482 111.309 289.794 112.928 289.794Z'} fill={'#F4BC22'} />
            <path d={'M100.418 275.138C102.037 275.138 103.35 273.825 103.35 272.206C103.35 270.588 102.037 269.275 100.418 269.275C98.7982 269.275 97.4855 270.588 97.4855 272.206C97.4855 273.825 98.7982 275.138 100.418 275.138Z'} fill={'#F4BC22'} />
            <path d={'M90.3181 258.668C90.9215 258.698 91.5201 258.547 92.0366 258.234C92.5532 257.921 92.964 257.46 93.2162 256.911C93.4683 256.362 93.5501 255.751 93.4511 255.155C93.3521 254.559 93.0768 254.007 92.6608 253.569C92.2447 253.131 91.7069 252.828 91.1168 252.698C90.5267 252.569 89.9114 252.619 89.3502 252.843C88.789 253.067 88.3077 253.453 87.9684 253.953C87.6291 254.453 87.4473 255.042 87.4465 255.646C87.4456 256.422 87.7432 257.169 88.2778 257.731C88.8124 258.294 89.5429 258.629 90.3181 258.668Z'} fill={'#F4BC22'} />
            <path d={'M82.9443 240.84C83.5422 240.84 84.1266 240.663 84.6237 240.331C85.1208 239.999 85.5082 239.527 85.737 238.975C85.9658 238.423 86.0256 237.815 85.909 237.229C85.7924 236.643 85.5045 236.104 85.0817 235.682C84.659 235.259 84.1204 234.971 83.534 234.855C82.9477 234.738 82.3399 234.798 81.7876 235.027C81.2352 235.255 80.7631 235.643 80.431 236.14C80.0989 236.637 79.9216 237.221 79.9216 237.819C79.9216 238.62 80.24 239.389 80.8069 239.955C81.3738 240.522 82.1426 240.84 82.9443 240.84Z'} fill={'#F4BC22'} />
            <path d={'M78.5907 222.105C79.1886 222.105 79.773 221.928 80.2701 221.596C80.7672 221.264 81.1546 220.792 81.3834 220.24C81.6122 219.688 81.672 219.08 81.5554 218.494C81.4388 217.908 81.1509 217.369 80.7282 216.947C80.3054 216.524 79.7668 216.236 79.1805 216.12C78.5941 216.003 77.9863 216.063 77.434 216.292C76.8817 216.52 76.4096 216.908 76.0774 217.405C75.7453 217.902 75.568 218.486 75.568 219.084C75.568 219.885 75.8865 220.654 76.4533 221.22C77.0202 221.787 77.7891 222.105 78.5907 222.105Z'} fill={'#F4BC22'} />
            <path d={'M76.9282 202.886C77.5261 202.886 78.1105 202.708 78.6076 202.376C79.1047 202.044 79.4921 201.572 79.7209 201.02C79.9497 200.468 80.0095 199.86 79.8929 199.274C79.7763 198.688 79.4884 198.15 79.0657 197.727C78.6429 197.304 78.1043 197.017 77.518 196.9C76.9316 196.783 76.3238 196.843 75.7715 197.072C75.2191 197.301 74.7471 197.688 74.4149 198.185C74.0828 198.682 73.9055 199.266 73.9055 199.864C73.9055 200.665 74.224 201.434 74.7908 202.001C75.3577 202.567 76.1266 202.886 76.9282 202.886Z'} fill={'#F4BC22'} />
            <path d={'M78.5923 183.636C79.1902 183.636 79.7746 183.459 80.2717 183.127C80.7688 182.795 81.1562 182.323 81.385 181.77C81.6138 181.218 81.6736 180.611 81.557 180.025C81.4404 179.438 81.1525 178.9 80.7297 178.477C80.307 178.055 79.7684 177.767 79.182 177.65C78.5957 177.534 77.9879 177.594 77.4356 177.822C76.8833 178.051 76.4112 178.438 76.079 178.935C75.7469 179.432 75.5696 180.016 75.5696 180.614C75.5696 181.415 75.8881 182.184 76.4549 182.751C77.0218 183.317 77.7907 183.636 78.5923 183.636Z'} fill={'#F4BC22'} />
            <path d={'M82.9443 164.901C83.5421 164.901 84.1265 164.723 84.6236 164.391C85.1207 164.059 85.5081 163.587 85.7369 163.035C85.9657 162.483 86.0255 161.875 85.9089 161.289C85.7923 160.703 85.5044 160.165 85.0816 159.742C84.6589 159.319 84.1203 159.032 83.534 158.915C82.9476 158.798 82.3398 158.858 81.7875 159.087C81.2352 159.316 80.7631 159.703 80.4309 160.2C80.0988 160.697 79.9215 161.281 79.9215 161.879C79.9215 162.68 80.24 163.449 80.8069 164.015C81.3737 164.582 82.1426 164.901 82.9443 164.901Z'} fill={'#F4BC22'} />
            <path d={'M90.3192 147.073C90.7716 147.213 91.2507 147.245 91.7178 147.167C92.1849 147.088 92.627 146.901 93.0084 146.62C93.3898 146.339 93.6998 145.972 93.9135 145.55C94.1272 145.127 94.2385 144.66 94.2385 144.187C94.2385 143.713 94.1272 143.246 93.9135 142.824C93.6998 142.401 93.3898 142.034 93.0084 141.754C92.627 141.473 92.1849 141.285 91.7178 141.207C91.2507 141.128 90.7716 141.16 90.3192 141.301C89.7032 141.492 89.1645 141.875 88.782 142.395C88.3994 142.914 88.193 143.542 88.193 144.187C88.193 144.832 88.3994 145.46 88.782 145.979C89.1645 146.498 89.7032 146.881 90.3192 147.073Z'} fill={'#F4BC22'} />
            <path d={'M100.418 130.633C102.02 130.633 103.32 129.334 103.32 127.732C103.32 126.13 102.02 124.831 100.418 124.831C98.815 124.831 97.5158 126.13 97.5158 127.732C97.5158 129.334 98.815 130.633 100.418 130.633Z'} fill={'#F4BC22'} />
            <path d={'M112.929 115.977C114.532 115.977 115.831 114.678 115.831 113.076C115.831 111.474 114.532 110.175 112.929 110.175C111.326 110.175 110.027 111.474 110.027 113.076C110.027 114.678 111.326 115.977 112.929 115.977Z'} fill={'#F4BC22'} />
            <path d={'M127.619 103.467C128.217 103.467 128.801 103.29 129.298 102.958C129.795 102.626 130.183 102.154 130.411 101.601C130.64 101.049 130.7 100.442 130.583 99.8555C130.467 99.2693 130.179 98.7309 129.756 98.3083C129.333 97.8856 128.795 97.5978 128.208 97.4812C127.622 97.3646 127.014 97.4245 126.462 97.6532C125.91 97.8819 125.438 98.2692 125.105 98.7662C124.773 99.2631 124.596 99.8474 124.596 100.445C124.596 101.246 124.914 102.015 125.481 102.582C126.048 103.148 126.817 103.467 127.619 103.467Z'} fill={'#F4BC22'} />
            <path d={'M144.064 93.375C144.662 93.375 145.247 93.1978 145.744 92.8657C146.241 92.5337 146.628 92.0617 146.857 91.5095C147.086 90.9574 147.146 90.3498 147.029 89.7636C146.912 89.1774 146.624 88.639 146.202 88.2164C145.779 87.7938 145.24 87.5059 144.654 87.3894C144.068 87.2728 143.46 87.3326 142.908 87.5613C142.355 87.79 141.883 88.1773 141.551 88.6743C141.219 89.1712 141.042 89.7555 141.042 90.3531C141.042 91.1546 141.36 91.9232 141.927 92.4899C142.494 93.0566 143.263 93.375 144.064 93.375Z'} fill={'#F4BC22'} />
            <path d={'M161.869 86.0007C162.466 86.0007 163.051 85.8235 163.548 85.4914C164.045 85.1594 164.432 84.6874 164.661 84.1353C164.89 83.5831 164.95 82.9755 164.833 82.3893C164.717 81.8031 164.429 81.2647 164.006 80.8421C163.583 80.4195 163.045 80.1317 162.458 80.0151C161.872 79.8985 161.264 79.9583 160.712 80.187C160.159 80.4158 159.687 80.8031 159.355 81.3C159.023 81.7969 158.846 82.3812 158.846 82.9789C158.846 83.7803 159.164 84.5489 159.731 85.1156C160.298 85.6823 161.067 86.0007 161.869 86.0007Z'} fill={'#F4BC22'} />
            <path d={'M180.642 81.498C182.245 81.498 183.544 80.1992 183.544 78.597C183.544 76.9949 182.245 75.6961 180.642 75.6961C179.039 75.6961 177.74 76.9949 177.74 78.597C177.74 80.1992 179.039 81.498 180.642 81.498Z'} fill={'#F4BC22'} />
            <path d={'M199.863 79.9873C200.461 79.9873 201.045 79.8101 201.542 79.478C202.039 79.146 202.427 78.674 202.655 78.1219C202.884 77.5697 202.944 76.9621 202.827 76.3759C202.711 75.7897 202.423 75.2513 202 74.8287C201.577 74.4061 201.039 74.1183 200.452 74.0017C199.866 73.8851 199.258 73.9449 198.706 74.1736C198.154 74.4023 197.682 74.7897 197.349 75.2866C197.017 75.7835 196.84 76.3678 196.84 76.9655C196.84 77.7669 197.158 78.5355 197.725 79.1022C198.292 79.6689 199.061 79.9873 199.863 79.9873Z'} fill={'#F4BC22'} />
            <path d={'M219.088 81.498C220.691 81.498 221.99 80.1992 221.99 78.597C221.99 76.9949 220.691 75.6961 219.088 75.6961C217.485 75.6961 216.186 76.9949 216.186 78.597C216.186 80.1992 217.485 81.498 219.088 81.498Z'} fill={'#F4BC22'} />
            <path d={'M237.858 86C239.461 86 240.76 84.7012 240.76 83.099C240.76 81.4969 239.461 80.1981 237.858 80.1981C236.255 80.1981 234.956 81.4969 234.956 83.099C234.956 84.7012 236.255 86 237.858 86Z'} fill={'#F4BC22'} />
            <path d={'M255.664 93.3751C256.262 93.3751 256.846 93.1979 257.343 92.8658C257.84 92.5338 258.228 92.0618 258.457 91.5097C258.685 90.9575 258.745 90.3499 258.629 89.7637C258.512 89.1775 258.224 88.6391 257.801 88.2165C257.379 87.7939 256.84 87.5061 256.254 87.3895C255.667 87.2729 255.059 87.3327 254.507 87.5614C253.955 87.7902 253.483 88.1775 253.151 88.6744C252.818 89.1713 252.641 89.7556 252.641 90.3533C252.641 91.1547 252.96 91.9233 253.526 92.49C254.093 93.0567 254.862 93.3751 255.664 93.3751Z'} fill={'#F4BC22'} />
            <path d={'M272.046 103.497C272.644 103.497 273.228 103.32 273.725 102.988C274.223 102.656 274.61 102.184 274.839 101.632C275.068 101.079 275.127 100.472 275.011 99.8856C274.894 99.2994 274.606 98.761 274.184 98.3383C273.761 97.9157 273.222 97.6279 272.636 97.5113C272.049 97.3947 271.442 97.4546 270.889 97.6833C270.337 97.912 269.865 98.2993 269.533 98.7963C269.201 99.2932 269.023 99.8774 269.023 100.475C269.023 101.277 269.342 102.045 269.909 102.612C270.476 103.179 271.244 103.497 272.046 103.497Z'} fill={'#F4BC22'} />
            <path d={'M286.768 116.007C288.387 116.007 289.7 114.695 289.7 113.076C289.7 111.457 288.387 110.145 286.768 110.145C285.149 110.145 283.836 111.457 283.836 113.076C283.836 114.695 285.149 116.007 286.768 116.007Z'} fill={'#F4BC22'} />
            <path d={'M299.252 130.693C299.85 130.693 300.435 130.516 300.932 130.184C301.429 129.852 301.816 129.38 302.045 128.828C302.274 128.276 302.334 127.668 302.217 127.082C302.1 126.496 301.813 125.957 301.39 125.535C300.967 125.112 300.428 124.824 299.842 124.708C299.256 124.591 298.648 124.651 298.096 124.88C297.543 125.108 297.071 125.496 296.739 125.993C296.407 126.49 296.23 127.074 296.23 127.672C296.23 128.473 296.548 129.242 297.115 129.808C297.682 130.375 298.451 130.693 299.252 130.693Z'} fill={'#F4BC22'} />
            <path d={'M309.378 147.133C309.976 147.133 310.56 146.956 311.057 146.624C311.555 146.292 311.942 145.82 312.171 145.268C312.4 144.715 312.459 144.108 312.343 143.522C312.226 142.935 311.938 142.397 311.516 141.974C311.093 141.552 310.554 141.264 309.968 141.147C309.382 141.031 308.774 141.091 308.221 141.319C307.669 141.548 307.197 141.935 306.865 142.432C306.533 142.929 306.355 143.514 306.355 144.111C306.355 144.913 306.674 145.681 307.241 146.248C307.808 146.815 308.576 147.133 309.378 147.133Z'} fill={'#F4BC22'} />
            <path d={'M316.755 164.931C318.375 164.931 319.688 163.618 319.688 161.999C319.688 160.381 318.375 159.068 316.755 159.068C315.136 159.068 313.823 160.381 313.823 161.999C313.823 163.618 315.136 164.931 316.755 164.931Z'} fill={'#F4BC22'} />
            <path d={'M321.255 183.667C322.875 183.667 324.188 182.354 324.188 180.735C324.188 179.116 322.875 177.804 321.255 177.804C319.636 177.804 318.323 179.116 318.323 180.735C318.323 182.354 319.636 183.667 321.255 183.667Z'} fill={'#F4BC22'} />
            <path d={'M338.154 202.463C338.641 202.469 339.118 202.33 339.525 202.063C339.933 201.796 340.251 201.415 340.44 200.966C340.629 200.518 340.68 200.023 340.587 199.545C340.493 199.068 340.259 198.629 339.915 198.285C339.571 197.941 339.132 197.707 338.654 197.613C338.176 197.52 337.682 197.571 337.233 197.76C336.784 197.949 336.402 198.267 336.136 198.674C335.869 199.082 335.73 199.559 335.736 200.046C335.736 200.687 335.99 201.302 336.444 201.755C336.897 202.208 337.512 202.463 338.154 202.463Z'} fill={'#F4BC22'} />
            <path d={'M336.794 221.652C337.28 221.658 337.756 221.519 338.163 221.254C338.569 220.989 338.888 220.608 339.078 220.162C339.268 219.715 339.32 219.222 339.229 218.745C339.138 218.268 338.908 217.829 338.566 217.484C338.225 217.139 337.789 216.903 337.313 216.806C336.838 216.709 336.344 216.755 335.895 216.94C335.445 217.124 335.061 217.438 334.791 217.841C334.52 218.244 334.376 218.719 334.376 219.204C334.376 219.848 334.63 220.466 335.082 220.924C335.535 221.382 336.15 221.644 336.794 221.652Z'} fill={'#F4BC22'} />
            <path d={'M332.804 240.508C334.14 240.508 335.222 239.426 335.222 238.091C335.222 236.756 334.14 235.673 332.804 235.673C331.469 235.673 330.386 236.756 330.386 238.091C330.386 239.426 331.469 240.508 332.804 240.508Z'} fill={'#F4BC22'} />
            <path d={'M326.186 258.639C326.671 258.645 327.147 258.507 327.554 258.241C327.961 257.976 328.279 257.596 328.469 257.149C328.659 256.702 328.712 256.209 328.621 255.733C328.53 255.256 328.299 254.817 327.958 254.472C327.617 254.126 327.181 253.89 326.705 253.793C326.229 253.696 325.735 253.743 325.286 253.927C324.837 254.112 324.453 254.425 324.182 254.828C323.912 255.232 323.768 255.706 323.768 256.191C323.767 256.835 324.021 257.453 324.474 257.912C324.927 258.37 325.542 258.631 326.186 258.639Z'} fill={'#F4BC22'} />
            <path d={'M317.147 275.652C317.624 275.646 318.089 275.499 318.483 275.23C318.876 274.96 319.181 274.581 319.36 274.139C319.538 273.696 319.582 273.211 319.485 272.744C319.389 272.277 319.156 271.849 318.816 271.514C318.477 271.179 318.046 270.952 317.578 270.861C317.11 270.77 316.625 270.82 316.185 271.004C315.745 271.188 315.369 271.497 315.105 271.894C314.84 272.291 314.699 272.757 314.699 273.234C314.699 273.554 314.763 273.871 314.886 274.166C315.009 274.462 315.19 274.729 315.418 274.954C315.646 275.179 315.916 275.357 316.213 275.476C316.51 275.596 316.827 275.656 317.147 275.652Z'} fill={'#F4BC22'} />
            <path d={'M305.812 291.275C306.297 291.275 306.77 291.132 307.173 290.863C307.575 290.594 307.889 290.211 308.074 289.764C308.26 289.317 308.308 288.825 308.214 288.35C308.119 287.875 307.886 287.439 307.544 287.097C307.201 286.754 306.765 286.521 306.29 286.427C305.815 286.332 305.323 286.381 304.875 286.566C304.428 286.751 304.045 287.065 303.776 287.468C303.507 287.87 303.364 288.343 303.364 288.828C303.36 289.15 303.42 289.47 303.542 289.769C303.664 290.068 303.844 290.339 304.072 290.567C304.3 290.795 304.572 290.976 304.87 291.097C305.169 291.219 305.49 291.279 305.812 291.275Z'} fill={'#F4BC22'} />
            <path d={'M292.389 305.206C292.988 305.139 293.54 304.854 293.942 304.405C294.344 303.957 294.566 303.376 294.566 302.773C294.566 302.171 294.344 301.59 293.942 301.142C293.54 300.693 292.988 300.408 292.389 300.341C291.79 300.408 291.237 300.693 290.836 301.142C290.434 301.59 290.212 302.171 290.212 302.773C290.212 303.376 290.434 303.957 290.836 304.405C291.237 304.854 291.79 305.139 292.389 305.206Z'} fill={'#F4BC22'} />
            <path d={'M277.186 317.021C277.671 317.027 278.148 316.889 278.554 316.623C278.961 316.358 279.279 315.978 279.469 315.531C279.659 315.084 279.712 314.591 279.621 314.115C279.53 313.638 279.299 313.199 278.958 312.853C278.617 312.508 278.181 312.272 277.705 312.175C277.229 312.078 276.735 312.125 276.286 312.309C275.837 312.493 275.453 312.807 275.182 313.21C274.912 313.613 274.768 314.088 274.768 314.573C274.768 315.217 275.021 315.835 275.474 316.293C275.927 316.752 276.542 317.013 277.186 317.021Z'} fill={'#F4BC22'} />
            <path d={'M260.47 326.66C260.957 326.666 261.435 326.527 261.842 326.26C262.249 325.994 262.568 325.612 262.757 325.163C262.946 324.715 262.997 324.22 262.903 323.743C262.81 323.265 262.576 322.826 262.232 322.482C261.887 322.138 261.448 321.904 260.971 321.811C260.493 321.717 259.998 321.768 259.549 321.957C259.101 322.146 258.719 322.465 258.452 322.872C258.185 323.279 258.046 323.756 258.052 324.243C258.052 324.884 258.307 325.499 258.76 325.952C259.214 326.406 259.829 326.66 260.47 326.66Z'} fill={'#F4BC22'} />
            <path d={'M242.607 333.883C243.092 333.883 243.567 333.738 243.97 333.468C244.373 333.198 244.687 332.814 244.872 332.365C245.056 331.916 245.103 331.422 245.006 330.946C244.909 330.471 244.673 330.035 244.327 329.694C243.982 329.353 243.543 329.122 243.066 329.031C242.589 328.94 242.096 328.993 241.649 329.183C241.202 329.372 240.822 329.691 240.556 330.097C240.291 330.504 240.152 330.98 240.158 331.465C240.158 331.785 240.222 332.102 240.345 332.397C240.469 332.693 240.65 332.961 240.877 333.185C241.105 333.41 241.375 333.588 241.672 333.708C241.969 333.827 242.287 333.887 242.607 333.883Z'} fill={'#F4BC22'} />
            <path d={'M223.867 338.445C224.345 338.445 224.812 338.303 225.21 338.038C225.608 337.772 225.918 337.395 226.101 336.953C226.284 336.511 226.332 336.025 226.238 335.556C226.145 335.087 225.915 334.656 225.576 334.318C225.238 333.98 224.807 333.75 224.338 333.657C223.869 333.563 223.383 333.611 222.941 333.794C222.499 333.977 222.122 334.287 221.856 334.685C221.59 335.082 221.448 335.55 221.448 336.028C221.448 336.669 221.703 337.284 222.157 337.737C222.61 338.19 223.225 338.445 223.867 338.445Z'} fill={'#F4BC22'} />
            <path d={'M204.698 340.561C205.175 340.555 205.64 340.409 206.034 340.139C206.427 339.87 206.732 339.491 206.911 339.048C207.089 338.606 207.133 338.121 207.036 337.654C206.939 337.187 206.707 336.759 206.367 336.424C206.028 336.089 205.597 335.862 205.129 335.771C204.661 335.68 204.176 335.73 203.736 335.914C203.296 336.097 202.92 336.407 202.656 336.804C202.391 337.201 202.25 337.667 202.25 338.144C202.25 338.464 202.313 338.781 202.437 339.076C202.56 339.371 202.741 339.639 202.969 339.864C203.197 340.089 203.467 340.266 203.764 340.386C204.061 340.506 204.378 340.565 204.698 340.561Z'} fill={'#F4BC22'} />
            <path d={'M185.417 339.896C185.902 339.896 186.377 339.752 186.78 339.481C187.183 339.211 187.497 338.827 187.682 338.378C187.866 337.929 187.913 337.435 187.816 336.96C187.719 336.484 187.483 336.048 187.137 335.707C186.792 335.366 186.353 335.135 185.876 335.044C185.399 334.953 184.906 335.006 184.459 335.196C184.012 335.386 183.632 335.704 183.366 336.111C183.101 336.517 182.962 336.993 182.968 337.479C182.968 337.799 183.032 338.115 183.155 338.411C183.279 338.706 183.46 338.974 183.687 339.199C183.915 339.424 184.185 339.601 184.482 339.721C184.779 339.84 185.097 339.9 185.417 339.896Z'} fill={'#F4BC22'} />
            <path d={'M166.401 336.543C166.887 336.549 167.365 336.409 167.772 336.143C168.179 335.876 168.498 335.494 168.687 335.046C168.876 334.597 168.927 334.102 168.833 333.625C168.74 333.147 168.506 332.708 168.162 332.364C167.818 332.02 167.379 331.786 166.901 331.693C166.423 331.599 165.928 331.65 165.48 331.839C165.031 332.028 164.649 332.347 164.382 332.754C164.116 333.161 163.976 333.638 163.982 334.125C163.982 334.766 164.237 335.381 164.691 335.834C165.144 336.288 165.759 336.543 166.401 336.543Z'} fill={'#F4BC22'} />
            <path d={'M148.054 330.589C149.39 330.589 150.473 329.507 150.473 328.172C150.473 326.837 149.39 325.754 148.054 325.754C146.719 325.754 145.636 326.837 145.636 328.172C145.636 329.507 146.719 330.589 148.054 330.589Z'} fill={'#F4BC22'} />
            <path d={'M130.704 322.128C131.19 322.134 131.668 321.995 132.075 321.728C132.482 321.462 132.801 321.08 132.99 320.631C133.179 320.183 133.23 319.688 133.136 319.211C133.043 318.733 132.809 318.294 132.465 317.95C132.121 317.606 131.682 317.372 131.204 317.279C130.726 317.185 130.231 317.236 129.783 317.425C129.334 317.614 128.952 317.933 128.685 318.34C128.419 318.747 128.279 319.224 128.285 319.711C128.285 320.352 128.54 320.967 128.994 321.42C129.447 321.874 130.062 322.128 130.704 322.128Z'} fill={'#F4BC22'} />
            <path d={'M114.864 311.249C115.351 311.255 115.828 311.116 116.236 310.849C116.643 310.583 116.961 310.201 117.15 309.752C117.339 309.304 117.39 308.809 117.297 308.332C117.203 307.854 116.97 307.415 116.625 307.071C116.281 306.727 115.842 306.493 115.364 306.4C114.887 306.306 114.392 306.357 113.943 306.546C113.495 306.735 113.113 307.053 112.846 307.461C112.579 307.868 112.44 308.345 112.446 308.832C112.446 309.473 112.701 310.088 113.154 310.541C113.608 310.994 114.223 311.249 114.864 311.249Z'} fill={'#F4BC22'} />
            <path d={'M100.386 298.436C100.873 298.442 101.35 298.303 101.757 298.036C102.165 297.77 102.483 297.388 102.672 296.939C102.861 296.491 102.912 295.996 102.819 295.519C102.725 295.041 102.491 294.602 102.147 294.258C101.803 293.914 101.364 293.68 100.886 293.587C100.408 293.493 99.9137 293.544 99.4651 293.733C99.0165 293.922 98.6344 294.241 98.3677 294.648C98.1009 295.055 97.9617 295.532 97.9677 296.019C97.9677 296.66 98.2224 297.275 98.6759 297.728C99.1294 298.182 99.7445 298.436 100.386 298.436Z'} fill={'#F4BC22'} />
            <path d={'M87.9912 283.629C89.3101 283.629 90.3792 282.56 90.3792 281.242C90.3792 279.923 89.3101 278.855 87.9912 278.855C86.6724 278.855 85.6033 279.923 85.6033 281.242C85.6033 282.56 86.6724 283.629 87.9912 283.629Z'} fill={'#F4BC22'} />
            <path d={'M77.7443 267.251C78.2166 267.251 78.6783 267.111 79.071 266.849C79.4637 266.587 79.7698 266.214 79.9505 265.777C80.1313 265.341 80.1785 264.861 80.0864 264.398C79.9943 263.935 79.7668 263.51 79.4329 263.176C79.0989 262.842 78.6734 262.615 78.2102 262.522C77.747 262.43 77.2668 262.478 76.8305 262.658C76.3941 262.839 76.0212 263.145 75.7588 263.538C75.4964 263.93 75.3564 264.392 75.3564 264.864C75.3564 265.497 75.6079 266.104 76.0558 266.552C76.5036 267 77.111 267.251 77.7443 267.251Z'} fill={'#F4BC22'} />
            <path d={'M69.9158 249.634C70.3881 249.634 70.8498 249.494 71.2425 249.231C71.6352 248.969 71.9413 248.596 72.122 248.16C72.3028 247.724 72.3501 247.244 72.2579 246.781C72.1658 246.318 71.9383 245.892 71.6044 245.558C71.2704 245.225 70.8449 244.997 70.3817 244.905C69.9185 244.813 69.4383 244.86 69.002 245.041C68.5657 245.222 68.1927 245.528 67.9303 245.92C67.6679 246.313 67.5279 246.774 67.5279 247.247C67.5279 247.88 67.7795 248.487 68.2273 248.935C68.6751 249.382 69.2825 249.634 69.9158 249.634Z'} fill={'#F4BC22'} />
            <path d={'M64.5969 231.11C65.2302 231.11 65.8376 230.859 66.2854 230.411C66.7332 229.963 66.9848 229.356 66.9848 228.723C66.9848 228.09 66.7332 227.483 66.2854 227.035C65.8376 226.587 65.2302 226.336 64.5969 226.336C63.9635 226.336 63.3561 226.587 62.9083 227.035C62.4605 227.483 62.2089 228.09 62.2089 228.723C62.2089 229.356 62.4605 229.963 62.9083 230.411C63.3561 230.859 63.9635 231.11 64.5969 231.11Z'} fill={'#F4BC22'} />
            <path d={'M61.9052 212.013C62.3775 212.013 62.8391 211.873 63.2318 211.611C63.6245 211.349 63.9306 210.976 64.1114 210.539C64.2921 210.103 64.3394 209.623 64.2472 209.16C64.1551 208.697 63.9277 208.272 63.5937 207.938C63.2597 207.604 62.8342 207.377 62.371 207.285C61.9078 207.192 61.4277 207.24 60.9913 207.42C60.555 207.601 60.182 207.907 59.9196 208.3C59.6572 208.692 59.5172 209.154 59.5172 209.626C59.5172 210.259 59.7688 210.866 60.2166 211.314C60.6644 211.762 61.2718 212.013 61.9052 212.013Z'} fill={'#F4BC22'} />
            <path d={'M61.9053 192.702C62.3789 192.702 62.8418 192.561 63.2351 192.297C63.6285 192.034 63.9345 191.659 64.1143 191.221C64.2941 190.783 64.3396 190.302 64.245 189.838C64.1504 189.374 63.9199 188.949 63.5829 188.616C63.2459 188.283 62.8176 188.058 62.3524 187.97C61.8872 187.881 61.4062 187.933 60.9704 188.118C60.5346 188.303 60.1638 188.614 59.905 189.011C59.6463 189.407 59.5114 189.872 59.5174 190.345C59.5173 190.657 59.5793 190.966 59.6997 191.254C59.8201 191.542 59.9964 191.803 60.2186 192.022C60.4407 192.242 60.7042 192.415 60.9938 192.531C61.2833 192.648 61.5932 192.706 61.9053 192.702Z'} fill={'#F4BC22'} />
            <path d={'M64.596 173.605C64.9301 173.643 65.2684 173.61 65.5888 173.508C65.9091 173.406 66.2044 173.237 66.4551 173.013C66.7058 172.789 66.9065 172.515 67.0438 172.208C67.1812 171.901 67.2522 171.569 67.2522 171.233C67.2522 170.897 67.1812 170.564 67.0438 170.258C66.9065 169.951 66.7058 169.676 66.4551 169.452C66.2044 169.229 65.9091 169.06 65.5888 168.958C65.2684 168.856 64.9301 168.823 64.596 168.861C64.2619 168.823 63.9236 168.856 63.6032 168.958C63.2828 169.06 62.9876 169.229 62.7369 169.452C62.4861 169.676 62.2855 169.951 62.1481 170.258C62.0108 170.564 61.9398 170.897 61.9398 171.233C61.9398 171.569 62.0108 171.901 62.1481 172.208C62.2855 172.515 62.4861 172.789 62.7369 173.013C62.9876 173.237 63.2828 173.406 63.6032 173.508C63.9236 173.61 64.2619 173.643 64.596 173.605Z'} fill={'#F4BC22'} />
            <path d={'M69.9155 155.081C70.3878 155.081 70.8495 154.941 71.2422 154.679C71.6349 154.417 71.941 154.044 72.1217 153.607C72.3024 153.171 72.3497 152.691 72.2576 152.228C72.1654 151.765 71.938 151.34 71.604 151.006C71.2701 150.672 70.8446 150.445 70.3814 150.353C69.9181 150.26 69.438 150.308 69.0017 150.488C68.5653 150.669 68.1924 150.975 67.93 151.368C67.6676 151.76 67.5275 152.222 67.5275 152.694C67.5275 153.327 67.7791 153.934 68.227 154.382C68.6748 154.83 69.2822 155.081 69.9155 155.081Z'} fill={'#F4BC22'} />
            <path d={'M77.7442 137.464C79.0631 137.464 80.1322 136.395 80.1322 135.076C80.1322 133.758 79.0631 132.689 77.7442 132.689C76.4254 132.689 75.3563 133.758 75.3563 135.076C75.3563 136.395 76.4254 137.464 77.7442 137.464Z'} fill={'#F4BC22'} />
            <path d={'M87.9914 121.086C88.465 121.086 88.9279 120.945 89.3212 120.681C89.7145 120.417 90.0206 120.043 90.2004 119.605C90.3802 119.167 90.4257 118.685 90.3311 118.221C90.2365 117.757 90.006 117.332 89.669 117C89.332 116.667 88.9037 116.442 88.4385 116.353C87.9733 116.265 87.4923 116.316 87.0565 116.502C86.6207 116.687 86.2499 116.998 85.9911 117.394C85.7324 117.791 85.5975 118.255 85.6035 118.729C85.6114 119.356 85.8665 119.956 86.3135 120.397C86.7605 120.838 87.3633 121.086 87.9914 121.086Z'} fill={'#F4BC22'} />
            <path d={'M100.386 106.338C100.858 106.338 101.32 106.198 101.712 105.935C102.105 105.673 102.411 105.3 102.592 104.864C102.773 104.428 102.82 103.948 102.728 103.485C102.636 103.022 102.408 102.596 102.074 102.263C101.74 101.929 101.315 101.701 100.851 101.609C100.388 101.517 99.9081 101.564 99.4718 101.745C99.0354 101.926 98.6625 102.232 98.4001 102.624C98.1377 103.017 97.9976 103.478 97.9976 103.951C97.9976 104.584 98.2492 105.191 98.6971 105.639C99.1449 106.086 99.7523 106.338 100.386 106.338Z'} fill={'#F4BC22'} />
            <path d={'M114.713 93.4339C116.032 93.4339 117.101 92.3651 117.101 91.0467C117.101 89.7283 116.032 88.6594 114.713 88.6594C113.394 88.6594 112.325 89.7283 112.325 91.0467C112.325 92.3651 113.394 93.4339 114.713 93.4339Z'} fill={'#F4BC22'} />
            <path d={'M130.703 82.6477C131.175 82.6477 131.637 82.5076 132.03 82.2453C132.422 81.983 132.728 81.6102 132.909 81.174C133.09 80.7377 133.137 80.2577 133.045 79.7947C132.953 79.3316 132.726 78.9062 132.392 78.5723C132.058 78.2385 131.632 78.0111 131.169 77.919C130.706 77.8269 130.226 77.8742 129.789 78.0548C129.353 78.2355 128.98 78.5415 128.718 78.9341C128.455 79.3267 128.315 79.7882 128.315 80.2604C128.315 80.8935 128.567 81.5007 129.014 81.9484C129.462 82.3961 130.07 82.6477 130.703 82.6477Z'} fill={'#F4BC22'} />
            <path d={'M148.115 74.1858C148.587 74.1858 149.049 74.0458 149.442 73.7834C149.834 73.5211 150.14 73.1483 150.321 72.7121C150.502 72.2759 150.549 71.7959 150.457 71.3328C150.365 70.8697 150.137 70.4443 149.803 70.1105C149.47 69.7766 149.044 69.5493 148.581 69.4571C148.118 69.365 147.637 69.4123 147.201 69.593C146.765 69.7737 146.392 70.0797 146.129 70.4722C145.867 70.8648 145.727 71.3264 145.727 71.7985C145.727 72.4317 145.979 73.0389 146.426 73.4866C146.874 73.9343 147.482 74.1858 148.115 74.1858Z'} fill={'#F4BC22'} />
            <path d={'M166.404 68.2318C167.723 68.2318 168.792 67.1629 168.792 65.8445C168.792 64.5261 167.723 63.4573 166.404 63.4573C165.085 63.4573 164.016 64.5261 164.016 65.8445C164.016 67.1629 165.085 68.2318 166.404 68.2318Z'} fill={'#F4BC22'} />
            <path d={'M185.416 64.8781C186.05 64.8781 186.657 64.6266 187.105 64.1789C187.553 63.7312 187.804 63.124 187.804 62.4908C187.804 61.8577 187.553 61.2505 187.105 60.8028C186.657 60.3551 186.05 60.1035 185.416 60.1035C185.103 60.1035 184.792 60.1653 184.502 60.2853C184.213 60.4052 183.949 60.5811 183.728 60.8028C183.506 61.0244 183.33 61.2876 183.21 61.5772C183.09 61.8669 183.028 62.1773 183.028 62.4908C183.028 62.8043 183.09 63.1147 183.21 63.4044C183.33 63.694 183.506 63.9572 183.728 64.1789C183.949 64.4005 184.213 64.5764 184.502 64.6964C184.792 64.8163 185.103 64.8781 185.416 64.8781Z'} fill={'#F4BC22'} />
            <path d={'M204.702 64.1838C206.021 64.1838 207.09 63.115 207.09 61.7965C207.09 60.4781 206.021 59.4093 204.702 59.4093C203.384 59.4093 202.314 60.4781 202.314 61.7965C202.314 63.115 203.384 64.1838 204.702 64.1838Z'} fill={'#F4BC22'} />
            <path d={'M223.867 66.2082C224.5 66.2082 225.107 65.9567 225.555 65.509C226.003 65.0613 226.255 64.4541 226.255 63.821C226.255 63.1879 226.003 62.5806 225.555 62.1329C225.107 61.6853 224.5 61.4337 223.867 61.4337C223.553 61.4337 223.243 61.4955 222.953 61.6155C222.663 61.7354 222.4 61.9113 222.178 62.1329C221.956 62.3546 221.78 62.6178 221.66 62.9074C221.54 63.1971 221.479 63.5075 221.479 63.821C221.479 64.1345 221.54 64.4449 221.66 64.7346C221.78 65.0242 221.956 65.2874 222.178 65.509C222.4 65.7307 222.663 65.9066 222.953 66.0265C223.243 66.1465 223.553 66.2082 223.867 66.2082Z'} fill={'#F4BC22'} />
            <path d={'M242.606 70.8918C243.239 70.8918 243.846 70.6403 244.294 70.1926C244.742 69.7449 244.994 69.1377 244.994 68.5045C244.994 67.8714 244.742 67.2642 244.294 66.8165C243.846 66.3688 243.239 66.1173 242.606 66.1173C242.292 66.1173 241.982 66.179 241.692 66.299C241.402 66.419 241.139 66.5948 240.917 66.8165C240.695 67.0382 240.52 67.3013 240.4 67.591C240.28 67.8806 240.218 68.191 240.218 68.5045C240.218 68.818 240.28 69.1285 240.4 69.4181C240.52 69.7077 240.695 69.9709 240.917 70.1926C241.139 70.4142 241.402 70.5901 241.692 70.7101C241.982 70.83 242.292 70.8918 242.606 70.8918Z'} fill={'#F4BC22'} />
            <path d={'M260.47 78.114C260.942 78.114 261.404 77.974 261.797 77.7117C262.189 77.4493 262.495 77.0765 262.676 76.6403C262.857 76.2041 262.904 75.7241 262.812 75.261C262.72 74.7979 262.492 74.3725 262.158 74.0387C261.824 73.7048 261.399 73.4775 260.936 73.3853C260.473 73.2932 259.992 73.3405 259.556 73.5212C259.12 73.7019 258.747 74.0079 258.484 74.4004C258.222 74.793 258.082 75.2546 258.082 75.7267C258.082 76.0402 258.144 76.3507 258.264 76.6403C258.384 76.9299 258.56 77.1931 258.781 77.4148C259.003 77.6365 259.266 77.8123 259.556 77.9323C259.846 78.0522 260.156 78.114 260.47 78.114Z'} fill={'#F4BC22'} />
            <path d={'M277.186 87.6321C277.658 87.6321 278.12 87.4921 278.513 87.2298C278.905 86.9675 279.211 86.5947 279.392 86.1585C279.573 85.7222 279.62 85.2423 279.528 84.7792C279.436 84.3161 279.208 83.8907 278.874 83.5569C278.54 83.223 278.115 82.9956 277.652 82.9035C277.189 82.8114 276.708 82.8587 276.272 83.0394C275.836 83.2201 275.463 83.526 275.2 83.9186C274.938 84.3112 274.798 84.7727 274.798 85.2449C274.798 85.878 275.049 86.4852 275.497 86.9329C275.945 87.3806 276.553 87.6321 277.186 87.6321Z'} fill={'#F4BC22'} />
            <path d={'M292.389 99.7198C292.988 99.653 293.541 99.368 293.942 98.9192C294.344 98.4704 294.566 97.8894 294.566 97.2872C294.566 96.6851 294.344 96.1041 293.942 95.6553C293.541 95.2065 292.988 94.9215 292.389 94.8547C291.79 94.9215 291.237 95.2065 290.836 95.6553C290.434 96.1041 290.212 96.6851 290.212 97.2872C290.212 97.8894 290.434 98.4704 290.836 98.9192C291.237 99.368 291.79 99.653 292.389 99.7198Z'} fill={'#F4BC22'} />
            <path d={'M305.812 113.53C306.289 113.524 306.753 113.377 307.147 113.108C307.541 112.839 307.846 112.459 308.024 112.017C308.203 111.575 308.246 111.09 308.15 110.623C308.053 110.156 307.82 109.728 307.481 109.393C307.142 109.058 306.711 108.83 306.243 108.74C305.774 108.649 305.29 108.698 304.85 108.882C304.409 109.066 304.034 109.376 303.769 109.773C303.505 110.169 303.364 110.636 303.364 111.113C303.364 111.433 303.427 111.749 303.551 112.045C303.674 112.34 303.855 112.608 304.083 112.833C304.31 113.058 304.58 113.235 304.877 113.355C305.174 113.474 305.492 113.534 305.812 113.53Z'} fill={'#F4BC22'} />
            <path d={'M317.148 129.153C317.632 129.153 318.105 129.01 318.508 128.741C318.91 128.472 319.224 128.09 319.41 127.642C319.595 127.195 319.643 126.703 319.549 126.228C319.454 125.753 319.221 125.317 318.879 124.975C318.536 124.633 318.1 124.399 317.625 124.305C317.15 124.211 316.658 124.259 316.211 124.444C315.763 124.629 315.381 124.943 315.112 125.346C314.843 125.748 314.699 126.221 314.699 126.706C314.695 127.028 314.756 127.348 314.877 127.647C314.999 127.946 315.179 128.217 315.407 128.445C315.635 128.674 315.907 128.854 316.206 128.975C316.505 129.097 316.825 129.157 317.148 129.153Z'} fill={'#F4BC22'} />
            <path d={'M326.186 146.166C326.827 146.166 327.442 145.912 327.896 145.458C328.349 145.005 328.604 144.39 328.604 143.749C328.604 143.108 328.349 142.493 327.896 142.039C327.442 141.586 326.827 141.331 326.186 141.331C325.544 141.331 324.929 141.586 324.476 142.039C324.022 142.493 323.768 143.108 323.768 143.749C323.768 144.39 324.022 145.005 324.476 145.458C324.929 145.912 325.544 146.166 326.186 146.166Z'} fill={'#F4BC22'} />
            <path d={'M332.805 164.297C333.291 164.297 333.765 164.152 334.168 163.882C334.572 163.612 334.885 163.228 335.07 162.779C335.254 162.33 335.301 161.836 335.204 161.36C335.107 160.885 334.871 160.449 334.525 160.107C334.18 159.766 333.741 159.536 333.264 159.445C332.787 159.354 332.294 159.407 331.847 159.596C331.4 159.786 331.02 160.105 330.754 160.511C330.489 160.918 330.351 161.394 330.357 161.879C330.357 162.199 330.42 162.516 330.543 162.811C330.667 163.107 330.848 163.375 331.076 163.599C331.303 163.824 331.573 164.002 331.87 164.121C332.167 164.241 332.485 164.301 332.805 164.297Z'} fill={'#F4BC22'} />
            <path d={'M336.794 183.153C337.272 183.153 337.74 183.012 338.138 182.746C338.535 182.48 338.845 182.103 339.028 181.661C339.211 181.219 339.259 180.733 339.166 180.264C339.072 179.795 338.842 179.365 338.504 179.027C338.166 178.688 337.735 178.458 337.266 178.365C336.797 178.272 336.311 178.32 335.869 178.503C335.427 178.685 335.049 178.995 334.783 179.393C334.518 179.79 334.376 180.258 334.376 180.736C334.376 181.377 334.631 181.992 335.084 182.445C335.538 182.899 336.153 183.153 336.794 183.153Z'} fill={'#F4BC22'} />
            <path d={'M353.51 201.888C354.562 201.888 355.415 201.036 355.415 199.985C355.415 198.933 354.562 198.081 353.51 198.081C352.459 198.081 351.606 198.933 351.606 199.985C351.606 201.036 352.459 201.888 353.51 201.888Z'} fill={'#F4BC22'} />
            <path d={'M352.302 221.138C352.81 221.138 353.297 220.939 353.659 220.582C354.021 220.226 354.228 219.742 354.236 219.234C354.236 218.721 354.032 218.229 353.67 217.867C353.307 217.504 352.815 217.3 352.302 217.3C351.794 217.308 351.309 217.515 350.953 217.877C350.597 218.239 350.397 218.727 350.397 219.234C350.397 219.739 350.598 220.223 350.955 220.58C351.312 220.937 351.797 221.138 352.302 221.138Z'} fill={'#F4BC22'} />
            <path d={'M348.674 240.085C348.926 240.089 349.177 240.042 349.412 239.949C349.646 239.855 349.86 239.715 350.04 239.538C350.22 239.361 350.363 239.149 350.46 238.917C350.558 238.684 350.608 238.433 350.608 238.181C350.642 237.909 350.618 237.632 350.537 237.37C350.457 237.108 350.321 236.866 350.139 236.66C349.958 236.455 349.735 236.29 349.484 236.177C349.234 236.064 348.963 236.006 348.689 236.006C348.414 236.006 348.143 236.064 347.893 236.177C347.643 236.29 347.419 236.455 347.238 236.66C347.056 236.866 346.921 237.108 346.84 237.37C346.759 237.632 346.735 237.909 346.769 238.181C346.769 238.686 346.97 239.17 347.327 239.527C347.684 239.884 348.169 240.085 348.674 240.085Z'} fill={'#F4BC22'} />
            <path d={'M342.721 258.428C343.226 258.428 343.71 258.228 344.067 257.87C344.424 257.513 344.625 257.029 344.625 256.524C344.625 256.019 344.424 255.535 344.067 255.178C343.71 254.821 343.226 254.621 342.721 254.621C342.216 254.621 341.731 254.821 341.374 255.178C341.017 255.535 340.816 256.019 340.816 256.524C340.816 257.029 341.017 257.513 341.374 257.87C341.731 258.228 342.216 258.428 342.721 258.428Z'} fill={'#F4BC22'} />
            <path d={'M334.497 275.894C335.005 275.894 335.493 275.694 335.854 275.338C336.216 274.982 336.424 274.497 336.432 273.99C336.432 273.477 336.228 272.985 335.865 272.622C335.502 272.26 335.01 272.056 334.497 272.056C333.989 272.064 333.505 272.271 333.149 272.633C332.792 272.995 332.593 273.482 332.593 273.99C332.593 274.495 332.793 274.979 333.151 275.336C333.508 275.693 333.992 275.894 334.497 275.894Z'} fill={'#F4BC22'} />
            <path d={'M324.159 292.182C325.211 292.182 326.064 291.329 326.064 290.278C326.064 289.227 325.211 288.374 324.159 288.374C323.107 288.374 322.255 289.227 322.255 290.278C322.255 291.329 323.107 292.182 324.159 292.182Z'} fill={'#F4BC22'} />
            <path d={'M311.856 307.019C312.908 307.019 313.761 306.166 313.761 305.115C313.761 304.064 312.908 303.211 311.856 303.211C310.805 303.211 309.952 304.064 309.952 305.115C309.952 306.166 310.805 307.019 311.856 307.019Z'} fill={'#F4BC22'} />
            <path d={'M297.803 320.223C298.855 320.223 299.707 319.371 299.707 318.319C299.707 317.268 298.855 316.415 297.803 316.415C296.751 316.415 295.899 317.268 295.899 318.319C295.899 319.371 296.751 320.223 297.803 320.223Z'} fill={'#F4BC22'} />
            <path d={'M282.202 331.587C282.708 331.587 283.192 331.386 283.549 331.029C283.906 330.672 284.107 330.188 284.107 329.683C284.107 329.175 283.907 328.688 283.551 328.326C283.195 327.964 282.71 327.757 282.202 327.749C281.689 327.749 281.197 327.953 280.835 328.316C280.472 328.678 280.268 329.17 280.268 329.683C280.276 330.191 280.483 330.675 280.845 331.031C281.207 331.387 281.695 331.587 282.202 331.587Z'} fill={'#F4BC22'} />
            <path d={'M265.276 340.893C265.789 340.893 266.281 340.689 266.644 340.326C267.006 339.964 267.21 339.472 267.21 338.959C267.202 338.451 266.995 337.967 266.633 337.611C266.271 337.255 265.784 337.055 265.276 337.055C264.771 337.055 264.286 337.256 263.929 337.613C263.572 337.97 263.371 338.454 263.371 338.959C263.371 339.467 263.571 339.954 263.927 340.316C264.284 340.678 264.768 340.885 265.276 340.893Z'} fill={'#F4BC22'} />
            <path d={'M247.351 347.965C248.402 347.965 249.255 347.112 249.255 346.061C249.255 345.009 248.402 344.157 247.351 344.157C246.299 344.157 245.446 345.009 245.446 346.061C245.446 347.112 246.299 347.965 247.351 347.965Z'} fill={'#F4BC22'} />
            <path d={'M228.642 352.769C229.149 352.769 229.637 352.57 229.999 352.214C230.361 351.857 230.568 351.373 230.576 350.865C230.576 350.353 230.372 349.861 230.009 349.498C229.647 349.135 229.155 348.931 228.642 348.931C228.134 348.939 227.649 349.147 227.293 349.509C226.937 349.87 226.737 350.358 226.737 350.865C226.737 351.37 226.938 351.855 227.295 352.212C227.652 352.569 228.136 352.769 228.642 352.769Z'} fill={'#F4BC22'} />
            <path d={'M209.507 355.187C210.558 355.187 211.411 354.334 211.411 353.283C211.411 352.231 210.558 351.379 209.507 351.379C208.455 351.379 207.602 352.231 207.602 353.283C207.602 354.334 208.455 355.187 209.507 355.187Z'} fill={'#F4BC22'} />
            <path d={'M190.222 355.187C191.274 355.187 192.126 354.335 192.126 353.283C192.126 352.232 191.274 351.379 190.222 351.379C189.17 351.379 188.318 352.232 188.318 353.283C188.318 354.335 189.17 355.187 190.222 355.187Z'} fill={'#F4BC22'} />
            <path d={'M171.058 352.769C171.566 352.769 172.053 352.57 172.415 352.213C172.777 351.857 172.985 351.373 172.993 350.865C172.993 350.352 172.789 349.861 172.426 349.498C172.063 349.135 171.571 348.931 171.058 348.931C170.55 348.939 170.066 349.147 169.71 349.508C169.353 349.87 169.154 350.358 169.154 350.865C169.154 351.37 169.354 351.855 169.711 352.212C170.069 352.569 170.553 352.769 171.058 352.769Z'} fill={'#F4BC22'} />
            <path d={'M152.379 347.965C153.43 347.965 154.283 347.112 154.283 346.061C154.283 345.009 153.43 344.157 152.379 344.157C151.327 344.157 150.474 345.009 150.474 346.061C150.474 347.112 151.327 347.965 152.379 347.965Z'} fill={'#F4BC22'} />
            <path d={'M134.45 340.864C135.502 340.864 136.355 340.012 136.355 338.96C136.355 337.909 135.502 337.056 134.45 337.056C133.399 337.056 132.546 337.909 132.546 338.96C132.546 340.012 133.399 340.864 134.45 340.864Z'} fill={'#F4BC22'} />
            <path d={'M117.523 331.586C118.031 331.586 118.518 331.386 118.88 331.03C119.242 330.674 119.45 330.19 119.457 329.682C119.457 329.169 119.254 328.677 118.891 328.315C118.528 327.952 118.036 327.748 117.523 327.748C117.015 327.756 116.531 327.963 116.174 328.325C115.818 328.687 115.618 329.174 115.619 329.682C115.619 329.932 115.668 330.18 115.763 330.411C115.859 330.642 115.999 330.851 116.176 331.028C116.353 331.205 116.563 331.345 116.794 331.441C117.025 331.537 117.273 331.586 117.523 331.586Z'} fill={'#F4BC22'} />
            <path d={'M101.926 320.224C102.978 320.224 103.83 319.372 103.83 318.32C103.83 317.269 102.978 316.416 101.926 316.416C100.874 316.416 100.022 317.269 100.022 318.32C100.022 319.372 100.874 320.224 101.926 320.224Z'} fill={'#F4BC22'} />
            <path d={'M87.8416 307.019C88.8933 307.019 89.7459 306.167 89.7459 305.115C89.7459 304.064 88.8933 303.211 87.8416 303.211C86.7898 303.211 85.9372 304.064 85.9372 305.115C85.9372 306.167 86.7898 307.019 87.8416 307.019Z'} fill={'#F4BC22'} />
            <path d={'M75.5398 292.152C76.5749 292.152 77.4139 291.313 77.4139 290.278C77.4139 289.243 76.5749 288.404 75.5398 288.404C74.5048 288.404 73.6657 289.243 73.6657 290.278C73.6657 291.313 74.5048 292.152 75.5398 292.152Z'} fill={'#F4BC22'} />
            <path d={'M65.2014 275.864C66.2364 275.864 67.0755 275.025 67.0755 273.99C67.0755 272.956 66.2364 272.117 65.2014 272.117C64.1664 272.117 63.3273 272.956 63.3273 273.99C63.3273 275.025 64.1664 275.864 65.2014 275.864Z'} fill={'#F4BC22'} />
            <path d={'M57.0092 258.397C57.5063 258.397 57.983 258.2 58.3344 257.848C58.6859 257.497 58.8833 257.02 58.8833 256.523C58.8833 256.026 58.6859 255.55 58.3344 255.199C57.983 254.847 57.5063 254.65 57.0092 254.65C56.5122 254.65 56.0355 254.847 55.684 255.199C55.3326 255.55 55.1351 256.026 55.1351 256.523C55.1351 257.02 55.3326 257.497 55.684 257.848C56.0355 258.2 56.5122 258.397 57.0092 258.397Z'} fill={'#F4BC22'} />
            <path d={'M51.0263 240.054C52.0613 240.054 52.9004 239.216 52.9004 238.181C52.9004 237.146 52.0613 236.307 51.0263 236.307C49.9913 236.307 49.1522 237.146 49.1522 238.181C49.1522 239.216 49.9913 240.054 51.0263 240.054Z'} fill={'#F4BC22'} />
            <path d={'M47.4285 221.107C48.4635 221.107 49.3026 220.268 49.3026 219.234C49.3026 218.199 48.4635 217.36 47.4285 217.36C46.3935 217.36 45.5544 218.199 45.5544 219.234C45.5544 220.268 46.3935 221.107 47.4285 221.107Z'} fill={'#F4BC22'} />
            <path d={'M46.2194 201.859C46.4655 201.859 46.7092 201.81 46.9366 201.716C47.1639 201.622 47.3705 201.484 47.5446 201.31C47.7186 201.136 47.8566 200.93 47.9508 200.702C48.045 200.475 48.0935 200.231 48.0935 199.985C48.0935 199.488 47.896 199.012 47.5446 198.66C47.1931 198.309 46.7164 198.112 46.2194 198.112C45.9721 198.108 45.7266 198.153 45.4974 198.246C45.2682 198.339 45.0599 198.477 44.8851 198.651C44.7102 198.826 44.5723 199.034 44.4796 199.264C44.3869 199.493 44.3412 199.738 44.3453 199.985C44.3453 200.231 44.3938 200.475 44.4879 200.702C44.5821 200.93 44.7202 201.136 44.8942 201.31C45.0682 201.484 45.2748 201.622 45.5022 201.716C45.7296 201.81 45.9733 201.859 46.2194 201.859Z'} fill={'#F4BC22'} />
            <path d={'M47.4288 182.609C47.9258 182.609 48.4025 182.412 48.7539 182.061C49.1054 181.709 49.3029 181.233 49.3029 180.736C49.3029 180.239 49.1054 179.762 48.7539 179.411C48.4025 179.06 47.9258 178.862 47.4288 178.862C47.1815 178.858 46.936 178.904 46.7068 178.996C46.4775 179.089 46.2693 179.227 46.0945 179.402C45.9196 179.577 45.7817 179.785 45.689 180.014C45.5962 180.243 45.5506 180.489 45.5547 180.736C45.5547 181.233 45.7521 181.709 46.1036 182.061C46.455 182.412 46.9317 182.609 47.4288 182.609Z'} fill={'#F4BC22'} />
            <path d={'M51.0234 163.663C51.4012 163.669 51.7723 163.563 52.0895 163.357C52.4066 163.152 52.6554 162.857 52.8041 162.509C52.9529 162.162 52.995 161.778 52.9249 161.407C52.8549 161.036 52.676 160.694 52.4109 160.424C52.1458 160.155 51.8066 159.971 51.4364 159.895C51.0662 159.819 50.6817 159.855 50.332 159.998C49.9823 160.141 49.683 160.385 49.4724 160.699C49.2618 161.013 49.1493 161.382 49.1493 161.76C49.1492 162.259 49.3457 162.739 49.6963 163.095C50.0469 163.451 50.5236 163.655 51.0234 163.663Z'} fill={'#F4BC22'} />
            <path d={'M57.0092 145.29C57.5062 145.29 57.9829 145.092 58.3344 144.741C58.6858 144.39 58.8833 143.913 58.8833 143.416C58.8833 142.919 58.6858 142.443 58.3344 142.091C57.9829 141.74 57.5062 141.543 57.0092 141.543C56.5121 141.543 56.0354 141.74 55.684 142.091C55.3325 142.443 55.1351 142.919 55.1351 143.416C55.131 143.663 55.1767 143.909 55.2694 144.138C55.3621 144.367 55.5 144.575 55.6749 144.75C55.8497 144.925 56.058 145.063 56.2872 145.155C56.5164 145.248 56.7619 145.294 57.0092 145.29Z'} fill={'#F4BC22'} />
            <path d={'M65.2005 127.853C66.2355 127.853 67.0746 127.014 67.0746 125.979C67.0746 124.945 66.2355 124.106 65.2005 124.106C64.1655 124.106 63.3264 124.945 63.3264 125.979C63.3264 127.014 64.1655 127.853 65.2005 127.853Z'} fill={'#F4BC22'} />
            <path d={'M75.5398 111.565C76.5749 111.565 77.4139 110.726 77.4139 109.691C77.4139 108.656 76.5749 107.818 75.5398 107.818C74.5048 107.818 73.6657 108.656 73.6657 109.691C73.6657 110.726 74.5048 111.565 75.5398 111.565Z'} fill={'#F4BC22'} />
            <path d={'M87.8413 96.6986C88.8763 96.6986 89.7154 95.8598 89.7154 94.825C89.7154 93.7903 88.8763 92.9515 87.8413 92.9515C86.8063 92.9515 85.9672 93.7903 85.9672 94.825C85.9672 95.8598 86.8063 96.6986 87.8413 96.6986Z'} fill={'#F4BC22'} />
            <path d={'M101.926 83.4921C102.423 83.4921 102.9 83.2947 103.251 82.9434C103.603 82.592 103.8 82.1155 103.8 81.6186C103.8 81.1217 103.603 80.6451 103.251 80.2938C102.9 79.9424 102.423 79.745 101.926 79.745C101.429 79.745 100.952 79.9424 100.601 80.2938C100.25 80.6451 100.052 81.1217 100.052 81.6186C100.052 82.1155 100.25 82.592 100.601 82.9434C100.952 83.2947 101.429 83.4921 101.926 83.4921Z'} fill={'#F4BC22'} />
            <path d={'M117.524 72.1613C118.559 72.1613 119.398 71.3224 119.398 70.2877C119.398 69.253 118.559 68.4142 117.524 68.4142C116.489 68.4142 115.65 69.253 115.65 70.2877C115.65 71.3224 116.489 72.1613 117.524 72.1613Z'} fill={'#F4BC22'} />
            <path d={'M134.45 62.8547C134.82 62.8487 135.179 62.7338 135.483 62.5244C135.787 62.3149 136.023 62.0203 136.16 61.6774C136.297 61.3346 136.33 60.9589 136.254 60.5975C136.179 60.2361 135.998 59.9051 135.735 59.6461C135.471 59.3871 135.137 59.2116 134.775 59.1417C134.412 59.0718 134.037 59.1107 133.696 59.2533C133.355 59.396 133.065 59.6361 132.86 59.9435C132.655 60.2509 132.546 60.6119 132.546 60.9812C132.546 61.2298 132.595 61.4759 132.691 61.7052C132.788 61.9345 132.928 62.1424 133.106 62.3167C133.283 62.4911 133.493 62.6285 133.724 62.7208C133.955 62.8132 134.202 62.8587 134.45 62.8547Z'} fill={'#F4BC22'} />
            <path d={'M152.375 55.7532C153.41 55.7532 154.249 54.9144 154.249 53.8796C154.249 52.8449 153.41 52.0061 152.375 52.0061C151.34 52.0061 150.501 52.8449 150.501 53.8796C150.501 54.9144 151.34 55.7532 152.375 55.7532Z'} fill={'#F4BC22'} />
            <path d={'M171.057 50.947C172.092 50.947 172.931 50.1082 172.931 49.0735C172.931 48.0387 172.092 47.1999 171.057 47.1999C170.022 47.1999 169.183 48.0387 169.183 49.0735C169.183 50.1082 170.022 50.947 171.057 50.947Z'} fill={'#F4BC22'} />
            <path d={'M190.222 48.5304C190.719 48.5304 191.195 48.3331 191.547 47.9817C191.898 47.6303 192.096 47.1538 192.096 46.6569C192.096 46.16 191.898 45.6835 191.547 45.3321C191.195 44.9808 190.719 44.7834 190.222 44.7834C189.725 44.7834 189.248 44.9808 188.896 45.3321C188.545 45.6835 188.348 46.16 188.348 46.6569C188.343 46.9041 188.389 47.1495 188.482 47.3787C188.575 47.6078 188.713 47.816 188.887 47.9908C189.062 48.1656 189.27 48.3034 189.5 48.3962C189.729 48.4889 189.974 48.5345 190.222 48.5304Z'} fill={'#F4BC22'} />
            <path d={'M209.507 48.5308C209.753 48.5308 209.996 48.4824 210.224 48.3882C210.451 48.2941 210.658 48.1561 210.832 47.9821C211.006 47.8081 211.144 47.6016 211.238 47.3743C211.332 47.147 211.381 46.9033 211.381 46.6573C211.381 46.4113 211.332 46.1676 211.238 45.9403C211.144 45.713 211.006 45.5065 210.832 45.3325C210.658 45.1585 210.451 45.0205 210.224 44.9264C209.996 44.8322 209.753 44.7838 209.507 44.7838C209.26 44.7838 209.017 44.8322 208.789 44.9264C208.562 45.0205 208.355 45.1585 208.181 45.3325C208.007 45.5065 207.869 45.713 207.775 45.9403C207.681 46.1676 207.632 46.4113 207.632 46.6573C207.632 47.1542 207.83 47.6307 208.181 47.9821C208.533 48.3335 209.01 48.5308 209.507 48.5308Z'} fill={'#F4BC22'} />
            <path d={'M228.641 50.9471C228.888 50.9512 229.134 50.9055 229.363 50.8128C229.592 50.7201 229.8 50.5823 229.975 50.4075C230.15 50.2327 230.288 50.0245 230.381 49.7953C230.473 49.5662 230.519 49.3207 230.515 49.0736C230.515 48.5767 230.318 48.1001 229.966 47.7488C229.615 47.3974 229.138 47.2 228.641 47.2C228.395 47.2 228.151 47.2485 227.924 47.3426C227.696 47.4368 227.49 47.5748 227.316 47.7488C227.142 47.9227 227.004 48.1293 226.909 48.3566C226.815 48.5839 226.767 48.8275 226.767 49.0736C226.767 49.3196 226.815 49.5632 226.909 49.7905C227.004 50.0178 227.142 50.2244 227.316 50.3983C227.49 50.5723 227.696 50.7103 227.924 50.8045C228.151 50.8986 228.395 50.9471 228.641 50.9471Z'} fill={'#F4BC22'} />
            <path d={'M247.352 55.7533C247.849 55.7533 248.325 55.5559 248.677 55.2046C249.028 54.8532 249.226 54.3767 249.226 53.8798C249.226 53.3829 249.028 52.9063 248.677 52.555C248.325 52.2036 247.849 52.0062 247.352 52.0062C246.855 52.0062 246.378 52.2036 246.026 52.555C245.675 52.9063 245.478 53.3829 245.478 53.8798C245.478 54.3767 245.675 54.8532 246.026 55.2046C246.378 55.5559 246.855 55.7533 247.352 55.7533Z'} fill={'#F4BC22'} />
            <path d={'M265.275 62.8534C265.646 62.8534 266.008 62.7435 266.316 62.5376C266.625 62.3318 266.865 62.0392 267.007 61.6968C267.148 61.3545 267.186 60.9778 267.113 60.6143C267.041 60.2509 266.863 59.9171 266.6 59.6551C266.338 59.393 266.004 59.2146 265.641 59.1423C265.277 59.07 264.9 59.1071 264.558 59.2489C264.216 59.3907 263.923 59.6309 263.717 59.939C263.511 60.2471 263.401 60.6093 263.401 60.9799C263.401 61.4767 263.599 61.9533 263.95 62.3046C264.302 62.656 264.778 62.8534 265.275 62.8534Z'} fill={'#F4BC22'} />
            <path d={'M282.203 72.1615C283.239 72.1615 284.078 71.3227 284.078 70.288C284.078 69.2533 283.239 68.4144 282.203 68.4144C281.168 68.4144 280.329 69.2533 280.329 70.288C280.329 71.3227 281.168 72.1615 282.203 72.1615Z'} fill={'#F4BC22'} />
            <path d={'M297.8 83.4921C298.169 83.492 298.53 83.3829 298.838 83.1783C299.145 82.9737 299.385 82.6829 299.528 82.3423C299.671 82.0017 299.71 81.6266 299.64 81.264C299.57 80.9014 299.394 80.5676 299.135 80.3044C298.876 80.0412 298.545 79.8604 298.183 79.7848C297.822 79.7091 297.446 79.7419 297.103 79.8791C296.76 80.0163 296.466 80.2517 296.256 80.5558C296.046 80.8599 295.932 81.2191 295.926 81.5883C295.922 81.8369 295.967 82.0838 296.059 82.3146C296.152 82.5454 296.289 82.7555 296.464 82.9327C296.638 83.1099 296.846 83.2506 297.075 83.3466C297.305 83.4427 297.551 83.4921 297.8 83.4921Z'} fill={'#F4BC22'} />
            <path d={'M311.856 96.729C312.908 96.729 313.761 95.8767 313.761 94.8252C313.761 93.7738 312.908 92.9215 311.856 92.9215C310.805 92.9215 309.952 93.7738 309.952 94.8252C309.952 95.8767 310.805 96.729 311.856 96.729Z'} fill={'#F4BC22'} />
            <path d={'M324.159 111.596C325.211 111.596 326.064 110.743 326.064 109.692C326.064 108.641 325.211 107.788 324.159 107.788C323.107 107.788 322.255 108.641 322.255 109.692C322.255 110.743 323.107 111.596 324.159 111.596Z'} fill={'#F4BC22'} />
            <path d={'M334.497 127.884C335.005 127.884 335.492 127.684 335.854 127.328C336.216 126.972 336.424 126.488 336.431 125.98C336.431 125.467 336.228 124.975 335.865 124.613C335.502 124.25 335.01 124.046 334.497 124.046C333.989 124.054 333.505 124.261 333.148 124.623C332.792 124.985 332.593 125.472 332.593 125.98C332.593 126.485 332.793 126.969 333.15 127.326C333.507 127.683 333.992 127.884 334.497 127.884Z'} fill={'#F4BC22'} />
            <path d={'M342.721 145.32C343.772 145.32 344.625 144.468 344.625 143.416C344.625 142.365 343.772 141.512 342.721 141.512C341.669 141.512 340.816 142.365 340.816 143.416C340.816 144.468 341.669 145.32 342.721 145.32Z'} fill={'#F4BC22'} />
            <path d={'M348.673 163.692C349.057 163.698 349.434 163.59 349.756 163.381C350.078 163.172 350.331 162.872 350.482 162.52C350.633 162.167 350.675 161.777 350.604 161.4C350.533 161.023 350.351 160.675 350.082 160.402C349.813 160.128 349.468 159.941 349.092 159.864C348.716 159.787 348.325 159.823 347.97 159.969C347.615 160.114 347.311 160.362 347.097 160.681C346.883 160.999 346.769 161.375 346.769 161.758C346.765 162.011 346.811 162.262 346.905 162.496C346.999 162.731 347.138 162.944 347.316 163.124C347.493 163.304 347.704 163.447 347.937 163.545C348.17 163.642 348.42 163.692 348.673 163.692Z'} fill={'#F4BC22'} />
            <path d={'M352.302 182.639C352.809 182.639 353.297 182.44 353.659 182.084C354.021 181.727 354.228 181.243 354.236 180.736C354.236 180.223 354.032 179.731 353.669 179.368C353.307 179.005 352.815 178.802 352.302 178.802C351.794 178.81 351.309 179.017 350.953 179.379C350.597 179.74 350.397 180.228 350.397 180.736C350.397 181.24 350.598 181.725 350.955 182.082C351.312 182.439 351.796 182.639 352.302 182.639Z'} fill={'#F4BC22'} />
            <path d={'M368.775 201.376C369.058 201.376 369.334 201.292 369.568 201.134C369.802 200.977 369.984 200.753 370.091 200.492C370.197 200.231 370.224 199.944 370.166 199.667C370.109 199.391 369.971 199.138 369.769 198.941C369.567 198.743 369.311 198.61 369.034 198.559C368.756 198.508 368.47 198.54 368.211 198.652C367.952 198.765 367.732 198.951 367.579 199.189C367.427 199.426 367.349 199.704 367.355 199.986C367.363 200.357 367.516 200.711 367.781 200.971C368.047 201.231 368.404 201.376 368.775 201.376Z'} fill={'#F4BC22'} />
            <path d={'M367.777 220.594C368.154 220.594 368.516 220.444 368.782 220.178C369.048 219.912 369.198 219.55 369.198 219.174C369.198 218.988 369.161 218.805 369.089 218.635C369.018 218.464 368.913 218.309 368.78 218.18C368.648 218.05 368.491 217.949 368.319 217.881C368.147 217.813 367.963 217.78 367.777 217.784C367.594 217.779 367.411 217.813 367.241 217.881C367.07 217.949 366.915 218.052 366.785 218.182C366.655 218.311 366.553 218.466 366.484 218.637C366.416 218.807 366.383 218.99 366.387 219.174C366.383 219.359 366.416 219.543 366.484 219.715C366.552 219.887 366.654 220.044 366.783 220.176C366.913 220.308 367.068 220.413 367.238 220.485C367.409 220.557 367.592 220.594 367.777 220.594Z'} fill={'#F4BC22'} />
            <path d={'M364.483 239.661C364.859 239.661 365.221 239.512 365.487 239.245C365.754 238.979 365.903 238.618 365.903 238.241C365.854 237.904 365.684 237.596 365.426 237.373C365.168 237.15 364.839 237.028 364.498 237.028C364.157 237.028 363.827 237.15 363.569 237.373C363.311 237.596 363.142 237.904 363.092 238.241C363.092 238.613 363.238 238.969 363.497 239.235C363.757 239.5 364.111 239.654 364.483 239.661Z'} fill={'#F4BC22'} />
            <path d={'M359.043 258.155C359.326 258.161 359.605 258.082 359.843 257.928C360.081 257.775 360.268 257.553 360.379 257.292C360.491 257.032 360.521 256.744 360.467 256.465C360.414 256.187 360.278 255.931 360.077 255.731C359.877 255.53 359.621 255.395 359.342 255.341C359.064 255.287 358.776 255.318 358.515 255.429C358.254 255.54 358.033 255.727 357.879 255.965C357.725 256.203 357.646 256.482 357.652 256.765C357.66 257.131 357.809 257.48 358.068 257.739C358.327 257.998 358.676 258.147 359.043 258.155Z'} fill={'#F4BC22'} />
            <path d={'M351.545 275.925C351.827 275.925 352.103 275.841 352.337 275.683C352.572 275.526 352.754 275.302 352.86 275.041C352.967 274.78 352.993 274.493 352.936 274.216C352.878 273.94 352.74 273.687 352.538 273.49C352.337 273.292 352.081 273.16 351.803 273.108C351.526 273.057 351.239 273.089 350.98 273.202C350.721 273.314 350.501 273.5 350.349 273.738C350.196 273.975 350.118 274.253 350.124 274.535C350.132 274.906 350.285 275.26 350.551 275.52C350.816 275.78 351.173 275.925 351.545 275.925Z'} fill={'#F4BC22'} />
            <path d={'M342.054 292.725C342.391 292.676 342.7 292.507 342.922 292.249C343.145 291.991 343.268 291.661 343.268 291.32C343.268 290.979 343.145 290.65 342.922 290.392C342.7 290.134 342.391 289.965 342.054 289.915C341.852 289.885 341.646 289.9 341.45 289.957C341.254 290.014 341.073 290.112 340.919 290.245C340.764 290.379 340.64 290.544 340.555 290.729C340.47 290.915 340.427 291.116 340.427 291.32C340.427 291.524 340.47 291.726 340.555 291.911C340.64 292.097 340.764 292.262 340.919 292.395C341.073 292.528 341.254 292.627 341.45 292.684C341.646 292.741 341.852 292.755 342.054 292.725Z'} fill={'#F4BC22'} />
            <path d={'M330.72 308.227C331.001 308.227 331.275 308.143 331.509 307.987C331.742 307.831 331.925 307.609 332.032 307.35C332.14 307.09 332.168 306.805 332.113 306.529C332.058 306.254 331.923 306.001 331.724 305.802C331.525 305.603 331.272 305.468 330.997 305.413C330.721 305.358 330.435 305.387 330.176 305.494C329.916 305.602 329.694 305.784 329.538 306.017C329.382 306.251 329.299 306.525 329.299 306.806C329.299 307.183 329.449 307.544 329.715 307.811C329.981 308.077 330.343 308.227 330.72 308.227Z'} fill={'#F4BC22'} />
            <path d={'M317.661 322.551C317.998 322.501 318.306 322.332 318.529 322.074C318.752 321.816 318.875 321.486 318.875 321.145C318.875 320.805 318.752 320.475 318.529 320.217C318.306 319.959 317.998 319.79 317.661 319.74C317.459 319.711 317.253 319.725 317.057 319.782C316.861 319.839 316.68 319.937 316.526 320.071C316.371 320.204 316.247 320.369 316.162 320.554C316.078 320.74 316.034 320.941 316.034 321.145C316.034 321.349 316.078 321.551 316.162 321.736C316.247 321.922 316.371 322.087 316.526 322.22C316.68 322.354 316.861 322.452 317.057 322.509C317.253 322.566 317.459 322.58 317.661 322.551Z'} fill={'#F4BC22'} />
            <path d={'M303.06 335.182C303.343 335.188 303.622 335.109 303.86 334.956C304.098 334.802 304.285 334.58 304.396 334.32C304.508 334.059 304.538 333.771 304.484 333.493C304.431 333.214 304.295 332.958 304.094 332.758C303.894 332.558 303.638 332.422 303.359 332.368C303.081 332.314 302.793 332.345 302.532 332.456C302.271 332.567 302.05 332.754 301.896 332.992C301.742 333.23 301.663 333.509 301.669 333.792C301.677 334.158 301.826 334.508 302.085 334.767C302.344 335.026 302.693 335.175 303.06 335.182Z'} fill={'#F4BC22'} />
            <path d={'M287.163 346.091C287.54 346.091 287.901 345.941 288.168 345.675C288.434 345.408 288.584 345.047 288.584 344.67C288.534 344.333 288.365 344.025 288.107 343.802C287.849 343.579 287.519 343.457 287.178 343.457C286.837 343.457 286.508 343.579 286.25 343.802C285.992 344.025 285.822 344.333 285.773 344.67C285.773 345.042 285.918 345.399 286.178 345.664C286.438 345.93 286.792 346.083 287.163 346.091Z'} fill={'#F4BC22'} />
            <path d={'M270.08 355.097C270.865 355.097 271.501 354.461 271.501 353.676C271.501 352.892 270.865 352.256 270.08 352.256C269.295 352.256 268.659 352.892 268.659 353.676C268.659 354.461 269.295 355.097 270.08 355.097Z'} fill={'#F4BC22'} />
            <path d={'M252.099 362.107C252.883 362.107 253.519 361.471 253.519 360.687C253.519 359.903 252.883 359.267 252.099 359.267C251.314 359.267 250.678 359.903 250.678 360.687C250.678 361.471 251.314 362.107 252.099 362.107Z'} fill={'#F4BC22'} />
            <path d={'M233.417 366.971C233.602 366.975 233.786 366.942 233.958 366.874C234.13 366.806 234.287 366.704 234.419 366.574C234.552 366.445 234.657 366.29 234.729 366.12C234.8 365.949 234.837 365.766 234.837 365.581C234.867 365.379 234.853 365.173 234.796 364.977C234.739 364.781 234.64 364.6 234.507 364.446C234.373 364.291 234.208 364.168 234.023 364.083C233.837 363.998 233.636 363.954 233.432 363.954C233.228 363.954 233.026 363.998 232.84 364.083C232.655 364.168 232.49 364.291 232.356 364.446C232.223 364.6 232.125 364.781 232.068 364.977C232.011 365.173 231.996 365.379 232.026 365.581C232.026 365.949 232.173 366.303 232.433 366.564C232.694 366.824 233.048 366.971 233.417 366.971Z'} fill={'#F4BC22'} />
            <path d={'M214.313 369.721C214.596 369.727 214.875 369.648 215.113 369.495C215.351 369.341 215.538 369.119 215.649 368.859C215.761 368.598 215.791 368.31 215.737 368.032C215.684 367.753 215.548 367.497 215.347 367.297C215.147 367.097 214.891 366.961 214.612 366.907C214.334 366.853 214.046 366.884 213.785 366.995C213.524 367.106 213.303 367.293 213.149 367.531C212.995 367.769 212.916 368.048 212.922 368.331C212.93 368.697 213.079 369.047 213.338 369.306C213.597 369.565 213.946 369.714 214.313 369.721Z'} fill={'#F4BC22'} />
            <path d={'M195.027 370.295C195.404 370.295 195.765 370.146 196.032 369.879C196.298 369.613 196.448 369.252 196.448 368.875C196.398 368.538 196.229 368.23 195.971 368.007C195.713 367.784 195.383 367.661 195.042 367.661C194.701 367.661 194.371 367.784 194.113 368.007C193.855 368.23 193.686 368.538 193.637 368.875C193.636 369.247 193.782 369.603 194.042 369.869C194.302 370.134 194.655 370.287 195.027 370.295Z'} fill={'#F4BC22'} />
            <path d={'M175.803 368.664C176.005 368.693 176.211 368.679 176.407 368.622C176.603 368.565 176.784 368.467 176.938 368.333C177.093 368.2 177.217 368.035 177.301 367.85C177.386 367.664 177.43 367.463 177.43 367.259C177.43 367.055 177.386 366.853 177.301 366.668C177.217 366.482 177.093 366.317 176.938 366.184C176.784 366.05 176.603 365.952 176.407 365.895C176.211 365.838 176.005 365.824 175.803 365.853C175.466 365.903 175.157 366.072 174.934 366.33C174.712 366.588 174.589 366.918 174.589 367.259C174.589 367.599 174.712 367.929 174.934 368.187C175.157 368.445 175.466 368.614 175.803 368.664Z'} fill={'#F4BC22'} />
            <path d={'M156.88 364.796C157.164 364.802 157.442 364.723 157.681 364.569C157.919 364.416 158.106 364.194 158.217 363.933C158.328 363.673 158.359 363.385 158.305 363.106C158.251 362.828 158.115 362.572 157.915 362.372C157.714 362.171 157.458 362.035 157.18 361.982C156.901 361.928 156.613 361.959 156.353 362.07C156.092 362.181 155.87 362.368 155.716 362.606C155.563 362.844 155.484 363.123 155.49 363.406C155.497 363.772 155.646 364.121 155.905 364.38C156.165 364.639 156.514 364.788 156.88 364.796Z'} fill={'#F4BC22'} />
            <path d={'M138.533 358.843C138.735 358.873 138.941 358.859 139.137 358.802C139.333 358.745 139.514 358.646 139.668 358.513C139.823 358.38 139.947 358.215 140.032 358.029C140.117 357.844 140.161 357.642 140.161 357.438C140.161 357.234 140.117 357.032 140.032 356.847C139.947 356.662 139.823 356.496 139.668 356.363C139.514 356.23 139.333 356.131 139.137 356.074C138.941 356.017 138.735 356.003 138.533 356.033C138.196 356.082 137.888 356.252 137.665 356.51C137.442 356.768 137.319 357.097 137.319 357.438C137.319 357.779 137.442 358.108 137.665 358.366C137.888 358.624 138.196 358.794 138.533 358.843Z'} fill={'#F4BC22'} />
            <path d={'M120.911 350.836C121.287 350.836 121.649 350.686 121.915 350.42C122.182 350.154 122.331 349.792 122.331 349.416C122.282 349.078 122.113 348.77 121.854 348.547C121.596 348.324 121.267 348.202 120.926 348.202C120.585 348.202 120.255 348.324 119.997 348.547C119.739 348.77 119.57 349.078 119.52 349.416C119.52 349.787 119.666 350.144 119.926 350.409C120.186 350.675 120.539 350.828 120.911 350.836Z'} fill={'#F4BC22'} />
            <path d={'M104.468 340.803C104.737 340.803 105 340.724 105.224 340.574C105.447 340.425 105.622 340.212 105.725 339.964C105.828 339.716 105.855 339.442 105.802 339.178C105.75 338.915 105.62 338.672 105.43 338.482C105.24 338.292 104.997 338.162 104.733 338.11C104.47 338.057 104.196 338.084 103.947 338.187C103.699 338.29 103.486 338.464 103.337 338.688C103.188 338.912 103.108 339.175 103.108 339.444C103.108 339.804 103.251 340.15 103.506 340.405C103.761 340.66 104.107 340.803 104.468 340.803Z'} fill={'#F4BC22'} />
            <path d={'M89.172 329.048C89.4495 329.054 89.7224 328.977 89.9556 328.826C90.1888 328.676 90.3717 328.459 90.4807 328.204C90.5896 327.949 90.6197 327.667 90.567 327.395C90.5144 327.122 90.3813 326.872 90.1851 326.675C89.9888 326.479 89.7383 326.346 89.4658 326.294C89.1933 326.241 88.9112 326.271 88.656 326.38C88.4007 326.489 88.1839 326.672 88.0334 326.905C87.883 327.138 87.8057 327.411 87.8117 327.688C87.8117 328.049 87.955 328.395 88.2101 328.65C88.4652 328.905 88.8112 329.048 89.172 329.048Z'} fill={'#F4BC22'} />
            <path d={'M75.3295 315.631C75.613 315.637 75.8917 315.558 76.1299 315.405C76.3682 315.251 76.5549 315.029 76.6662 314.769C76.7774 314.508 76.8081 314.22 76.7542 313.942C76.7004 313.663 76.5645 313.407 76.364 313.207C76.1635 313.007 75.9076 312.871 75.6292 312.817C75.3509 312.763 75.0627 312.794 74.8019 312.905C74.5411 313.016 74.3196 313.203 74.1658 313.441C74.012 313.679 73.933 313.958 73.939 314.241C73.9467 314.607 74.0957 314.957 74.3548 315.216C74.6139 315.475 74.9631 315.624 75.3295 315.631Z'} fill={'#F4BC22'} />
            <path d={'M63.1142 300.642C63.3832 300.642 63.6462 300.562 63.8699 300.413C64.0936 300.263 64.2679 300.051 64.3709 299.802C64.4738 299.554 64.5008 299.281 64.4483 299.017C64.3958 298.753 64.2663 298.511 64.076 298.32C63.8858 298.13 63.6434 298.001 63.3796 297.948C63.1157 297.896 62.8422 297.923 62.5937 298.026C62.3451 298.129 62.1327 298.303 61.9832 298.526C61.8337 298.75 61.754 299.013 61.754 299.282C61.754 299.643 61.8973 299.989 62.1524 300.244C62.4075 300.499 62.7534 300.642 63.1142 300.642Z'} fill={'#F4BC22'} />
            <path d={'M52.6848 284.414C52.9538 284.414 53.2168 284.334 53.4405 284.185C53.6641 284.035 53.8385 283.823 53.9414 283.574C54.0444 283.326 54.0713 283.052 54.0189 282.789C53.9664 282.525 53.8368 282.283 53.6466 282.092C53.4564 281.902 53.214 281.773 52.9501 281.72C52.6863 281.668 52.4128 281.695 52.1642 281.798C51.9157 281.901 51.7032 282.075 51.5538 282.298C51.4043 282.522 51.3245 282.785 51.3245 283.054C51.3245 283.415 51.4678 283.76 51.7229 284.016C51.978 284.271 52.324 284.414 52.6848 284.414Z'} fill={'#F4BC22'} />
            <path d={'M44.1626 267.1C44.4316 267.1 44.6946 267.02 44.9183 266.871C45.142 266.721 45.3164 266.509 45.4193 266.261C45.5223 266.012 45.5492 265.739 45.4967 265.475C45.4442 265.211 45.3147 264.969 45.1244 264.779C44.9342 264.588 44.6918 264.459 44.428 264.406C44.1641 264.354 43.8906 264.381 43.6421 264.484C43.3935 264.587 43.1811 264.761 43.0316 264.985C42.8821 265.208 42.8024 265.471 42.8024 265.74C42.8024 266.101 42.9457 266.447 43.2008 266.702C43.4559 266.957 43.8018 267.1 44.1626 267.1Z'} fill={'#F4BC22'} />
            <path d={'M37.6938 248.938C37.9628 248.938 38.2258 248.859 38.4495 248.709C38.6732 248.56 38.8475 248.347 38.9505 248.099C39.0534 247.85 39.0804 247.577 39.0279 247.313C38.9754 247.049 38.8459 246.807 38.6556 246.617C38.4654 246.427 38.223 246.297 37.9592 246.245C37.6953 246.192 37.4218 246.219 37.1732 246.322C36.9247 246.425 36.7123 246.599 36.5628 246.823C36.4133 247.047 36.3335 247.31 36.3335 247.579C36.3335 247.939 36.4769 248.285 36.732 248.54C36.987 248.795 37.333 248.938 37.6938 248.938Z'} fill={'#F4BC22'} />
            <path d={'M33.2505 230.143C33.5195 230.143 33.7825 230.064 34.0062 229.914C34.2299 229.765 34.4042 229.552 34.5072 229.304C34.6101 229.055 34.6371 228.782 34.5846 228.518C34.5321 228.254 34.4026 228.012 34.2123 227.822C34.0221 227.632 33.7797 227.502 33.5159 227.45C33.252 227.397 32.9785 227.424 32.7299 227.527C32.4814 227.63 32.269 227.804 32.1195 228.028C31.97 228.252 31.8903 228.515 31.8903 228.784C31.8903 229.144 32.0336 229.49 32.2887 229.745C32.5438 230 32.8897 230.143 33.2505 230.143Z'} fill={'#F4BC22'} />
            <path d={'M31.1056 210.985C31.3746 210.985 31.6376 210.905 31.8613 210.756C32.085 210.606 32.2593 210.394 32.3623 210.145C32.4652 209.897 32.4922 209.623 32.4397 209.36C32.3872 209.096 32.2576 208.854 32.0674 208.663C31.8772 208.473 31.6348 208.344 31.3709 208.291C31.1071 208.239 30.8336 208.266 30.585 208.369C30.3365 208.472 30.124 208.646 29.9746 208.87C29.8251 209.093 29.7453 209.356 29.7453 209.625C29.7453 209.986 29.8886 210.331 30.1437 210.587C30.3988 210.842 30.7448 210.985 31.1056 210.985Z'} fill={'#F4BC22'} />
            <path d={'M31.1031 191.704C31.3818 191.71 31.6559 191.632 31.8897 191.48C32.1234 191.328 32.3061 191.11 32.4141 190.853C32.522 190.596 32.5501 190.312 32.4949 190.039C32.4396 189.766 32.3034 189.516 32.1041 189.321C31.9048 189.126 31.6514 188.995 31.377 188.946C31.1025 188.897 30.8197 188.932 30.5651 189.045C30.3105 189.159 30.096 189.347 29.9494 189.584C29.8027 189.821 29.7308 190.096 29.7428 190.375C29.7581 190.728 29.9072 191.062 30.1599 191.309C30.4126 191.556 30.7498 191.697 31.1031 191.704Z'} fill={'#F4BC22'} />
            <path d={'M33.2508 172.516C33.5198 172.516 33.7828 172.436 34.0065 172.286C34.2302 172.137 34.4045 171.925 34.5075 171.676C34.6104 171.428 34.6374 171.154 34.5849 170.89C34.5324 170.627 34.4029 170.384 34.2126 170.194C34.0224 170.004 33.78 169.874 33.5162 169.822C33.2523 169.77 32.9788 169.796 32.7302 169.899C32.4817 170.002 32.2693 170.177 32.1198 170.4C31.9703 170.624 31.8905 170.887 31.8905 171.156C31.8905 171.516 32.0339 171.862 32.289 172.117C32.544 172.372 32.89 172.516 33.2508 172.516Z'} fill={'#F4BC22'} />
            <path d={'M37.6937 153.721C37.9627 153.721 38.2257 153.641 38.4494 153.492C38.6731 153.342 38.8474 153.13 38.9504 152.881C39.0533 152.633 39.0803 152.359 39.0278 152.096C38.9753 151.832 38.8458 151.59 38.6555 151.399C38.4653 151.209 38.2229 151.08 37.9591 151.027C37.6952 150.975 37.4217 151.002 37.1732 151.105C36.9246 151.208 36.7122 151.382 36.5627 151.605C36.4132 151.829 36.3335 152.092 36.3335 152.361C36.3335 152.722 36.4768 153.067 36.7319 153.322C36.987 153.578 37.3329 153.721 37.6937 153.721Z'} fill={'#F4BC22'} />
            <path d={'M44.163 135.559C44.4321 135.559 44.6951 135.48 44.9188 135.33C45.1424 135.181 45.3168 134.968 45.4197 134.72C45.5227 134.472 45.5496 134.198 45.4972 133.934C45.4447 133.671 45.3151 133.428 45.1249 133.238C44.9346 133.048 44.6923 132.918 44.4284 132.866C44.1646 132.813 43.8911 132.84 43.6425 132.943C43.3939 133.046 43.1815 133.221 43.032 133.444C42.8826 133.668 42.8028 133.931 42.8028 134.2C42.8028 134.378 42.838 134.555 42.9063 134.72C42.9747 134.885 43.0749 135.035 43.2012 135.161C43.3275 135.287 43.4775 135.388 43.6425 135.456C43.8075 135.524 43.9844 135.559 44.163 135.559Z'} fill={'#F4BC22'} />
            <path d={'M52.6848 118.245C52.9538 118.245 53.2168 118.165 53.4405 118.015C53.6642 117.866 53.8385 117.654 53.9415 117.405C54.0444 117.157 54.0713 116.883 54.0189 116.62C53.9664 116.356 53.8368 116.113 53.6466 115.923C53.4564 115.733 53.214 115.604 52.9501 115.551C52.6863 115.499 52.4128 115.526 52.1642 115.629C51.9157 115.731 51.7032 115.906 51.5538 116.129C51.4043 116.353 51.3245 116.616 51.3245 116.885C51.3245 117.245 51.4678 117.591 51.7229 117.846C51.978 118.101 52.324 118.245 52.6848 118.245Z'} fill={'#F4BC22'} />
            <path d={'M63.1142 102.017C63.3832 102.017 63.6462 101.937 63.8699 101.787C64.0936 101.638 64.2679 101.426 64.3709 101.177C64.4739 100.929 64.5008 100.655 64.4483 100.391C64.3958 100.128 64.2663 99.8854 64.076 99.6952C63.8858 99.5051 63.6434 99.3755 63.3796 99.3231C63.1157 99.2706 62.8422 99.2975 62.5937 99.4004C62.3451 99.5034 62.1327 99.6777 61.9832 99.9013C61.8337 100.125 61.754 100.388 61.754 100.657C61.754 101.017 61.8973 101.363 62.1524 101.618C62.4075 101.873 62.7534 102.017 63.1142 102.017Z'} fill={'#F4BC22'} />
            <path d={'M75.3255 87.0889C75.5945 87.0889 75.8575 87.0092 76.0812 86.8598C76.3049 86.7104 76.4792 86.498 76.5822 86.2495C76.6851 86.001 76.7121 85.7276 76.6596 85.4638C76.6071 85.2 76.4775 84.9577 76.2873 84.7676C76.0971 84.5774 75.8547 84.4479 75.5909 84.3954C75.327 84.3429 75.0535 84.3699 74.8049 84.4728C74.5564 84.5757 74.3439 84.75 74.1945 84.9736C74.045 85.1972 73.9652 85.4602 73.9652 85.7291C73.9652 86.0898 74.1086 86.4356 74.3636 86.6907C74.6187 86.9457 74.9647 87.0889 75.3255 87.0889Z'} fill={'#F4BC22'} />
            <path d={'M89.1714 73.6413C89.4405 73.6413 89.7034 73.5615 89.9271 73.4121C90.1508 73.2627 90.3252 73.0503 90.4281 72.8018C90.5311 72.5533 90.558 72.2799 90.5055 72.0161C90.453 71.7523 90.3235 71.51 90.1333 71.3199C89.943 71.1297 89.7007 71.0002 89.4368 70.9477C89.1729 70.8952 88.8994 70.9222 88.6509 71.0251C88.4023 71.128 88.1899 71.3023 88.0404 71.5259C87.891 71.7496 87.8112 72.0125 87.8112 72.2814C87.8112 72.6421 87.9545 72.988 88.2096 73.243C88.4647 73.498 88.8107 73.6413 89.1714 73.6413Z'} fill={'#F4BC22'} />
            <path d={'M104.468 61.856C104.737 61.856 105 61.7762 105.224 61.6268C105.447 61.4774 105.622 61.265 105.725 61.0165C105.828 60.768 105.855 60.4946 105.802 60.2308C105.75 59.9671 105.62 59.7248 105.43 59.5346C105.24 59.3444 104.997 59.2149 104.733 59.1624C104.469 59.11 104.196 59.1369 103.947 59.2398C103.699 59.3427 103.486 59.517 103.337 59.7407C103.188 59.9643 103.108 60.2272 103.108 60.4961C103.108 60.8568 103.251 61.2027 103.506 61.4577C103.761 61.7127 104.107 61.856 104.468 61.856Z'} fill={'#F4BC22'} />
            <path d={'M120.971 51.8843C121.722 51.8843 122.331 51.2755 122.331 50.5244C122.331 49.7734 121.722 49.1646 120.971 49.1646C120.22 49.1646 119.611 49.7734 119.611 50.5244C119.611 51.2755 120.22 51.8843 120.971 51.8843Z'} fill={'#F4BC22'} />
            <path d={'M138.531 43.8764C138.8 43.8764 139.063 43.7966 139.287 43.6472C139.511 43.4978 139.685 43.2854 139.788 43.037C139.891 42.7885 139.918 42.5151 139.866 42.2513C139.813 41.9875 139.683 41.7452 139.493 41.555C139.303 41.3649 139.061 41.2353 138.797 41.1829C138.533 41.1304 138.259 41.1573 138.011 41.2603C137.762 41.3632 137.55 41.5375 137.4 41.7611C137.251 41.9847 137.171 42.2476 137.171 42.5166C137.171 42.6951 137.206 42.872 137.275 43.037C137.343 43.2019 137.443 43.3518 137.57 43.4781C137.696 43.6044 137.846 43.7045 138.011 43.7729C138.176 43.8412 138.353 43.8764 138.531 43.8764Z'} fill={'#F4BC22'} />
            <path d={'M156.881 37.9236C157.15 37.9236 157.413 37.8438 157.637 37.6944C157.861 37.545 158.035 37.3326 158.138 37.0841C158.241 36.8356 158.268 36.5622 158.215 36.2984C158.163 36.0347 158.033 35.7924 157.843 35.6022C157.653 35.412 157.41 35.2825 157.147 35.23C156.883 35.1776 156.609 35.2045 156.361 35.3074C156.112 35.4103 155.9 35.5846 155.75 35.8083C155.601 36.0319 155.521 36.2948 155.521 36.5637C155.521 36.9244 155.664 37.2703 155.919 37.5253C156.174 37.7803 156.52 37.9236 156.881 37.9236Z'} fill={'#F4BC22'} />
            <path d={'M175.802 34.0858C176.071 34.0858 176.334 34.006 176.558 33.8566C176.782 33.7072 176.956 33.4948 177.059 33.2463C177.162 32.9978 177.189 32.7244 177.136 32.4606C177.084 32.1969 176.954 31.9546 176.764 31.7644C176.574 31.5742 176.331 31.4447 176.068 31.3922C175.804 31.3398 175.53 31.3667 175.282 31.4696C175.033 31.5725 174.821 31.7468 174.671 31.9704C174.522 32.1941 174.442 32.457 174.442 32.7259C174.442 33.0866 174.585 33.4325 174.84 33.6875C175.095 33.9425 175.441 34.0858 175.802 34.0858Z'} fill={'#F4BC22'} />
            <path d={'M195.027 32.4231C195.296 32.4231 195.559 32.3434 195.783 32.194C196.006 32.0445 196.181 31.8322 196.284 31.5837C196.387 31.3352 196.414 31.0618 196.361 30.798C196.309 30.5342 196.179 30.2919 195.989 30.1018C195.799 29.9116 195.556 29.7821 195.292 29.7296C195.029 29.6771 194.755 29.7041 194.507 29.807C194.258 29.9099 194.046 30.0842 193.896 30.3078C193.747 30.5314 193.667 30.7944 193.667 31.0633C193.667 31.424 193.81 31.7698 194.065 32.0248C194.32 32.2799 194.666 32.4231 195.027 32.4231Z'} fill={'#F4BC22'} />
            <path d={'M214.312 32.998C214.59 33.0039 214.863 32.9268 215.096 32.7763C215.329 32.6259 215.512 32.4092 215.621 32.154C215.73 31.8988 215.76 31.6168 215.708 31.3444C215.655 31.072 215.522 30.8215 215.326 30.6253C215.129 30.4291 214.879 30.2961 214.606 30.2435C214.334 30.1908 214.052 30.2209 213.796 30.3298C213.541 30.4387 213.324 30.6216 213.174 30.8547C213.023 31.0879 212.946 31.3607 212.952 31.6381C212.952 31.9988 213.096 32.3447 213.351 32.5997C213.606 32.8547 213.952 32.998 214.312 32.998Z'} fill={'#F4BC22'} />
            <path d={'M233.417 35.7177C233.686 35.7177 233.949 35.6379 234.173 35.4885C234.396 35.3391 234.571 35.1267 234.673 34.8782C234.776 34.6298 234.803 34.3563 234.751 34.0926C234.698 33.8288 234.569 33.5865 234.379 33.3963C234.188 33.2061 233.946 33.0766 233.682 33.0242C233.418 32.9717 233.145 32.9986 232.896 33.1015C232.648 33.2045 232.435 33.3787 232.286 33.6024C232.136 33.826 232.057 34.0889 232.057 34.3579C232.057 34.7185 232.2 35.0644 232.455 35.3194C232.71 35.5744 233.056 35.7177 233.417 35.7177Z'} fill={'#F4BC22'} />
            <path d={'M252.096 40.6427C252.369 40.6732 252.645 40.6202 252.888 40.4905C253.131 40.3609 253.328 40.1607 253.455 39.9165C253.581 39.6722 253.631 39.3954 253.596 39.1225C253.562 38.8495 253.446 38.5934 253.263 38.3878C253.081 38.1823 252.84 38.0369 252.573 37.971C252.306 37.9051 252.025 37.9218 251.767 38.0187C251.51 38.1156 251.288 38.2884 251.131 38.5141C250.973 38.7397 250.888 39.0078 250.887 39.2828C250.885 39.6188 251.007 39.9436 251.23 40.1947C251.453 40.4458 251.762 40.6053 252.096 40.6427Z'} fill={'#F4BC22'} />
            <path d={'M270.084 47.624C270.353 47.624 270.616 47.5442 270.84 47.3948C271.063 47.2454 271.238 47.033 271.341 46.7845C271.444 46.536 271.471 46.2626 271.418 45.9988C271.366 45.7351 271.236 45.4928 271.046 45.3026C270.856 45.1124 270.613 44.9829 270.349 44.9304C270.086 44.878 269.812 44.9049 269.564 45.0078C269.315 45.1107 269.103 45.285 268.953 45.5087C268.804 45.7323 268.724 45.9952 268.724 46.2641C268.724 46.6248 268.867 46.9707 269.122 47.2257C269.377 47.4807 269.723 47.624 270.084 47.624Z'} fill={'#F4BC22'} />
            <path d={'M287.159 56.6289C287.428 56.6289 287.691 56.5492 287.915 56.3997C288.139 56.2503 288.313 56.038 288.416 55.7895C288.519 55.541 288.546 55.2676 288.493 55.0038C288.441 54.74 288.311 54.4977 288.121 54.3075C287.931 54.1174 287.689 53.9879 287.425 53.9354C287.161 53.8829 286.887 53.9098 286.639 54.0128C286.39 54.1157 286.178 54.29 286.028 54.5136C285.879 54.7372 285.799 55.0001 285.799 55.2691C285.799 55.6297 285.943 55.9756 286.198 56.2306C286.453 56.4857 286.799 56.6289 287.159 56.6289Z'} fill={'#F4BC22'} />
            <path d={'M303.06 67.5384C303.811 67.5384 304.42 66.9296 304.42 66.1786C304.42 65.4276 303.811 64.8188 303.06 64.8188C302.309 64.8188 301.7 65.4276 301.7 66.1786C301.7 66.9296 302.309 67.5384 303.06 67.5384Z'} fill={'#F4BC22'} />
            <path d={'M317.659 80.1691C317.928 80.1691 318.191 80.0893 318.415 79.9399C318.639 79.7905 318.813 79.5781 318.916 79.3297C319.019 79.0812 319.046 78.8078 318.993 78.544C318.941 78.2802 318.811 78.0379 318.621 77.8478C318.431 77.6576 318.188 77.5281 317.925 77.4756C317.661 77.4231 317.387 77.4501 317.139 77.553C316.89 77.6559 316.678 77.8302 316.528 78.0538C316.379 78.2774 316.299 78.5403 316.299 78.8093C316.299 79.1699 316.442 79.5158 316.697 79.7708C316.952 80.0258 317.298 80.1691 317.659 80.1691Z'} fill={'#F4BC22'} />
            <path d={'M330.719 94.3709C331.08 94.3709 331.426 94.2277 331.681 93.9726C331.936 93.7176 332.079 93.3718 332.079 93.0111C332.079 92.6505 331.936 92.3046 331.681 92.0496C331.426 91.7946 331.08 91.6513 330.719 91.6513C330.358 91.6513 330.012 91.7946 329.757 92.0496C329.502 92.3046 329.359 92.6505 329.359 93.0111C329.359 93.3718 329.502 93.7176 329.757 93.9726C330.012 94.2277 330.358 94.3709 330.719 94.3709Z'} fill={'#F4BC22'} />
            <path d={'M342.055 109.994C342.332 110 342.605 109.923 342.838 109.772C343.072 109.622 343.255 109.405 343.364 109.15C343.472 108.895 343.503 108.613 343.45 108.341C343.397 108.068 343.264 107.818 343.068 107.621C342.872 107.425 342.621 107.292 342.349 107.24C342.076 107.187 341.794 107.217 341.539 107.326C341.283 107.435 341.067 107.618 340.916 107.851C340.766 108.084 340.688 108.357 340.694 108.634C340.694 108.995 340.838 109.341 341.093 109.596C341.348 109.851 341.694 109.994 342.055 109.994Z'} fill={'#F4BC22'} />
            <path d={'M351.545 126.916C351.828 126.916 352.103 126.832 352.338 126.675C352.572 126.517 352.754 126.294 352.86 126.032C352.967 125.771 352.993 125.484 352.936 125.208C352.879 124.931 352.74 124.678 352.539 124.481C352.337 124.284 352.081 124.151 351.803 124.099C351.526 124.048 351.239 124.081 350.98 124.193C350.721 124.305 350.502 124.492 350.349 124.729C350.197 124.967 350.119 125.244 350.125 125.526C350.132 125.898 350.286 126.251 350.551 126.511C350.817 126.771 351.174 126.916 351.545 126.916Z'} fill={'#F4BC22'} />
            <path d={'M359.04 144.594C359.323 144.6 359.602 144.521 359.84 144.368C360.078 144.214 360.265 143.992 360.376 143.732C360.488 143.471 360.518 143.183 360.465 142.905C360.411 142.626 360.275 142.37 360.074 142.17C359.874 141.97 359.618 141.834 359.34 141.78C359.061 141.726 358.773 141.757 358.512 141.868C358.251 141.979 358.03 142.166 357.876 142.404C357.722 142.642 357.643 142.921 357.649 143.204C357.657 143.57 357.806 143.92 358.065 144.179C358.324 144.438 358.673 144.587 359.04 144.594Z'} fill={'#F4BC22'} />
            <path d={'M364.484 163.18C364.86 163.18 365.222 163.03 365.488 162.764C365.755 162.497 365.904 162.136 365.904 161.76C365.855 161.422 365.685 161.114 365.427 160.891C365.169 160.669 364.84 160.546 364.499 160.546C364.158 160.546 363.828 160.669 363.57 160.891C363.312 161.114 363.143 161.422 363.093 161.76C363.093 162.131 363.238 162.488 363.498 162.753C363.758 163.019 364.112 163.172 364.484 163.18Z'} fill={'#F4BC22'} />
            <path d={'M367.78 182.125C367.982 182.155 368.188 182.141 368.383 182.084C368.579 182.027 368.761 181.928 368.915 181.795C369.07 181.662 369.193 181.497 369.278 181.311C369.363 181.126 369.407 180.924 369.407 180.72C369.407 180.516 369.363 180.314 369.278 180.129C369.193 179.944 369.07 179.779 368.915 179.645C368.761 179.512 368.579 179.413 368.383 179.356C368.188 179.299 367.982 179.285 367.78 179.315C367.442 179.364 367.134 179.534 366.911 179.792C366.688 180.05 366.566 180.379 366.566 180.72C366.566 181.061 366.688 181.39 366.911 181.648C367.134 181.906 367.442 182.076 367.78 182.125Z'} fill={'#F4BC22'} />
            <path d={'M384.252 200.891C384.753 200.891 385.159 200.485 385.159 199.985C385.159 199.484 384.753 199.078 384.252 199.078C383.751 199.078 383.345 199.484 383.345 199.985C383.345 200.485 383.751 200.891 384.252 200.891Z'} fill={'#F4BC22'} />
            <path d={'M383.224 220.141C383.355 220.165 383.489 220.16 383.618 220.127C383.747 220.093 383.866 220.032 383.969 219.946C384.071 219.861 384.153 219.755 384.21 219.634C384.266 219.514 384.295 219.383 384.295 219.25C384.295 219.117 384.266 218.985 384.21 218.865C384.153 218.744 384.071 218.638 383.969 218.553C383.866 218.468 383.747 218.406 383.618 218.372C383.489 218.339 383.355 218.334 383.224 218.358C383.015 218.397 382.827 218.507 382.692 218.67C382.556 218.833 382.482 219.038 382.482 219.25C382.482 219.461 382.556 219.667 382.692 219.829C382.827 219.992 383.015 220.103 383.224 220.141Z'} fill={'#F4BC22'} />
            <path d={'M380.234 239.209C380.734 239.209 381.14 238.803 381.14 238.302C381.14 237.801 380.734 237.395 380.234 237.395C379.733 237.395 379.327 237.801 379.327 238.302C379.327 238.803 379.733 239.209 380.234 239.209Z'} fill={'#F4BC22'} />
            <path d={'M375.215 257.823C375.396 257.829 375.576 257.78 375.73 257.682C375.883 257.585 376.004 257.444 376.076 257.277C376.149 257.11 376.169 256.926 376.135 256.747C376.101 256.568 376.014 256.404 375.886 256.276C375.757 256.147 375.593 256.06 375.414 256.026C375.235 255.992 375.051 256.012 374.884 256.085C374.717 256.157 374.576 256.278 374.478 256.432C374.381 256.585 374.332 256.765 374.338 256.946C374.338 257.179 374.43 257.402 374.595 257.566C374.759 257.73 374.982 257.823 375.215 257.823Z'} fill={'#F4BC22'} />
            <path d={'M368.291 275.833C368.473 275.839 368.652 275.791 368.806 275.693C368.96 275.596 369.081 275.455 369.153 275.288C369.225 275.121 369.246 274.936 369.212 274.758C369.178 274.579 369.091 274.415 368.962 274.286C368.833 274.158 368.669 274.071 368.49 274.037C368.312 274.003 368.127 274.023 367.96 274.096C367.793 274.168 367.652 274.289 367.555 274.442C367.457 274.596 367.409 274.775 367.415 274.957C367.415 275.19 367.507 275.412 367.671 275.577C367.836 275.741 368.059 275.833 368.291 275.833Z'} fill={'#F4BC22'} />
            <path d={'M359.556 293.057C360.057 293.057 360.463 292.651 360.463 292.15C360.463 291.65 360.057 291.244 359.556 291.244C359.055 291.244 358.649 291.65 358.649 292.15C358.649 292.651 359.055 293.057 359.556 293.057Z'} fill={'#F4BC22'} />
            <path d={'M349.036 309.224C349.537 309.224 349.943 308.818 349.943 308.317C349.943 307.817 349.537 307.411 349.036 307.411C348.536 307.411 348.13 307.817 348.13 308.317C348.13 308.818 348.536 309.224 349.036 309.224Z'} fill={'#F4BC22'} />
            <path d={'M336.885 324.213C337.386 324.213 337.792 323.807 337.792 323.307C337.792 322.806 337.386 322.4 336.885 322.4C336.385 322.4 335.979 322.806 335.979 323.307C335.979 323.807 336.385 324.213 336.885 324.213Z'} fill={'#F4BC22'} />
            <path d={'M323.255 337.841C323.463 337.803 323.651 337.693 323.787 337.53C323.922 337.367 323.997 337.162 323.997 336.95C323.997 336.738 323.922 336.533 323.787 336.37C323.651 336.207 323.463 336.097 323.255 336.058C323.124 336.034 322.989 336.039 322.86 336.073C322.732 336.106 322.612 336.168 322.51 336.253C322.407 336.338 322.325 336.444 322.269 336.565C322.212 336.685 322.183 336.817 322.183 336.95C322.183 337.083 322.212 337.214 322.269 337.335C322.325 337.455 322.407 337.562 322.51 337.647C322.612 337.732 322.732 337.793 322.86 337.827C322.989 337.861 323.124 337.865 323.255 337.841Z'} fill={'#F4BC22'} />
            <path d={'M308.32 349.988C308.502 349.994 308.681 349.946 308.835 349.848C308.989 349.751 309.109 349.61 309.182 349.443C309.254 349.276 309.275 349.091 309.241 348.913C309.207 348.734 309.12 348.57 308.991 348.441C308.862 348.313 308.698 348.226 308.519 348.192C308.341 348.158 308.156 348.178 307.989 348.251C307.822 348.323 307.681 348.444 307.584 348.597C307.486 348.751 307.437 348.93 307.443 349.112C307.443 349.345 307.536 349.567 307.7 349.732C307.865 349.896 308.088 349.988 308.32 349.988Z'} fill={'#F4BC22'} />
            <path d={'M292.057 360.505C292.266 360.466 292.454 360.356 292.59 360.193C292.725 360.03 292.799 359.825 292.799 359.613C292.799 359.402 292.725 359.196 292.59 359.034C292.454 358.871 292.266 358.76 292.057 358.722C291.927 358.698 291.792 358.703 291.663 358.736C291.535 358.77 291.415 358.831 291.312 358.917C291.21 359.002 291.128 359.108 291.071 359.229C291.015 359.349 290.986 359.48 290.986 359.613C290.986 359.746 291.015 359.878 291.071 359.998C291.128 360.119 291.21 360.225 291.312 360.31C291.415 360.395 291.535 360.457 291.663 360.491C291.792 360.524 291.927 360.529 292.057 360.505Z'} fill={'#F4BC22'} />
            <path d={'M274.858 369.268C275.037 369.268 275.212 369.215 275.362 369.115C275.511 369.016 275.627 368.874 275.696 368.709C275.764 368.543 275.782 368.361 275.747 368.185C275.712 368.009 275.626 367.847 275.499 367.721C275.372 367.594 275.211 367.508 275.035 367.473C274.859 367.438 274.676 367.456 274.511 367.524C274.345 367.593 274.203 367.709 274.104 367.858C274.004 368.007 273.951 368.182 273.951 368.362C273.951 368.602 274.046 368.833 274.217 369.003C274.387 369.173 274.617 369.268 274.858 369.268Z'} fill={'#F4BC22'} />
            <path d={'M256.843 376.188C257.344 376.188 257.75 375.782 257.75 375.282C257.75 374.781 257.344 374.375 256.843 374.375C256.342 374.375 255.936 374.781 255.936 375.282C255.936 375.782 256.342 376.188 256.843 376.188Z'} fill={'#F4BC22'} />
            <path d={'M238.191 381.174C238.692 381.174 239.098 380.768 239.098 380.267C239.098 379.767 238.692 379.361 238.191 379.361C237.69 379.361 237.284 379.767 237.284 380.267C237.284 380.768 237.69 381.174 238.191 381.174Z'} fill={'#F4BC22'} />
            <path d={'M219.118 384.197C219.618 384.197 220.024 383.791 220.024 383.29C220.024 382.789 219.618 382.383 219.118 382.383C218.617 382.383 218.211 382.789 218.211 383.29C218.211 383.791 218.617 384.197 219.118 384.197Z'} fill={'#F4BC22'} />
            <path d={'M199.862 385.225C200.363 385.225 200.769 384.819 200.769 384.318C200.769 383.818 200.363 383.412 199.862 383.412C199.361 383.412 198.955 383.818 198.955 384.318C198.955 384.819 199.361 385.225 199.862 385.225Z'} fill={'#F4BC22'} />
            <path d={'M180.581 384.196C181.081 384.196 181.487 383.791 181.487 383.29C181.487 382.789 181.081 382.383 180.581 382.383C180.08 382.383 179.674 382.789 179.674 383.29C179.674 383.791 180.08 384.196 180.581 384.196Z'} fill={'#F4BC22'} />
            <path d={'M161.534 381.174C162.035 381.174 162.441 380.768 162.441 380.267C162.441 379.767 162.035 379.361 161.534 379.361C161.033 379.361 160.627 379.767 160.627 380.267C160.627 380.768 161.033 381.174 161.534 381.174Z'} fill={'#F4BC22'} />
            <path d={'M142.886 376.188C143.386 376.188 143.792 375.782 143.792 375.281C143.792 374.781 143.386 374.375 142.886 374.375C142.385 374.375 141.979 374.781 141.979 375.281C141.979 375.782 142.385 376.188 142.886 376.188Z'} fill={'#F4BC22'} />
            <path d={'M124.87 369.209C125.095 369.209 125.31 369.119 125.469 368.961C125.628 368.802 125.717 368.587 125.717 368.362C125.709 368.14 125.618 367.93 125.461 367.773C125.303 367.615 125.093 367.524 124.87 367.516C124.646 367.516 124.431 367.606 124.272 367.764C124.113 367.923 124.024 368.138 124.024 368.362C124.02 368.475 124.039 368.587 124.08 368.691C124.121 368.796 124.183 368.891 124.263 368.97C124.342 369.05 124.437 369.112 124.542 369.153C124.646 369.194 124.758 369.213 124.87 369.209Z'} fill={'#F4BC22'} />
            <path d={'M107.671 360.475C107.872 360.437 108.053 360.331 108.184 360.173C108.314 360.016 108.385 359.818 108.385 359.614C108.385 359.41 108.314 359.212 108.184 359.054C108.053 358.897 107.872 358.79 107.671 358.753C107.47 358.79 107.289 358.897 107.158 359.054C107.028 359.212 106.956 359.41 106.956 359.614C106.956 359.818 107.028 360.016 107.158 360.173C107.289 360.331 107.47 360.437 107.671 360.475Z'} fill={'#F4BC22'} />
            <path d={'M91.4704 349.959C91.6462 349.965 91.8197 349.918 91.9684 349.824C92.1171 349.73 92.2341 349.594 92.3042 349.432C92.3742 349.271 92.3941 349.092 92.3613 348.92C92.3284 348.747 92.2443 348.588 92.1199 348.464C91.9956 348.339 91.8366 348.255 91.6638 348.223C91.491 348.19 91.3123 348.21 91.151 348.28C90.9896 348.35 90.8531 348.467 90.7591 348.615C90.6651 348.764 90.6181 348.937 90.624 349.113C90.6316 349.335 90.7231 349.546 90.8803 349.703C91.0374 349.86 91.2483 349.952 91.4704 349.959Z'} fill={'#F4BC22'} />
            <path d={'M76.4753 337.811C76.9428 337.811 77.3217 337.432 77.3217 336.965C77.3217 336.498 76.9428 336.119 76.4753 336.119C76.0079 336.119 75.629 336.498 75.629 336.965C75.629 337.432 76.0079 337.811 76.4753 337.811Z'} fill={'#F4BC22'} />
            <path d={'M62.8438 324.182C63.0446 324.144 63.226 324.037 63.3564 323.88C63.4869 323.723 63.5583 323.525 63.5583 323.321C63.5583 323.116 63.4869 322.918 63.3564 322.761C63.226 322.604 63.0447 322.497 62.8438 322.459C62.643 322.497 62.4616 322.604 62.3312 322.761C62.2007 322.918 62.1293 323.116 62.1293 323.321C62.1293 323.525 62.2007 323.723 62.3312 323.88C62.4616 324.037 62.643 324.144 62.8438 324.182Z'} fill={'#F4BC22'} />
            <path d={'M50.6922 309.164C50.9167 309.164 51.1319 309.075 51.2907 308.916C51.4494 308.758 51.5386 308.542 51.5386 308.318C51.531 308.096 51.4394 307.885 51.2823 307.728C51.1252 307.571 50.9143 307.479 50.6922 307.472C50.4677 307.472 50.2524 307.561 50.0937 307.72C49.935 307.878 49.8458 308.093 49.8458 308.318C49.8416 308.43 49.8606 308.542 49.9016 308.647C49.9426 308.751 50.0048 308.846 50.0843 308.926C50.1637 309.005 50.2587 309.067 50.3633 309.108C50.468 309.149 50.5799 309.168 50.6922 309.164Z'} fill={'#F4BC22'} />
            <path d={'M40.1733 293.119C40.3742 293.081 40.5555 292.974 40.686 292.817C40.8164 292.66 40.8879 292.462 40.8879 292.257C40.8879 292.053 40.8164 291.855 40.686 291.698C40.5555 291.541 40.3742 291.434 40.1733 291.396C39.9725 291.434 39.7912 291.541 39.6607 291.698C39.5302 291.855 39.4588 292.053 39.4588 292.257C39.4588 292.462 39.5302 292.66 39.6607 292.817C39.7912 292.974 39.9725 293.081 40.1733 293.119Z'} fill={'#F4BC22'} />
            <path d={'M31.4069 275.802C31.6314 275.802 31.8466 275.713 32.0054 275.555C32.1641 275.396 32.2533 275.181 32.2533 274.956C32.2575 274.844 32.2385 274.732 32.1975 274.628C32.1565 274.523 32.0943 274.428 32.0148 274.349C31.9353 274.269 31.8403 274.207 31.7357 274.166C31.6311 274.125 31.5192 274.106 31.4069 274.11C31.1824 274.11 30.9671 274.199 30.8084 274.358C30.6497 274.517 30.5605 274.732 30.5605 274.956C30.5681 275.178 30.6597 275.389 30.8168 275.546C30.9739 275.703 31.1848 275.795 31.4069 275.802Z'} fill={'#F4BC22'} />
            <path d={'M24.4846 257.793C24.952 257.793 25.3309 257.414 25.3309 256.946C25.3309 256.479 24.952 256.1 24.4846 256.1C24.0171 256.1 23.6382 256.479 23.6382 256.946C23.6382 257.414 24.0171 257.793 24.4846 257.793Z'} fill={'#F4BC22'} />
            <path d={'M19.495 239.147C19.7195 239.147 19.9348 239.058 20.0935 238.899C20.2522 238.74 20.3414 238.525 20.3414 238.301C20.3456 238.189 20.3266 238.077 20.2856 237.972C20.2446 237.868 20.1824 237.773 20.1029 237.693C20.0235 237.614 19.9285 237.552 19.8239 237.511C19.7193 237.47 19.6073 237.451 19.495 237.455C19.2705 237.455 19.0553 237.544 18.8965 237.703C18.7378 237.861 18.6486 238.076 18.6486 238.301C18.6562 238.523 18.7478 238.734 18.9049 238.891C19.062 239.048 19.2729 239.139 19.495 239.147Z'} fill={'#F4BC22'} />
            <path d={'M16.4742 220.081C16.5879 220.085 16.7012 220.066 16.8074 220.025C16.9136 219.984 17.0105 219.923 17.0924 219.844C17.1742 219.765 17.2392 219.67 17.2837 219.566C17.3281 219.461 17.3509 219.348 17.3508 219.235C17.3131 219.034 17.2063 218.853 17.049 218.722C16.8917 218.592 16.6937 218.52 16.4894 218.52C16.285 218.52 16.087 218.592 15.9297 218.722C15.7724 218.853 15.6657 219.034 15.6279 219.235C15.6279 219.459 15.7171 219.674 15.8758 219.833C16.0345 219.992 16.2498 220.081 16.4742 220.081Z'} fill={'#F4BC22'} />
            <path d={'M15.4765 200.771C15.6392 200.771 15.7981 200.722 15.9329 200.631C16.0677 200.54 16.1722 200.411 16.233 200.26C16.2938 200.109 16.3081 199.944 16.274 199.785C16.24 199.625 16.1592 199.48 16.042 199.367C15.9249 199.255 15.7766 199.179 15.6164 199.151C15.4561 199.123 15.2912 199.144 15.1427 199.21C14.9942 199.277 14.8689 199.386 14.783 199.524C14.697 199.662 14.6543 199.823 14.6604 199.985C14.6682 200.196 14.7576 200.396 14.9098 200.543C15.062 200.689 15.2652 200.771 15.4765 200.771Z'} fill={'#F4BC22'} />
            <path d={'M16.4742 181.552C16.9417 181.552 17.3206 181.173 17.3206 180.706C17.3206 180.239 16.9417 179.86 16.4742 179.86C16.0068 179.86 15.6279 180.239 15.6279 180.706C15.6279 181.173 16.0068 181.552 16.4742 181.552Z'} fill={'#F4BC22'} />
            <path d={'M19.4959 162.515C19.6967 162.477 19.878 162.37 20.0085 162.213C20.139 162.056 20.2104 161.858 20.2104 161.654C20.2104 161.449 20.139 161.251 20.0085 161.094C19.878 160.937 19.6967 160.83 19.4959 160.792C19.295 160.83 19.1137 160.937 18.9832 161.094C18.8527 161.251 18.7813 161.449 18.7813 161.654C18.7813 161.858 18.8527 162.056 18.9832 162.213C19.1137 162.37 19.295 162.477 19.4959 162.515Z'} fill={'#F4BC22'} />
            <path d={'M24.4848 143.87C24.9523 143.87 25.3312 143.491 25.3312 143.024C25.3312 142.557 24.9523 142.178 24.4848 142.178C24.0174 142.178 23.6384 142.557 23.6384 143.024C23.6384 143.491 24.0174 143.87 24.4848 143.87Z'} fill={'#F4BC22'} />
            <path d={'M31.4069 125.798C31.5696 125.798 31.7285 125.75 31.8633 125.658C31.9981 125.567 32.1026 125.438 32.1633 125.287C32.2241 125.136 32.2384 124.971 32.2044 124.812C32.1704 124.653 32.0896 124.508 31.9724 124.395C31.8552 124.282 31.707 124.207 31.5467 124.179C31.3865 124.151 31.2215 124.171 31.073 124.238C30.9245 124.304 30.7993 124.413 30.7133 124.551C30.6274 124.69 30.5847 124.85 30.5907 125.013C30.5986 125.224 30.688 125.424 30.8402 125.57C30.9924 125.717 31.1956 125.799 31.4069 125.798Z'} fill={'#F4BC22'} />
            <path d={'M40.1726 108.605C40.3353 108.605 40.4942 108.556 40.629 108.465C40.7638 108.374 40.8683 108.245 40.9291 108.094C40.9898 107.943 41.0041 107.777 40.9701 107.618C40.9361 107.459 40.8553 107.314 40.7381 107.201C40.6209 107.088 40.4727 107.013 40.3125 106.985C40.1522 106.957 39.9872 106.978 39.8387 107.044C39.6902 107.111 39.565 107.22 39.479 107.358C39.3931 107.496 39.3504 107.657 39.3564 107.819C39.3643 108.03 39.4537 108.23 39.6059 108.377C39.7581 108.523 39.9613 108.605 40.1726 108.605Z'} fill={'#F4BC22'} />
            <path d={'M50.6891 92.4667C50.9136 92.4667 51.1289 92.3775 51.2876 92.2188C51.4463 92.0602 51.5355 91.8449 51.5355 91.6205C51.528 91.3985 51.4364 91.1877 51.2793 91.0306C51.1221 90.8735 50.9112 90.782 50.6891 90.7744C50.4647 90.7744 50.2494 90.8636 50.0907 91.0222C49.9319 91.1809 49.8428 91.3961 49.8428 91.6205C49.8385 91.7328 49.8575 91.8447 49.8985 91.9493C49.9396 92.0539 50.0018 92.1488 50.0812 92.2283C50.1607 92.3077 50.2557 92.3699 50.3603 92.4109C50.4649 92.4519 50.5769 92.4709 50.6891 92.4667Z'} fill={'#F4BC22'} />
            <path d={'M62.8423 77.4491C63.0587 77.4491 63.2663 77.3631 63.4194 77.2101C63.5724 77.0571 63.6584 76.8495 63.6584 76.6331C63.6584 76.4167 63.5724 76.2092 63.4194 76.0562C63.2663 75.9032 63.0587 75.8172 62.8423 75.8172C62.6258 75.8172 62.4182 75.9032 62.2652 76.0562C62.1121 76.2092 62.0261 76.4167 62.0261 76.6331C62.0261 76.8495 62.1121 77.0571 62.2652 77.2101C62.4182 77.3631 62.6258 77.4491 62.8423 77.4491Z'} fill={'#F4BC22'} />
            <path d={'M76.4754 63.7911C76.9262 63.7911 77.2916 63.4258 77.2916 62.9752C77.2916 62.5246 76.9262 62.1593 76.4754 62.1593C76.0247 62.1593 75.6593 62.5246 75.6593 62.9752C75.6593 63.4258 76.0247 63.7911 76.4754 63.7911Z'} fill={'#F4BC22'} />
            <path d={'M91.4699 51.7032C91.9373 51.7032 92.3163 51.3244 92.3163 50.8571C92.3163 50.3898 91.9373 50.011 91.4699 50.011C91.0025 50.011 90.6235 50.3898 90.6235 50.8571C90.6235 51.3244 91.0025 51.7032 91.4699 51.7032Z'} fill={'#F4BC22'} />
            <path d={'M107.669 41.1575C108.12 41.1575 108.485 40.7923 108.485 40.3416C108.485 39.891 108.12 39.5258 107.669 39.5258C107.219 39.5258 106.853 39.891 106.853 40.3416C106.853 40.7923 107.219 41.1575 107.669 41.1575Z'} fill={'#F4BC22'} />
            <path d={'M124.87 32.3939C125.031 32.3939 125.189 32.346 125.323 32.2564C125.458 32.1667 125.562 32.0393 125.624 31.8902C125.686 31.7411 125.702 31.5771 125.67 31.4188C125.639 31.2605 125.561 31.1152 125.447 31.0011C125.333 30.8869 125.187 30.8092 125.029 30.7778C124.871 30.7463 124.707 30.7624 124.558 30.8242C124.409 30.8859 124.281 30.9905 124.191 31.1247C124.102 31.2589 124.054 31.4166 124.054 31.578C124.054 31.7944 124.14 32.0019 124.293 32.1549C124.446 32.3079 124.654 32.3939 124.87 32.3939Z'} fill={'#F4BC22'} />
            <path d={'M142.885 25.5044C143.11 25.5044 143.325 25.4153 143.484 25.2566C143.643 25.0979 143.732 24.8827 143.732 24.6583C143.724 24.4363 143.633 24.2254 143.476 24.0684C143.318 23.9113 143.108 23.8197 142.885 23.8122C142.774 23.8122 142.664 23.8341 142.562 23.8766C142.459 23.9191 142.366 23.9814 142.287 24.06C142.208 24.1386 142.146 24.2319 142.104 24.3345C142.061 24.4372 142.039 24.5472 142.039 24.6583C142.035 24.7706 142.054 24.8825 142.095 24.9871C142.136 25.0916 142.198 25.1866 142.278 25.266C142.357 25.3455 142.452 25.4076 142.557 25.4487C142.661 25.4897 142.773 25.5087 142.885 25.5044Z'} fill={'#F4BC22'} />
            <path d={'M161.538 20.5181C162.005 20.5181 162.384 20.1393 162.384 19.672C162.384 19.2047 162.005 18.8259 161.538 18.8259C161.07 18.8259 160.691 19.2047 160.691 19.672C160.691 20.1393 161.07 20.5181 161.538 20.5181Z'} fill={'#F4BC22'} />
            <path d={'M180.581 17.4966C180.688 17.4927 180.793 17.4677 180.891 17.4231C180.988 17.3784 181.076 17.315 181.149 17.2364C181.222 17.1578 181.279 17.0656 181.316 16.9651C181.353 16.8645 181.37 16.7576 181.366 16.6505C181.366 16.4261 181.277 16.2109 181.119 16.0522C180.96 15.8935 180.745 15.8044 180.52 15.8044C180.298 15.8119 180.087 15.9035 179.93 16.0605C179.773 16.2176 179.681 16.4285 179.674 16.6505C179.673 16.7669 179.697 16.8821 179.743 16.9889C179.79 17.0957 179.857 17.1918 179.943 17.2712C180.028 17.3506 180.128 17.4116 180.238 17.4504C180.348 17.4892 180.464 17.5049 180.581 17.4966Z'} fill={'#F4BC22'} />
            <path d={'M199.863 16.4391C200.026 16.439 200.185 16.3903 200.32 16.2992C200.454 16.2081 200.559 16.0789 200.62 15.928C200.68 15.7772 200.695 15.6116 200.661 15.4526C200.627 15.2935 200.546 15.1483 200.429 15.0355C200.312 14.9227 200.163 14.8474 200.003 14.8195C199.843 14.7915 199.678 14.812 199.529 14.8784C199.381 14.9448 199.256 15.0541 199.17 15.1922C199.084 15.3303 199.041 15.4909 199.047 15.6534C199.055 15.8645 199.144 16.0644 199.297 16.2109C199.449 16.3574 199.652 16.4392 199.863 16.4391Z'} fill={'#F4BC22'} />
            <path d={'M219.118 17.4966C219.585 17.4966 219.964 17.1178 219.964 16.6505C219.964 16.1832 219.585 15.8044 219.118 15.8044C218.65 15.8044 218.271 16.1832 218.271 16.6505C218.271 17.1178 218.65 17.4966 219.118 17.4966Z'} fill={'#F4BC22'} />
            <path d={'M238.191 20.5181C238.658 20.5181 239.037 20.1393 239.037 19.672C239.037 19.2047 238.658 18.8259 238.191 18.8259C237.724 18.8259 237.345 19.2047 237.345 19.672C237.345 20.1393 237.724 20.5181 238.191 20.5181Z'} fill={'#F4BC22'} />
            <path d={'M256.935 25.5045C257.159 25.5045 257.375 25.4153 257.534 25.2566C257.692 25.098 257.781 24.8827 257.781 24.6583C257.774 24.4363 257.682 24.2255 257.525 24.0684C257.368 23.9113 257.157 23.8198 256.935 23.8122C256.824 23.8122 256.714 23.8341 256.611 23.8766C256.508 23.9192 256.415 23.9815 256.336 24.06C256.258 24.1386 256.196 24.2319 256.153 24.3345C256.11 24.4372 256.089 24.5472 256.089 24.6583C256.084 24.7706 256.103 24.8825 256.144 24.9871C256.185 25.0917 256.248 25.1866 256.327 25.2661C256.407 25.3455 256.502 25.4077 256.606 25.4487C256.711 25.4897 256.823 25.5087 256.935 25.5045Z'} fill={'#F4BC22'} />
            <path d={'M274.859 32.4244C275.326 32.4244 275.705 32.0456 275.705 31.5783C275.705 31.111 275.326 30.7322 274.859 30.7322C274.391 30.7322 274.012 31.111 274.012 31.5783C274.012 32.0456 274.391 32.4244 274.859 32.4244Z'} fill={'#F4BC22'} />
            <path d={'M292.056 41.1871C292.278 41.1796 292.489 41.0881 292.646 40.931C292.803 40.7739 292.895 40.563 292.903 40.341C292.903 40.1166 292.813 39.9014 292.655 39.7427C292.496 39.5841 292.281 39.4949 292.056 39.4949C291.944 39.4907 291.832 39.5097 291.727 39.5507C291.623 39.5917 291.528 39.6539 291.448 39.7333C291.369 39.8127 291.307 39.9077 291.266 40.0123C291.224 40.1169 291.206 40.2288 291.21 40.341C291.21 40.5654 291.299 40.7806 291.458 40.9393C291.616 41.098 291.832 41.1871 292.056 41.1871Z'} fill={'#F4BC22'} />
            <path d={'M308.229 51.7038C308.697 51.7038 309.076 51.3249 309.076 50.8576C309.076 50.3903 308.697 50.0115 308.229 50.0115C307.762 50.0115 307.383 50.3903 307.383 50.8576C307.383 51.3249 307.762 51.7038 308.229 51.7038Z'} fill={'#F4BC22'} />
            <path d={'M323.252 63.851C323.426 63.851 323.595 63.7996 323.739 63.7033C323.884 63.607 323.996 63.4701 324.062 63.31C324.129 63.1499 324.146 62.9737 324.112 62.8037C324.078 62.6337 323.995 62.4775 323.872 62.355C323.75 62.2324 323.593 62.149 323.423 62.1152C323.253 62.0813 323.077 62.0987 322.917 62.165C322.757 62.2313 322.62 62.3437 322.523 62.4878C322.427 62.6319 322.376 62.8013 322.376 62.9746C322.376 63.2071 322.468 63.43 322.633 63.5943C322.797 63.7586 323.02 63.851 323.252 63.851Z'} fill={'#F4BC22'} />
            <path d={'M336.886 77.4793C337.054 77.4793 337.217 77.4297 337.357 77.3367C337.496 77.2437 337.604 77.1116 337.668 76.957C337.732 76.8024 337.749 76.6322 337.716 76.4681C337.684 76.304 337.603 76.1532 337.485 76.0349C337.366 75.9165 337.216 75.836 337.052 75.8033C336.887 75.7707 336.717 75.7874 336.563 75.8515C336.408 75.9155 336.276 76.024 336.183 76.1631C336.09 76.3022 336.04 76.4658 336.04 76.6332C336.036 76.7454 336.055 76.8574 336.096 76.9619C336.137 77.0665 336.199 77.1615 336.279 77.2409C336.358 77.3203 336.453 77.3825 336.558 77.4235C336.662 77.4646 336.774 77.4835 336.886 77.4793Z'} fill={'#F4BC22'} />
            <path d={'M349.037 92.4686C349.505 92.4686 349.884 92.0898 349.884 91.6225C349.884 91.1552 349.505 90.7764 349.037 90.7764C348.57 90.7764 348.191 91.1552 348.191 91.6225C348.191 92.0898 348.57 92.4686 349.037 92.4686Z'} fill={'#F4BC22'} />
            <path d={'M359.557 108.665C360.024 108.665 360.403 108.286 360.403 107.819C360.403 107.352 360.024 106.973 359.557 106.973C359.089 106.973 358.711 107.352 358.711 107.819C358.711 108.286 359.089 108.665 359.557 108.665Z'} fill={'#F4BC22'} />
            <path d={'M368.292 125.859C368.759 125.859 369.138 125.48 369.138 125.013C369.138 124.546 368.759 124.167 368.292 124.167C367.824 124.167 367.446 124.546 367.446 125.013C367.446 125.48 367.824 125.859 368.292 125.859Z'} fill={'#F4BC22'} />
            <path d={'M375.215 143.899C375.396 143.905 375.576 143.857 375.73 143.759C375.883 143.662 376.004 143.521 376.076 143.354C376.149 143.187 376.169 143.002 376.135 142.824C376.101 142.645 376.014 142.481 375.886 142.352C375.757 142.224 375.593 142.137 375.414 142.103C375.235 142.069 375.051 142.089 374.884 142.162C374.717 142.234 374.576 142.355 374.478 142.508C374.381 142.662 374.332 142.841 374.338 143.023C374.338 143.255 374.43 143.478 374.595 143.643C374.759 143.807 374.982 143.899 375.215 143.899Z'} fill={'#F4BC22'} />
            <path d={'M380.231 162.544C380.731 162.544 381.137 162.138 381.137 161.638C381.137 161.137 380.731 160.731 380.231 160.731C379.73 160.731 379.324 161.137 379.324 161.638C379.324 162.138 379.73 162.544 380.231 162.544Z'} fill={'#F4BC22'} />
            <path d={'M383.224 181.612C383.725 181.612 384.131 181.206 384.131 180.705C384.131 180.205 383.725 179.799 383.224 179.799C382.723 179.799 382.317 180.205 382.317 180.705C382.317 181.206 382.723 181.612 383.224 181.612Z'} fill={'#F4BC22'} />
            <path d={'M399.608 200.347C399.658 200.352 399.709 200.345 399.756 200.329C399.804 200.313 399.847 200.287 399.884 200.252C399.921 200.218 399.951 200.177 399.971 200.131C399.991 200.085 400.001 200.035 400.001 199.985C400.001 199.933 399.991 199.882 399.971 199.834C399.951 199.787 399.922 199.743 399.886 199.707C399.849 199.671 399.806 199.642 399.758 199.622C399.711 199.602 399.66 199.592 399.608 199.592C399.556 199.592 399.505 199.602 399.458 199.622C399.41 199.642 399.367 199.671 399.33 199.707C399.294 199.743 399.265 199.787 399.245 199.834C399.225 199.882 399.215 199.933 399.215 199.985C399.215 200.035 399.225 200.085 399.245 200.131C399.265 200.177 399.295 200.218 399.332 200.252C399.369 200.287 399.412 200.313 399.46 200.329C399.507 200.345 399.558 200.352 399.608 200.347Z'} fill={'#F4BC22'} />
            <path d={'M398.669 219.626C398.774 219.626 398.874 219.585 398.947 219.511C399.021 219.437 399.062 219.337 399.062 219.233C399.063 219.183 399.052 219.133 399.032 219.087C399.012 219.041 398.983 219 398.946 218.965C398.909 218.931 398.865 218.905 398.818 218.889C398.77 218.873 398.72 218.866 398.669 218.871C398.573 218.871 398.481 218.909 398.413 218.977C398.345 219.045 398.307 219.137 398.307 219.233C398.302 219.283 398.309 219.334 398.325 219.381C398.341 219.429 398.367 219.472 398.402 219.509C398.436 219.546 398.477 219.576 398.523 219.596C398.569 219.616 398.619 219.626 398.669 219.626Z'} fill={'#F4BC22'} />
            <path d={'M395.979 238.725C396.083 238.725 396.183 238.684 396.257 238.61C396.331 238.536 396.372 238.436 396.372 238.332C396.372 238.228 396.331 238.128 396.257 238.054C396.183 237.981 396.083 237.939 395.979 237.939C395.875 237.939 395.775 237.981 395.701 238.054C395.628 238.128 395.586 238.228 395.586 238.332C395.586 238.436 395.628 238.536 395.701 238.61C395.775 238.684 395.875 238.725 395.979 238.725Z'} fill={'#F4BC22'} />
            <path d={'M391.264 257.46C391.368 257.46 391.468 257.419 391.542 257.345C391.616 257.272 391.657 257.172 391.657 257.068C391.657 257.017 391.647 256.968 391.627 256.922C391.607 256.876 391.577 256.834 391.54 256.8C391.504 256.766 391.46 256.74 391.412 256.724C391.365 256.707 391.314 256.701 391.264 256.705C391.168 256.705 391.076 256.743 391.008 256.811C390.94 256.879 390.901 256.972 390.901 257.068C390.897 257.118 390.904 257.168 390.92 257.216C390.936 257.263 390.962 257.307 390.996 257.344C391.031 257.381 391.072 257.41 391.118 257.43C391.164 257.45 391.214 257.461 391.264 257.46Z'} fill={'#F4BC22'} />
            <path d={'M384.856 275.681C385.073 275.681 385.249 275.505 385.249 275.288C385.249 275.071 385.073 274.895 384.856 274.895C384.639 274.895 384.463 275.071 384.463 275.288C384.463 275.505 384.639 275.681 384.856 275.681Z'} fill={'#F4BC22'} />
            <path d={'M376.726 293.119C376.776 293.123 376.827 293.117 376.874 293.1C376.922 293.084 376.965 293.058 377.002 293.024C377.039 292.99 377.069 292.948 377.089 292.902C377.109 292.856 377.119 292.807 377.119 292.756C377.119 292.652 377.078 292.552 377.004 292.478C376.93 292.405 376.83 292.363 376.726 292.363C376.676 292.363 376.626 292.373 376.58 292.394C376.534 292.414 376.492 292.443 376.458 292.48C376.424 292.517 376.398 292.561 376.382 292.608C376.365 292.656 376.359 292.706 376.363 292.756C376.363 292.852 376.402 292.945 376.47 293.013C376.538 293.081 376.63 293.119 376.726 293.119Z'} fill={'#F4BC22'} />
            <path d={'M366.962 309.799C367.066 309.799 367.166 309.758 367.24 309.684C367.314 309.61 367.355 309.511 367.355 309.406C367.355 309.302 367.314 309.202 367.24 309.129C367.166 309.055 367.066 309.014 366.962 309.014C366.858 309.014 366.758 309.055 366.684 309.129C366.61 309.202 366.569 309.302 366.569 309.406C366.569 309.511 366.61 309.61 366.684 309.684C366.758 309.758 366.858 309.799 366.962 309.799Z'} fill={'#F4BC22'} />
            <path d={'M355.594 325.391C355.644 325.395 355.695 325.389 355.742 325.372C355.79 325.356 355.833 325.33 355.87 325.296C355.907 325.262 355.937 325.221 355.957 325.174C355.977 325.128 355.987 325.079 355.987 325.028C355.987 324.924 355.946 324.824 355.872 324.751C355.798 324.677 355.698 324.636 355.594 324.636C355.544 324.635 355.494 324.646 355.448 324.666C355.402 324.686 355.361 324.715 355.326 324.752C355.292 324.789 355.266 324.833 355.25 324.88C355.234 324.928 355.227 324.978 355.232 325.028C355.232 325.076 355.241 325.123 355.259 325.167C355.277 325.211 355.304 325.251 355.338 325.285C355.371 325.318 355.411 325.345 355.455 325.363C355.499 325.382 355.547 325.391 355.594 325.391Z'} fill={'#F4BC22'} />
            <path d={'M342.809 339.805C342.897 339.805 342.982 339.77 343.044 339.708C343.107 339.645 343.142 339.561 343.142 339.473C343.146 339.427 343.14 339.38 343.126 339.336C343.111 339.292 343.087 339.252 343.056 339.218C343.024 339.184 342.986 339.157 342.944 339.138C342.901 339.119 342.856 339.11 342.809 339.11C342.713 339.11 342.621 339.148 342.553 339.216C342.485 339.284 342.447 339.377 342.447 339.473C342.454 339.564 342.496 339.648 342.563 339.71C342.63 339.771 342.718 339.805 342.809 339.805Z'} fill={'#F4BC22'} />
            <path d={'M328.664 352.98C328.768 352.98 328.868 352.939 328.941 352.865C329.015 352.791 329.056 352.691 329.056 352.587C329.056 352.483 329.015 352.383 328.941 352.309C328.868 352.236 328.768 352.194 328.664 352.194C328.559 352.194 328.459 352.236 328.386 352.309C328.312 352.383 328.271 352.483 328.271 352.587C328.271 352.691 328.312 352.791 328.386 352.865C328.459 352.939 328.559 352.98 328.664 352.98Z'} fill={'#F4BC22'} />
            <path d={'M313.341 364.704C313.445 364.704 313.545 364.663 313.618 364.589C313.692 364.516 313.733 364.416 313.733 364.311C313.733 364.207 313.692 364.107 313.618 364.034C313.545 363.96 313.445 363.919 313.341 363.919C313.236 363.919 313.136 363.96 313.063 364.034C312.989 364.107 312.948 364.207 312.948 364.311C312.948 364.416 312.989 364.516 313.063 364.589C313.136 364.663 313.236 364.704 313.341 364.704Z'} fill={'#F4BC22'} />
            <path d={'M296.925 374.889C297.029 374.889 297.129 374.848 297.203 374.774C297.277 374.7 297.318 374.601 297.318 374.496C297.318 374.392 297.277 374.292 297.203 374.219C297.129 374.145 297.029 374.104 296.925 374.104C296.821 374.104 296.721 374.145 296.647 374.219C296.573 374.292 296.532 374.392 296.532 374.496C296.532 374.601 296.573 374.7 296.647 374.774C296.721 374.848 296.821 374.889 296.925 374.889Z'} fill={'#F4BC22'} />
            <path d={'M279.634 383.442C279.712 383.442 279.787 383.419 279.852 383.375C279.917 383.332 279.967 383.271 279.997 383.199C280.027 383.127 280.034 383.048 280.019 382.972C280.004 382.896 279.967 382.826 279.912 382.771C279.857 382.716 279.787 382.679 279.71 382.664C279.634 382.648 279.555 382.656 279.483 382.686C279.412 382.716 279.35 382.766 279.307 382.831C279.264 382.895 279.241 382.971 279.241 383.049C279.241 383.153 279.282 383.253 279.356 383.327C279.43 383.4 279.53 383.442 279.634 383.442Z'} fill={'#F4BC22'} />
            <path d={'M261.588 390.27C261.638 390.274 261.688 390.268 261.736 390.252C261.783 390.235 261.827 390.209 261.864 390.175C261.901 390.141 261.93 390.1 261.95 390.053C261.971 390.007 261.981 389.958 261.981 389.907C261.981 389.803 261.939 389.703 261.866 389.63C261.792 389.556 261.692 389.515 261.588 389.515C261.483 389.515 261.383 389.556 261.31 389.63C261.236 389.703 261.195 389.803 261.195 389.907C261.194 389.958 261.205 390.007 261.225 390.053C261.245 390.1 261.274 390.141 261.311 390.175C261.348 390.209 261.392 390.235 261.44 390.252C261.487 390.268 261.538 390.274 261.588 390.27Z'} fill={'#F4BC22'} />
            <path d={'M242.969 395.346C243.074 395.346 243.174 395.305 243.247 395.231C243.321 395.158 243.362 395.058 243.362 394.953C243.362 394.849 243.321 394.749 243.247 394.676C243.174 394.602 243.074 394.561 242.969 394.561C242.865 394.561 242.765 394.602 242.691 394.676C242.618 394.749 242.576 394.849 242.576 394.953C242.576 395.005 242.587 395.056 242.606 395.104C242.626 395.151 242.655 395.195 242.691 395.231C242.728 395.268 242.771 395.297 242.819 395.316C242.867 395.336 242.918 395.346 242.969 395.346Z'} fill={'#F4BC22'} />
            <path d={'M223.925 398.581C223.975 398.585 224.025 398.579 224.073 398.563C224.12 398.546 224.164 398.52 224.201 398.486C224.238 398.452 224.267 398.411 224.288 398.364C224.308 398.318 224.318 398.269 224.318 398.218C224.318 398.114 224.276 398.014 224.203 397.941C224.129 397.867 224.029 397.825 223.925 397.825C223.874 397.825 223.825 397.836 223.779 397.856C223.732 397.876 223.691 397.905 223.657 397.942C223.623 397.979 223.597 398.023 223.58 398.07C223.564 398.118 223.558 398.168 223.562 398.218C223.562 398.314 223.6 398.407 223.668 398.475C223.736 398.543 223.829 398.581 223.925 398.581Z'} fill={'#F4BC22'} />
            <path d={'M204.702 400C204.806 400 204.906 399.959 204.979 399.885C205.053 399.811 205.094 399.711 205.094 399.607C205.094 399.503 205.053 399.403 204.979 399.329C204.906 399.256 204.806 399.214 204.702 399.214C204.597 399.214 204.497 399.256 204.424 399.329C204.35 399.403 204.309 399.503 204.309 399.607C204.309 399.711 204.35 399.811 204.424 399.885C204.497 399.959 204.597 400 204.702 400Z'} fill={'#F4BC22'} />
            <path d={'M185.383 399.517C185.433 399.521 185.484 399.515 185.531 399.498C185.579 399.482 185.623 399.456 185.66 399.422C185.696 399.388 185.726 399.346 185.746 399.3C185.766 399.254 185.776 399.204 185.776 399.154C185.776 399.05 185.735 398.95 185.661 398.876C185.587 398.803 185.487 398.761 185.383 398.761C185.333 398.761 185.283 398.771 185.237 398.791C185.191 398.811 185.15 398.841 185.115 398.878C185.081 398.915 185.055 398.958 185.039 399.006C185.023 399.053 185.016 399.104 185.02 399.154C185.02 399.25 185.059 399.342 185.127 399.41C185.195 399.478 185.287 399.517 185.383 399.517Z'} fill={'#F4BC22'} />
            <path d={'M166.253 397.161C166.299 397.165 166.346 397.159 166.39 397.145C166.434 397.13 166.474 397.106 166.508 397.075C166.542 397.043 166.57 397.005 166.588 396.963C166.607 396.92 166.616 396.875 166.616 396.828C166.616 396.732 166.578 396.64 166.51 396.572C166.442 396.504 166.35 396.466 166.253 396.466C166.207 396.465 166.161 396.475 166.119 396.493C166.076 396.512 166.038 396.539 166.007 396.573C165.976 396.608 165.952 396.648 165.937 396.692C165.922 396.736 165.917 396.782 165.921 396.828C165.921 396.872 165.929 396.915 165.946 396.955C165.963 396.996 165.987 397.032 166.018 397.063C166.049 397.094 166.086 397.119 166.126 397.135C166.166 397.152 166.21 397.161 166.253 397.161Z'} fill={'#F4BC22'} />
            <path d={'M147.391 392.99C147.457 392.99 147.521 392.97 147.576 392.934C147.63 392.897 147.673 392.845 147.698 392.784C147.723 392.724 147.73 392.657 147.717 392.592C147.704 392.528 147.673 392.469 147.626 392.422C147.58 392.376 147.52 392.344 147.456 392.331C147.391 392.318 147.325 392.325 147.264 392.35C147.203 392.375 147.151 392.418 147.115 392.473C147.078 392.527 147.059 392.591 147.059 392.657C147.059 392.701 147.067 392.744 147.084 392.784C147.101 392.825 147.125 392.861 147.156 392.892C147.187 392.923 147.223 392.948 147.264 392.964C147.304 392.981 147.347 392.99 147.391 392.99Z'} fill={'#F4BC22'} />
            <path d={'M129.041 387.037C129.129 387.037 129.214 387.002 129.276 386.94C129.339 386.878 129.374 386.793 129.374 386.705C129.378 386.659 129.372 386.612 129.357 386.568C129.343 386.525 129.319 386.484 129.288 386.45C129.256 386.416 129.218 386.389 129.176 386.37C129.133 386.352 129.087 386.342 129.041 386.342C128.945 386.342 128.853 386.381 128.785 386.449C128.717 386.517 128.678 386.609 128.678 386.705C128.678 386.751 128.688 386.797 128.706 386.84C128.725 386.882 128.752 386.92 128.786 386.951C128.82 386.983 128.861 387.006 128.905 387.021C128.948 387.036 128.995 387.042 129.041 387.037Z'} fill={'#F4BC22'} />
            <path d={'M111.326 379.332C111.414 379.332 111.499 379.297 111.561 379.234C111.623 379.172 111.658 379.088 111.658 379C111.658 378.911 111.623 378.827 111.561 378.764C111.499 378.702 111.414 378.667 111.326 378.667C111.238 378.667 111.153 378.702 111.091 378.764C111.028 378.827 110.993 378.911 110.993 379C110.993 379.088 111.028 379.172 111.091 379.234C111.153 379.297 111.238 379.332 111.326 379.332Z'} fill={'#F4BC22'} />
            <path d={'M94.459 369.935C94.5248 369.935 94.5891 369.915 94.6438 369.879C94.6984 369.842 94.7411 369.79 94.7662 369.729C94.7914 369.669 94.798 369.602 94.7851 369.537C94.7723 369.473 94.7407 369.414 94.6942 369.367C94.6477 369.321 94.5884 369.289 94.5239 369.276C94.4594 369.263 94.3926 369.27 94.3318 369.295C94.271 369.32 94.2191 369.363 94.1826 369.418C94.1461 369.472 94.1265 369.536 94.1265 369.602C94.1265 369.69 94.1616 369.775 94.2239 369.837C94.2863 369.9 94.3709 369.935 94.459 369.935Z'} fill={'#F4BC22'} />
            <path d={'M78.593 358.994C78.6393 358.994 78.6852 358.984 78.7276 358.966C78.7701 358.947 78.8082 358.92 78.8395 358.886C78.8708 358.852 78.8946 358.811 78.9094 358.767C78.9243 358.724 78.9297 358.677 78.9255 358.631C78.9255 358.543 78.8905 358.458 78.8281 358.396C78.7658 358.334 78.6812 358.299 78.593 358.299C78.5469 358.294 78.5004 358.3 78.4565 358.315C78.4126 358.33 78.3722 358.353 78.3381 358.385C78.3039 358.416 78.2767 358.454 78.2581 358.496C78.2396 358.539 78.2301 358.585 78.2303 358.631C78.2303 358.727 78.2685 358.819 78.3365 358.887C78.4046 358.955 78.4968 358.994 78.593 358.994Z'} fill={'#F4BC22'} />
            <path d={'M63.8379 346.544C63.9261 346.544 64.0106 346.509 64.073 346.447C64.1353 346.384 64.1704 346.3 64.1704 346.212C64.1746 346.165 64.1691 346.119 64.1543 346.075C64.1395 346.031 64.1157 345.991 64.0844 345.957C64.0531 345.923 64.015 345.895 63.9725 345.877C63.9301 345.858 63.8842 345.849 63.8379 345.849C63.7902 345.849 63.7431 345.858 63.6991 345.877C63.655 345.895 63.6151 345.922 63.5814 345.955C63.5477 345.989 63.521 346.029 63.5028 346.073C63.4845 346.117 63.4751 346.164 63.4751 346.212C63.4749 346.258 63.4844 346.304 63.503 346.346C63.5216 346.389 63.5488 346.427 63.583 346.458C63.6171 346.489 63.6574 346.513 63.7013 346.528C63.7452 346.543 63.7917 346.548 63.8379 346.544Z'} fill={'#F4BC22'} />
            <path d={'M50.3301 332.735C50.5138 332.735 50.6626 332.586 50.6626 332.402C50.6626 332.219 50.5138 332.07 50.3301 332.07C50.1465 332.07 49.9976 332.219 49.9976 332.402C49.9976 332.586 50.1465 332.735 50.3301 332.735Z'} fill={'#F4BC22'} />
            <path d={'M38.2705 317.686C38.3587 317.686 38.4433 317.651 38.5056 317.588C38.568 317.526 38.603 317.442 38.603 317.353C38.603 317.265 38.568 317.181 38.5056 317.118C38.4433 317.056 38.3587 317.021 38.2705 317.021C38.1823 317.021 38.0977 317.056 38.0354 317.118C37.973 317.181 37.938 317.265 37.938 317.353C37.938 317.442 37.973 317.526 38.0354 317.588C38.0977 317.651 38.1823 317.686 38.2705 317.686Z'} fill={'#F4BC22'} />
            <path d={'M27.6895 301.549C27.8731 301.549 28.022 301.4 28.022 301.217C28.022 301.033 27.8731 300.884 27.6895 300.884C27.5058 300.884 27.357 301.033 27.357 301.217C27.357 301.4 27.5058 301.549 27.6895 301.549Z'} fill={'#F4BC22'} />
            <path d={'M18.7095 284.476C18.762 284.478 18.8141 284.468 18.8618 284.445C18.9094 284.423 18.9512 284.39 18.9836 284.349C19.0161 284.308 19.0383 284.26 19.0484 284.208C19.0585 284.157 19.0564 284.103 19.042 284.053C19.042 283.965 19.007 283.88 18.9446 283.818C18.8822 283.756 18.7977 283.72 18.7095 283.72C18.6213 283.72 18.5367 283.756 18.4744 283.818C18.412 283.88 18.377 283.965 18.377 284.053C18.3626 284.103 18.3604 284.157 18.3706 284.208C18.3807 284.26 18.4029 284.308 18.4354 284.349C18.4678 284.39 18.5095 284.423 18.5572 284.445C18.6048 284.468 18.657 284.478 18.7095 284.476Z'} fill={'#F4BC22'} />
            <path d={'M11.4263 266.587C11.4921 266.587 11.5564 266.567 11.611 266.531C11.6657 266.494 11.7083 266.442 11.7335 266.381C11.7587 266.321 11.7653 266.254 11.7524 266.189C11.7396 266.125 11.7079 266.066 11.6614 266.019C11.6149 265.973 11.5557 265.941 11.4912 265.928C11.4267 265.915 11.3598 265.922 11.2991 265.947C11.2383 265.972 11.1864 266.015 11.1498 266.069C11.1133 266.124 11.0938 266.188 11.0938 266.254C11.0938 266.342 11.1288 266.427 11.1912 266.489C11.2536 266.552 11.3381 266.587 11.4263 266.587Z'} fill={'#F4BC22'} />
            <path d={'M6.04597 248.093C6.13415 248.093 6.21873 248.058 6.28108 247.995C6.34344 247.933 6.37847 247.848 6.37847 247.76C6.37847 247.672 6.34344 247.587 6.28108 247.525C6.21873 247.463 6.13415 247.428 6.04597 247.428C5.95778 247.428 5.87321 247.463 5.81085 247.525C5.7485 247.587 5.71347 247.672 5.71347 247.76C5.71347 247.848 5.7485 247.933 5.81085 247.995C5.87321 248.058 5.95778 248.093 6.04597 248.093Z'} fill={'#F4BC22'} />
            <path d={'M2.20672 229.176C2.25304 229.176 2.2989 229.166 2.34135 229.148C2.38379 229.129 2.42189 229.102 2.4532 229.068C2.4845 229.034 2.50833 228.993 2.52314 228.949C2.53795 228.906 2.54343 228.859 2.53922 228.813C2.53922 228.725 2.50419 228.64 2.44183 228.578C2.37947 228.516 2.2949 228.481 2.20672 228.481C2.11853 228.481 2.03396 228.516 1.9716 228.578C1.90924 228.64 1.87421 228.725 1.87421 228.813C1.87 228.859 1.87548 228.906 1.89029 228.949C1.9051 228.993 1.92893 229.034 1.96023 229.068C1.99154 229.102 2.02964 229.129 2.07208 229.148C2.11453 229.166 2.16039 229.176 2.20672 229.176Z'} fill={'#F4BC22'} />
            <path d={'M0.33261 209.957C0.420795 209.957 0.505368 209.922 0.567725 209.859C0.630081 209.797 0.665112 209.712 0.665112 209.624C0.665112 209.536 0.630081 209.452 0.567725 209.389C0.505368 209.327 0.420795 209.292 0.33261 209.292C0.244425 209.292 0.159852 209.327 0.0974959 209.389C0.0351397 209.452 0.000108345 209.536 0.000108332 209.624C0.000108318 209.712 0.0351397 209.797 0.0974959 209.859C0.159852 209.922 0.244425 209.957 0.33261 209.957Z'} fill={'#F4BC22'} />
            <path d={'M0.332502 190.678C0.419092 190.68 0.503257 190.65 0.569783 190.594C0.63631 190.539 0.680807 190.462 0.695232 190.376C0.695424 190.33 0.685932 190.284 0.667364 190.242C0.648797 190.199 0.621564 190.161 0.587412 190.13C0.55326 190.098 0.512943 190.075 0.469046 190.06C0.425148 190.045 0.37864 190.04 0.332502 190.044C0.288837 190.044 0.2456 190.052 0.205259 190.069C0.164918 190.086 0.128263 190.11 0.0973877 190.141C0.0350314 190.203 1.36117e-08 190.288 0 190.376C0.00756202 190.459 0.0459047 190.536 0.10746 190.592C0.169016 190.648 0.249313 190.679 0.332502 190.678Z'} fill={'#F4BC22'} />
            <path d={'M2.20725 171.429C2.28513 171.422 2.35812 171.388 2.4134 171.333C2.46868 171.277 2.50272 171.204 2.50953 171.126C2.50953 171.046 2.47768 170.969 2.42099 170.913C2.36431 170.856 2.28742 170.824 2.20725 170.824C2.16639 170.819 2.12495 170.824 2.08607 170.837C2.04718 170.851 2.01186 170.873 1.98276 170.902C1.95366 170.931 1.93154 170.966 1.91806 171.005C1.90459 171.044 1.90012 171.086 1.90498 171.126C1.90498 171.207 1.93683 171.283 1.99351 171.34C2.0502 171.397 2.12709 171.429 2.20725 171.429Z'} fill={'#F4BC22'} />
            <path d={'M6.04492 152.482C6.08334 152.482 6.12137 152.474 6.15652 152.459C6.19166 152.443 6.22312 152.42 6.24882 152.392C6.27452 152.363 6.29388 152.33 6.30563 152.293C6.31737 152.256 6.32124 152.218 6.31697 152.18C6.31735 152.104 6.28968 152.032 6.23938 151.976C6.18907 151.92 6.11974 151.885 6.04492 151.877C5.96475 151.877 5.88787 151.909 5.83118 151.966C5.77449 152.023 5.74265 152.099 5.74265 152.18C5.73779 152.22 5.74226 152.262 5.75573 152.301C5.76921 152.34 5.79132 152.375 5.82043 152.404C5.84953 152.433 5.88485 152.455 5.92373 152.469C5.96262 152.482 6.00406 152.487 6.04492 152.482Z'} fill={'#F4BC22'} />
            <path d={'M11.4261 133.988C11.467 133.993 11.5084 133.989 11.5473 133.975C11.5862 133.962 11.6215 133.94 11.6506 133.91C11.6797 133.881 11.7018 133.846 11.7153 133.807C11.7287 133.768 11.7332 133.727 11.7284 133.686C11.7284 133.646 11.7205 133.607 11.7054 133.57C11.6902 133.534 11.6679 133.5 11.6398 133.472C11.6118 133.444 11.5784 133.422 11.5418 133.407C11.5051 133.392 11.4658 133.384 11.4261 133.384C11.3482 133.391 11.2752 133.425 11.2199 133.48C11.1647 133.535 11.1306 133.608 11.1238 133.686C11.1238 133.766 11.1557 133.843 11.2123 133.9C11.269 133.956 11.3459 133.988 11.4261 133.988Z'} fill={'#F4BC22'} />
            <path d={'M18.7121 116.129C18.79 116.123 18.863 116.089 18.9182 116.033C18.9735 115.978 19.0075 115.905 19.0144 115.827C19.0144 115.747 18.9825 115.67 18.9258 115.614C18.8691 115.557 18.7922 115.525 18.7121 115.525C18.6319 115.525 18.555 115.557 18.4983 115.614C18.4417 115.67 18.4098 115.747 18.4098 115.827C18.4166 115.905 18.4507 115.978 18.5059 116.033C18.5612 116.089 18.6342 116.123 18.7121 116.129Z'} fill={'#F4BC22'} />
            <path d={'M27.689 99.0552C27.8559 99.0552 27.9913 98.9199 27.9913 98.753C27.9913 98.5861 27.8559 98.4508 27.689 98.4508C27.522 98.4508 27.3867 98.5861 27.3867 98.753C27.3867 98.9199 27.522 99.0552 27.689 99.0552Z'} fill={'#F4BC22'} />
            <path d={'M38.2687 82.8884C38.3489 82.8884 38.4258 82.8566 38.4825 82.7999C38.5391 82.7432 38.571 82.6664 38.571 82.5862C38.5635 82.5114 38.5283 82.4421 38.4724 82.3918C38.4165 82.3415 38.3439 82.3139 38.2687 82.3143C38.2305 82.31 38.1919 82.3139 38.1553 82.3256C38.1187 82.3373 38.085 82.3567 38.0565 82.3824C38.0279 82.4081 38.0051 82.4395 37.9896 82.4747C37.9741 82.5098 37.9662 82.5478 37.9664 82.5862C37.9616 82.6271 37.9661 82.6685 37.9795 82.7074C37.993 82.7462 38.0151 82.7816 38.0442 82.8107C38.0733 82.8397 38.1086 82.8619 38.1475 82.8753C38.1864 82.8888 38.2278 82.8933 38.2687 82.8884Z'} fill={'#F4BC22'} />
            <path d={'M50.3286 67.84C50.3667 67.8443 50.4054 67.8405 50.442 67.8287C50.4785 67.817 50.5122 67.7976 50.5408 67.7719C50.5693 67.7462 50.5921 67.7148 50.6077 67.6796C50.6232 67.6445 50.6311 67.6065 50.6308 67.5681C50.6357 67.5272 50.6312 67.4858 50.6177 67.4469C50.6043 67.408 50.5822 67.3727 50.5531 67.3436C50.524 67.3146 50.4886 67.2924 50.4497 67.279C50.4109 67.2655 50.3694 67.261 50.3286 67.2659C50.2901 67.2657 50.2521 67.2736 50.217 67.2891C50.1818 67.3046 50.1504 67.3274 50.1247 67.3559C50.099 67.3845 50.0796 67.4181 50.0678 67.4547C50.0561 67.4913 50.0522 67.5299 50.0565 67.5681C50.0565 67.6402 50.0852 67.7094 50.1362 67.7604C50.1872 67.8114 50.2564 67.84 50.3286 67.84Z'} fill={'#F4BC22'} />
            <path d={'M63.8396 54.0302C63.9117 54.0302 63.9809 54.0016 64.032 53.9506C64.083 53.8996 64.1116 53.8304 64.1116 53.7582C64.1159 53.7201 64.112 53.6814 64.1003 53.6449C64.0885 53.6083 64.0692 53.5746 64.0435 53.5461C64.0178 53.5175 63.9863 53.4947 63.9512 53.4792C63.916 53.4637 63.878 53.4558 63.8396 53.4561C63.7987 53.4512 63.7573 53.4557 63.7184 53.4691C63.6795 53.4826 63.6442 53.5047 63.6151 53.5338C63.586 53.5629 63.5639 53.5982 63.5504 53.6371C63.5369 53.676 63.5325 53.7174 63.5373 53.7582C63.5371 53.7967 63.545 53.8347 63.5605 53.8698C63.576 53.9049 63.5988 53.9364 63.6274 53.9621C63.6559 53.9878 63.6896 54.0071 63.7262 54.0189C63.7628 54.0306 63.8014 54.0345 63.8396 54.0302Z'} fill={'#F4BC22'} />
            <path d={'M78.5913 41.6095C78.6661 41.602 78.7355 41.5668 78.7858 41.511C78.8361 41.4551 78.8637 41.3825 78.8634 41.3073C78.8676 41.2691 78.8638 41.2305 78.852 41.1939C78.8403 41.1574 78.8209 41.1237 78.7952 41.0951C78.7695 41.0666 78.7381 41.0438 78.7029 41.0283C78.6678 41.0128 78.6297 41.0049 78.5913 41.0051C78.5505 41.0003 78.509 41.0047 78.4701 41.0182C78.4312 41.0317 78.3959 41.0538 78.3668 41.0829C78.3377 41.112 78.3156 41.1473 78.3021 41.1861C78.2887 41.225 78.2842 41.2664 78.289 41.3073C78.289 41.3874 78.3209 41.4643 78.3776 41.521C78.4343 41.5776 78.5111 41.6095 78.5913 41.6095Z'} fill={'#F4BC22'} />
            <path d={'M94.4591 30.6407C94.6261 30.6407 94.7614 30.5054 94.7614 30.3385C94.7614 30.1716 94.6261 30.0364 94.4591 30.0364C94.2922 30.0364 94.1569 30.1716 94.1569 30.3385C94.1569 30.5054 94.2922 30.6407 94.4591 30.6407Z'} fill={'#F4BC22'} />
            <path d={'M111.33 21.1515C111.41 21.1515 111.487 21.1196 111.543 21.063C111.6 21.0063 111.632 20.9294 111.632 20.8493C111.637 20.8084 111.632 20.767 111.619 20.7281C111.605 20.6893 111.583 20.6539 111.554 20.6249C111.525 20.5958 111.49 20.5736 111.451 20.5602C111.412 20.5467 111.371 20.5422 111.33 20.5471C111.25 20.5471 111.173 20.5789 111.116 20.6356C111.059 20.6923 111.027 20.7691 111.027 20.8493C111.034 20.9271 111.068 21.0001 111.124 21.0554C111.179 21.1106 111.252 21.1447 111.33 21.1515Z'} fill={'#F4BC22'} />
            <path d={'M129.04 13.5682C129.115 13.5607 129.184 13.5256 129.235 13.4697C129.285 13.4138 129.312 13.3412 129.312 13.266C129.316 13.2279 129.313 13.1892 129.301 13.1526C129.289 13.1161 129.27 13.0824 129.244 13.0539C129.218 13.0253 129.187 13.0025 129.152 12.987C129.117 12.9715 129.078 12.9636 129.04 12.9638C128.999 12.959 128.958 12.9635 128.919 12.9769C128.88 12.9904 128.845 13.0125 128.816 13.0416C128.786 13.0707 128.764 13.106 128.751 13.1449C128.737 13.1837 128.733 13.2252 128.738 13.266C128.738 13.3462 128.77 13.423 128.826 13.4797C128.883 13.5364 128.96 13.5682 129.04 13.5682Z'} fill={'#F4BC22'} />
            <path d={'M147.389 7.58435C147.429 7.58435 147.468 7.57653 147.505 7.56135C147.541 7.54616 147.575 7.5239 147.603 7.49584C147.631 7.46778 147.653 7.43447 147.668 7.39781C147.683 7.36114 147.691 7.32185 147.691 7.28217C147.684 7.20737 147.648 7.13806 147.593 7.08777C147.537 7.03748 147.464 7.00982 147.389 7.0102C147.351 7.00593 147.312 7.0098 147.275 7.02154C147.239 7.03328 147.205 7.05264 147.177 7.07833C147.148 7.10402 147.125 7.13547 147.11 7.17061C147.094 7.20574 147.086 7.24376 147.087 7.28217C147.082 7.32302 147.086 7.36444 147.1 7.40332C147.113 7.44219 147.135 7.4775 147.164 7.5066C147.193 7.53569 147.229 7.5578 147.268 7.57127C147.307 7.58474 147.348 7.58921 147.389 7.58435Z'} fill={'#F4BC22'} />
            <path d={'M166.252 3.44488C166.332 3.44488 166.409 3.41304 166.466 3.35637C166.522 3.2997 166.554 3.22284 166.554 3.14269C166.559 3.10184 166.555 3.06042 166.541 3.02154C166.528 2.98267 166.506 2.94735 166.477 2.91826C166.447 2.88917 166.412 2.86706 166.373 2.85359C166.334 2.84012 166.293 2.83565 166.252 2.84051C166.172 2.84051 166.095 2.87235 166.038 2.92902C165.982 2.98569 165.95 3.06255 165.95 3.14269C165.957 3.22055 165.991 3.29352 166.046 3.34878C166.101 3.40405 166.174 3.43807 166.252 3.44488Z'} fill={'#F4BC22'} />
            <path d={'M185.385 1.14761C185.473 1.14761 185.558 1.11259 185.62 1.05025C185.683 0.987912 185.718 0.903364 185.718 0.815206C185.718 0.727047 185.683 0.642499 185.62 0.580162C185.558 0.517824 185.473 0.482803 185.385 0.482803C185.297 0.482803 185.213 0.517824 185.15 0.580162C185.088 0.642499 185.053 0.727047 185.053 0.815206C185.053 0.903364 185.088 0.987912 185.15 1.05025C185.213 1.11259 185.297 1.14761 185.385 1.14761Z'} fill={'#F4BC22'} />
            <path d={'M204.702 0.664805C204.885 0.664805 205.034 0.515983 205.034 0.332402C205.034 0.148822 204.885 0 204.702 0C204.518 0 204.369 0.148822 204.369 0.332402C204.369 0.515983 204.518 0.664805 204.702 0.664805Z'} fill={'#F4BC22'} />
            <path d={'M223.925 2.08576C223.972 2.08997 224.018 2.0845 224.062 2.06969C224.106 2.05488 224.146 2.03106 224.18 1.99977C224.214 1.96847 224.242 1.93038 224.26 1.88795C224.279 1.84552 224.288 1.79967 224.288 1.75336C224.288 1.65719 224.25 1.56495 224.182 1.49695C224.114 1.42894 224.022 1.39074 223.925 1.39074C223.848 1.41193 223.781 1.46028 223.737 1.5269C223.692 1.59352 223.673 1.67393 223.684 1.75336C223.681 1.82785 223.703 1.90112 223.747 1.9614C223.791 2.02169 223.854 2.06548 223.925 2.08576Z'} fill={'#F4BC22'} />
            <path d={'M242.97 5.31842C243.035 5.31842 243.1 5.29893 243.154 5.2624C243.209 5.22588 243.252 5.17396 243.277 5.11322C243.302 5.05249 243.309 4.98565 243.296 4.92117C243.283 4.85669 243.251 4.79746 243.205 4.75098C243.158 4.70449 243.099 4.67283 243.034 4.66001C242.97 4.64718 242.903 4.65376 242.842 4.67892C242.782 4.70408 242.73 4.74668 242.693 4.80135C242.657 4.85601 242.637 4.92028 242.637 4.98602C242.637 5.07418 242.672 5.15873 242.734 5.22106C242.797 5.2834 242.881 5.31842 242.97 5.31842Z'} fill={'#F4BC22'} />
            <path d={'M261.589 10.3942C261.654 10.3942 261.719 10.3747 261.773 10.3382C261.828 10.3017 261.87 10.2497 261.896 10.189C261.921 10.1283 261.927 10.0614 261.915 9.99696C261.902 9.93248 261.87 9.87325 261.824 9.82676C261.777 9.78028 261.718 9.74862 261.653 9.73579C261.589 9.72297 261.522 9.72955 261.461 9.75471C261.401 9.77987 261.349 9.82247 261.312 9.87713C261.276 9.9318 261.256 9.99606 261.256 10.0618C261.256 10.15 261.291 10.2345 261.353 10.2968C261.416 10.3592 261.5 10.3942 261.589 10.3942Z'} fill={'#F4BC22'} />
            <path d={'M279.634 17.2231C279.7 17.2231 279.764 17.2036 279.819 17.1671C279.874 17.1306 279.916 17.0787 279.941 17.0179C279.967 16.9572 279.973 16.8904 279.96 16.8259C279.947 16.7614 279.916 16.7022 279.869 16.6557C279.823 16.6092 279.764 16.5775 279.699 16.5647C279.635 16.5519 279.568 16.5585 279.507 16.5836C279.446 16.6088 279.394 16.6514 279.358 16.7061C279.321 16.7607 279.302 16.825 279.302 16.8907C279.302 16.9344 279.31 16.9776 279.327 17.0179C279.344 17.0583 279.368 17.0949 279.399 17.1258C279.43 17.1566 279.467 17.1811 279.507 17.1978C279.547 17.2145 279.591 17.2231 279.634 17.2231Z'} fill={'#F4BC22'} />
            <path d={'M296.925 25.7754C297.013 25.7754 297.097 25.7403 297.16 25.678C297.222 25.6157 297.257 25.5311 297.257 25.443C297.257 25.3548 297.222 25.2703 297.16 25.2079C297.097 25.1456 297.013 25.1106 296.925 25.1106C296.836 25.1106 296.752 25.1456 296.689 25.2079C296.627 25.2703 296.592 25.3548 296.592 25.443C296.592 25.5311 296.627 25.6157 296.689 25.678C296.752 25.7403 296.836 25.7754 296.925 25.7754Z'} fill={'#F4BC22'} />
            <path d={'M313.338 35.9601C313.404 35.9601 313.468 35.9406 313.523 35.9041C313.577 35.8676 313.62 35.8157 313.645 35.7549C313.67 35.6942 313.677 35.6273 313.664 35.5629C313.651 35.4984 313.62 35.4392 313.573 35.3927C313.526 35.3462 313.467 35.3145 313.403 35.3017C313.338 35.2889 313.271 35.2955 313.211 35.3206C313.15 35.3458 313.098 35.3884 313.061 35.443C313.025 35.4977 313.005 35.562 313.005 35.6277C313.005 35.7159 313.04 35.8004 313.103 35.8628C313.165 35.9251 313.25 35.9601 313.338 35.9601Z'} fill={'#F4BC22'} />
            <path d={'M328.665 47.6846C328.73 47.6846 328.795 47.6651 328.849 47.6285C328.904 47.592 328.947 47.5401 328.972 47.4794C328.997 47.4186 329.003 47.3518 328.991 47.2873C328.978 47.2228 328.946 47.1636 328.9 47.1171C328.853 47.0706 328.794 47.039 328.729 47.0262C328.665 47.0133 328.598 47.0199 328.537 47.0451C328.477 47.0702 328.425 47.1128 328.388 47.1675C328.352 47.2222 328.332 47.2864 328.332 47.3522C328.332 47.4403 328.367 47.5249 328.429 47.5872C328.492 47.6496 328.576 47.6846 328.665 47.6846Z'} fill={'#F4BC22'} />
            <path d={'M342.809 60.8303C342.897 60.8303 342.982 60.7953 343.044 60.7329C343.107 60.6706 343.142 60.5861 343.142 60.4979C343.146 60.4518 343.14 60.4053 343.126 60.3614C343.111 60.3175 343.087 60.2772 343.056 60.2431C343.024 60.2089 342.986 60.1817 342.944 60.1631C342.901 60.1446 342.855 60.1351 342.809 60.1353C342.713 60.1353 342.621 60.1735 342.553 60.2415C342.485 60.3095 342.446 60.4017 342.446 60.4979C342.454 60.5887 342.496 60.6734 342.563 60.735C342.63 60.7966 342.718 60.8306 342.809 60.8303Z'} fill={'#F4BC22'} />
            <path d={'M355.596 75.273C355.684 75.273 355.769 75.2379 355.831 75.1756C355.894 75.1133 355.929 75.0287 355.929 74.9406C355.929 74.8524 355.894 74.7678 355.831 74.7055C355.769 74.6432 355.684 74.6081 355.596 74.6081C355.508 74.6081 355.423 74.6432 355.361 74.7055C355.299 74.7678 355.264 74.8524 355.264 74.9406C355.264 75.0287 355.299 75.1133 355.361 75.1756C355.423 75.2379 355.508 75.273 355.596 75.273Z'} fill={'#F4BC22'} />
            <path d={'M366.963 90.8658C367.051 90.8658 367.135 90.8308 367.198 90.7685C367.26 90.7061 367.295 90.6216 367.295 90.5334C367.295 90.4453 367.26 90.3607 367.198 90.2984C367.135 90.236 367.051 90.201 366.963 90.201C366.875 90.201 366.79 90.236 366.728 90.2984C366.665 90.3607 366.63 90.4453 366.63 90.5334C366.63 90.6216 366.665 90.7061 366.728 90.7685C366.79 90.8308 366.875 90.8658 366.963 90.8658Z'} fill={'#F4BC22'} />
            <path d={'M376.725 107.517C376.909 107.517 377.058 107.368 377.058 107.185C377.058 107.001 376.909 106.852 376.725 106.852C376.541 106.852 376.393 107.001 376.393 107.185C376.393 107.368 376.541 107.517 376.725 107.517Z'} fill={'#F4BC22'} />
            <path d={'M384.854 125.013C384.901 125.017 384.947 125.011 384.991 124.996C385.035 124.982 385.075 124.958 385.109 124.927C385.144 124.895 385.171 124.857 385.189 124.815C385.208 124.772 385.217 124.727 385.217 124.68C385.217 124.584 385.179 124.492 385.111 124.424C385.043 124.356 384.951 124.318 384.854 124.318C384.808 124.317 384.762 124.327 384.72 124.345C384.677 124.364 384.639 124.391 384.608 124.425C384.577 124.46 384.553 124.5 384.538 124.544C384.523 124.588 384.518 124.634 384.522 124.68C384.522 124.768 384.557 124.853 384.619 124.915C384.682 124.978 384.766 125.013 384.854 125.013Z'} fill={'#F4BC22'} />
            <path d={'M391.264 143.205C391.352 143.205 391.437 143.17 391.499 143.107C391.562 143.045 391.597 142.96 391.597 142.872C391.597 142.784 391.562 142.7 391.499 142.637C391.437 142.575 391.352 142.54 391.264 142.54C391.176 142.54 391.091 142.575 391.029 142.637C390.967 142.7 390.932 142.784 390.932 142.872C390.932 142.96 390.967 143.045 391.029 143.107C391.091 143.17 391.176 143.205 391.264 143.205Z'} fill={'#F4BC22'} />
            <path d={'M395.98 161.94C396.046 161.94 396.11 161.92 396.165 161.884C396.219 161.847 396.262 161.795 396.287 161.735C396.312 161.674 396.319 161.607 396.306 161.543C396.293 161.478 396.262 161.419 396.215 161.372C396.169 161.326 396.109 161.294 396.045 161.281C395.98 161.269 395.913 161.275 395.853 161.3C395.792 161.325 395.74 161.368 395.703 161.423C395.667 161.477 395.647 161.542 395.647 161.607C395.647 161.696 395.682 161.78 395.745 161.842C395.807 161.905 395.892 161.94 395.98 161.94Z'} fill={'#F4BC22'} />
            <path d={'M398.668 181.038C398.852 181.038 399.001 180.89 399.001 180.706C399.001 180.522 398.852 180.374 398.668 180.374C398.484 180.374 398.336 180.522 398.336 180.706C398.336 180.89 398.484 181.038 398.668 181.038Z'} fill={'#F4BC22'} />
        </chakra.svg>
    );
}
