import { useAccordionContext, useAccordionItemState } from '@chakra-ui/accordion';
import { Image, ImageProps } from '@chakra-ui/react';
import type { SystemStyleObject } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import React from 'react';

import AccordionIconSrc from './Chevron Down.svg';

/**
 * Override code icon AccordionIcon from @chakra-ui/react with Figma svg.
 *
 * AccordionIcon gives a visual cue of the open/close state of the accordion item.
 * It rotates `180deg` based on the open/close state.
 */
export function AccordionIcon (props: ImageProps) {
    const { isOpen, isDisabled } = useAccordionItemState();
    const { reduceMotion } = useAccordionContext();
    const _className = cx('chakra-accordion__icon', props.className);
    const iconStyles: SystemStyleObject = {
        opacity: isDisabled ? 0.4 : 1,
        transform: isOpen ? 'rotate(-180deg)' : undefined,
        transition: reduceMotion ? undefined : 'transform 0.2s',
        transformOrigin: 'center'
    };

    return (
        <Image
            width={'24px'}
            height={'24px'}
            viewBox={'0 0 24 24'}
            aria-hidden
            className={_className}
            __css={iconStyles}
            {...props}
            src={AccordionIconSrc}
        />
    );
}
