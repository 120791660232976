import { Box, useRadioGroup } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import React, { type MutableRefObject } from 'react';

import { ChargebeeInstance } from '../../../../chargebee/chargebee.types';
import { CreditCardField } from '../../../../chargebee/credit-card-field/credit-card-field';
import { SavedCardsRadioButton } from '../saved-cards-radio-button';

type NewCardFieldProps = {
    cardRef: MutableRefObject<ChargebeeInstance>
    getRadioProps: ReturnType<typeof useRadioGroup>['getRadioProps']
    setValue: ReturnType<typeof useRadioGroup>['setValue']
    setIsNewCard: (isNew: boolean) => any
    isNewCard: boolean
};

export const NEW_CARD_PAYMENT_SOURCE_ID = 'new-card';

export function NewCard ({
    cardRef, getRadioProps, setIsNewCard, isNewCard
}: NewCardFieldProps) {
    const radioProps = getRadioProps({
        value: NEW_CARD_PAYMENT_SOURCE_ID
    });

    function handleSetIsDifferentCard () {
        radioProps?.onChange && radioProps.onChange(NEW_CARD_PAYMENT_SOURCE_ID);

        setIsNewCard(true);
    }

    if (isNewCard) {
        return (
            <SavedCardsRadioButton
                {...radioProps}
            >
                <CreditCardField wrapperStyles={{ mb: 'none' }} ref={cardRef} />
            </SavedCardsRadioButton>
        );
    }

    return (
        <Box
            as={'button'}
            color={'primary.600'}
            fontSize={'18px'}
            fontWeight={'medium'}
            onClick={handleSetIsDifferentCard}
            mt={'10px'}
        >
            {t`Use a different card`}
        </Box>
    );
}
