import * as Yup from 'yup';
import { TestContext } from 'yup';

import { LocationData } from '../../../../../../location/use-active-location';

export function createCheckoutFormSchema (location: LocationData) {
    return Yup.object().shape({
        location: Yup.object()
            .shape({
                countryCode: Yup.string().required(),
                state: Yup.string().nullable().test(testCountryState)
            }).required('location Required'),
        creditCardComplete: Yup.boolean().test(testCardState).required('Card Required')
    }).required('Required');

    function testCountryState (this: TestContext, value: any) {
        // If there are states in a country require one
        // from the user to be chosen in this form.
        const requiresState = Array.isArray(location.country.states) &&
            location.country.states.length > 0;

        if (requiresState && value === null) {
            return this.createError({
                message: 'State is required'
            });
        }

        return true;
    }
}

function testCardState (this: TestContext, value: any) {
    if (!value) {
        return this.createError({
            message: 'Incomplete'
        });
    }

    return true;
}
