import { TextInputControlProps } from '@3plearning/chakra-mathletics-theme';
import { t } from '@lingui/macro';
import { useField } from 'formik';
import pDebounce from 'p-debounce';
import pMemoize from 'p-memoize';
import { type ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    CheckZipCodeDocument,
    CheckZipCodeQuery,
    type CheckZipCodeQueryVariables
} from '../../../../../../../__generated__/gateway-graphql';
import type { FetcherOptions } from '../../../../../../../api/gateway/fetcher';
import { createStandaloneFetcher } from '../../../../../../../api/gateway/standalone-fetcher';
import { pickCountryFromCountryCode } from '../../../../../../../location/__utils__/pick-country-from-country-code';
import { type LocationData } from '../../../../../../../location/use-active-location';
import { subscriptionActions } from '../../../../../../../subscription-model/subscription.slice';
import type { SchemaOptions } from '../../../../create-account/components/create-account-form/__utils__/form-validation';
import { useUpdateCountryPlans } from './use-update-country-plans';

type ActiveCountryState = LocationData['country'];
type InputProps = Partial<TextInputControlProps>;

export function useLocationField ({
    country: initialActiveCountry,
    countries: allCountries
}: LocationData) {
    const {
        updateCountryChange,
        isLoading: isLoadingUpdateCountryPlans
    } = useUpdateCountryPlans();
    const [isLoading, setIsLoading] = useState(isLoadingUpdateCountryPlans);
    const inputRef = useRef(null as any);
    const [inputTextProps, setInputTextProps] = useState<InputProps>();
    const [inputProps, setInputProps] = useState<InputProps>();
    const [_, __, helpers] = useField('location');
    const [activeCountry, setActiveCountry] = useState<ActiveCountryState>(initialActiveCountry);
    const shouldShowStates = Array.isArray(activeCountry.states) && activeCountry.states.length > 0 && activeCountry.countryCode !== 'USA';
    const states = Array.isArray(activeCountry.states) && length > 0 ? activeCountry.states : null;
    const dispatch = useDispatch();

    useEffect(() => {
        // handle loading state change in all scenarios
        setIsLoading(isLoadingUpdateCountryPlans);
    }, [isLoadingUpdateCountryPlans, setIsLoading]);

    function handleSetState (event: ChangeEvent<any>) {
        const stateName = event.target.value === '' ? null : event.target.value;

        setActiveCountry({
            ...activeCountry,
            state: stateName
        });
        helpers.setValue({
            countryCode: activeCountry.countryCode,
            state: stateName
        });

        dispatch(subscriptionActions.updateLocationState(stateName));
    }

    function handleSetCountry (event: ChangeEvent<any>) {
        const countryCode = event.target.value;
        const country = pickCountryFromCountryCode(
            { countryCode },
            allCountries
        );

        if (!country) {
            throw new Error('Unable to determine country from event');
        }

        setIsLoading(true);

        helpers.setValue({
            countryCode: country.countryCode,
            state: null
        });

        setActiveCountry(country);
        updateCountryChange(country.countryCode).finally(() => {
            setIsLoading(false);
        });
    }

    const defaultCountry = activeCountry.countryCode;
    const defaultState = activeCountry.state || 0;
    const verifyZipCode = pDebounce<[FetcherOptions<CheckZipCodeQueryVariables>], CheckZipCodeQuery>(
        pMemoize(createStandaloneFetcher(), { cacheKey: JSON.stringify }) as any,
        500
    );
    const handleOnBlur = async ({ url }: SchemaOptions) => {
        const zipcode= inputRef.current.value;

        if(zipcode.length === 0) {
            setInputProps({
                isSuccess: false
            });
            setInputTextProps({
                helperText: t`ZIP - Code - Required`,
                isInvalid: true
            });
            dispatch(subscriptionActions.isValidZipcode({ isValidZipCode: false }));

            return false;
        } else if((zipcode.length > 0 && zipcode.length < 5) || (zipcode.length > 5)) {
            setInputProps({
                isSuccess: false
            });
            setInputTextProps({
                helperText: t`ZIP - Code - Validation - Message`,
                isInvalid: true
            });
            dispatch(subscriptionActions.isValidZipcode({ isValidZipCode: false }));

            return false;
        } else if(zipcode.length === 5) {
            const response = await verifyZipCode({
                query: CheckZipCodeDocument,
                url,
                variables: {
                    zipcode
                }
            });

            if (
                response.validation &&
                !response.validation.isValid
            ) {
                setInputProps({
                    isSuccess: false
                });
                setInputTextProps({
                    helperText: t`ZIP - Code - InValid - Error`,
                    isInvalid: true
                });
                dispatch(subscriptionActions.isValidZipcode({ isValidZipCode: false }));

                return false;
            } else if(response.validation && response.validation.stateName) {
                setInputProps({
                    isSuccess: true
                });
                setInputTextProps({
                    isInvalid: false
                });
                dispatch(subscriptionActions.updateLocationState(response.validation.stateName));
                dispatch(subscriptionActions.updateLocationZipCode({ zipcode }));
                dispatch(subscriptionActions.isValidZipcode({ isValidZipCode: true }));

                return true;
            }
        }
    };

    return {
        isLoading,
        shouldShowStates,
        activeCountry,
        allCountries,
        states,
        defaultCountry,
        defaultState,
        setCountry: handleSetCountry,
        setState: handleSetState,
        inputRef,
        inputTextProps,
        inputProps,
        handleOnBlur
    };
}
