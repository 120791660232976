import { Severity } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { Extras } from '@sentry/types';

import { useEnvConfig } from '../../env-config';
import { handleFetchError } from '../../sentry/handle-fetch-error';
import { fetcher } from './fetcher';

/**
 * The main hook used to inject the gateway url into generated
 * react query hooks.
 *
 * For custom queries look to use `useFetcher` instead which wraps
 * Sentry logging.
 */
export const useGeneratedQueryFetcher = <TData, TVariables> (
    query: string,
    headers?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
    const { PARENT_GATEWAY_API_URL } = useEnvConfig();

    return (variables) => fetcher<TData, TVariables>({
        url: PARENT_GATEWAY_API_URL,
        variables,
        headers,
        query
    });
};

type Options = {
    extras?: Extras
    severity?: Severity
    headers?: RequestInit['headers']
};

/**
 * Wrapper over fetcher that will automatically inject env url
 * from the env context in the app.
 *
 * This is used directly by the graphql generated query hooks.
 */
export const useFetcher = <TData, TVariables> (
    query: string,
    options: Options = {}
): ((variables?: TVariables) => Promise<TData>) => {
    const { extras, severity = Severity.Critical, headers } = options;
    const { PARENT_GATEWAY_API_URL } = useEnvConfig();

    let addFeatureFlag: string;

    if(window.location.search.includes('campaign')) {
        addFeatureFlag = PARENT_GATEWAY_API_URL.concat('?featureKey=campaign');
    } else {
        addFeatureFlag = PARENT_GATEWAY_API_URL;
    }

    return async (variables) => {
        try {
            // noinspection UnnecessaryLocalVariableJS // leave this for try/catch
            const response = await fetcher<TData, TVariables>({
                url: addFeatureFlag,
                variables,
                headers,
                query
            });

            return response;
        } catch (error) {
            console.error('fetcher error', error);
            Sentry.withScope((scope) => {
                scope.setLevel(severity);

                if (extras) {
                    scope.setExtras(extras);
                }

                handleFetchError(error);
            });

            throw error;
        }
    };
};
