import type { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
    global: () => ({
        'html': {
            // The design includes various backgrounds that overlap,
            // Keep the Y overflow for the scroll.
            overflowX: 'hidden'
        },
        '#root': {
            // Hide the overflow for the backgrounds affecting size.
            overflow: 'hidden'
        },
        // using https://github.com/WICG/focus-visible breaks <select> in theme
        // :not(.focus-visible)
        ':focus': {
            outline: 'none'
        },
        // For non-Chakra <a> elements eg usage of dangerouslySetInnerHTML
        'a:focus-visible': {
            boxShadow: 'var(--3p-shadows-outline)'
        }
    })
};

export default styles;
