/* istanbul ignore file */
// Mocking Chargebee global is non-trivial
import { RippleButton } from '@3plearning/chakra-mathletics-theme';
import { Text, VStack } from '@chakra-ui/react';
import { Form, useFormikContext } from 'formik';
import React, { type MutableRefObject, useState } from 'react';

import type { ChargebeeInstance } from '../../../../../chargebee/chargebee.types';
import { CreditCardField } from '../../../../../chargebee/credit-card-field/credit-card-field';
import { CheckoutBackgrounds } from '../../../../../checkout/checkout-backgrounds/checkout-backgrounds';
import { CheckoutPriceIpPanel } from '../../../../../checkout/checkout-price-panel/checkout-price-ip-panel';
import { CheckoutTermsIpPanel } from '../../../../../checkout/checkout-terms-panel/checkout-terms-ip-panel';
import { Coupon } from '../../../../../coupon/coupon';
import { useAppSelector } from '../../../../../store/store';
import { LocationField } from './location-field/location-field';

type Props = {
    submitButtonText: string | null
    cardRef: MutableRefObject<ChargebeeInstance>
};

export function CheckoutForm ({
    cardRef,
    submitButtonText
}: Props) {
    const { isSubmitting } = useFormikContext();
    const [isLoading, setIsLoading] = useState(false);
    const isValidZipCode = useAppSelector(
        rootState => rootState.subscription.isValidZipCode
    );
    const userLocationCountryCode = useAppSelector(
        rootState => rootState.subscription.location
    );
    const isDisabled = userLocationCountryCode?.countryCode === 'USA' && !isValidZipCode;

    function handleLocationLoadingChange (isLoading: boolean) {
        setIsLoading(isLoading);
    }

    return (
        <VStack
            as={Form}
            align={'start'}
            w={'100%'}
            maxW={'668px'}
            my={{
                mobile: '32px',
                tablet: '56px'
            }}
            spacing={{
                mobile: '24px',
                tablet: '56px'
            }}
        >
            <VStack
                w={'100%'}
                spacing={'32px'}
            >
                <VStack align={'start'} w={'100%'} spacing={'24px'}>
                    <Text textStyle={'formSectionHeading'}>
                        Location
                    </Text>

                    <LocationField
                        onLoadingChange={handleLocationLoadingChange}
                    />
                </VStack>

                <VStack align={'start'} w={'100%'} spacing={'24px'}>
                    <Text textStyle={'formSectionHeading'}>
                        Payment Details
                    </Text>

                    <CreditCardField ref={cardRef} />
                </VStack>

                <VStack
                    mt={'8px !important'}
                    width={{ mobile: '100%' }}
                    align={'start'}
                >
                    <Coupon />
                </VStack>

                <VStack width={'100%'}>
                    <CheckoutPriceIpPanel />
                </VStack>
            </VStack>

            <VStack>
                <CheckoutTermsIpPanel />
            </VStack>

            <CheckoutBackgrounds />

            <RippleButton
                isLoading={isLoading || isSubmitting || submitButtonText === null}
                size={'large'}
                type={'submit'}
                data-testid={'submit-button'}
                isDisabled={isDisabled}
            >
                {submitButtonText}
            </RippleButton>
        </VStack>
    );
}

