import { useEffect, useState } from 'react';

import { ProductItem, useCreateQuoteMutation } from '../__generated__/gateway-graphql';
import { isString } from '../__utils__/utils';
import { useAppDispatch } from '../store/store';
import { subscriptionActions } from '../subscription-model/subscription.slice';

export type UseGetQuoteAndPricingDataProps = {
    productCode: string | null
    childrenCount: number
    productItemFromStore: ProductItem | null
};

export function useGetQuoteAndPricingData ({ childrenCount, productCode, productItemFromStore }: UseGetQuoteAndPricingDataProps) {
    const dispatch = useAppDispatch();

    let isLoading = false;

    const { mutateAsync: createQuote } = useCreateQuoteMutation();
    const [productItem, setProductItem] = useState<UseGetQuoteAndPricingDataProps['productItemFromStore']>(null);

    useEffect(() => {
        const getUsers = async () => {
            try {
                if(!productCode) {
                    /** to wait till default trial plan productCode is returned by useTrialQuery */
                    return isLoading = true;
                }
                /** Select default trial product if product is not selected */
                const quoteAndPricingData = await createQuote({
                    payment: {
                        quantity: childrenCount,
                        planId: productCode
                    }
                });

                if(!quoteAndPricingData.result?.productItem ||
                    !quoteAndPricingData.result?.quoteId
                ) {
                    throw new Error('create quote could not retrieve data');
                }

                setProductItem(quoteAndPricingData.result.productItem);

                dispatch(subscriptionActions.updatePricingData({
                    productItem: quoteAndPricingData.result.productItem,
                    quoteId: quoteAndPricingData.result.quoteId,
                    coupon: null
                }));
                dispatch(subscriptionActions.chooseSubscription({
                    productCode: quoteAndPricingData?.result?.productItem.productCode,
                    childrenCount
                }));
            } catch (error: any) {
                throw new Error('error in create quote api', error);
            }
        };

        /** Call this createQuote only for default trial plan when productCode is not previously selected.
         * if above condition is successful pass default productCode for user based on his location
         * to createQuote for default trial plan */
        if (isString(productCode) && !productItemFromStore) {
            getUsers();
        } else {
            setProductItem(productItemFromStore);
        }
    }, [productCode]);

    return {
        isLoading,
        productItem
    };
}
