import { useDispatch } from 'react-redux';

import type {
    CouponDetails,
    GetPlanDataQuery,
    GetPlanDataQueryVariables
} from '../../../../../../../__generated__/gateway-graphql';
import {
    GetPlanDataDocument,
    useUpdateQuoteMutation
} from '../../../../../../../__generated__/gateway-graphql';
import type { ProductItemWithText } from '../../../../../../../api/gateway/onboarding-data-contract';
import { useFetcher } from '../../../../../../../api/gateway/use-fetcher';
import { useSelectedProductIpQuery } from '../../../../../../../hooks/selected-product/use-selected-product-ip-query';
import { pickCountryFromCountryCode } from '../../../../../../../location/__utils__/pick-country-from-country-code';
import { useGetCountriesQuery } from '../../../../../../../location/use-get-countries-query';
import { useAppSelector } from '../../../../../../../store/store';
import { subscriptionActions } from '../../../../../../../subscription-model/subscription.slice';

/**
 * When the user changes the country code, we also need to
 * resolve a new Chargebee plan "productCode". This code
 * will need to be determined by the user's current choice and an
 * "equivalent" plan for a different countryCode.
 */
export function useUpdateCountryPlans () {
    const dispatch = useDispatch();
    const { mutateAsync: updateQuote } = useUpdateQuoteMutation();
    const getQuoteAndPricingData = useAppSelector(
        state => state.subscription.quoteAndPricingData
    );
    const {
        data: selectedProductDetailsData
    } = useSelectedProductIpQuery();
    const {
        data: allCountriesData,
        isLoading: isLoadingGetCountriesQuery,
        isSuccess: isSuccessGetCountriesQuery
    } = useGetCountriesQuery();
    const countries = allCountriesData?.countries || [];
    const currentProduct = selectedProductDetailsData?.productItem || null;
    const fetchPlanData = useFetcher<GetPlanDataQuery, GetPlanDataQueryVariables>(GetPlanDataDocument);
    const isLoading = isLoadingGetCountriesQuery;
    const isSuccess = isSuccessGetCountriesQuery;

    return {
        isLoading,
        isSuccess,
        updateCountryChange: async (countryCode: string) => {
            const country = pickCountryFromCountryCode({
                countryCode
            }, countries);

            if (!country) {
                throw new Error('Unable to determine country from countryCode ' + countryCode);
            }

            const { currencyCode } = country;

            if (!currentProduct) {
                const payload = {
                    currencyCode,
                    countryCode
                };

                dispatch(subscriptionActions.updateLocationCountryCode(payload));

                return payload;
            }

            const productCode = await pickEquivalentProductFromCurrencyCode(<Options>{
                fetchPlanData,
                currencyCode,
                currentProduct,
                dispatch,
                quoteId: getQuoteAndPricingData?.quoteId,
                coupon: getQuoteAndPricingData?.coupon
            });

            if (!productCode) {
                throw new Error('unable to find match');
            }

            /** Update the existing quote with new plan when country is changed in checkout page */
            const quoteAndPricingData = await updateQuote({
                quoteInput: {
                    quoteId: getQuoteAndPricingData?.quoteId,
                    currencyCode: country.currencyCode
                }
            });

            if(!quoteAndPricingData.intentObject?.productItem ||
                !quoteAndPricingData.intentObject?.quoteId
            ) {
                throw new Error('update quote api could not retrieve data');
            }

            dispatch(subscriptionActions.updatePricingData({
                productItem: quoteAndPricingData.intentObject.productItem,
                quoteId: quoteAndPricingData.intentObject.quoteId,
                coupon: null
            }));
            /** remove coupon details when country is changed */
            dispatch(subscriptionActions.isCountryChanged({ isCountryChanged: true }));
            const payload = {
                productCode,
                currencyCode: country.currencyCode,
                countryCode: country.countryCode
            };

            dispatch(subscriptionActions.updateLocationCountryCode(payload));

            return payload;
        }
    };
}

type Options = {
    currentProduct: ProductItemWithText
    currencyCode: string
    fetchPlanData: (variables: GetPlanDataQueryVariables) => Promise<GetPlanDataQuery>
    dispatch: ReturnType<typeof useDispatch>
    quoteId: string
    coupon: CouponDetails
};

/**
 * For switching between different countries by currencyCode
 * we need a mapping to find equivalent plans between different codes.
 *
 * The subscriptionType eg "Monthly | Annual" values are seen to
 * be consistently equivalent between currencyCode product data.
 */
async function pickEquivalentProductFromCurrencyCode ({
    currentProduct,
    currencyCode,
    fetchPlanData,
    dispatch,
    quoteId,
    coupon
}: Options): Promise<string | null> {
    const planData = await fetchPlanData({
        options: {
            currencyCode
        }
    });
    const productItems = planData.plan?.productItems || [];
    /** Find selected product in new plan list of changed country */
    const equivalentSubscription = productItems.find(
        // Assumption on mapping subscriptionType will be consistent between countries
        product => product.subscriptionType === currentProduct.subscriptionType
    );
    /** Get children count of selected product */
    const priceListQuantity = currentProduct.priceList[0].quantity;

    if (equivalentSubscription) {
        const priceListSelected = equivalentSubscription.priceList[priceListQuantity - 1];

        /** When we change the country from drop down, select the pricing plan for
         * that particular country by checking the product code and children count selected
         * and then store it in redux so that it can be used to reflect the correct data on FE */
        dispatch(subscriptionActions.updatePricingData({
            productItem: {
                ...equivalentSubscription,
                priceList: [{
                    ...priceListSelected
                }]
            },
            quoteId,
            coupon
        }));
    }

    return equivalentSubscription?.productCode || null;
}
