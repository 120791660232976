import { useMathleticsToast } from '@3plearning/chakra-mathletics-theme';
import { useFormik } from 'formik';
import { useMemo, useRef } from 'react';
import { type To, useNavigate } from 'react-router-dom';

import {
    useCreateCustomerSubscriptionMutation,
    useCreatePaymentIntentFromQuoteMutation,
    useLogExceptionMutation
} from '../../../__generated__/gateway-graphql';
import type { ChargebeeInstance } from '../../../chargebee/chargebee.types';
import { useAppSelector } from '../../../store/store';
import type { SalesforceURLData } from '../../../subscription-model/subscription.types';
import { createExistingCustomerSubscription } from '../create-existing-customer-subscription';
import { createExistingCustomerSchema } from './__utils__/existing-customer-schema';

type Options = {
    contactUsUrl: string
    salesforce: SalesforceURLData
    chargebeeInstance: ChargebeeInstance
    successRedirectTo: To
};

export function useExistingCustomerCheckoutPage ({
    contactUsUrl, salesforce, chargebeeInstance, successRedirectTo
}: Options) {
    const validationSchema = useMemo(createExistingCustomerSchema, []);
    const { mutateAsync: createPaymentIntentFromQuote } = useCreatePaymentIntentFromQuoteMutation();
    const { mutateAsync: createCustomerSubscription } = useCreateCustomerSubscriptionMutation();
    const { mutateAsync: logException } = useLogExceptionMutation();
    const toast = useMathleticsToast();
    const cardRef = useRef<ChargebeeInstance>(null as any);
    const navigate = useNavigate();
    const subscription = useAppSelector(state => state.subscription);
    const formValue = useFormik({
        initialValues: {
            creditCardComplete: false,
            customerPaymentSource: undefined
        },
        validationSchema,
        onSubmit: (formValues) => {
            return createExistingCustomerSubscription({
                successRedirectTo,
                chargebeeInstance,
                formValues,
                salesforce,
                createPaymentIntentFromQuote,
                createCustomerSubscription,
                logException,
                cardRef,
                navigate,
                toast,
                contactUsUrl,
                subscription
            });
        }
    });

    function handleBack () {
        navigate(-1);
    }

    return {
        formValue,
        cardRef,
        onBack: handleBack
    };
}
