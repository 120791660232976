import { isString } from '../../__utils__/utils';
import { useUrlQuery } from '../../api/contentful/urls/use-url-query';

export function useContentfulUrl (contentName: string) {
    const query = useUrlQuery(contentName);
    const applicationUrl = typeof query.data?.url === 'string' ? query.data : null;
    const isSuccessWithNoData = !applicationUrl && query.isSuccess;

    if (isSuccessWithNoData) {
        console.error('Unable to find Url content with name', contentName);
    }

    if (!isString(applicationUrl?.url)) {
        return null;
    }

    return applicationUrl?.url;
}
