// no value in covering this
/* istanbul ignore file */
import * as Sentry from '@sentry/react';

type GraphQLErrorExtras = {
    url: string
    query: string
    variables?: any
};

/**
 * Basic errors for adding extra data to Sentry logging,
 * having custom errors is restricted in api support as CRA
 * transpiles the code down.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#custom_error_types
 * @see https://stackoverflow.com/a/33877501
 */
type GraphQLStatusErrorContext =
    & GraphQLErrorExtras
    & {
    status: number
};

export class StatusCodeError extends Error {
    constructor (message: string, extras: GraphQLStatusErrorContext) {
        super(message);

        Sentry.setExtras(extras);
    }

    get name () {
        return this.constructor.name;
    }
}

type GraphQLErrorItem = {
    message: string
    path: Array<string>
    locations: Array<any>
};

type GraphQLQueryErrorsContext =
    & GraphQLErrorExtras
    & {
    errors: Array<GraphQLErrorItem>
};

export class GraphQLError extends Error {
    constructor (message: string, extras: GraphQLQueryErrorsContext) {
        super(message);

        Sentry.setExtras(extras);
    }

    get name () {
        return this.constructor.name;
    }
}
