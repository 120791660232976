import {
    Box, Flex, Text, Stack
} from '@chakra-ui/react';
import React, { type PropsWithChildren } from 'react';

import { isCyberWeekCampaignActive, isStringDefined } from '../../__utils__/utils';
import { useQuoteAndPricingSelector } from '../../subscription-model/subscription.selectors';
import { BackButton } from './components/back-button';
import { SpecialsRibbon } from './components/specials-ribbon';

type Props = PropsWithChildren<{
    headingText: string
    subtitleText?: string | null
    ribbonText?: string
    onBack?: (() => any) | null
    isSelectPage?: boolean
}>;

export function PageHeading ({
    headingText, subtitleText = null, ribbonText, onBack = null, isSelectPage = false, children
}: Props) {
    const quoteAndPricingData = useQuoteAndPricingSelector();
    const internalCoupon = quoteAndPricingData?.productItem?.internalCoupon || null;
    const isCampaignActive = isStringDefined(internalCoupon);
    const isCyberMondayCampaign = isCyberWeekCampaignActive(internalCoupon);
    const showRibbon = (!isSelectPage && isCampaignActive) || (!isSelectPage && quoteAndPricingData?.productItem?.isTrial);
    const hasSubtitleText = typeof subtitleText === 'string';
    // the design has slightly different page title styles when there
    // is a back button and when there is not.
    const largeTitleMode = onBack !== null;
    const pageTitleStyle = largeTitleMode ? 'pageTitleMedium' : 'pageTitleLarge';
    const marginTop = largeTitleMode ? {
        mobile: '16px',
        tablet: '48px',
        desktop: '40px'
    } : '48px';

    return (
        <Box
            mt={marginTop}
            mb={{
                mobile: '16px',
                pageHeaderTablet: '24px'
            }}
            w={'100%'}
        >
            <Flex
                justify={'space-between'}
                align={'center'}
                position={'relative'}
                minHeight={{
                    mobile: '44px',
                    pageHeaderTablet: '48px',
                    desktop: '58px'
                }}
                {...hasSubtitleText && { mb: '14px' }}
            >
                <Flex
                    align={'start'}
                    flexDirection={{
                        mobile: 'column',
                        fieldRowTablet: 'row'
                    }}
                >
                    {largeTitleMode ?
                        <BackButton
                            onClick={onBack}
                            mr={{
                                tablet: '24',
                                fieldRowTablet: '28px'
                            }}
                            mt={0}
                            left={{
                                mobile: '24px',
                                fieldRowTablet: 0
                            }}
                        />
                        : null}

                    <Stack
                        ml={{
                            mobile: '24px',
                            fieldRowTablet: 0
                        }}
                    >
                        <Text
                            data-testid={'page-title'}
                            sx={{
                                label: 'page-heading'
                            }}
                            textStyle={pageTitleStyle}
                            as={'h1'}
                        >
                            {headingText}
                        </Text>

                        {children}

                        {hasSubtitleText ? <Text
                            data-testid={'page-subtitle'}
                            textStyle={'pageSubtitle'}
                            {...largeTitleMode ? {
                                ml: {
                                    mobile: 'unset',
                                    pageHeaderTablet: '74px'
                                }
                            } : null}
                        >
                            {subtitleText}
                        </Text> : null}
                    </Stack>
                </Flex>

                { showRibbon ?
                    <SpecialsRibbon
                        sloganText={ribbonText}
                        isCampaignActive={isCampaignActive}
                        isCyberMondayCampaign={isCyberMondayCampaign}
                    /> :
                    null
                }
            </Flex>
        </Box>
    );
}

