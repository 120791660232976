import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import { useProgressState } from './components/hooks/use-progress-state';
import { ProgressBar } from './components/progress-bar';
import { Step } from './components/step';

export type ProgressStep = {
    label: string
    path: string
    status?: string
};

export type StepperProps = {
    steps: ProgressStep[]
};

export function Stepper ({ steps }: StepperProps) {
    const { value, status } = useProgressState(steps);

    return (
        <Box
            data-testid={'stepper-component'}
            w={'100%'}
            mx={'auto'}
            maxW={'676px'}
            h={'38px'}
            px={{
                mobile: '24px',
                tablet: '32px'
            }}
        >
            <Box as={'div'} aria-label={'Steps'} position={'relative'}>
                {steps.length < 6 ?
                    <>
                        <Flex justify={'space-between'} align={'center'} listStyleType={'none'} zIndex={1}>
                            {
                                steps.map((step, index) => (
                                    <Step
                                        label={step.label}
                                        key={step.label}
                                        totalSteps={steps.length}
                                        status={status[index].status}
                                    >
                                        {index + 1}
                                    </Step>
                                ))}
                        </Flex>
                        <ProgressBar value={value} />
                    </> :
                    <Flex justifyContent={'center'} zIndex={1}>
                        <Box color={'error.600'}>Error: Number of steps cannot be more than 5</Box>
                    </Flex>
                }
            </Box>
        </Box>
    );
}
