import {
    Image, SkeletonCircle
} from '@chakra-ui/react';
import React from 'react';

import {
    CardContainer, SubscriptionCardImageStyles, SubscriptionText
} from './subscription-card.styled';

export type SubscriptionCardDetails = {
    imgSrc: string
    subscriptionText: string
    altName: string
};

export const SubscriptionCard = ({
    altName, imgSrc, subscriptionText
}: SubscriptionCardDetails) => {
    return (
        <CardContainer data-testid={'subscription-card'}>
            <div>
                <Image
                    sx={SubscriptionCardImageStyles}
                    alt={altName}
                    src={imgSrc}
                    fallback={<SkeletonCircle sx={SubscriptionCardImageStyles} />}
                />
            </div>
            <SubscriptionText data-testid={'subscription-text'}>
                {subscriptionText}
            </SubscriptionText>
        </CardContainer>
    );
};
