/** mocking an animation library is not practical **/
/* istanbul ignore file */
import React from 'react';

import { LottieWhenVisible } from '../../../lottie/lottie-when-visible';
import * as animationData from './steps.json';

export function StepsAnim () {
    return (
        <LottieWhenVisible
            data-testid={'steps-anim'}
            animationData={animationData}
        />
    );
}
