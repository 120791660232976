// no value in covering this

export type ParamsDictionary = {
    [key: string]: undefined | string
};

/**
 * Gets the url params as a key value dictionary,
 * react-router-dom's also has useParams hook, but it is coupled to route path matching.
 */
export const useQueryParams = <T extends ParamsDictionary> (): T => {
    const params = new URLSearchParams(window.location.search.replace('?', ''));

    return Object.fromEntries(params) as T;
};
