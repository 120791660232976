import {
    SkeletonCircle,
    Image,
    Text
} from '@chakra-ui/react';
import React from 'react';

import { ContentfulLink } from '../components/contentful-link/contentful-link';
import FacebookLogoSrc from './assets/Facebook.svg';
import InstagramLogoSrc from './assets/Instagram.svg';
import LinkedInLogoSrc from './assets/LinkedIn.svg';
import TwitterLogoSrc from './assets/Twitter.svg';
import YouTubeLogoSrc from './assets/YouTube.svg';
import {
    FooterContainer,
    LinksAndImagesSection,
    SocialMediaContainer,
    CopyrightsContainer,
    MenuSection,
    LinkStyles,
    TextStyles,
    LogoStyles
} from './footer.styled';

type SocialMediaLink = {
    label: string
    iconSrc: string
    alt: string
};

type SocialMediaMenuData = Array<SocialMediaLink>;

const LINKS_MENU = ['Contact Us', 'Terms and Conditions', 'Privacy Policy'];
const SOCIAL_MEDIA_LINKS: SocialMediaMenuData = [
    {
        label: 'Twitter',
        alt: 'Twitter',
        iconSrc: TwitterLogoSrc
    },
    {
        label: 'Instagram',
        alt: 'Instagram',
        iconSrc: InstagramLogoSrc
    },
    {
        label: 'LinkedIn',
        alt: 'LinkedIn',
        iconSrc: LinkedInLogoSrc
    },
    {
        label: 'Youtube',
        alt: 'Youtube',
        iconSrc: YouTubeLogoSrc
    },
    {
        label: 'Facebook',
        alt: 'Facebook',
        iconSrc: FacebookLogoSrc
    }
];

export function Footer () {
    const renderMenuSection = (linkMenu: string[]) => {
        return (
            linkMenu.map((item) => (
                <ContentfulLink
                    contentName={item}
                    data-testid={item}
                    key={`linkId-${item}`}
                    sx={LinkStyles}
                    isExternal
                >
                    {item}
                </ContentfulLink>
            ))
        );
    };
    const renderSocialMediaSection = (socialMediaList: SocialMediaMenuData) => {
        return (
            socialMediaList.map((item) => (
                <ContentfulLink
                    title={item.label}
                    contentName={item.label}
                    key={`socialMediaImage-${item.label}`}
                    isExternal
                    mx={{
                        mobile: '8px',
                        footerTablet: '6px',
                        footerDesktop: '12px'
                    }}
                >
                    <Image
                        sx={LogoStyles}
                        src={item.iconSrc}
                        alt={item.alt}
                        fallback={<SkeletonCircle sx={LogoStyles} />}
                    />
                </ContentfulLink>
            ))
        );
    };

    return (
        <FooterContainer data-testid={'footer'}>
            <LinksAndImagesSection>
                <MenuSection>
                    {renderMenuSection(LINKS_MENU)}
                </MenuSection>

                <SocialMediaContainer>
                    <Text
                        sx={TextStyles}
                        data-testid={'Follow us'}
                        color={'neutral.600'}
                    >Follow us</Text>

                    {renderSocialMediaSection(SOCIAL_MEDIA_LINKS)}
                </SocialMediaContainer>
            </LinksAndImagesSection>

            <CopyrightsContainer>
                © 2020-2022 3P Learning. All Rights Reserved.
            </CopyrightsContainer>
        </FooterContainer>
    );
}
