/* istanbul ignore file */
import { useBoolean } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { useField, useFormikContext } from 'formik';
import { useState } from 'react';

import { resolveFieldsError } from './error-messages';
import { useChargebeeFieldStyles, useCheckoutFormRowStackMode } from './use-chargebee-field-styles';
import type {
    ChargebeeChangeData, ChargebeeFields, ChargebeeValidationFields, ValidationState
} from './use-credit-card-field.types';

export const initialValidationState: ValidationState = {
    isAllValid: true,
    isAllComplete: false,
    creditCardComplete: false,
    fields: {
        number: {
            isEmpty: true,
            isError: false,
            isComplete: false,
            error: null
        },
        expiry: {
            isEmpty: true,
            isError: false,
            isComplete: false,
            error: null
        },
        cvv: {
            isEmpty: true,
            isError: false,
            isComplete: false,
            error: null
        }
    }
};

type Options = {
    wrapperStyles?: SystemStyleObject
};

/**
 * Integration hook for Chargebee and Formik.
 *
 * @see https://mathletics.atlassian.net/browse/FRAME-5446
 */
export function useCreditCardField ({ wrapperStyles = {} }: Options) {
    const [isLoading, { off: onLoadingComplete }] = useBoolean(true);
    const [focus, setFocus] = useState<null | ChargebeeFields>(null);
    const isRowStackMode = useCheckoutFormRowStackMode();
    const [validationState, setValidationState] = useState<ValidationState>(
        initialValidationState
    );
    const formikContext = useFormikContext();
    const hasCalledSubmit = formikContext.submitCount > 0;
    const [_, { error: formikFieldError = null }, helpers] = useField({
        name: 'creditCardComplete'
    });
    const formFieldError = resolveFieldsError(
        validationState,
        formikFieldError,
        isRowStackMode,
        hasCalledSubmit
    );
    const onChange = (change: ChargebeeChangeData) => {
        const nextValidationState = combineValidationState(change, validationState);

        setValidationState(nextValidationState);

        // This updates the validation state of the formik form,
        // eg creditCardComplete flag represents all chargebee fields.
        helpers.setValue(nextValidationState.creditCardComplete, true);
    };
    const onReady = () => {
        onLoadingComplete();
    };
    const onFocus = (field: ChargebeeFields) => (e: any) => {
        setFocus(field);
    };
    const onBlur = (e: any) => {
        setFocus(null);
    };
    const styles = useChargebeeFieldStyles({
        wrapperStyles,
        validationState,
        focus,
        isLoading,
        formFieldError
    });

    return {
        onChange,
        onReady,
        onFocus,
        onBlur,
        formFieldError,
        isLoading,
        validationState,
        isRowStackMode,
        ...styles
    };
}

export function combineValidationState (
    change: ChargebeeChangeData,
    currentState: ValidationState
): ValidationState {
    currentState.fields[change.field as ChargebeeValidationFields] = {
        error: change.error || null,
        isError: Object.values(change.error || {}).length > 0,
        isComplete: change.complete,
        isEmpty: change.empty
    };

    const isValidAll = Object
        .values(currentState.fields)
        .every(field => field.isError === false);
    const isCompleteAll = Object
        .values(currentState.fields)
        .every(field => field.isComplete === true);

    return {
        fields: currentState.fields,
        isAllValid: isValidAll,
        isAllComplete: isCompleteAll,
        creditCardComplete: isValidAll && isCompleteAll
    };
}
