import React from 'react';

import { useSelectedProductIpQuery } from '../../hooks/selected-product/use-selected-product-ip-query';
import { CheckoutTermsPanelView } from './checkout-terms-panel-view';
import { CheckoutTermsPanelSkeleton } from './components/checkout-terms-panel-skeleton';

export const CheckoutTermsIpPanel = () => {
    const { data } = useSelectedProductIpQuery();

    if (!data) {
        return <CheckoutTermsPanelSkeleton />;
    }

    return (
        <CheckoutTermsPanelView
            data={data}
        />
    );
};
