import React from 'react';

import { Testimonial } from '../../fetch-static-content/contentful/testimonials';
import {
    RootWrapper,
    AuthorWrapper,
    ContentWrapper,
    AuthorLabel,
    TestimonialText
} from '../testimonial.styles';
import { BurstBackground } from './burst-background';
import { QuoteIcon } from './quote-icon';
import { SpeakerImage } from './speaker-image';

type Props = Testimonial;

export function TestimonialView ({
    testimonialText, authorFirstName, authorImageDesktop, authorImageMobile, authorType
}: Props) {
    return (
        <RootWrapper data-testid={'testimonial-view'}>
            <BurstBackground />

            <ContentWrapper>
                <TestimonialText>
                    {testimonialText}
                </TestimonialText>

                <AuthorWrapper>
                    <SpeakerImage
                        {...{
                            authorImageDesktop,
                            authorImageMobile
                        }}
                    />

                    <AuthorLabel data-testid={'testimonial-speaker'}>
                        <strong>{authorFirstName}</strong>, {authorType}
                    </AuthorLabel>
                </AuthorWrapper>

                <QuoteIcon />
            </ContentWrapper>
        </RootWrapper>
    );
}
