import React from 'react';

import { usePlansSalesforce } from '../../../plans/use-plans-salesforce';
import { Pricing } from '../../pricing';

type Props = {
    subscribeChosenRoute: string
};

export function PricingSalesforceUrlContract ({ subscribeChosenRoute }: Props) {
    const pricingProps = usePlansSalesforce();

    return (
        <Pricing
            {...pricingProps}
            subscribeChosenRoute={subscribeChosenRoute}
        />
    );
}
