import { TestContext } from 'yup';
import * as Yup from 'yup';

import { NEW_CARD_PAYMENT_SOURCE_ID } from '../../../payment-sources/components/new-card/new-card';

export function createExistingCustomerSchema () {
    return Yup.object().shape({
        customerPaymentSource: Yup.string().required().test(testNewOrExisting),
        creditCardComplete: Yup.boolean().required('Card Required')
    }).required('Required');

    function testNewOrExisting (this: TestContext, value: any) {
        if (value === NEW_CARD_PAYMENT_SOURCE_ID) {
            if (!this.parent.creditCardComplete) {
                return this.createError({
                    message: 'Credit card is required'
                });
            }
        }

        return true;
    }
}
