// mocking atcb_init in jest properly is out of scope
/* istanbul ignore file */
import { useMemo } from 'react';

import type { ProductItem } from '../../../../../../__generated__/gateway-graphql';

export type UseSubscriptionReminderOptions = {
    nextPaymentDate: string
    calenderReminderTitle: string
    calendarReminderDescription: string
};

export function useSubscriptionReminder (options: UseSubscriptionReminderOptions) {
    return useMemo(() => {
        return {
            google: createATCBData('google', options),
            outlook: createATCBData('outlook', options),
            ical: createATCBData('ical', options)
        };
    }, [options]);
}

function createATCBData (
    type: 'google' | 'outlook' | 'ical',
    {
        calenderReminderTitle, nextPaymentDate, calendarReminderDescription
    }: UseSubscriptionReminderOptions
) {
    const calObj = {
        name: calenderReminderTitle,
        startDate: getReminderDate(nextPaymentDate),
        endDate: getReminderDate(nextPaymentDate),
        trigger: 'click',
        iCalFileName: '3p-Subscription-Reminder-Event',
        label: '',
        options: [''],
        description: calendarReminderDescription
    };

    switch (type) {
    case 'ical':
        calObj.label = 'iCal';
        calObj.options = ['iCal'];
        break;
    case 'outlook':
        calObj.label = 'Outlook';
        calObj.options = ['Outlook.com'];
        break;
    case 'google':
        calObj.label = 'Google';
        calObj.options = ['Google'];
        break;
    }

    return calObj;
}

function getReminderDate (nextPaymentDate: Partial<ProductItem> | string) {
    let date = new Date(nextPaymentDate.toString());

    date = new Date(date.setDate(date.getDate() - 3));

    return new Date(date.toISOString());
}
