// eslint-disable-next-line import/order
import { startDataPrefetcher } from './prefetching';
// eslint-disable-next-line import/order
import { initGlobals } from './__utils__/init-globals';

// Intentionally run this before React renders the root
startDataPrefetcher();

/* istanbul ignore file */
// eslint-disable-next-line import/order
import { addBuildNumber } from './build-number';

addBuildNumber();

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './app/app';
import { ReactStrictMode } from './app/components/react-strict-mode';
import { ErrorBoundary } from './routes/error/errors/error-boundary';
import { initSentry } from './sentry/init';

initSentry();

initGlobals();

// Render the recommended React root:
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <ReactStrictMode>
        <ErrorBoundary context={'root.render'}>
            <App />
        </ErrorBoundary>
    </ReactStrictMode>
);
