/* istanbul ignore file */
import { RippleButton } from '@3plearning/chakra-mathletics-theme';
// Mocking Chargebee global is non-trivial
import { Text, VStack } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { Form, useFormikContext } from 'formik';
import React, { type MutableRefObject } from 'react';

import type { ChargebeeInstance } from '../../../chargebee/chargebee.types';
import { Coupon } from '../../../coupon/coupon';
import { CheckoutBackgrounds } from '../../checkout-backgrounds/checkout-backgrounds';
import { CheckoutPriceSalesforcePanel } from '../../checkout-price-panel/checkout-price-salesforce-panel';
import { CheckoutTermsSalesforcePanel } from '../../checkout-terms-panel/checkout-terms-salesforce-panel';
import { PaymentSources } from '../../payment-sources/payment-sources';

type Props = {
    cardRef: MutableRefObject<ChargebeeInstance>
};

export function ExistingCustomerCheckoutView ({ cardRef }: Props) {
    const { isSubmitting } = useFormikContext();

    return (
        <VStack
            as={Form}
            align={'start'}
            w={'100%'}
            maxW={'668px'}
            my={{
                mobile: '32px',
                tablet: '56px'
            }}
            spacing={{
                mobile: '24px',
                tablet: '56px'
            }}
        >
            <VStack
                w={'100%'}
                spacing={'32px'}
                align={'start'}
            >
                <VStack align={'start'} w={'100%'} spacing={'24px'} mb={'22px'}>
                    <Text textStyle={'formSectionHeading'}>
                        Payment Details
                    </Text>

                    <PaymentSources
                        cardRef={cardRef}
                    />
                </VStack>

                <VStack
                    mt={'8px !important'}
                    width={{ mobile: '100%' }}
                    align={'start'}
                >
                    <Coupon />
                </VStack>

                <VStack width={'100%'}>
                    <CheckoutPriceSalesforcePanel />
                </VStack>
            </VStack>

            <VStack>
                <CheckoutTermsSalesforcePanel />
            </VStack>

            <CheckoutBackgrounds />

            <RippleButton
                size={'large'}
                type={'submit'}
                data-testid={'submit-button'}
                isLoading={isSubmitting}
            >
                {t`Purchase`}
            </RippleButton>
        </VStack>
    );
}
