/* eslint-disable max-len */
import {
    chakra, useMediaQuery
} from '@chakra-ui/react';
import React from 'react';

export function BlueCircularBackground () {
    const [isMobileVersion, isTablet] = useMediaQuery([
        '(max-width: 375px)',
        '(max-width: 1304px)'
    ]);
    const size = isTablet ? '550px' : '746px';

    if (isMobileVersion) {
        return null;
    }

    return (
        <chakra.svg
            sx={{
                'position': 'absolute',
                'top': { desktop: '-256px' },
                'left': {
                    tablet: '-130px',
                    desktop: '-106px'
                },
                '@media screen and (max-width: 1304px)': { top: '-216px' }
            }}
            zIndex={'-1'}
            width= {size}
            height= {size}
            viewBox= {'0 0 746 746'}
            fill= {'none'}
            xmlns= {'http://www.w3.org/2000/svg'}
        >
            <g opacity={'0.1'} filter={'url(#filter0_b_1363_16791)'}>
                <ellipse cx={'472.544'} cy={'473.31'} rx={'247.715'} ry={'247.715'} transform={'rotate(-165.144 472.544 473.31)'} fill={'url(#paint0_linear_1363_16791)'} />
            </g>
            <g opacity={'0.1'} filter={'url(#filter1_b_1363_16791)'}>
                <circle cx={'507.329'} cy={'422.072'} r={'166.716'} transform={'rotate(130.848 507.329 422.072)'} fill={'url(#paint1_linear_1363_16791)'} />
            </g>
            <defs>
                <filter id={'filter0_b_1363_16791'} x={'109.769'} y={'110.535'} width={'725.553'} height={'725.551'} filterUnits={'userSpaceOnUse'} colorInterpolationFilters={'sRGB'}>
                    <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
                    <feGaussianBlur in={'BackgroundImage'} stdDeviation={'57.5'} />
                    <feComposite in2={'SourceAlpha'} operator={'in'} result={'effect1_backgroundBlur_1363_16791'} />
                    <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_backgroundBlur_1363_16791'} result={'shape'} />
                </filter>
                <filter id={'filter1_b_1363_16791'} x={'225.61'} y={'140.352'} width={'563.437'} height={'563.438'} filterUnits={'userSpaceOnUse'} colorInterpolationFilters={'sRGB'}>
                    <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
                    <feGaussianBlur in={'BackgroundImage'} stdDeviation={'57.5'} />
                    <feComposite in2={'SourceAlpha'} operator={'in'} result={'effect1_backgroundBlur_1363_16791'} />
                    <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_backgroundBlur_1363_16791'} result={'shape'} />
                </filter>
                <linearGradient id={'paint0_linear_1363_16791'} x1={'234.045'} y1={'554.776'} x2={'715.351'} y2={'530.106'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'#FFF8E3'} />
                    <stop offset={'1'} stopColor={'#A0D2FF'} />
                </linearGradient>
                <linearGradient id={'paint1_linear_1363_16791'} x1={'346.815'} y1={'476.899'} x2={'670.741'} y2={'460.296'} gradientUnits={'userSpaceOnUse'}>
                    <stop stopColor={'#FFF8E3'} />
                    <stop offset={'1'} stopColor={'#72D5FF'} />
                </linearGradient>
            </defs>
        </chakra.svg>
    );
}
