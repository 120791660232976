import { RippleButton, type RippleButtonProps } from '@3plearning/chakra-mathletics-theme';
import React, { type HTMLAttributeAnchorTarget } from 'react';

import { RippleButtonSkeleton } from './ripple-button-skeleton';
import { useContentfulUrl } from './use-contentful-url';

export type ContentfulUrlButtonProps =
    & RippleButtonProps
    & {
    contentfulUrlName: string
    target?: HTMLAttributeAnchorTarget
};

export function ContentfulUrlButton ({
    contentfulUrlName,
    target = '_self',
    onClick,
    ...restProps
}: ContentfulUrlButtonProps) {
    const url = useContentfulUrl(contentfulUrlName);
    const handleOnClick: RippleButtonProps['onClick'] = (event) => {
        url && open(url, '_self');
        onClick && onClick(event);
    };

    if (!url) {
        return (
            <RippleButtonSkeleton
                borderRadius={'20px'}
                height={'54px'}
                {...restProps}
            />
        );
    }

    return (
        <RippleButton
            data-testid={'contentful-url-button'}
            height={'54px'}
            {...restProps}
            onClick={handleOnClick}
        />
    );
}

