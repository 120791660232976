import { RippleButton } from '@3plearning/chakra-mathletics-theme';
import React from 'react';

import { useUrlQuery } from '../../api/contentful/urls/use-url-query';
import { RippleButtonSkeleton } from '../../components/contentful-url-button/ripple-button-skeleton';

export function HeaderLoginButton () {
    const query = useUrlQuery('Sign In');
    const href = typeof query.data?.url === 'string' ? query.data.url : undefined;

    if (href === undefined) {
        return (
            <RippleButtonSkeleton
                h={'40px'}
                w={'90px'}
                borderRadius={20}
            />
        );
    }

    return (
        <RippleButton
            data-testid={'logInOut-button'}
            variant={'secondary'}
            size={'medium'}
            onClick={() => open(href, '_self')}
        >
            Log in
        </RippleButton>
    );
}
