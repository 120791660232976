import { t } from '@lingui/macro';
import React from 'react';

import { SuccessView } from '../../../../checkout/success/success-view';

/**
 * Story https://mathletics.atlassian.net/browse/FRAME-5714
 */
export function AddSuccessRoute () {
    return (
        <SuccessView
            data-testid={'subscription-add-purchase-success-route'}
            headingText={t`Purchase successful`}
            bodyText={t`You’re almost there. Return to the Parent Dashboard and add your child.`}
            buttonText={t`Return to Parent Dashboard`}
        />
    );
}
