import {
    chakra, Box, Text
} from '@chakra-ui/react';

export const Container = chakra(Box, {
    baseStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        bg: {
            mobile: 'neutral.50',
            subscriptionInclusionsTablet: 'transparent'
        },
        border: {
            mobile: '6px solid white',
            subscriptionInclusionsTablet: 0
        },
        borderRadius: {
            mobile: '20px',
            subscriptionInclusionsTablet: 'none'
        },
        m: {
            mobile: '5px',
            subscriptionInclusionsTablet: '72px 0 0 0'
        },
        p: {
            mobile: '15px',
            subscriptionInclusionsTablet: 0
        }
    }
});

export const HeaderText = chakra(Box, {
    baseStyle: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '24px'
    }
});

export const CardWrapper = chakra(Box, {
    baseStyle: {
        'position': 'relative',
        'display': 'flex',
        'flexWrap': {
            mobile: 'wrap',
            desktop: 'nowrap'
        },
        'flexDirection': {
            mobile: 'column',
            subscriptionInclusionsTablet: 'row'
        },
        'justifyContent': {
            mobile: 'left',
            subscriptionInclusionsTablet: 'center'
        },
        'mb': {
            mobile: '12px',
            subscriptionInclusionsTablet: '32px'
        },
        'mt': {
            mobile: '12px',
            subscriptionInclusionsTablet: '20px'
        },
        'maxWidth': {
            mobile: '800px',
            desktop: '100%'
        },
        '@media screen and (max-width: 1304px)': {
            maxWidth: '800px',
            flexWrap: 'wrap'
        }
    }
});

export const RootWrapper = chakra(Box, {
    baseStyle: {
        position: 'relative',
        w: {
            mobile: '232px',
            subscriptionInclusionsTablet: '390px'
        },
        h: {
            mobile: '63px',
            subscriptionInclusionsTablet: '124px'
        }
    }
});

export const ContentWrapper = chakra(Box, {
    baseStyle: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: '20px',
        backgroundColor: {
            mobile: 'transparent',
            subscriptionInclusionsTablet: 'app.white'
        },
        borderWidth: {
            mobile: 0,
            subscriptionInclusionsTablet: '1px'
        },
        borderColor: {
            mobile: 'transparent',
            subscriptionInclusionsTablet: 'neutral.200'
        },
        borderStyle: 'solid'
    }
});

export const TrialDurationText = chakra(Text, {
    baseStyle: {
        zIndex: 1,
        width: '100%',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '150%',
        color: 'neutral.700',
        letterSpacing: '-0.1px',
        fontSize: '16px',
        p: {
            mobile: '0px 40px',
            subscriptionInclusionsTablet: '30px 40px'
        }
    }
});
