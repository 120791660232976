/* istanbul ignore file */
import React from 'react';
import {
    Route, Routes, Navigate
} from 'react-router-dom';

import { LoginRoute } from './login/login-route';
import { ResetPasswordRoute } from './reset-password/reset-password-route';
import { SetPasswordRoute } from './set-password/set-password-route';
import { VerifyEmailRoute } from './verify-email/verify-email-route';

export function AccountRoute () {
    return (
        <Routes>
            <Route
                path={'login'}
                element={<LoginRoute />}
            />

            <Route
                path={'reset-password'}
                element={<ResetPasswordRoute />}
            />

            <Route
                path={'set-password'}
                element={<SetPasswordRoute />}
            />

            <Route
                path={'verify-email'}
                element={
                    <VerifyEmailRoute />
                }
            />

            <Route
                path={'*'}
                element={<Navigate to={'/error/not-found'} />}
            />
        </Routes>
    );
}
